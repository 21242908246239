import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mediaQueries } from 'styles/utils/mediaQueries';
import flexbox from 'styles/utils/flexbox';

export const HeaderStyledCommon = styled.div`
  ${({ centerHeaderMenu }) =>
    flexbox({
      justifyContent: centerHeaderMenu ? 'center' : 'space-between',
      alignItems: 'center',
    })}

  position: relative;
  height: ${({ customHeaderHeight, isHotelPage, isCheckoutPage }) =>
    customHeaderHeight ? customHeaderHeight : isHotelPage || isCheckoutPage ? '50px' : '72px'};
  z-index: 4;
  background: ${({ background, theme }) => (background ? theme.colors[background[0]][background[1]] : 'none')};

  ${mediaQueries.below.l`
    padding-right: 0;
    height:unset;
    min-height:${({ customHeaderHeight }) => (customHeaderHeight ? customHeaderHeight : '72px')};

    ${({ isAccountBooking, isNorwegian }) =>
      isAccountBooking &&
      !isNorwegian &&
      `
      padding: 16px 32px 0;
    `}
  `}
`;

export const HeaderLeftCommon = styled.div`
  ${({ justifyContent }) =>
    flexbox({
      alignItems: 'center',
      justifyContent: justifyContent ? justifyContent : 'initial',
    })}

  flex: 1 1 0;
  position: relative;
  height: 100%;
`;

export const LogoWraperCommon = styled.div`
  display: inline-block;
  padding: 0 16px;

  a {
    margin-right: 0;
  }

  ${mediaQueries.fromAndBelow.tablet`
    padding: 0;

    a {
      display: block;
    }
  `}

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-width: 80px;
  }
`;

export const LogoWraperCommonNorwegian = styled(LogoWraperCommon)`
  position: relative;
  padding: 0 7px 0 0;
  background-color: white;

  &:after {
    content: '';
    display: block;
    width: 4px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.secondary.default};
  }

  &:before {
    content: '';
    display: block;
    width: 3px;
    height: 100%;
    position: absolute;
    right: 4px;
    top: 0;
    background-color: #ffffff;
  }

  a {
    ${flexbox({
      flexDirection: 'column',
      justifyContent: 'center',
    })}

    height: 100%;
  }

  ${mediaQueries.fromAndBelow.tablet`
    padding: 0 7px 0 0;

    a {
      height: initial;
    }
  `}
`;

export const LogoWraperAirMalta = styled(LogoWraperCommon)`
  position: relative;
  padding: 0 7px 0 0;
  background-color: white;

  a {
    ${flexbox({
      flexDirection: 'column',
      justifyContent: 'center',
    })}
  }

  ${mediaQueries.fromAndBelow.tablet`
    padding: 0 7px 0 0;

    a {
      height: initial;
    }
  `}
`;

export const LogoWrapperCommonAirBaltic = styled.div`
  position: relative;
  padding: 0 7px 0 0;
  background-color: white;

  a {
    ${flexbox({
      flexDirection: 'column',
      justifyContent: 'center',
    })}
  }

  ${mediaQueries.fromAndBelow.tablet`
    padding: 0 7px 0 0;

    a {
      height: initial;
    }
  `}
`;

export const LogoWrapperCommonHillmanTravel = styled.div`
  position: relative;
  padding: 0 7px 0 0;
  background-color: white;

  a {
    ${flexbox({
      flexDirection: 'column',
      justifyContent: 'center',
    })}
  }

  ${mediaQueries.fromAndBelow.tablet`
  padding: 0 7px 0 0;

  a {
    height: initial;
  }
`}
`;

export const LogoWrapperCommonCyprus = styled.div`
  position: relative;
  padding: 0 7px 0 10px;
  background-color: white;

  a {
    ${flexbox({
      flexDirection: 'column',
      justifyContent: 'center',
    })}
  }

  ${mediaQueries.fromAndBelow.tablet`
  padding: 0 7px 0 10px;

  a {
    height: initial;
  }
`}
`;

export const HamburgerButtonCommon = (props) => (
  <HamburgerButton {...props}>
    <FontAwesomeIcon icon='bars' />
  </HamburgerButton>
);

const HamburgerButton = styled.button`
  display: none;
  height: 100%;
  width: 42px;
  margin: ${({ margin }) => margin};
  background: none;
  border: none;
  font-size: 26px;
  color: ${({ theme, dark }) => (dark ? theme.colors.primary.dark : theme.colors.text.white)};
  cursor: pointer;

  ${({ forMedia }) =>
    forMedia &&
    css`
      ${mediaQueries[forMedia[0]][forMedia[1]]`
      display: block;
    `}
    `}
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NEW_COOKIECONSENT_STATUS } from 'constants/cookiesKeys';
//THIS FILE IS DEPRECATED
// import CookieConsentComponent from './CookieConsentComponent';
import getCookie from 'utils/cookie/getCookie';
import setCookie from 'utils/cookie/setCookie';
import NewCookieConsentComponent from './NewCookieConsentComponent';
import trackCookieEvent from 'managers/gtmEventTracking/utils/trackCookieEvent';
import { setCookieBanner } from 'state/cookie/actions';

class CookieConsent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adjustSettings: false,
      cookieConsents: {
        necessary: true,
        analytics: true,
        marketing: false,
      },
    };
    this.handleOnClickAcceptAll = this.handleOnClickAcceptAll.bind(this);
    this.handleOnClickAdjustSettings = this.handleOnClickAdjustSettings.bind(this);
    this.handleOnClickBack = this.handleOnClickBack.bind(this);
    this.handleOnToggleAnalytics = this.handleOnToggleAnalytics.bind(this);
    this.handleOnToggleMarketing = this.handleOnToggleMarketing.bind(this);
    this.handleOnSaveSettings = this.handleOnSaveSettings.bind(this);
  }

  componentDidMount() {
    const { modalIframe, checkoutOnlyWhitelabel, setCookieBanner } = this.props;
    if ((modalIframe && modalIframe === '1') || checkoutOnlyWhitelabel) {
      return;
    }

    if (!getCookie(NEW_COOKIECONSENT_STATUS)) {
      setCookieBanner(true);
    }
  }

  handleOnClickAcceptAll() {
    const { setCookieBanner } = this.props;
    this.setState(
      (prevState) => ({
        adjustSettings: false,
        cookieConsents: {
          necessary: true,
          analytics: true,
          marketing: true,
        },
      }),
      () => {
        setCookie(NEW_COOKIECONSENT_STATUS, JSON.stringify(this.state.cookieConsents), 365);
        setCookieBanner(false);
        trackCookieEvent();
      }
    );
  }

  handleOnClickAdjustSettings() {
    this.setState({ adjustSettings: true });
  }
  handleOnClickBack() {
    this.setState({ adjustSettings: false });
  }
  handleOnToggleAnalytics(event) {
    const isChecked = event.target.checked;
    this.setState((prevState) => ({
      cookieConsents: {
        ...prevState.cookieConsents,
        analytics: isChecked,
      },
    }));
  }
  handleOnToggleMarketing(event) {
    const isChecked = event.target.checked;
    this.setState((prevState) => ({
      cookieConsents: {
        ...prevState.cookieConsents,
        marketing: isChecked,
      },
    }));
  }
  handleOnSaveSettings() {
    const { setCookieBanner } = this.props;
    const notAllAccepted = !this.state.cookieConsents.analytics || !this.state.cookieConsents.marketing;
    const expirationDays = notAllAccepted ? 30 : 365;
    setCookie(NEW_COOKIECONSENT_STATUS, JSON.stringify(this.state.cookieConsents), expirationDays);
    trackCookieEvent();
    setCookieBanner(false);
  }

  render() {
    const { adjustSettings, cookieConsents } = this.state;
    const { analytics, marketing } = cookieConsents;
    const { displayCookieBanner, isCountrySelectionPage, isHolidayPirates } = this.props;

    if (!displayCookieBanner || isCountrySelectionPage) {
      return null;
    }

    const cookie = getCookie(NEW_COOKIECONSENT_STATUS) && JSON.parse(getCookie(NEW_COOKIECONSENT_STATUS));
    const defaultAnalytics = cookie ? cookie.analytics : analytics;
    const defaultMarketing = cookie ? cookie.marketing : marketing;

    return (
      <NewCookieConsentComponent
        onClickAcceptAll={this.handleOnClickAcceptAll}
        onClickAdjustSettings={this.handleOnClickAdjustSettings}
        onClickBack={this.handleOnClickBack}
        adjustSettings={adjustSettings}
        onToggleAnalytics={this.handleOnToggleAnalytics}
        onToggleMarketing={this.handleOnToggleMarketing}
        onSaveSettings={this.handleOnSaveSettings}
        defaultAnalytics={defaultAnalytics}
        defaultMarketing={defaultMarketing}
        isHolidayPirates={isHolidayPirates}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    modalIframe: state.urlSearchQuery.value.modal_iframe,
    checkoutOnlyWhitelabel: state.settings.value.checkoutOnlyWhitelabel,
    displayCookieBanner: state.cookie.displayCookieBanner,
    isCountrySelectionPage: state.currentPageComponent.isCountrySelection,
    isHolidayPirates: state.settings.value.customSettings && state.settings.value.customSettings.isHolidayPirates,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setCookieBanner(displayCookieBanner) {
      return dispatch(setCookieBanner(displayCookieBanner));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieConsent);

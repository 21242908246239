import React from 'react';
import styled from 'styled-components';
import InfiniteCircleDots from 'components/spinners/InfiniteCircleDots';

const CalendarLoading = () => (
  <LoadingRoot>
    <SpinnerWrap>
      <InfiniteCircleDots removeMargin useAdjacentColor />
    </SpinnerWrap>
  </LoadingRoot>
);

export default CalendarLoading;

// Styled components
const LoadingRoot = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  backdrop-filter: blur(2px);
  z-index: 3;
`;
const SpinnerWrap = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

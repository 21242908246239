import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

export default function Sliders({ type, handleOnScroll }) {
  return (
    <Root type={type}>
      <Button type={type} onClick={() => handleOnScroll(type)}>
        <FontAwesomeIcon icon={`chevron-${type}`} />
      </Button>
    </Root>
  );
}

const Root = styled.div(({ type }) => ({
  background: `linear-gradient(to ${
    type === 'left' ? 'right' : 'left'
  }, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))`,
  width: '46px',
  height: '80%',
  position: 'absolute',
  left: type === 'left' ? '0' : 'unset',
  right: type === 'right' ? '0' : 'unset',
  top: 10,
  bottom: 0,
  margin: 'auto 0',
  alignContent: 'center',

  '@media (max-width: 1024px)': {
    display: 'none',
  },
}));

const Button = styled('button')(({ theme, type }) => ({
  height: '30px',
  width: '30px',
  border: 'none',
  background: theme.colors.adjacent.default,
  color: theme.colors.buttons.primary.text,
  cursor: 'pointer',
  float: type,
  borderRadius: '6px',
}));

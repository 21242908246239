import axis from 'axis.js';
import React from 'react';

export function setSelectedChildren(children) {
  //Separating selected & notSelected options from react-select, the children/options can be type 'option' or 'group' ( origin, destinations );
  if (children && axis.isArray(children)) {
    const [selected, notSelected] = children.reduce(
      (acc, child) => {
        if (child.props.type === 'option') {
          acc[child.props.isSelected ? 0 : 1].push(child);
        } else {
          const groupedOptions = child.props.children.reduce(
            (groupAcc, option) => {
              groupAcc[option.props.isSelected ? 0 : 1].push(option);
              return groupAcc;
            },
            [[], []]
          );

          groupedOptions[0].length > 0 &&
            acc[0].push(React.cloneElement(child, { key: child.props.label }, ...groupedOptions[0]));

          groupedOptions[1].length > 0 &&
            acc[1].push(React.cloneElement(child, { key: child.props.label }, ...groupedOptions[1]));
        }

        return acc;
      },
      [[], []]
    );

    return {
      selectedOptions: selected,
      notSelectedOptions: notSelected,
    };
  }
}

import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useDispatch } from 'react-redux';

import Heart from 'components/Heart';
import { mediaQueries } from 'styles/utils/mediaQueries';
import { SAVED_PACKAGES as SAVED_PACKAGES_PAGE } from 'constants/pages';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import { CLICKED_MY_FAVORITES } from 'managers/gtmEventTracking/constants/eventsNames';

function NumberedHeartComponent({ number, pathToSavedPackages, isFrontpage, isHotelPage, isCheckoutPage }) {
  const dispatch = useDispatch();

  return (
    <Root
      isFrontpage={isFrontpage}
      isCheckoutPage={isCheckoutPage}
      role='button'
      onClick={() => {
        dispatch(executeTrackingEvent(CLICKED_MY_FAVORITES, { eventValue: number }));
      }}
      isHotelPage={isHotelPage}
    >
      <Link href={SAVED_PACKAGES_PAGE} as={pathToSavedPackages}>
        <A>
          <Heart static isFrontpage={isFrontpage} isHotelPage={isHotelPage} isCheckoutPage={isCheckoutPage} />
          <HeartNumber isFrontpage={isFrontpage}>{number}</HeartNumber>
        </A>
      </Link>
    </Root>
  );
}

export default NumberedHeartComponent;

const A = styled('a')({});

const Root = styled.div`
  position: relative;
  width: ${({ isFrontpage, isHotelPage, isCheckoutPage }) =>
    isFrontpage ? '30px' : isHotelPage || isCheckoutPage ? '34px' : '42px'};
  height: ${({ isFrontpage, isHotelPage, isCheckoutPage }) =>
    isFrontpage ? '30px' : isHotelPage || isCheckoutPage ? '34px' : '48px'};
  cursor: pointer;

  ${mediaQueries.below.l`
   margin-top: ${({ isFrontpage }) => (isFrontpage ? '0px' : '40px')};
   height: ${({ isFrontpage }) => (isFrontpage ? '30px' : '0px')};;
`}
`;

const HeartNumber = styled.p`
  position: absolute;
  top: ${({ isFrontpage }) => (isFrontpage ? '4px' : '-15%')};
  left: ${({ isFrontpage }) => (isFrontpage ? '0px' : '76%')};
  border-radius: ${({ isFrontpage }) => (isFrontpage ? '0' : '50%')};
  width: ${({ isFrontpage }) => (isFrontpage ? '100%' : '16px')};
  background-color: ${({ theme, isFrontpage }) =>
    isFrontpage ? 'transparent' : (theme.colors.heart && theme.colors.heart.count) || theme.colors.adjacent.default};
  color: ${({ theme }) => theme.colors.buttons.primary.text};
  font-weight: 600;
  text-align: center;
  font-size: 12px;
`;

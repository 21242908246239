import * as fetchingStatus from 'constants/status/fetchingStatus';
import { getHotelQuerySearch } from 'httpClient/requests/hotelQuerySearch';
import { setFetching, setInputValue, setResults } from '../actions';

export default function fetchHotelQuerySearch(value) {
  return async (dispatch, getState) => {
    try {
      const { fetching } = getState().hotelQuerySearch;

      if (fetching === fetchingStatus.IN_PROGRESS) {
        return;
      }

      dispatch(setFetching(fetchingStatus.IN_PROGRESS));
      dispatch(setInputValue(value));

      const { data } = await getHotelQuerySearch(value);

      dispatch(setResults(data));
      dispatch(setFetching(fetchingStatus.SUCCEEDED));
    } catch (error) {
      dispatch(setFetching(fetchingStatus.FAILED));
      window.Rollbar.error(`Unexpected error on fetchHotelQuerySearch
      ${JSON.stringify(error)}
      `);
    }
  };
}

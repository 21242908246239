import fontDefinitions from 'styles/fonts/definitions';
import { updateFonts } from '../actions';
import websiteIds from 'constants/website.config';

export default function loadFonts() {
  return (dispatch, getState) => {
    const { airlineWhitelabel, whitelabel, websiteId, customSettings } = getState().settings.value;
    let fontKey = null;
    const fontKeySettings = customSettings && customSettings.fontKey;

    if (
      [
        websiteIds['Hillman Travel EU'].ID,
        websiteIds['Hillman Travel NL'].ID,
        websiteIds['BE Hillman Travel FR'].ID,
        websiteIds['BE Hillman Travel NL'].ID,
        websiteIds['Hillman Travel FR'].ID,
      ].includes(websiteId)
    ) {
      fontKey = 'hillman.travel';
    } else if (websiteIds['Amano Holidays'].ID === websiteId) {
      fontKey = 'amano.holidays';
    } else if (websiteIds['Offerilla Holidays'].ID === websiteId) {
      fontKey = 'matkat.offerilla';
    } else {
      fontKey = airlineWhitelabel ? airlineWhitelabel.hostname : whitelabel ? whitelabel : fontKeySettings;
    }

    if (fontKey && fontKey !== 'vacante.vola.ro') {
      import(`styles/fonts/fonts.${fontKey}.scss`)
        .then(() => {
          const fonts = fontDefinitions[fontKey];
          dispatch(updateFonts(fonts));
        })
        .catch((error) => {
          console.error('Failed to load font: ', `styles/fonts/fonts.${fontKey}.scss`);
          window.Rollbar.error(`Failed to load font: styles/fonts/fonts.${fontKey}.scss`, fontKey);
        });
    }
  };
}

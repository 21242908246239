import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import MenuList from 'components/menus/menuList/MenuListComponent';
import OverflowMenu from './components/overflowMenu/OverflowMenu';
import NumberedHeart from './components/numberedHeart/NumberedHeart';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  menuContent: PropTypes.arrayOf(
    PropTypes.shape({
      menuOrder: PropTypes.number,
      slug: PropTypes.string,
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  isHamburger: PropTypes.bool,
  isLightBackground: PropTypes.bool,
};

function HeaderMenuComponent({
  menuContent,
  isHamburger,
  isLightBackground,
  transitionToCheckout,
  showForMedia,
  airlineWhitelabel,
  thinMenu,
  isFrontpage,
  isHotelPage,
  isCheckoutPage,
}) {
  return (
    <Root
      isHamburger={isHamburger}
      isLightBackground={isLightBackground}
      transitionToCheckout={transitionToCheckout}
      showForMedia={showForMedia}
      airlineWhitelabel={airlineWhitelabel}
      thinMenu={thinMenu}
    >
      {isHamburger ? (
        <>
          <NumberedHeart isFrontpage={isFrontpage} isHotelPage={isHotelPage} isCheckoutPage={isCheckoutPage} />
          <OverflowMenu content={menuContent} isHotelPage={isHotelPage} isCheckoutPage={isCheckoutPage} />
        </>
      ) : (
        <>
          <MenuList items={menuContent} isHotelPage={isHotelPage} isCheckoutPage={isCheckoutPage} />
          <NumberedHeart isFrontpage={isFrontpage} isHotelPage={isHotelPage} isCheckoutPage={isCheckoutPage} />
        </>
      )}
    </Root>
  );
}

HeaderMenuComponent.propTypes = propTypes;

export default HeaderMenuComponent;

const Root = styled.div`
  ${flexbox({
    alignItems: 'center',
  })}
  flex: 0 1 auto;
  gap: 10px;

  position: relative;
  left: 0;
  visibility: ${({ transitionToCheckout }) => (transitionToCheckout ? 'hidden' : 'visible')};
  display: none;
  height: 100%;
  transition: left 0.2s ease-in;

  a {
    color: ${({ isHamburger, isLightBackground, theme }) =>
      isHamburger
        ? theme.colors.text.default
        : isLightBackground
        ? theme.colors.primary.dark
        : theme.colors.text.white};
    font-weight: ${({ thinMenu }) => (thinMenu ? 400 : 600)};
    font-size: ${({ thinMenu }) => (thinMenu ? '16px' : '18px')};
    margin-right: 1rem;
    text-shadow: ${({ isHamburger, isLightBackground }) =>
      isHamburger || isLightBackground ? 'none' : '0px 0px 40px rgba(0,0,100,0.75)'};
  }

  ${({ showForMedia }) =>
    showForMedia &&
    css`
      ${mediaQueries[showForMedia[0]][showForMedia[1]]`
      display: flex;
    `}
    `}
`;

import proxy from 'httpClient/requests';

export function hybridCheckout(body) {
  return proxy({
    httpMethod: 'POST',
    version: 3,
    apiMethod: 'hybridCheckout/initiate',
    body,
  });
}

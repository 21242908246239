import styled, { keyframes } from 'styled-components';

export default styled.div`
  position: relative;
  color: ${({ useAdjacentColor, theme }) =>
    useAdjacentColor ? theme.colors.adjacent.default : theme.colors.primary.default};
  font-size: 10px;
  margin: ${({ removeMargin }) => !removeMargin && '75px 75px 0 auto'};
  width: 10px;
  height: 10px;
  border-radius: 50%;

  text-indent: -9999em;
  animation: ${({ tiny }) => (tiny ? loadTiny : loadMedium)} 1.3s infinite linear;
  transform: translateZ(0);
`;

const loadTiny = keyframes`
  0%, 100% {
    box-shadow:
      0 -1.7em 0 0.1em,
      1.2em -1.2em 0 0em,
      1.7em 0 0 -0.8em,
      1.2em 1.2em 0 -0.8em,
      0 1.7em 0 -0.8em,
      -1.2em 1.2em 0 -0.8em,
      -1.7em 0 0 -0.8em,
      -1.2em -1.2em 0 0;
  }
  12.5% {
    box-shadow:
      0 -1.7em 0 0,
      1.2em -1.2em 0 0.1em,
      1.7em 0 0 0,
      1.2em 1.2em 0 -0.8em,
      0 1.7em 0 -0.8em,
      -1.2em 1.2em 0 -0.8em,
      -1.7em 0 0 -0.8em,
      -1.2em -1.2em 0 -0.8em;
  }
  25% {
    box-shadow:
      0 -1.7em 0 -0.4em,
      1.2em -1.2em 0 0,
      1.7em 0 0 0.1em,
      1.2em 1.2em 0 0,
      0 1.7em 0 -0.8em,
      -1.2em 1.2em 0 -0.8em,
      -1.7em 0 0 -0.8em,
      -1.2em -1.2em 0 -0.8em;
  }
  37.5% {
    box-shadow:
      0 -1.7em 0 -0.8em,
      1.2em -1.2em 0 -0.8em,
      1.7em 0em 0 0,
      1.2em 1.2em 0 0.1em,
      0 1.7em 0 0em,
      -1.2em 1.2em 0 -0.8em,
      -1.7em 0em 0 -0.8em,
      -1.2em -1.2em 0 -0.8em;
  }
  50% {
    box-shadow:
      0 -1.7em 0 -0.8em,
      1.2em -1.2em 0 -0.8em,
      1.7em 0 0 -0.8em,
      1.2em 1.2em 0 0em,
      0 1.7em 0 0.1em,
      -1.2em 1.2em 0 0,
      -1.7em 0em 0 -0.8em,
      -1.2em -1.2em 0 -0.8em;
  }
  62.5% {
    box-shadow:
      0 -1.7em 0 -0.8em,
      1.2em -1.2em 0 -0.8em,
      1.7em 0 0 -0.8em,
      1.2em 1.2em 0 -0.8em,
      0 1.7em 0 0,
      -1.2em 1.2em 0 0.1em,
      -1.7em 0 0 0,
      -1.2em -1.2em 0 -0.8em;
  }
  75% {
    box-shadow:
      0em -1.7em 0 -0.8em,
      1.2em -1.2em 0 -0.8em,
      1.7em 0em 0 -0.8em,
      1.2em 1.2em 0 -0.8em,
      0 1.7em 0 -0.8em,
      -1.2em 1.2em 0 0,
      -1.7em 0em 0 0.1em,
      -1.2em -1.2em 0 0;
  }
  87.5% {
    box-shadow:
      0em -1.7em 0 0,
      1.2em -1.2em 0 -0.8em,
      1.7em 0 0 -0.8em,
      1.2em 1.2em 0 -0.8em,
      0 1.7em 0 -0.8em,
      -1.2em 1.2em 0 0,
      -1.7em 0em 0 0,
      -1.2em -1.2em 0 0.1em;
  }
`;

const loadMedium = keyframes`
  0%, 100% {
    box-shadow:
      0 -3em 0 0.2em,
      2em -2em 0 0em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 0;
  }
  12.5% {
    box-shadow:
      0 -3em 0 0,
      2em -2em 0 0.2em,
      3em 0 0 0,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow:
      0 -3em 0 -0.5em,
      2em -2em 0 0,
      3em 0 0 0.2em,
      2em 2em 0 0,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0em 0 0,
      2em 2em 0 0.2em,
      0 3em 0 0em,
      -2em 2em 0 -1em,
      -3em 0em 0 -1em,
      -2em -2em 0 -1em;
  }
  50% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 0em,
      0 3em 0 0.2em,
      -2em 2em 0 0,
      -3em 0em 0 -1em,
      -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 0,
      -2em 2em 0 0.2em,
      -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow:
      0em -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0em 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0em 0 0.2em,
      -2em -2em 0 0;
  }
  87.5% {
    box-shadow:
      0em -3em 0 0,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
`;

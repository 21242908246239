export default function getOtherExtrasProductForBookingAndPayments(selectedOtherExtras, data) {
  const {
    transfer: selectedTransfer,
    vehicleRentals: selectedVehicleRentals,
    cancellationProtection: selectedCancellationProtection,
    insurance: selectedInsurance,
    co2Compensation,
    earthToday,
  } = selectedOtherExtras;
  const { checkoutTransfers, selectedFlightsCombinations } = data;
  const checkoutTransfer =
    selectedTransfer && checkoutTransfers.find(({ productToken }) => productToken === selectedTransfer);
  const transfers =
    selectedTransfer && checkoutTransfer
      ? {
          searchToken: checkoutTransfer.searchToken,
          productToken: checkoutTransfer.productToken,
        }
      : null;

  if (selectedTransfer && selectedFlightsCombinations && transfers) {
    const leaveFlights = selectedFlightsCombinations[0].legs[0].segments;
    const returnFlights = selectedFlightsCombinations[0].legs[1].segments;

    transfers.leaveFlightNumber = leaveFlights[leaveFlights.length - 1].flightNumber;
    transfers.returnFlightNumber = returnFlights[0].flightNumber;
  }

  return {
    transfers: transfers ? [transfers] : null,
    carRentals: selectedVehicleRentals,
    cancellationProtections: selectedCancellationProtection ? [{ productToken: selectedCancellationProtection }] : null,
    insurances: selectedInsurance ? [{ productToken: selectedInsurance }] : null,
    co2Compensation: co2Compensation ? true : false,
    earthTodayToken: earthToday,
  };
}

import Router from 'next/router';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { getContent } from 'httpClient/requests/content';
import * as fetchingStatus from 'constants/status/fetchingStatus';
import { NOT_FOUND as ROUTE_KEY_NOT_FOUND } from 'constants/routesKeys';
import { NOT_FOUND as PAGE_NOT_FOUND, TEMPLATE as PAGE_TEMPLATE } from 'constants/pages';
import { getRoutePathByRouteKey } from 'state/routes/operations';
import { setFetching, setResolverData, setRedirect } from '../actions';
import getUrlPartsFromString from 'utils/string/getUrlPartsFromString';
import log from 'state/logger/operations/log';
import { CONSOLE as LOGGER_TYPE_CONSOLE } from 'state/logger/constants/loggerType';
import { WARN as LOG_TYPE_WARN } from 'state/logger/constants/logType';

export default function fetchTemplateData(pathname) {
  return async (dispatch, getState) => {
    const { template, settings, routes } = getState();
    const { airlineWhitelabel } = settings.value;
    const { selectedCountry } = routes;

    // NextJS's Router.asPath returns the pathname with query, and we need just the pathname
    pathname = pathname.split('?')[0];

    if (airlineWhitelabel && selectedCountry) {
      const indexOfSelectedLanguage = pathname.indexOf(`${selectedCountry}/`);
      if (indexOfSelectedLanguage === 0 || indexOfSelectedLanguage === 1) {
        // NextJS's Router.asPath returns the pathname without a starting slash
        pathname = pathname.replace(selectedCountry, '').replace('//', '/');
      }
    }

    if (template.slug && decodeURI(template.slug).replace(/\//g, '') === decodeURI(pathname).replace(/\//g, '')) {
      return;
    }

    dispatch(setFetching(fetchingStatus.IN_PROGRESS));
    dispatch(showLoading());

    // Replace trailing slash in pathname
    pathname = pathname.replace(/\/+$/, '');

    if(pathname.includes("undefined")){
      dispatch(
        log({
          loggerType: LOGGER_TYPE_CONSOLE,
          logType: LOG_TYPE_WARN,
          message: `Undefined in fetchTemplateData.js.`,
          args: { 
            slug: template.slug, 
            pathname
           },
        })
      );
    }

    try {
      const { data } = await getContent(pathname);

      if (data && data.length === 0) {
        const pathToNotFound = dispatch(getRoutePathByRouteKey(ROUTE_KEY_NOT_FOUND));
        Router.push(PAGE_NOT_FOUND, pathToNotFound);
        dispatch(hideLoading());
        dispatch(setFetching(fetchingStatus.SUCCEEDED));
        return;
      }

      if (data && data.length === undefined && data.redirection) {
        const redirectPathname = getUrlPartsFromString(data.redirection.url).pathname;
        Router.push(PAGE_TEMPLATE, redirectPathname);
        dispatch(setRedirect(redirectPathname));
        dispatch(hideLoading());
        dispatch(setFetching(fetchingStatus.SUCCEEDED));
        return;
      }

      dispatch(setResolverData(data[0]));
      dispatch(hideLoading());
      dispatch(setFetching(fetchingStatus.SUCCEEDED));
      return data[0];
    } catch (error) {
      console.error(error);
      dispatch(setFetching(fetchingStatus.FAILED));
    }
  };
}

import React from 'react';
import styled from 'styled-components';
import ScrollIndicator from 'components/indicators/scrollIndicator/ScrollIndicatorComponent';
import DestinationsStage from '../common/DestinationsStageComponent';

function StageDestinationsComponent({ destinations }) {
  return (
    <Root>
      <div>{destinations}</div>
      {destinations.length > 8 && (
        <ScrollIndicatorWrap>
          <ScrollIndicator />
        </ScrollIndicatorWrap>
      )}
    </Root>
  );
}

export default StageDestinationsComponent;

const Root = styled(DestinationsStage)`
  position: relative;
  width: 40%;
  background-color: ${({ theme }) => theme.colors.background.greyV3};
  border-radius: 8px;
  box-shadow: 0px 0px 4px 1px rgb(0, 0, 0, 0.07);
`;

const ScrollIndicatorWrap = styled.div`
  position: sticky;
  bottom: 5px;
  top: 0;
  display: flex;
  justify-content: flex-end;
  width: 20px;
  margin-left: auto;
`;

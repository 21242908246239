import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'i18n';
import { mediaQueries } from 'styles/utils/mediaQueries';
import truncate from 'styles/utils/truncate';

function SingleValue({ children, ...props }) {
  const { data } = props.data;

  if (data && data.translation) {
    props.data.label = (
      <FormattedMessage
        id={data.translation.id}
        description={data.translation.description}
        defaultMessage={data.translation.defaultMessage}
      >
        {(text) => text}
      </FormattedMessage>
    );
  }

  return (
    <components.SingleValue {...props}>
      <Container isFrontpage={props.selectProps.data.isFrontpage}>
        {props.selectProps.data.isNarrow && (
          <Description>
            <FormattedMessage id={'origin.label'} description={'Origin'} defaultMessage={'Origin'} />
          </Description>
        )}
        <Origins>{children || props.data.label}</Origins>
        {props.isSelected && <Icon icon={'check-circle'} />}
      </Container>
    </components.SingleValue>
  );
}

export default SingleValue;

const Origins = styled.div`
  padding: 4px 0px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.darkGrey};
  ${truncate()}
`;

const Description = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.adjacent.default};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-top: ${({ isFrontpage }) => (isFrontpage ? '8px' : 0)};
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.inputs.radio.backgroundChecked};

  ${mediaQueries.fromAndAbove.desktop`
    display: none;
  `};
`;

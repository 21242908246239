import * as stepsKeys from 'state/checkoutPage/wizard/constants/stepsKeys';
import * as summariesTypes from '../constants/summariesTypes';
import { setCurrentlyOpenSummaries } from '../actions';

export default function updateCurrentlyOpenSummariesByStepId(stepId) {
  return (dispatch, getState) => {
    let summaryType, id, exclusive;

    switch (stepId) {
      case stepsKeys.SELECT_ROOMS:
        summaryType = summariesTypes.HOTEL;
        id = getState().checkoutPagePackagesSearch.value.hotels[0].id;
        exclusive = true;
        break;

      case stepsKeys.SELECT_FLIGHT:
        summaryType = summariesTypes.FLIGHT;
        // id = getState().checkoutPageTravelSummary.selectedFlights[0];
        break;

      case stepsKeys.SELECT_FLIGHT_EXTRAS:
        summaryType = summariesTypes.FLIGHT_EXTRAS;
        break;

      case stepsKeys.SELECT_OTHER_EXTRAS:
        summaryType = summariesTypes.OTHER_EXTRAS;
        break;

      case stepsKeys.PERSONAL_DATA:
      case stepsKeys.PAY_AND_BOOK:
        break;

      default:
        break;
    }

    const currentlyOpenSummaries = summaryType !== undefined ? [{ summaryType, id, exclusive }] : [];

    dispatch(setCurrentlyOpenSummaries(currentlyOpenSummaries));
  };
}

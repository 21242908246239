import React from 'react';
import styled from 'styled-components';
import AbandonedCart from './components/abandonedCart/AbandonedCart';

function AbandonedCartsComponent({ abandonedCarts }) {
  return (
    <Root>
      {abandonedCarts.map((item) => (
        <AbandonedCart key={item.uuid} {...item} />
      ))}
    </Root>
  );
}

export default AbandonedCartsComponent;

const Root = styled.div``;

import Select from 'react-select';
import React from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
const { SingleValue, Option } = components;

const IconSingleValue = (props) => (
  <SingleValue {...props}>
    <img
      alt=''
      src={props.data.flagImage}
      style={{
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        border: '1px solid hsl(0, 0%, 80%)',
      }}
    />
  </SingleValue>
);

const IconOption = (props) => (
  <Option {...props}>
    <a href={props.data.url} rel='noopener noreferrer' target='_blank'>
      <img
        src={props.data.flagImage}
        style={{ height: '30px', width: '30px', marginRight: '5px', borderRadius: '50%' }}
        alt=''
      />
      <p style={{ color: 'black', whiteSpace: 'nowrap', overflow: 'hidden' }}>
        {props.data.countryTranslated} &#40;{props.data.languageTranslated}&#41;
      </p>
    </a>
  </Option>
);
const CountryPickerComponent = (props) => {
  const {
    countries,
    selectedCountry,
    colors,
    isHillmanTravel,
    isFrontpage,
    airlineWhitelabel,
    transitionToCheckout,
    centerHeaderMenu,
  } = props;

  const propsForStyles = {
    airlineWhitelabel: airlineWhitelabel,
    isFrontpage: isFrontpage,
    isHillmanTravel: isHillmanTravel,
    isAirMalta: airlineWhitelabel && airlineWhitelabel.isAirMalta,
    isNorwegian: airlineWhitelabel && airlineWhitelabel.isNorwegian,
    isAirBaltic: airlineWhitelabel && airlineWhitelabel.isAirBaltic,
  };

  return (
    <Root transitionToCheckout={transitionToCheckout} centerHeaderMenu={centerHeaderMenu}>
      <Select
        placeholder={'countryPlaceholder'}
        styles={getSelectCountryStyles(colors, propsForStyles)}
        className='select-country'
        classNamePrefix='select-country'
        options={countries}
        value={selectedCountry}
        components={{ SingleValue: IconSingleValue, Option: IconOption }}
        isClearable={false}
        isSearchable={false}
        maxMenuHeight='auto'
      />
    </Root>
  );
};
const Root = styled.div`
  position: relative;
  z-index: 1;

  margin: ${(props) => (props.centerHeaderMenu ? '0px' : ' 3px auto 0px auto')};
  border-radius: 0;
  display: ${(props) => (props.transitionToCheckout ? 'none' : 'flex')};
  @media (max-width: 380px) {
    margin: -10px;
  }
  .select-country {
    display: flex;
    flex-direction: row;
  }
`;
const getSelectCountryStyles = function (colors, propsForStyles) {
  return {
    control: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
      border: 'transparent',
      cursor: 'pointer',
      borderRadius: '20px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: colors.primary.default,
      },
      padding: '0 10px',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: colors.text.default,
      fontSize: '16px',
      fontWeight: '600',
    }),
    menu: (styles) => ({
      ...styles,
      top: 'calc(100% + 1px)',
      left: propsForStyles.airlineWhitelabel ? '-150%' : '-100%',
      marginTop: '0',
      border: '1px solid',
      borderColor: colors.primary.default,
      borderRadius: 0,
      fontSize: '14px',
      display: 'flex',
      width: 'auto',
      flexDirection: 'column',
      justifyContent: 'start',
      marginRight: '10px',
      '@keyframes fadeIn': {
        '0%': {
          opacity: '0',
          transform: 'translateY(2rem)',
        },
        '100%': {
          opacity: '1',
          transform: 'translateY(0)',
        },
      },
      animation: 'fadeIn 0.4s ease-in-out',
      '@media only screen and (max-width: 415px)': {
        ...styles['@media only screen and (max-width: 415px)'],
        left: propsForStyles.isFrontpage && propsForStyles.airlineWhitelabel ? '-120%' : '-50%',
        '@media only screen and (max-width: 390px)': {
          ...styles['@media only screen and (max-width: 390px)'],
          left: propsForStyles.isFrontpage && propsForStyles.airlineWhitelabel ? '-140%' : '-80%',
        },
      },
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      width: 'auto',
      backgroundColor: isFocused ? colors.primary.default : colors.background.light,
      cursor: 'pointer',
      color: isSelected ? colors.text.default : 'inherit',
      '& a': {
        display: 'flex',
        alignItems: 'center',
      },
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      display: 'block',
      '@media only screen and (max-width: 390px)': {
        ...styles['@media only screen and (max-width: 390px)'],
        display: propsForStyles.isFrontpage
          ? propsForStyles.isHillmanTravel || propsForStyles.isAirMalta || propsForStyles.isAirBaltic
            ? 'none'
            : 'block'
          : 'block',
      },
      '@media only screen and (max-width: 415px)': {
        ...styles['@media only screen and (max-width: 415px)'],
        display: !propsForStyles.isFrontpage && propsForStyles.isAirMalta ? 'none' : 'block',
      },
    }),
    valueContainer: (styles) => ({
      ...styles,
      height: '40px',
      width: '40px',
    }),
    menuList: (styles) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };
};

export default CountryPickerComponent;

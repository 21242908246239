import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import Range from 'components/inputs/range/RangeComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';
import arrowDash from 'static/icons/arrow-dash.svg';

const propTypes = {
  numberOfDays: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isOnCalendar: PropTypes.bool,
};

function NumberOfDaysComponent({ numberOfDays, onChange, isOnCalendar }) {
  return (
    <Root isOnCalendar={isOnCalendar}>
      <NumberOfDaysValues>
        {isOnCalendar ? (
          <Value>{numberOfDays.value[0] || numberOfDays.min}</Value>
        ) : (
          <FormattedMessage
            id='filters.component.numberOfDays.days.label'
            description={`{number, number} {number, plural, one {day} other {days} }`}
            defaultMessage={`{number, number} {number, plural, one {day} other {days} }`}
            values={{
              number: numberOfDays.value[0] || numberOfDays.min,
            }}
            tagName={'span'}
          />
        )}
        <img src={arrowDash} alt='' />
        {isOnCalendar ? (
          <Value>{numberOfDays.value[1] || numberOfDays.max}</Value>
        ) : (
          <FormattedMessage
            id='filters.component.numberOfDays.days.label'
            description={`{number, number} {number, plural, one {day} other {days} }`}
            defaultMessage={`{number, number} {number, plural, one {day} other {days} }`}
            values={{
              number: numberOfDays.value[1] || numberOfDays.max,
            }}
            tagName={'span'}
          />
        )}
      </NumberOfDaysValues>
      <Range
        values={numberOfDays}
        onChange={onChange}
        mobileHandle={{
          key: 'number-of-days-handle',
          label: function (value) {
            if (isOnCalendar) {
              return <Value>{value}</Value>;
            }
            return (
              <FormattedMessage
                id={'filters.component.numberOfDays.days.label'}
                description={'{number, number} {number, plural, one {day} other {days} }'}
                defaultMessage={'{number, number} {number, plural, one {day} other {days} }'}
                values={{ number: value }}
              />
            );
          },
        }}
      />
    </Root>
  );
}

NumberOfDaysComponent.propTypes = propTypes;

export default NumberOfDaysComponent;

const Root = styled.div`
  user-select: none;
  ${mediaQueries.above.tablet`
     margin: 16px 0;
  `}
  ${mediaQueries.below.desktop`
    width: 90%;
  `}
`;

const NumberOfDaysValues = styled.div`
  margin-bottom: 2px;
  font-size: 16px;
  text-align: center;
  display: flex;
  justify-content: center;

  span {
    color: ${(props) => props.theme.colors.primary.dark};
    font-size: 14px;
    font-weight: bold;

    &:first-child {
      margin-right: 4px;
    }

    &:last-child {
      margin-left: 4px;
    }
  }

  ${mediaQueries.below.l`
    display: none;
  `}
`;
const Value = styled.span`
  display: flex;
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    })
  ).isRequired,
  activeAction: PropTypes.string,
  possitionBottom: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

function ActionBarComponent({ actions, activeAction, possitionBottom, onClick }) {
  return (
    <Root possitionBottom={possitionBottom}>
      <ActionButtons>
        {actions
          .filter(({ disabled }) => !disabled)
          .map(({ id, icon }) => (
            <ActionWrapper key={id} data-action-id={id} onClick={onClick} isActive={id === activeAction}>
              <ActionIcon icon={icon} />
            </ActionWrapper>
          ))}
      </ActionButtons>
    </Root>
  );
}

ActionBarComponent.propTypes = propTypes;

export default ActionBarComponent;

const Root = styled.div`
  display: block;
  position: fixed;
  bottom: ${({ possitionBottom }) => possitionBottom}px;
  right: 0;
  padding-bottom: 5px;
  transition: bottom 0.3s;

  ${mediaQueries.fromAndAbove.l`
    display: none;
  `}
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary.dark : theme.colors.primary.default)};
  border-radius: 50%;
  font-size: 21px;
  text-align: center;
  cursor: pointer;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.primary.default : theme.colors.primary.dark)};
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.3);
`;

const ActionIcon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 100%;
`;

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import { mediaQueries } from 'styles/utils/mediaQueries';
import { darken } from 'polished';
import CookieSettingsComponent from './CookieSettingsComponent';
import LinkIntercept from 'components/linkIntercept/LinkIntercept';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NewCookieConsentComponent({
  onClickAcceptAll,
  onClickAdjustSettings,
  onClickBack,
  adjustSettings,
  onToggleAnalytics,
  onToggleMarketing,
  onSaveSettings,
  defaultAnalytics,
  defaultMarketing,
  isHolidayPirates,
}) {
  return (
    <Root role='dialog' aria-live='polite' aria-label='cookieconsent' aria-describedby='cookieconsent:desc'>
      <Header adjustSettings={adjustSettings}>
        {adjustSettings && <Icon icon='chevron-left' onClick={onClickBack} />}
        <Icon icon='times' onClick={onClickAcceptAll} />
      </Header>

      {!adjustSettings ? (
        <Content>
          <CookieBody>
            <Title>
              <FormattedMessage
                id={'cookieConsent.banner.titleLabel'}
                description={'Before you book your next holiday...'}
                defaultMessage={'Before you book your next holiday...'}
              />
            </Title>
            <Description>
              <FormattedMessage
                id={'cookieConsent.banner.description'}
                description={
                  'To improve your experience on our websites, we use cookies. You can choose to accept all cookies or to adjust them. Read more about our'
                }
                defaultMessage={
                  'To improve your experience on our websites, we use cookies. You can choose to accept all cookies or to adjust them. Read more about our'
                }
              />
              &nbsp;
              <CookiePolicyLink>
                <LinkIntercept to={'/cookies'}>
                  <FormattedMessage
                    id={'cookieConsent.banner.cookiePolicy'}
                    description={'cookie policy'}
                    defaultMessage={'cookie policy'}
                  />
                </LinkIntercept>
              </CookiePolicyLink>
            </Description>
          </CookieBody>
          <CookieFooter>
            <FooterButtons>
              <AcceptAll onClick={onClickAcceptAll}>
                <FormattedMessage
                  id={'cookieConsent.buttons.acceptAll'}
                  description={'Accept all'}
                  defaultMessage={'Accept all'}
                />
              </AcceptAll>
              <AdjustSettings onClick={onClickAdjustSettings}>
                <FormattedMessage
                  id={'cookieConsent.buttons.adjustSettings'}
                  description={'Manage cookies'}
                  defaultMessage={'Manage cookies'}
                />
              </AdjustSettings>
            </FooterButtons>
          </CookieFooter>
        </Content>
      ) : (
        <CookieSettingsComponent
          onClickAcceptAll={onClickAcceptAll}
          onToggleAnalytics={onToggleAnalytics}
          onToggleMarketing={onToggleMarketing}
          onSaveSettings={onSaveSettings}
          defaultAnalytics={defaultAnalytics}
          defaultMarketing={defaultMarketing}
          isHolidayPirates={isHolidayPirates}
        />
      )}
    </Root>
  );
}

export default NewCookieConsentComponent;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 10px;
  right: 10px;
  transform: translate(-10px, 0px);
  border-radius: 8px;
  max-width: 375px;
  overflow: hidden;
  z-index: 999999;
  max-height: 98vh;
  box-shadow: rgb(0 0 0 / 19%) 0px 10px 20px, rgb(0 0 0 / 23%) 0px 6px 6px;
  ${mediaQueries.fromAndBelow.tablet`
    width: 94vw;
  `}
`;

const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.background.light};
`;
const CookieBody = styled.div`
  padding: 0 14px 10px 14px;
  ${mediaQueries.fromAndBelow.tablet`
      padding: 0 24px 10px 14px;
  `}
`;

const Title = styled.h3`
  font-size: 17px;
  margin-bottom: 0.5rem;
  font-weight: 600;
  ${mediaQueries.fromAndBelow.tablet`
    display:none
  `}
`;
const Description = styled.div`
  font-size: 15px;
  line-height: 24px;
`;
const CookieFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.background.grey};
  padding: 10px 14px 10px 14px;
`;

const FooterButtons = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 26px;
  ${mediaQueries.fromAndBelow.tablet`
    flex-wrap: wrap;
  `}
`;

const AcceptAll = styled.div`
  line-height: 1;
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.text.white};
  background-color: ${({ theme }) => theme.colors.secondary.default};
  cursor: pointer;
  margin-right: 14px;
  &:hover {
    background-color: ${({ theme }) => darken('0.10', theme.colors.secondary.default)};
  }
`;
const AdjustSettings = styled.div`
  line-height: 1;
  padding: 8px 12px 10px 12px;
  color: ${({ theme }) => theme.colors.secondary.default};
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary.default};

  &:hover {
    color: ${({ theme }) => darken('0.20', theme.colors.secondary.default)};
    border-bottom: 1px solid ${({ theme }) => darken('0.20', theme.colors.secondary.default)};
  }
`;
const CookiePolicyLink = styled.div`
  border-bottom: ${({ theme }) => ` 2px solid ${theme.colors.background.grey}`};
  color: ${({ theme }) => theme.colors.secondary.default};
  font-weight: 700;
  cursor: pointer;
  display: contents;
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.background.light};
  display: flex;
  justify-content: space-between;
  z-index: 2;
  padding: 8px 14px 0 14px;
  align-items: center;
  height: ${({ adjustSettings }) => (adjustSettings ? '28px' : '20px')};
`;

const Icon = styled(FontAwesomeIcon)`
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  color: ${({ theme }) => darken('0.20', theme.colors.background.grey)};
  &:only-child {
    margin-left: auto;
  }
`;

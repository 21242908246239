/**
/**
 * Retries a fetch operation with a specified maximum number of retries until valid data is obtained.
 *

 * @param {Function} fetchFunction - The function to perform the fetch operation.
 * @param {number} [maxRetries=3] - The maximum number of retries.
 * @param {Function} [isDataValid = () => true] - A function to check if the fetched data is valid, because some endpoints return 200 OK but the data is not valid and we should retry.
 * @param {string} [fetchMethodName=''] - The name of the fetch method (used in error messages).
 * @param {...any} rest - Additional arguments to be passed to the fetch function.
 * @returns {Promise<any>} - A promise that resolves with the fetched data if successful.
 * @throws {string[]} - An array of error messages if max retries are exceeded without obtaining valid data.
 * 
 *  * NOTE: THIS FUNCTION WORKS WITH AXIOS HTTP CLIENT/INSTANCE
 */

export async function retryFetch({
  fetchFunction,
  maxRetries = 3,
  isDataValid = () => true,
  fetchMethodName = '',
  ...rest
}) {
  const errors = [];
  for (let i = 0; i < maxRetries; i++) {
    try {
      const response = await fetchFunction(rest);
      const { data } = response;

      if (isDataValid(data)) {
        return data;
      } else {
        errors.push(`Data not valid, retrying...`, data);
      }
    } catch (error) {
      errors.push(`Retrying ${i}...`, error.message);
    }
  }

  errors.push(`Error on ${fetchMethodName}: Max fetch retries exceeded.`);
  throw new Error(JSON.stringify(errors));
}

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setTravelersInitialValue(travelers) {
  return {
    type: types.SET_TRAVELERS_INITIAL_VALUE,
    payload: {
      travelers,
    },
  };
}

export function setTravelerInformation(roomIndex, index, passengerType, traveler) {
  return {
    type: types.SET_TRAVELER_INFORMATION,
    payload: {
      roomIndex,
      index,
      passengerType,
      traveler,
    },
  };
}

export function updateTravelerInputValueAndValidate(
  name,
  value,
  roomIndex,
  index,
  passengerType,
  validity,
  additionalData
) {
  return {
    type: types.UPDATE_TRAVELER_INPUT_VALUE_AND_VALIDATE,
    payload: {
      name,
      value,
      roomIndex,
      index,
      passengerType,
      validity,
      additionalData,
    },
  };
}

export function setValidity(validity) {
  return {
    type: types.SET_VALIDITY,
    payload: {
      validity,
    },
  };
}

export function setNamesSpelledCorrectly(namesSpelledCorrectly) {
  return {
    type: types.SET_NAMES_SPELLED_CORRECTLY,
    payload: {
      namesSpelledCorrectly,
    },
  };
}

export function setNamesSpelledCorrectlyVisible(namesSpelledCorrectlyVisible) {
  return {
    type: types.SET_NAMES_SPELLED_CORRECTLY_VISIBLE,
    payload: {
      namesSpelledCorrectlyVisible,
    },
  };
}

export function resetTravelers() {
  return {
    type: types.RESET_TRAVELERS,
  };
}

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFetching(fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      fetching,
    },
  };
}

export function setVehicleRentals(value) {
  return {
    type: types.SET_VEHICLE_RENTALS,
    payload: {
      value,
    },
  };
}

export function setFilteredVehicles(filteredVehicles) {
  return {
    type: types.SET_FILTERED_VEHICLES,
    payload: {
      filteredVehicles,
    },
  };
}

export function setDriversDatesOfBirth(driversDatesOfBirth) {
  return {
    type: types.SET_DRIVERS_DATES_OF_BIRTH,
    payload: {
      driversDatesOfBirth,
    },
  };
}

export function setCurrentRequestParameters(currentRequestParameters) {
  return {
    type: types.SET_CURRENT_REQUEST_PARAMETERS,
    payload: {
      currentRequestParameters,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

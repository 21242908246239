import React from 'react';
import styled from 'styled-components';

import Divider from 'components/Divider';
import HeaderMenuTransition from '../../headerMenu/HeaderMenuTransition';
import { MediaQueryWrap } from 'routes/_components/layout/MediaContextProvider';
import Portal from 'components/portal/Portal';
import MediaQuery from 'react-responsive';
import { breakpoints } from 'styles/themes/main';
import SelectOrigins from '../components/SelectOrigins/SelectOrigins';
import SelectDestinations from '../components/SelectDestinations/SelectDestinations';
import SelectDates from '../components/SelectDates/SelectDates';
import SelectRoomConfigurations from '../components/selectRoomConfigurations/SelectRoomConfigurations';
import SearchButton from '../components/SearchButton/SearchButton';
import SearchIconButton from '../../dropdownSearchTrigger/components/SearchIconButton';

const styles = {
  width: '140px',
  borderRadius: '8px',
  height: '48px',
  maxHeight: '48px !important',

  '@media (max-width: 1140px)': {
    width: '50px',
  },

  '@media (max-width: 1024px)': {
    width: '100%',
  },
};

export default function SearchV2({
  isFrontpage,
  inputsHidden,
  onClickClose,
  airlineWhitelabel,
  isSearchPage,
  isHotelPage,
  menuContent,
}) {
  const medias = [
    {
      key: 'header-menu-mobile',
      query: { lessThan: 'md' },
      component: (
        <HeaderMenuTransition
          menuContent={menuContent}
          showForMedia={['fromAndAbove', 'l']}
          isHotelPage={isHotelPage}
        />
      ),
    },
    {
      key: 'header-menu-large',
      query: { greaterThanOrEqual: 'md' },
      component: (
        <HeaderMenuTransition
          menuContent={menuContent}
          isHamburger={true}
          isLightBackground={true}
          showForMedia={['fromAndAbove', 'l']}
          isHotelPage={isHotelPage}
        />
      ),
    },
  ];

  return (
    <Container>
      <Wrapper>
        <Section>
          <SelectOrigins isFrontpage={isFrontpage} />
          <StyledDivider />
          <SelectDestinations isFrontpage={isFrontpage} />
        </Section>
        <Section>
          <SelectDates isFrontpage={isFrontpage} isSearchPage={isSearchPage} />
        </Section>
        <Section>
          <SelectRoomConfigurations isFrontpage={isFrontpage} isHotelPage={isHotelPage} />
        </Section>
        <MediaQuery minWidth={1024} maxWidth={1140}>
          {(matches) => (
            <Button
              styles={styles}
              content={matches ? <SearchButtonComponent /> : undefined}
              isFrontpage={isFrontpage}
              isHotelPage={isHotelPage}
              isSearchPage={isSearchPage}
              inputsHidden={inputsHidden}
              onClickClose={onClickClose}
              airlineWhitelabel={airlineWhitelabel}
            />
          )}
        </MediaQuery>
        <MediaQuery maxWidth={breakpoints.below.desktop}>
          {(matches) => {
            return (
              <Portal usePortal={matches}>
                <MediaQueryWrap medias={medias} />
              </Portal>
            );
          }}
        </MediaQuery>
      </Wrapper>
    </Container>
  );
}

const SearchButtonComponent = styled(SearchIconButton)({
  width: '100%',
  height: '100%',
});

const Button = styled(SearchButton)({
  width: '100%',
});

const StyledDivider = styled(Divider)({
  '@media (max-width: 768px)': {
    width: '95%',
    height: '2px',
    margin: '0 auto',
  },
});

const Container = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const Wrapper = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: '8px',
  padding: '0 16px',
  backgroundColor: theme.colors.primary.light,
  zIndex: 2,

  '@media (max-width: 1024px)': {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    zIndex: 1,
    padding: 0,
    backgroundColor: 'transparent',
  },
}));

const Section = styled('div')(({ theme }) => ({
  height: '48px',
  display: 'flex',
  backgroundColor: 'white',
  borderRadius: '8px',
  padding: '4px',

  '&:hover': {
    boxShadow: `0px 0px 0px 1px ${theme.colors.adjacent.default}`,
  },

  '@media (max-width: 1024px)': {
    width: '100%',
  },

  '@media (max-width: 768px)': {
    height: '100%',
    gap: '10px',
    flexDirection: 'column',
  },

  '.date-range-picker-container': {
    minWidth: '280px',

    '@media (max-width: 1520px) and (min-width: 1024px)': {
      maxWidth: '200px',
    },

    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
}));

import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';

export const LogoBasic = styled.img`
  height: 31px;
  padding: ${({ padding }) => (padding ? padding : 'initial')};

  ${mediaQueries.fromAndAbove.desktop`
    height: ${({ isFrontpage }) => (isFrontpage ? '40px' : '31px')};
  `}
`;

export const LogoBasicNovaturas = styled.img`
  padding: ${({ padding }) => (padding ? padding : 'initial')};
  width: auto;
  height: auto;
`;

export const LogoBasicNorwegian = styled.img`
  height: 40px;
  width: 120px;

  ${mediaQueries.fromAndAbove.desktop`
    height: 40px;
    width: 178px;
  `}
`;

export const LogoBasicCyprus = styled.img`
  height: 36px;
  width: 100px;

  ${mediaQueries.fromAndAbove.desktop`
    height: 40px;
    width: 130px;
  `}
`;

export const LogoBasicRSP = styled.img`
  height: ${({ isFrontpage }) => (isFrontpage ? '52px' : '31px')};
  padding: ${({ padding }) => (padding ? padding : 'initial')};

  ${mediaQueries.fromAndAbove.desktop`
    height: ${({ isFrontpage }) => (isFrontpage ? '76px' : '31px')};
  `}
`;

export const LogoBasicLarge = styled.img`
  height: 44px;
  padding: ${({ padding }) => (padding ? padding : 'initial')};

  ${mediaQueries.fromAndAbove.desktop`
    height: ${({ isFrontpage }) => (isFrontpage ? '70px' : '31px')};
  `}
`;

import { getCountries } from 'httpClient/requests/countries';
import * as fetchingStatus from 'constants/status/fetchingStatus';
import { setFetching, setCountries } from './actions';
import filterCountries from './selectors';

export default function fetchCountries() {
  return async (dispatch, getState) => {
    const { fetching } = getState().countryPicker;
    const language = getState().settings.value.locale.language;
    const websiteId = getState().settings.value.websiteId;

    if (fetching === fetchingStatus.IN_PROGRESS) {
      return;
    }

    dispatch(setFetching(fetchingStatus.IN_PROGRESS));

    try {
      const { data } = await getCountries(websiteId);

      const filteredData = await filterCountries(data, language);

      dispatch(setCountries(filteredData));
      dispatch(setFetching(fetchingStatus.SUCCEEDED));
    } catch (error) {
      dispatch(setFetching(fetchingStatus.FAILED));
      // console.error(error);
    }
  };
}

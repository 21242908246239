import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CalendarSwitch from './CalendarSwitch';
import CalendarPeriodNumberOfDays from './CalendarPeriodNumberOfDays';

const propTypes = {
  isPeriodCalendar: PropTypes.bool,
};

const CalendarOptionsComponent = ({ resetInputFocus }) => (
  <Root>
    <CalendarSwitch resetInputFocus={resetInputFocus} />
    <CalendarPeriodNumberOfDays resetInputFocus={resetInputFocus} />
  </Root>
);

CalendarOptionsComponent.propTypes = propTypes;

export default CalendarOptionsComponent;

// Styled components

const Root = styled.div`
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

import React from 'react';

function LinkInterceptComponent({ to, style, onClick, target, children }) {
  return (
    <a href={to} style={style} onClick={onClick} target={target}>
      {children}
    </a>
  );
}

export default LinkInterceptComponent;

import { resetSelectedDates } from '../actions';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import websiteIds from 'constants/website.config';

export default function evalResetSelectedDates() {
  return (dispatch, getState) => {
    const {
      dates,
      airlineCalendar: { availableDates },
    } = getState().selectDates;
    const { websiteId } = getState().settings.value;
    const website = Object.values(websiteIds).find((el) => el.ID === websiteId);
    const isDayBlocked = website && website.calendarDaysBlocked;

    //This checks if we don't have airline-availableDates or days should not be blocked , there is no need to reset dates

    if (!isDayBlocked || _isEmpty(availableDates)) {
      return;
    }

    if (dates.from !== null && dates.to !== null) {
      const fromYear = moment(dates.from).year();
      const fromMonth = moment(dates.from).month();
      const fromDay = moment(dates.from).date();

      const toYear = moment(dates.to).year();
      const toMonth = moment(dates.to).month();
      const toDay = moment(dates.to).date();

      const leaveDateAvailable =
        availableDates[fromYear] &&
        availableDates[fromYear][fromMonth] &&
        availableDates[fromYear][fromMonth][fromDay] &&
        availableDates[fromYear][fromMonth][fromDay].leave;

      const returnDateAvailable =
        availableDates[toYear] &&
        availableDates[toYear][toMonth] &&
        availableDates[toYear][toMonth][toDay] &&
        availableDates[toYear][toMonth][toDay].return;

      if (!leaveDateAvailable || !returnDateAvailable) {
        dispatch(resetSelectedDates());
      }
    } else {
      //if at least one date is not selected , reset dates
      dispatch(resetSelectedDates());
    }
  };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import axis from 'axis.js';
import withControlledReactSelect from 'components/hocs/withControlledReactSelect';
import { HOTEL_ONLY } from 'constants/selectValues';
import updateAvailableDestinationsForSelectedOrigins from 'state/selectDestinations/operations/updateAvailableDestinationsForSelectedOrigins';
import evalFetchCorrectCalendar from 'state/selectDates/operations/evalFetchCorrectCalendar';
import fetchOrigins from 'state/selectOrigins/operations/fetchOrigins';
import formatOriginsForReactSelect from 'state/selectOrigins/selectors/formatOriginsForReactSelect';
import { setHotelOnly } from 'state/selectDestinations/actions';
import { setFlexibleDatesDisabled } from 'state/selectDates/actions';
import { setMissingValuesForInput } from 'state/searchConditions/actions';
import { setHotelScoreReset } from 'state/searchResultsFilters/actions';
import SelectOriginsComponent from './SelectOriginsComponent';
import getIsOptionSelected from 'utils/getIsOptionSelected';
import { isMobile as getIsMobile } from 'utils/isMobile';

class SelectOrigins extends Component {
  constructor(props) {
    super(props);

    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnFocusMobile = this.handleOnFocusMobile.bind(this);
    this.handleOnChangeOrigins = this.handleOnChangeOrigins.bind(this);
  }

  async componentDidMount() {
    const { origins, fetchOrigins, hydrateSelectedOrigins } = this.props;
    const requests = [];

    if (origins.length === 0) {
      requests.push(fetchOrigins());
    }

    try {
      await axios.all(requests);

      hydrateSelectedOrigins();

      const {
        selectedOrigins,
        showAllOrigins,
        updateAvailableDestinationsForSelectedOrigins,
        evalFetchCorrectCalendar,
      } = this.props;

      if (!selectedOrigins || (selectedOrigins && !axis.isFunction(selectedOrigins.map))) {
        return;
      }

      const selectedOriginsCodes = selectedOrigins.map(({ value }) => value);
      const hotelOnlyValueIsPresent = selectedOriginsCodes.indexOf(HOTEL_ONLY) !== -1;

      if (selectedOriginsCodes.length > 0 && !hotelOnlyValueIsPresent) {
        if (!showAllOrigins) {
          updateAvailableDestinationsForSelectedOrigins();
        }

        evalFetchCorrectCalendar();
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleOnFocus() {
    const { tooltipIsVisible, onFocus } = this.props;

    if (tooltipIsVisible) {
      this.props.setMissingValuesForInput('selectOrigins', false);
    }

    if (axis.isFunction(onFocus)) {
      onFocus();
    }
  }

  handleOnFocusMobile() {
    this.handleOnFocus();
  }

  handleOnChangeOrigins(selectedOrigins) {
    const { selectedOrigins: stateSelectedOrigins } = this.props;
    if (selectedOrigins && stateSelectedOrigins) {
      selectedOrigins = getIsOptionSelected(selectedOrigins, stateSelectedOrigins);
    }
    if (!selectedOrigins) {
      selectedOrigins = [];
    } else if (!axis.isArray(selectedOrigins)) {
      selectedOrigins = [selectedOrigins];
    }

    const selectedOriginsValues = selectedOrigins.map(({ value }) => value);
    const indexOfNA = selectedOriginsValues.indexOf(HOTEL_ONLY);
    const isHotelOnly = indexOfNA !== -1 && indexOfNA === selectedOrigins.length - 1;

    this.props.setSelectedOrigins(selectedOrigins);
    this.props.setHotelScoreReset(true);

    if (!isHotelOnly) {
      this.props.setHotelOnly(false);
      !this.props.showAllOrigins && this.props.updateAvailableDestinationsForSelectedOrigins();
      this.props.evalFetchCorrectCalendar();
    } else {
      this.props.setHotelOnly(true);
    }
    const { onRequestClose } = this.props;
    const isMobile = getIsMobile();
    !isMobile && onRequestClose();
  }

  render() {
    const {
      origins,
      availableOrigins,
      isFrontpage,
      isMulti,
      headerHeight,
      airlineWhitelabel,
      locale,
      tooltipIsVisible,
      instanceId,
      className,
      isDisabled,
      inputValue,
      menuIsOpen,
      onChangeInputValue,
      onRequestClose,
      disableHotelOnly,
      isHotelPage,
    } = this.props;

    //@TODO Should be refactored with configuration.
    const removeGot = airlineWhitelabel && airlineWhitelabel.isNorwegian && locale.language === 'no';

    const _disableHotelOnly = airlineWhitelabel || disableHotelOnly;
    const originsOptions = formatOriginsForReactSelect(
      { origins, availableOrigins },
      !_disableHotelOnly,
      undefined,
      removeGot
    );

    const _className = className ? className : 'select-origins';

    return (
      <SelectOriginsComponent
        originsOptions={originsOptions}
        selectedOrigins={this.props.selectedOrigins}
        onChange={this.handleOnChangeOrigins}
        onFocus={this.handleOnFocus}
        onFocusMobile={this.handleOnFocusMobile}
        airlineWhitelabel={airlineWhitelabel}
        isFrontpage={isFrontpage}
        isHotelPage={isHotelPage}
        isMulti={isMulti}
        isDisabled={(origins && origins.length === 1) || isDisabled}
        tooltipIsVisible={tooltipIsVisible}
        headerHeight={headerHeight}
        instanceId={instanceId}
        className={_className}
        inputValue={inputValue}
        menuIsOpen={menuIsOpen}
        onChangeInputValue={onChangeInputValue}
        onRequestClose={onRequestClose}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    headerHeight: state.refs.headerHeight,
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    locale: state.settings.value.locale,
    showAllOrigins: state.settings.value.showAllOrigins,
    disableHotelOnly: state.settings.value.customSettings && state.settings.value.customSettings.disableHotelOnly,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrigins() {
      return dispatch(fetchOrigins());
    },
    updateAvailableDestinationsForSelectedOrigins() {
      dispatch(updateAvailableDestinationsForSelectedOrigins());
    },
    evalFetchCorrectCalendar() {
      dispatch(evalFetchCorrectCalendar());
    },
    setFlexibleDatesDisabled(value) {
      dispatch(setFlexibleDatesDisabled(value));
    },
    setHotelScoreReset(reset) {
      dispatch(setHotelScoreReset(reset));
    },
    setMissingValuesForInput(input, value) {
      dispatch(setMissingValuesForInput(input, value));
    },
    setHotelOnly(hotelOnly) {
      dispatch(setHotelOnly(hotelOnly));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withControlledReactSelect()(SelectOrigins));

import styled from 'styled-components';

const DestinationsStage = styled.div`
  display: inline-block;
  height: 100%;
  overflow: auto;
  min-height: 200px;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary.default};
    border-radius: 10px;
  }
`;

export default DestinationsStage;

import { getCalendarHighlightsForOriginAndDestination } from 'httpClient/requests/selectDates';
import { updateCalendarHighlightsV3 } from '../actions';
import evalUpdateCalendarSpecialDates from './evalUpdateCalendarSpecialDates';

export default function fetchCalendarHighlightsForOriginAndDestination(origin, destination) {
  return (dispatch) => {
    getCalendarHighlightsForOriginAndDestination(origin, destination)
      .then((response) => {
        dispatch(updateCalendarHighlightsV3(origin, response.data));
        dispatch(evalUpdateCalendarSpecialDates());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

/**
 * When updating this file also update `server/src/constants/whitelabels/airlines`.
 */
export const NORWEGIAN_HOLIDAYS = 'norwegianholidays';
export const AIRMALTA_HOLIDAYS = 'airmaltaholiday';
export const AIRBALTIC_HOLIDAYS = 'airbalticholidays';
export const CY_HOLIDAY_DEALS = 'cyprusairways';
export const HILLMAN = 'hillman';
export const NOVATURAS_LT = 'atostogos.novaturas.lt';
export const NOVATURAS_LV = 'brivdienas.novatours.lv';
export const NOVATURAS_ET = 'puhkus.novatours.ee';

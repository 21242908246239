import React from 'react';
import styled from 'styled-components';
import DateHighlightInfo from './components/DateHighlightInfo';
import FlexibleDatesCheckbox from './components/FlexibleDatesCheckbox';
import { mediaQueries } from 'styles/utils/mediaQueries';

const CalendarDescription = ({
  airlineWhitelabel,
  company,
  flexibleDatesDisabled,
  calendarOption,
  onChangeCheckbox,
  isCheckboxChecked,
}) => (
  <Root>
    <DateHighlightInfo airlineWhitelabel={airlineWhitelabel} company={company} />
    <FlexibleDatesCheckbox
      flexibleDatesDisabled={flexibleDatesDisabled}
      calendarOption={calendarOption}
      onChangeCheckbox={onChangeCheckbox}
      isCheckboxChecked={isCheckboxChecked}
    />
  </Root>
);

export default CalendarDescription;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${mediaQueries.fromAndBelow.tablet`
     flex-direction: column;
     gap: 8px;
     margin-bottom: 10px;
  `};
`;

import { createOrUpdateAbandonedCart } from 'httpClient/requests/checkoutPage/abandonedCart';
import abandonedCartLS from 'managers/localStorage/abandonedCart/abandonedCartLS';
import getPackagesSearchRequestParameters from 'state/checkoutPage/packagesSearch/selectors/getPackagesSearchRequestParameters';
import getSelectedHotelsRoomsWithSelectedOption from 'state/checkoutPage/packagesSearch/selectors/getSelectedHotelsRoomsWithSelectedOption';
import getSelectedRoomsObservedProps from '../selectors/getSelectedRoomsObservedProps';
import getSelectedFlightsObservedProps from '../selectors/getSelectedFlightsObservedProps';
import getSelectedFlightsExtrasObservedProps from '../selectors/getSelectedFlightsExtrasObservedProps';
import getSelectedOtherExtrasObservedProps from '../selectors/getSelectedOtherExtrasObservedProps';
import { insertOrUpdateAbandonedCartCookie } from 'utils/cookie/masterDomainCookie/abandonedCartCookie';
import { getAbandonedCartForCookie } from './utils/getAbandonedCartForCookie';

export default function persistAbandonedCart(config) {
  return (dispatch) => {
    try {
      dispatch(_persistAbandonedCart(config));
    } catch (error) {
      console.error('Error on persist abandoned cart.', error);
      window.Rollbar.error('Error on persist abandoned cart.', error);
    }
  };
}

function _persistAbandonedCart(config = {}) {
  return (dispatch, getState) => {
    const { saveToBlob } = config;
    const {
      hotelPage,
      hotelPagePackageInfo,
      templatesHotelSearchParameters,
      checkoutPageAbandonedCart,
      checkoutPageFlightsDetails,
      checkoutPageTransfers,
      checkoutPageVehicleRental,
      checkoutPageInsurances,
      checkoutPageCancellationProtections,
      checkoutPageTravelSummary,
      checkoutPageCustomerDetails,
      checkoutPageTravelers,
      settings,
    } = getState();

    if (hotelPage.hotelsInfo && hotelPage.hotelsInfo.length > 1) {
      return null;
    }

    const currency = settings.value.locale.currency;
    const uuid = checkoutPageAbandonedCart.uuid;
    const websiteId = settings.value.websiteId;
    const masterDomain = settings.value.customSettings && settings.value.customSettings.masterDomain;

    // Hotels Search
    const {
      id,
      name,
      slug,
      images,
      location: { destination },
      stars,
    } = hotelPage.hotelsInfo[0];
    const { selectDates, selectOrigin, selectRooms } = templatesHotelSearchParameters;
    const hotelsSearchRequestParameters = getPackagesSearchRequestParameters(
      { selectDates, selectOrigin, selectRooms },
      hotelPagePackageInfo.current,
      { primaryHotelIds: id.toString(), currency }
    );
    const driversDatesOfBirth = checkoutPageVehicleRental.driversDatesOfBirth;

    // Selected Products
    const { selectedFlightsExtras, selectedOtherExtras } = checkoutPageTravelSummary;
    const flightsDetailsValue = checkoutPageFlightsDetails.value;
    const selectedRooms = dispatch(getSelectedHotelsRoomsWithSelectedOption());
    const selectedRoomsObservedProps = getSelectedRoomsObservedProps(selectedRooms);
    const selectedFligtsObservedProps = getSelectedFlightsObservedProps();
    const selectedFlightsExtrasObservedProps = getSelectedFlightsExtrasObservedProps(
      selectedFlightsExtras,
      flightsDetailsValue
    );
    const selectedOtherExtrasObservedProps = getSelectedOtherExtrasObservedProps(
      selectedOtherExtras,
      checkoutPageTransfers.transfers,
      checkoutPageVehicleRental.value && checkoutPageVehicleRental.value.carRentals,
      checkoutPageInsurances.insurances,
      checkoutPageCancellationProtections.cancellationProtections
    );

    const abandonedCartToPersist = {
      uuid,
      data: {
        hotelsSearchRequestParameters,
        driversDatesOfBirth,
        selectedRoomsObservedProps,
        selectedFligtsObservedProps,
        selectedFlightsExtrasObservedProps,
        selectedOtherExtrasObservedProps,
        checkoutPageCustomerDetails,
        checkoutPageTravelers,
        websiteId,
      },
      hotelInfo: {
        id,
        name,
        slug,
        images,
        location: destination.name,
        stars,
      },
    };

    if (masterDomain) {
      const abandonedCartCookieData = getAbandonedCartForCookie(
        uuid,
        hotelsSearchRequestParameters,
        abandonedCartToPersist.hotelInfo
      );
      insertOrUpdateAbandonedCartCookie(abandonedCartCookieData, masterDomain);
    }

    saveToBlob ? persistToBlob(abandonedCartToPersist) : abandonedCartLS.insertOrUpdate(abandonedCartToPersist);
    return uuid;
  };
}

async function persistToBlob(data) {
  try {
    await createOrUpdateAbandonedCart(data.uuid, JSON.stringify(data));
  } catch (error) {
    console.error('Error on createOrUpdateAbandonedCart', error);
  }
}

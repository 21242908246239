import React from 'react';
import { useSelector } from 'react-redux';

import { PromoHero, CustomPromoHero, AbandonedCarts } from 'components/banner/banners';

const promoHeroBannerConfig = {
  styles: { root: { margin: '17px 0 0 0' } },
};

export default function Banners() {
  const customPromoHero = useSelector(
    (state) => state.settings.value.customSettings && state.settings.value.customSettings.promoHero
  );
  const promoHeroBanner = useSelector((state) => state.settings.value.promoHero);

  const showAbandonedCart = customPromoHero && promoHeroBanner;

  return (
    <>
      <CustomPromoHero />
      <PromoHero bannerConfig={promoHeroBannerConfig} />
      {!showAbandonedCart && <AbandonedCarts />}
    </>
  );
}

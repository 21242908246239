import getHotelsProductForBookingAndPayments from 'state/checkoutPage/packagesSearch/selectors/getHotelsProductForBookingAndPayments';
import getFlightsProductForBookingAndPayments from 'state/checkoutPage/flights/transforms/getFlightsProductForBookingAndPayments';
import getOtherExtrasProductForBookingAndPayments from 'state/checkoutPage/travelSummary/selectors/getOtherExtrasProductForBookingAndPayments';
import calculateTotalPrice from 'state/checkoutPage/travelSummary/selectors/calculateTotalPrice';
import { getCustomerForBookingAndPayments } from 'state/checkoutPage/customerDetails/selectors';
import getTotalPrice from 'state/checkoutPage/payment/utils/getTotalPrice';
import getCookie from 'utils/cookie/getCookie';
import { CLARITY_USER_ID } from "constants/cookiesKeys"

export default function getBookingVerifyRequestParameters(
  searchToken,
  hotelOnly,
  customerDetails,
  travelers,
  selectedHotels,
  selectedRooms,
  selectedFlights,
  selectedFlightsExtras,
  selectedOtherExtras,
  selectedFlightsCombinations,
  paymentOptions,
  selectedPaymentOption,
  checkoutTransfers,
  promotionCode,
  commentToHotel,
  frequentFlyer,
  hotDealDepartureId,
  mock,
  travelDocumentRequired,
  travelDocumentResidenceCountryRequired,
  travelDocumentNationalityRequired,
  travelDocumentIssuedRequired
) {
  const _travelDocumentRequired = travelDocumentRequired && !mock;
  const _travelDocumentResidenceCountryRequired = travelDocumentResidenceCountryRequired && !mock;
  const _travelDocumentNationalityRequired = travelDocumentNationalityRequired && !mock;
  const _travelDocumentIssuedRequired = travelDocumentIssuedRequired && !mock;

  const customer = getCustomerForBookingAndPayments(customerDetails);
  const hotels = getHotelsProductForBookingAndPayments(
    searchToken,
    selectedHotels,
    selectedRooms,
    travelers,
    commentToHotel,
    _travelDocumentRequired,
    _travelDocumentResidenceCountryRequired,
    _travelDocumentNationalityRequired,
    _travelDocumentIssuedRequired
  );
  const flights = !hotelOnly
    ? getFlightsProductForBookingAndPayments(
        searchToken,
        selectedFlights,
        selectedFlightsExtras,
        travelers,
        undefined,
        undefined,
        frequentFlyer,
        _travelDocumentRequired,
        _travelDocumentResidenceCountryRequired,
        _travelDocumentNationalityRequired,
        _travelDocumentIssuedRequired
      )
    : null;
  const { transfers, carRentals, cancellationProtections, insurances, co2Compensation, earthTodayToken } =
    getOtherExtrasProductForBookingAndPayments(selectedOtherExtras, { checkoutTransfers, selectedFlightsCombinations });

  const { clientTotalPrice, clientTotalPriceNoPromo } = getTotalPriceBookingVerify(
    paymentOptions,
    selectedPaymentOption,
    mock
  );

  return {
    customer,
    promotionCode,
    products: {
      hotels,
      flights,
      transfers,
      carRentals,
      cancellationProtections,
      insurances,
      co2Compensation,
      hotDealDepartureId,
      earthTodayToken,
    },
    clientTotalPrice,
    clientTotalPriceNoPromo,
    sessionId: getCookie(CLARITY_USER_ID)
  };
}

export function getTotalPriceBookingVerify(paymentOptions, selectedPaymentOption, mock) {
  const clientTotalPrice = calculateTotalPrice().totalPrice;

  if (mock) {
    return { clientTotalPrice, clientTotalPriceNoPromo: clientTotalPrice };
  }

  return {
    clientTotalPrice:
      paymentOptions !== null ? getTotalPrice(paymentOptions, undefined, selectedPaymentOption) : clientTotalPrice,
    clientTotalPriceNoPromo:
      paymentOptions !== null
        ? getTotalPrice(paymentOptions, { type: 'totalNoPromo' }, selectedPaymentOption)
        : clientTotalPrice,
  };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopMessageComponent from './TopMessageComponent';

class TopMessage extends Component {
  render() {
    const { topMessage, currentPageComponent } = this.props;
    const { isCountrySelection } = currentPageComponent;

    if (topMessage === null || isCountrySelection) {
      return null;
    }

    const { style: severity, text } = topMessage;

    return <TopMessageComponent severity={severity} text={text} />;
  }
}

function mapStateToProps(state) {
  return {
    topMessage: state.settings.value.topMessage,
    currentPageComponent: state.currentPageComponent,
  };
}

export default connect(mapStateToProps)(TopMessage);

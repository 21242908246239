import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import truncate from 'styles/utils/truncate';

const propTypes = {
  onClick: PropTypes.func,
};

function ButtonPrimaryComponent({ onClick, disabled, children, isAirBaltic }) {
  return (
    <StyledButtonPrimary onClick={onClick} disabled={disabled} isAirBaltic={isAirBaltic}>
      {children}
    </StyledButtonPrimary>
  );
}

ButtonPrimaryComponent.propTypes = propTypes;

export default ButtonPrimaryComponent;

export const StyledButtonPrimary = styled.button`
  ${truncate()}
  width: 100%;
  padding: 12px 16px;
  background-color: ${({ theme, disabled, isAirBaltic }) =>
    !disabled
      ? isAirBaltic
        ? theme.colors.airBalticLogoBackground
        : theme.colors.adjacent.default
      : theme.colors.text.disabled};
  border: 0;
  border-radius: 8px;
  font-size: 1.6em;
  font-weight: 700;
  color: ${({ theme, isAirBaltic }) =>
    isAirBaltic ? theme.colors.adjacent.default : theme.colors.buttons.primary.text};
  cursor: pointer;
  box-shadow: ${({ theme }) => `0 0 0 2px ${theme.colors.buttons.primary.boxShadow}`};
  transition: all 150ms ease-out;

  &:hover {
    transform: ${({ disabled }) => !disabled && 'translateY(-1px)'};
  }

  &:active {
    transition: all 50ms ease-in;
    transform: ${({ disabled }) => !disabled && 'translateY(-1px)'};
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'rc-slider';
import createMobileHandle from './components/handleMobile/HandleMobileComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  values: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.arrayOf(PropTypes.number),
  }),
};

const handleWidthMobile = 66;

function RangeComponent({ values, mobileHandle, ...props }) {
  const MobileHandle = createMobileHandle(mobileHandle.key, mobileHandle.label, values.value);
  return (
    <Root>
      <Slider range {...values} {...props} allowCross={false} handleRender={MobileHandle} />
    </Root>
  );
}

RangeComponent.propTypes = propTypes;

export default RangeComponent;

const Root = styled.div`
  .rc-slider {
    min-width: 130px;

    .rc-slider-rail {
      background-color: ${({ theme }) => theme.colors.text.light};
    }

    .rc-slider-track {
      background-color: ${({ theme }) => theme.colors.primary.dark};
    }

    .rc-slider-handle {
      width: 10px;
      height: 10px;
      margin-top: -3px;
      background-color: ${({ theme }) => theme.colors.primary.dark};
      border: none;
    }
  }

  ${mediaQueries.below.l`
    margin-left: ${handleWidthMobile / 2}px;
    margin-right: ${handleWidthMobile / 2}px;

    .rc-slider {
      height: 28px;

      .rc-slider-rail,
      .rc-slider-track {
        height: 16px;
        border-radius: 16px;
      }

      .rc-slider-handle {
        width: ${handleWidthMobile}px;
        height: 28px;
        margin-top: -5px;
        border-radius: ${handleWidthMobile}px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        z-index:0;

        color: ${({ theme }) => theme.colors.text.white};
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
      }
    }
  `}
`;

import * as types from './types';

export function setFetching(fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      fetching,
    },
  };
}

export function setCountries(countries) {
  return {
    type: types.SET_COUNTRIES,
    payload: {
      countries,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PRICES as ACTION_ID_PRICES, HEADER_MENU as ACTION_ID_HEADER_MENU } from 'state/actionBar/constants/actionsIds';
import { setActiveAction } from 'state/actionBar/actions';
import ActionBarComponent from './ActionBarComponent';

class ActionBar extends Component {
  constructor(props) {
    super(props);

    this.handleOnClickAction = this.handleOnClickAction.bind(this);
  }

  handleOnClickAction(event) {
    const { setActiveAction } = this.props;
    setActiveAction(event.currentTarget.dataset.actionId);
  }

  render() {
    const { actionBar, filtersBarHeight, currentPageComponent, hideActionBar } = this.props;
    const { isFrontpage, isAccountLogin, isAccountBooking, isAgentLogin, isCountrySelection, isCheckoutPage } =
      currentPageComponent;
    const { actions, activeAction } = actionBar;
    const possitionBottom = activeAction === ACTION_ID_PRICES ? filtersBarHeight + 136 : filtersBarHeight;
    const possibleActions = actions.filter((action) => action.id === ACTION_ID_HEADER_MENU);

    if (
      hideActionBar ||
      isCountrySelection ||
      isFrontpage ||
      isAccountLogin ||
      isAgentLogin ||
      isAccountBooking ||
      isCheckoutPage
    ) {
      return null;
    }

    return (
      <ActionBarComponent
        actions={possibleActions}
        activeAction={activeAction}
        possitionBottom={possitionBottom}
        onClick={this.handleOnClickAction}
      />
    );
  }
}

function mapStateToProps(state) {
  const hideActionBar = state.settings.value.checkoutOnlyWhitelabel || state.urlSearchQuery.value.modal_iframe === '1';
  return {
    actionBar: state.actionBar,
    filtersBarHeight: state.refs.filtersBarHeight,
    currentPageComponent: state.currentPageComponent,
    hideActionBar: hideActionBar,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setActiveAction(action) {
      dispatch(setActiveAction(action));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionBar);

import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'next/router';

import HeadComponent from './HeadComponent';

function Head(props) {
  const templateMeta = useSelector((state) => state.template.meta);
  const settings = useSelector((state) => state.settings.value);

  return (
    <HeadComponent
      meta={{
        title: (templateMeta && templateMeta.title) || settings.meta.defaultTitle,
        description: (templateMeta && templateMeta.description) || settings.meta.defaultDescription,
        shareImageUrl: (templateMeta && templateMeta.shareImageUrl) || settings.meta.defaultShareImageUrl,
        siteName: (templateMeta && templateMeta.title) || settings.meta.siteName,
        locale: settings.locale.language,
        favicon: settings.style.favicon,
        txWebsiteId: settings.websiteId,
      }}
      preventIndexing={props.router.asPath.includes('?')}
    />
  );
}

export default withRouter(Head);

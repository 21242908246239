import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTransitionToCheckout } from 'state/templates/hotel/actions';
import HeaderMenuTransitionComponent from './HeaderMenuTransitionComponent';
import { setCurrentStepById } from 'state/checkoutPage/wizard/actions';
import { SELECT_ROOMS as STEP_KEY_SELECT_ROOMS } from 'state/checkoutPage/wizard/constants/stepsKeys';

class HeaderMenuTransition extends Component {
  constructor(props) {
    super(props);

    this.handleOnClickClose = this.handleOnClickClose.bind(this);
  }

  handleOnClickClose() {
    const { checkoutOnlyWhitelabel, urlSearchQuery, setTransitionToCheckout, setCurrentStepById } = this.props;

    if (checkoutOnlyWhitelabel) {
      const { rgBack } = urlSearchQuery;

      if (rgBack) {
        window.location.href = rgBack;
        return;
      }
    }

    setCurrentStepById(STEP_KEY_SELECT_ROOMS);
    setTransitionToCheckout(false);
  }

  render() {
    const { transitionToCheckout, ...props } = this.props;

    return (
      <HeaderMenuTransitionComponent
        headerMenuProps={props}
        transitionToCheckout={transitionToCheckout}
        onClickClose={this.handleOnClickClose}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    transitionToCheckout: state.templates.hotel.transitionToCheckout,
    checkoutOnlyWhitelabel: state.settings.value.checkoutOnlyWhitelabel,
    urlSearchQuery: state.urlSearchQuery.value,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setTransitionToCheckout(value) {
      dispatch(setTransitionToCheckout(value));
    },
    setCurrentStepById(stepId) {
      dispatch(setCurrentStepById(stepId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenuTransition);

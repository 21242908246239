import React, { Component } from 'react';
import { connect } from 'react-redux';
import CountryPickerComponent from './CountryPickerComponent';
import websiteIds from 'constants/website.config';

class CountryPicker extends Component {
  render() {
    const {
      colors,
      isFrontpage,
      isHillmanTravel,
      airlineWhitelabel,
      transitionToCheckout,
      countryPickerItems,
      selectedCountry,
      centerHeaderMenu,
    } = this.props;

    if (countryPickerItems) {
      return (
        <CountryPickerComponent
          isFrontpage={isFrontpage}
          isHillmanTravel={isHillmanTravel}
          airlineWhitelabel={airlineWhitelabel}
          countries={countryPickerItems}
          selectedCountry={selectedCountry}
          onSelectedCountryChange={this.handleOnSelectedCountryChange}
          colors={colors}
          transitionToCheckout={transitionToCheckout}
          centerHeaderMenu={centerHeaderMenu}
        />
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    colors: state.theme.colors,
    isHillmanTravel: [
      websiteIds['Hillman Travel EU'].ID,
      websiteIds['Hillman Travel NL'].ID,
      websiteIds['BE Hillman Travel FR'].ID,
      websiteIds['BE Hillman Travel NL'].ID,
      websiteIds['Hillman Travel FR'].ID,
    ].includes(state.settings.value.websiteId),
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    transitionToCheckout: state.templates.hotel.transitionToCheckout,
    countryPickerItems: state.countryPicker.items,
    selectedCountry: state.countryPicker.selectedItem,
    centerHeaderMenu: state.settings.value.customSettings && state.settings.value.customSettings.centerHeaderMenu,
  };
}

export default connect(mapStateToProps)(CountryPicker);

import { setForceSingleValue as selectOriginsSetForceSingleValue } from 'state/selectOrigins/actions';
import { setForceSingleValue as selectDestinationsSetForceSingleValue } from 'state/selectDestinations/actions';

export default function evalForceSingleValueForOriginsAndDestinations(destinationIsHotel) {
  return (dispatch, getState) => {
    const survivalMode = getState().settings.value.survivalMode;
    const singleDestinationAvailable = getState().selectDestinations.destinations.length === 1;
    const selectOriginsForceSingleValue = getState().selectOrigins.forceSingleValue;
    const selectDestinationsForceSingleValue = getState().selectDestinations.forceSingleValue;

    if (destinationIsHotel || survivalMode) {
      if (!selectOriginsForceSingleValue) {
        dispatch(selectOriginsSetForceSingleValue(true));
      }
    } else if (!destinationIsHotel) {
      if (selectOriginsForceSingleValue) {
        dispatch(selectOriginsSetForceSingleValue(false));
      }
    }

    if (destinationIsHotel || survivalMode || singleDestinationAvailable) {
      if (!selectDestinationsForceSingleValue) {
        dispatch(selectDestinationsSetForceSingleValue(true));
      }
    } else if (!destinationIsHotel) {
      if (selectDestinationsForceSingleValue) {
        dispatch(selectDestinationsSetForceSingleValue(false));
      }
    }
  };
}

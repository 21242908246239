import { getUpgradesByUpgradeToken } from 'state/checkoutPage/flightsDetails/selectors';
import * as passengerTypes from 'state/checkoutPage/travelers/constants/passengerTypes';

export default function getSelectedFlightsExtrasObservedProps(selectedFlightsExtras, flightsDetailsValue) {
  if (!selectedFlightsExtras || !flightsDetailsValue) {
    return;
  }

  const upgradeTokens = Object.values(selectedFlightsExtras)
    .map((item) => Object.values(item))
    .flat()
    .flat();
  const uniqueUpgradeTokens = [...new Set(upgradeTokens)];
  const upgrades = getUpgradesByUpgradeToken(uniqueUpgradeTokens, flightsDetailsValue);
  const transformUpgrade = getTransformUpgradeCallback(upgrades);
  const result = [];

  Object.values(passengerTypes).forEach((passengerType) => {
    Object.keys(selectedFlightsExtras[passengerType]).forEach((passengerIndex) => {
      result.push({
        traveler: {
          passengerType,
          passengerIndex,
        },
        upgrades: [selectedFlightsExtras[passengerType][passengerIndex]].flat().map(transformUpgrade),
      });
    });
  });

  return result;
}

function getTransformUpgradeCallback(upgrades) {
  return function transformUpgrade(upgradeToken) {
    return {
      direction: upgrades[upgradeToken].direction,
      type: upgrades[upgradeToken].type,
      perPassenger: upgrades[upgradeToken].perPassenger,
      allowedPaxTypes: upgrades[upgradeToken].allowedPaxTypes,
      piece: {
        quantity: upgrades[upgradeToken].piece.quantity,
        maxWeight: upgrades[upgradeToken].piece.maxWeight,
      },
    };
  };
}

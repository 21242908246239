import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  onClick: PropTypes.func,
};

function SecondaryButtonComponent({ onClick, children, ...props }) {
  return (
    <Root onClick={onClick} {...props}>
      {children}
    </Root>
  );
}

SecondaryButtonComponent.propTypes = propTypes;

export default SecondaryButtonComponent;

const Root = styled.button`
  padding: 8px 24px;
  background-color: ${({ theme }) => theme.colors.background.light};
  border: 1px solid ${({ theme }) => theme.colors.inputs.border.default};
  border-radius: 10px;
  font-size: 1.4em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.default};
  cursor: pointer;
  transition: all 0.15 ease-out;

  ${({ theme }) =>
    !theme.touchDevice &&
    `
    &:hover {
      background-color: ${theme.colors.inputs.border.default};
      color: ${theme.colors.text.white};
    }
  `}

  &:disabled,
  [disabled] {
    cursor: initial;
    background-color: ${({ theme }) => theme.colors.background.disabled};
    color: ${({ theme }) => theme.colors.inputs.border.default};

    ${({ theme }) =>
      !theme.touchDevice &&
      `
      &:hover {
        background-color: ${theme.colors.background.disabled};
        color: ${theme.colors.inputs.border.default};
      }
    `}
  }

  ${({ styles }) => styles};
`;

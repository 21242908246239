import { setCalendarDates } from '../actions';
import searchQueryLS from 'managers/localStorage/searchQuery/searchQueryLS';
import searchPropertiesKeys from 'state/_constants/searchPropertiesKeys';

export const updateSelectedDates = (calendarDates) => {
  return (dispatch) => {
    dispatch(setCalendarDates(calendarDates));
    searchQueryLS.update(searchPropertiesKeys.SELECTED_DATES_KEY, calendarDates);
  };
};

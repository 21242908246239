import * as types from './types';

export function setScrollToTimeStamp(scrollToTimeStamp) {
  return {
    type: types.SET_SCROLL_TO_TIME_STAMP,
    payload: {
      scrollToTimeStamp,
    },
  };
}
export function setIsDataFetching(isDataFetching) {
  return {
    type: types.SET_IS_DATA_FETCHING,
    payload: {
      isDataFetching,
    },
  };
}

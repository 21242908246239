import { resetSearchResultsFilters, setDisplayMap } from 'state/searchResultsFilters/actions';
import { fetchSearchResults } from '../actions';

export default function resetSearchResultsFiltersAndFetchSearchResults() {
  return (dispatch, getState) => {
    const displayMap = getState().searchResultsFilters.displayMap;

    dispatch(resetSearchResultsFilters());
    dispatch(fetchSearchResults());

    if (displayMap) {
      dispatch(setDisplayMap(false));
    }
  };
}

import { faArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import { faArrowsAltV } from '@fortawesome/pro-solid-svg-icons/faArrowsAltV';
import { faBaby } from '@fortawesome/pro-solid-svg-icons/faBaby';
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars';
import { faBackpack } from '@fortawesome/pro-solid-svg-icons/faBackpack';
import { faBed } from '@fortawesome/pro-solid-svg-icons/faBed';
import { faBinoculars } from '@fortawesome/pro-solid-svg-icons/faBinoculars';
import { faBriefcaseMedical } from '@fortawesome/pro-solid-svg-icons/faBriefcaseMedical';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding';
import { faBusAlt } from '@fortawesome/pro-solid-svg-icons/faBusAlt';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt';
import { faCar } from '@fortawesome/pro-solid-svg-icons/faCar';
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair';
import { faChartBar } from '@fortawesome/pro-solid-svg-icons/faChartBar';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faChild } from '@fortawesome/pro-solid-svg-icons/faChild';
import { faCity } from '@fortawesome/pro-solid-svg-icons/faCity';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faCocktail } from '@fortawesome/pro-solid-svg-icons/faCocktail';
import { faCompass } from '@fortawesome/pro-solid-svg-icons/faCompass';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { faEuroSign } from '@fortawesome/pro-solid-svg-icons';
import { faMap } from '@fortawesome/pro-solid-svg-icons';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons';
import { faDumbbell } from '@fortawesome/pro-solid-svg-icons/faDumbbell';
import { faExchangeAlt } from '@fortawesome/pro-solid-svg-icons/faExchangeAlt';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt';
import { faFemale } from '@fortawesome/pro-solid-svg-icons/faFemale';
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter';
import { faFlagAlt } from '@fortawesome/pro-solid-svg-icons/faFlagAlt';
import { faGasPump } from '@fortawesome/pro-solid-svg-icons/faGasPump';
import { faGlassCitrus } from '@fortawesome/pro-solid-svg-icons/faGlassCitrus';
import { faGlassMartiniAlt } from '@fortawesome/pro-solid-svg-icons/faGlassMartiniAlt';
import { faHamburger } from '@fortawesome/pro-solid-svg-icons/faHamburger';
import { faHandHoldingHeart } from '@fortawesome/pro-solid-svg-icons/faHandHoldingHeart';
import { faHeart as faHeartRegular } from '@fortawesome/pro-regular-svg-icons/faHeart';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faLifeRing } from '@fortawesome/pro-solid-svg-icons/faLifeRing';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faMale } from '@fortawesome/pro-solid-svg-icons/faMale';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { faMapMarkedAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkedAlt';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faMoneyBillAlt } from '@fortawesome/pro-solid-svg-icons/faMoneyBillAlt';
import { faMoon } from '@fortawesome/pro-solid-svg-icons/faMoon';
import { faMousePointer } from '@fortawesome/pro-solid-svg-icons/faMousePointer';
import { faMusic } from '@fortawesome/pro-solid-svg-icons/faMusic';
import { faParking } from '@fortawesome/pro-solid-svg-icons/faParking';
import { faPen } from '@fortawesome/pro-solid-svg-icons/faPen';
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone';
import { faPlane } from '@fortawesome/pro-solid-svg-icons/faPlane';
import { faPlaneArrival } from '@fortawesome/pro-solid-svg-icons/faPlaneArrival';
import { faPlaneDeparture } from '@fortawesome/pro-solid-svg-icons/faPlaneDeparture';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faRaindrops } from '@fortawesome/pro-solid-svg-icons/faRaindrops';
import { faRedo } from '@fortawesome/pro-solid-svg-icons/faRedo';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { faShieldAlt } from '@fortawesome/pro-solid-svg-icons/faShieldAlt';
import { faShieldAlt as faShieldAltDuotone } from '@fortawesome/pro-duotone-svg-icons/faShieldAlt';
import { faShieldVirus } from '@fortawesome/pro-solid-svg-icons/faShieldVirus';
import { faShieldVirus as faShieldVirusDuotone } from '@fortawesome/pro-duotone-svg-icons/faShieldVirus';
import { faSmokingBan } from '@fortawesome/pro-solid-svg-icons/faSmokingBan';
import { faSnowflake } from '@fortawesome/pro-solid-svg-icons/faSnowflake';
import { faSpa } from '@fortawesome/pro-solid-svg-icons/faSpa';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faSuitcase } from '@fortawesome/pro-solid-svg-icons/faSuitcase';
import { faSuitcaseRolling } from '@fortawesome/pro-solid-svg-icons/faSuitcaseRolling';
import { faSwimmer } from '@fortawesome/pro-solid-svg-icons/faSwimmer';
import { faSwimmingPool } from '@fortawesome/pro-solid-svg-icons/faSwimmingPool';
import { faShareAlt } from '@fortawesome/pro-solid-svg-icons/faShareAlt';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { faTram } from '@fortawesome/pro-solid-svg-icons/faTram';
import { faUmbrellaBeach } from '@fortawesome/pro-solid-svg-icons/faUmbrellaBeach';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils';
import { faWallet } from '@fortawesome/pro-solid-svg-icons/faWallet';
import { faWifi } from '@fortawesome/pro-solid-svg-icons/faWifi';
import { faIdCard } from '@fortawesome/pro-solid-svg-icons/faIdCard';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons/faGlobe';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faList } from '@fortawesome/pro-solid-svg-icons/faList';
import { tripxFaCarDoor } from 'static/icons/tripxFaIco/tripxFaCarDoor';
import { tripxFaTransmission } from 'static/icons/tripxFaIco/tripxFaTransmission';
import { faCarBus } from '@fortawesome/pro-solid-svg-icons/faCarBus';
import { faMoonStars } from '@fortawesome/pro-solid-svg-icons/faMoonStars';
import { faMoonCloud } from '@fortawesome/pro-solid-svg-icons/faMoonCloud';
import { faHotel } from '@fortawesome/pro-solid-svg-icons/faHotel';
import { faAlignLeft } from '@fortawesome/pro-solid-svg-icons/faAlignLeft';
import { faOven } from '@fortawesome/pro-solid-svg-icons/faOven';

library.add([
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltV,
  faBaby,
  faBars,
  faBackpack,
  faBed,
  faBinoculars,
  faBriefcaseMedical,
  faBuilding,
  faBusAlt,
  faCalendarAlt,
  faCar,
  faCity,
  faChair,
  faChartBar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faClock,
  faCompass,
  faCopy,
  faDumbbell,
  faExchangeAlt,
  faExclamationCircle,
  faExternalLinkAlt,
  faFemale,
  faFilter,
  faFlagAlt,
  faGasPump,
  faHamburger,
  faHandHoldingHeart,
  faInfoCircle,
  faKey,
  faLifeRing,
  faLink,
  faMale,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faMinus,
  faMoneyBillAlt,
  faMoon,
  faMousePointer,
  faParking,
  faPen,
  faPhone,
  faPlane,
  faPlaneArrival,
  faPlaneDeparture,
  faPlus,
  faQuestionCircle,
  faRedo,
  faSearch,
  faShieldAlt,
  faShieldAltDuotone,
  faShieldVirus,
  faShieldVirusDuotone,
  faSnowflake,
  faSpa,
  faStar,
  faSuitcase,
  faSuitcaseRolling,
  faSwimmer,
  faSwimmingPool,
  faShareAlt,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTram,
  faUmbrellaBeach,
  faUser,
  faUtensils,
  faWallet,
  faWifi,
  tripxFaCarDoor,
  tripxFaTransmission,
  faGlassMartiniAlt,
  faCocktail,
  faGlassCitrus,
  faSmokingBan,
  faRaindrops,
  faMusic,
  faGlobe,
  faIdCard,
  faCarBus,
  faMoonStars,
  faMoonCloud,
  faHotel,
  faEuroSign,
  faSlidersH,
  faMap,
  faAlignLeft,
  faList,
  faHeartRegular,
  faOven,
]);

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Margin } from 'styled-components-spacing';
import { FormattedMessage } from 'i18n';
import MediaQuery from 'react-responsive';
import { withContext } from 'i18n/context';
import { injectIntl } from 'react-intl';

import SelectModal from 'components/inputs/select/selectModal/SelectModalComponent';
import Spinner from 'components/spinners/InfiniteCircleDots';
import Portal from 'components/portal/Portal';
import Input from 'components/inputs/input_v2/Input';
import FooterComponent from './components/footer/FooterComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';
import { breakpoints } from 'styles/themes/main';

function MenuListComponent({
  open,
  inputValue,
  onClickClose,
  onChangeInputValue,
  onMouseDown,
  onTouchEnd,
  selectedOptions,
  notSelectedOptions,
  hotelQuerySearchFetching,
  placeholder,
  inputPlaceholder,
  intl,
}) {
  return (
    <MediaQuery maxWidth={breakpoints.above.desktop}>
      {(matches) => (
        <Portal usePortal={matches}>
          <SelectModal open={open}>
            <Container>
              <Root>
                <Header>
                  <Title>
                    <FormattedMessage {...placeholder} />
                  </Title>
                  <Close onClick={onClickClose}>
                    <FontAwesomeIcon icon='chevron-left' />
                  </Close>
                </Header>
                <InputWrapper>
                  <SearchIcon icon={'search'} />
                  <InputStyled
                    name={'menu-list-input'}
                    type={'text'}
                    value={inputValue}
                    onChange={onChangeInputValue}
                    onMouseDown={onMouseDown}
                    onTouchEnd={onTouchEnd}
                    placeholder={intl.formatMessage(inputPlaceholder)}
                  />
                </InputWrapper>
                {hotelQuerySearchFetching && notSelectedOptions.length === 0 && (
                  <Loader>
                    <Margin right='0.5' inline />
                    <SpinnerWrap>
                      <Spinner />
                    </SpinnerWrap>
                    <Margin right='0.5' inline />
                    <FormattedMessage
                      id={'loadingHotels.label'}
                      description={'Loading hotels...'}
                      defaultMessage={'Loading hotels...'}
                      tagName={'span'}
                    />
                  </Loader>
                )}
                {selectedOptions.length > 0 && (
                  <ChildrenContainer isSelected={true}>{selectedOptions}</ChildrenContainer>
                )}
                {notSelectedOptions.length > 0 && <ChildrenContainer>{notSelectedOptions}</ChildrenContainer>}
              </Root>
              <FixedFooter onRequestClose={onClickClose} />
            </Container>
          </SelectModal>
        </Portal>
      )}
    </MediaQuery>
  );
}

export default injectIntl(withContext()(MenuListComponent));

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const Root = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.background.greyV2};
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.darkGrey};
  padding-bottom: 20px;

  ${mediaQueries.fromAndAbove.desktop`
    user-select: initial;
    display: initial;
    flex-direction: initial;
    width: initial;
    background: initial;
  `}
`;

const Header = styled.div`
  z-index: 2;
  min-height: 48px;
  position: sticky;
  top: 0;
  padding: 18px;
  background: ${({ theme }) => theme.colors.background.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.greyV3};
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQueries.fromAndAbove.desktop`
    display: none;
  `}
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-weight: 600px;
`;

const Close = styled.div`
  display: flex;
  height: 100%;
  color: ${({ theme }) => theme.colors.adjacent.default};
  position: absolute;
  top: 18px;
  left: 18px;
  font-size: 20px;
`;

const ChildrenContainer = styled.div`
  background: ${({ theme, isSelected }) => (isSelected ? theme.colors.secondary.light : theme.colors.background.light)};
  box-shadow: 0px 4px 6px rgba(231, 231, 231, 0.1);
  margin: 0 18px;
  border-radius: 8px;
  padding: 6px 3px 3px 3px;

  max-height: ${({ isSelected }) => (isSelected ? '280px' : 'unset')};
  overflow-y: ${({ isSelected }) => (isSelected ? 'auto' : 'unset')};

  :not(:last-child) {
    margin-bottom: 18px;
  }
  .styled-select__group-heading {
    color: ${({ theme }) => theme.colors.text.grey};
    font-style: italic;
    font-weight: 400;
  }
`;

const InputWrapper = styled.div`
  padding: 0 18px;
  margin: 26px 0 18px 0;
`;

const Loader = styled.div`
  span {
    display: flex;
    justify-content: center;
    font-style: italic;
    color: ${({ theme }) => theme.colors.primary.dark};
    animation: react-placeholder-pulse 1.5s infinite;
  }
`;

const SpinnerWrap = styled.div`
  height: 100%;
  vertical-align: middle;
  display: flex;
  justify-content: center;

  div {
    width: 5px;
    height: 5px;
    margin: 20px;
    font-size: 7px;
  }
`;

const InputStyled = styled(Input)`
  border: 1px solid ${({ theme }) => theme.colors.background.greyV3};
  padding: 12px 18px 12px 40px;
  height: 46px;
  border-radius: 8px;
  font-size: 16px;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: relative;
  top: 30px;
  left: 18px;
  color: ${({ theme }) => theme.colors.primary.darkGrey};
`;

const FixedFooter = styled(FooterComponent)`
  position: sticky;
  bottom: 0;
`;

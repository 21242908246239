import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedCurrency } from 'i18n';

const CalendarPriceInfo = ({ calendarPriceInfo }) => (
  <PriceInfoWrap>
    <PriceInfo>
      {calendarPriceInfo.exactDepartureDate ? (
        <FormattedMessage
          id='selectDates.component.footer.priceInfo.exactDeparture'
          description='Prices for departure date {exactDeparture}'
          defaultMessage='Prices for departure date {exactDeparture}'
          values={{
            exactDeparture: calendarPriceInfo.exactDepartureDate,
          }}
        />
      ) : (
        <FormattedMessage
          id='selectDates.component.footer.priceInfo.exactStayDays'
          description='Prices from date for a duration of {exactStayDays} days.'
          defaultMessage='Prices from date for a duration of {exactStayDays} days.'
          values={{
            exactStayDays: calendarPriceInfo.exactStayDays,
          }}
        />
      )}
    </PriceInfo>
    {calendarPriceInfo.lowestPricesByRange && (
      <ColoredPriceRangesWrap>
        {Object.entries(calendarPriceInfo.lowestPricesByRange).map(([key, value]) => (
          <ColoredPriceRanges key={key} priceInRange={key}>
            <FormattedCurrency value={value} /> +
          </ColoredPriceRanges>
        ))}
      </ColoredPriceRangesWrap>
    )}
  </PriceInfoWrap>
);

export default CalendarPriceInfo;

// Styled components
const PriceInfoWrap = styled.div`
  display: flex;
`;

const PriceInfo = styled.div`
  flex: 1;
`;
const ColoredPriceRangesWrap = styled.div`
  display: flex;
  align-items: center;
`;
const ColoredPriceRanges = styled.div`
  padding: 2px 4px;
  background-color: ${({ theme, priceInRange }) => theme.colors.calendarDays[priceInRange]};
  font-size: 13px;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.text.white};
  margin-right: 0.5rem;
  font-weight: 700;
`;

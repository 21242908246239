'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'transmission';
var width = 20;
var height = 14.96;
var ligatures = [];
var unicode = 'tf002';
var svgPathData =
  'M18.8,12.8V7.2C19.5,6.7,20,5.9,20,5c0-1.4-1.1-2.5-2.5-2.5C16.1,2.5,15,3.6,15,5c0,0.9,0.5,1.7,1.3,2.2v1.6h-5V7.2 C12,6.7,12.5,5.9,12.5,5c0-1.4-1.1-2.5-2.5-2.5C8.6,2.5,7.5,3.6,7.5,5c0,0.9,0.5,1.7,1.3,2.2v1.6H2.5c-0.7,0-1.3,0.6-1.3,1.3v2.8 C0.5,13.3,0,14.1,0,15c0,1.4,1.1,2.5,2.5,2.5C3.9,17.5,5,16.4,5,15c0-0.9-0.5-1.7-1.3-2.2v-1.6h5v1.6C8,13.3,7.5,14.1,7.5,15 c0,1.4,1.1,2.5,2.5,2.5c1.4,0,2.5-1.1,2.5-2.5c0-0.9-0.5-1.7-1.3-2.2v-1.6h5v1.6C15.5,13.3,15,14.1,15,15c0,1.4,1.1,2.5,2.5,2.5 c1.4,0,2.5-1.1,2.5-2.5C20,14.1,19.5,13.3,18.8,12.8L18.8,12.8z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

exports.tripxFaTransmission = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;

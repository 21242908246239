import { useEffect, useRef } from 'react';

export default function useOnOutsideClick({ handleOnClick, isOpen, skip = false }) {
  const ref = useRef(null);

  useEffect(() => {
    if (skip) {
      return;
    }

    function handleClickOutside(event) {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        handleOnClick();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleOnClick, skip]);

  return { ref };
}

import React, { Component } from 'react';
import Router from 'next/router';
import { connect } from 'react-redux';
import { FRONTPAGE as ROUTE_KEY_FRONTPAGE } from 'constants/routesKeys';
import { HOTELPAGE } from 'constants/pages';
import { getRoutePathByRouteKey } from 'state/routes/operations';
import resetCheckoutState from 'state/checkoutPage/wizard/operations/resetCheckoutState';
import AbandonedCartComponent from './AbandonedCartComponent';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import { CLICKED_ABANDONED_CART } from 'managers/gtmEventTracking/constants/eventsNames';

class AbandonedCart extends Component {
  constructor(props) {
    super(props);

    this.handleOnClickFinishBooking = this.handleOnClickFinishBooking.bind(this);
  }

  handleOnClickFinishBooking() {
    const { uuid, hotelInfo, getRoutePathByRouteKey, resetCheckoutState, executeTrackingEvent } = this.props;

    executeTrackingEvent(CLICKED_ABANDONED_CART, { hotelInfo });
    resetCheckoutState(true);

    const pathToFrontpage = getRoutePathByRouteKey(ROUTE_KEY_FRONTPAGE).replace(/\/([^/]*)$/, '$1');
    const hotelPageUrl = `${pathToFrontpage}${hotelInfo.slug}?abandonedCart=${uuid}`;

    Router.push(
      {
        pathname: HOTELPAGE,
        query: {
          data: {
            requestParams: hotelInfo.slug.replace(/^\/c\//, ''), //reg-ex for removing `/c/` from the slug for request params in getInitialProps on checkout page
          },
        },
      },
      hotelPageUrl
    );
  }

  render() {
    const { uuid, hotelInfo, airlineWhitelabel, websiteId, data } = this.props;
    const cartWebsiteId = data && data.websiteId;

    if (cartWebsiteId !== websiteId) {
      return null;
    }

    return (
      <AbandonedCartComponent
        uuid={uuid}
        name={hotelInfo.name}
        image={hotelInfo.images[0]}
        onClickFinishBooking={this.handleOnClickFinishBooking}
        airlineWhitelabel={airlineWhitelabel}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    websiteId: state.settings.value.websiteId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRoutePathByRouteKey(routeKey) {
      return dispatch(getRoutePathByRouteKey(routeKey));
    },
    resetCheckoutState(includeHotelsSearch) {
      dispatch(resetCheckoutState(includeHotelsSearch));
    },
    executeTrackingEvent(eventName, eventData) {
      dispatch(executeTrackingEvent(eventName, eventData));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AbandonedCart);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AGENT_ID as AGENT_ID_COOKIE } from 'constants/cookiesKeys';
import { agentActions, agentOperations } from 'state/agent';
import getCookie from 'utils/cookie/getCookie';
import AgentTopBarComponent from './AgentTopBarComponent';

class AgentTopBar extends Component {
  componentDidMount() {
    const agentId = getCookie(AGENT_ID_COOKIE);

    if (agentId) {
      this.props.setAgentId(agentId);
    }
  }

  render() {
    const { agentId, currentPageComponent, handleOnClickLogout } = this.props;
    const { isCountrySelection, isAgentLogin } = currentPageComponent;

    if (!agentId || isCountrySelection || isAgentLogin) {
      return null;
    }

    return <AgentTopBarComponent agentId={agentId} onClickLogout={handleOnClickLogout} />;
  }
}

function mapStateToProps(state) {
  return {
    agentId: state.agent.agentId,
    currentPageComponent: state.currentPageComponent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setAgentId(agentId) {
      dispatch(agentActions.setAgentId(agentId));
    },
    handleOnClickLogout() {
      dispatch(agentOperations.agentLogout());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentTopBar);

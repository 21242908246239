import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';

export const CustomCloseIcon = ({ isFrontpage }) => {
  return (
    <Root isFrontpage={isFrontpage}>
      <span>&times;</span>
    </Root>
  );
};

const Root = styled.div`
  color: ${({ theme }) => theme.colors.primary.dark};
  margin-top: 6px;
  margin-right: ${({ isFrontpage }) => (isFrontpage ? '-1px' : '2px')};
  font-size: 24px;
  font-weight: 600;

  ${mediaQueries.fromAndBelow.tablet`
   color: ${({ theme }) => theme.colors.background.greyV4};
   margin-top: ${({ isFrontpage }) => (isFrontpage ? 0 : '10px')};
   margin-right: ${({ isFrontpage }) => (isFrontpage ? '-4px' : '6px')};
   font-weight: 500;
  `}
`;

import HttpClient from 'httpClient';
import proxy from 'httpClient/requests';

export function getCalendarHighlightsForOrigin(origin) {
  return HttpClient.get(`/wp-json/tripx/v1/calendar-highlights/${origin}`);
}

export function getCalendarHighlightsForOriginAndDestination(origin, destination) {
  return proxy({
    httpMethod: 'GET',
    timeout: 20,
    version: 3,
    apiMethod: 'calendarHighlights',
    queryString: { departureAirports: origin, destinationCodes: destination },
  });
}

export function getAirlineCalendarForOrigin(origin) {
  return HttpClient.get(`/wp-json/tripx/v1/calendar-highlights-airline/${origin}`);
}

export function getAirlineCalendarForOriginAndDestination(origin, destination) {
  return HttpClient.get(`/wp-json/tripx/v1/calendar-highlights-airline/${origin}-${destination}`);
}

import {
  NORWEGIAN_HOLIDAYS as NORWEGIAN_HOLIDAYS_AIRLINE,
  AIRMALTA_HOLIDAYS as AIRMALTA_HOLIDAYS_AIRLINE,
  AIRBALTIC_HOLIDAYS as AIRBALTIC_HOLIDAYS_AIRLINE,
  CY_HOLIDAY_DEALS,
  NOVATURAS_LT,
  NOVATURAS_LV,
  NOVATURAS_ET,
} from 'constants/airlines';
import {
  PACKAGES_DOHOP as PACKAGES_DOHOP_WHITELABEL,
  RESEGUIDEN as RESEGUIDEN_WHITELABEL,
  RESEGUIDEN_NO as RESEGUIDEN_NO_WHITELABEL,
  HILLMAN_TRAVEL_EU as HILLMAN_TRAVEL_EU_WHITELABEL,
  HILLMAN_TRAVEL_NL as HILLMAN_TRAVEL_NL_WHITELABEL,
  AMANO_HOLIDAYS as AMANO_HOLIDAYS_WHITELABEL,
  MATKAT_OFFERILLA as MATKAT_OFFERILLA_WHITELABEL,
  HOLIDAY_PIRATES as HOLIDAY_PIRATES_WHITELABEL,
  LOTHOTEL_WAKACJE as WAKACJE_WHITELABEL,
  HOLIDAYMIX_INVIA as HOLIDAYMIX_INVIA_WHITELABEL,
} from 'constants/whitelabels';

export default {
  [NORWEGIAN_HOLIDAYS_AIRLINE]: {
    sansSerif: 'ApercuProRegular, Helvetica, sans-serif',
    display: 'ApercuProBold, Helvetica, sans-serif',
    displaySubtle: 'ApercuProBold, Helvetica, sans-serif',
    fontSize: '60%',
  },
  [AIRMALTA_HOLIDAYS_AIRLINE]: {
    sansSerif: 'AllerRegular, Calibri, sans-serif',
    displaySubtle: 'AllerBold, Calibri, sans-serif',
    montserrat: 'MontserratSemiBold, Calibri, sans-serif',
    display: 'MontserratSemiBold, Calibri, sans-serif',
  },
  [AIRBALTIC_HOLIDAYS_AIRLINE]: {
    sansSerif: 'Roboto,Helvetica Neue,Helvetica,Trebuchet MS,Arial,sans-serif',
    displaySubtle: 'Roboto Medium,Helvetica Neue,Helvetica,Trebuchet MS,Arial,sans-serif',
    display: 'Roboto Medium,Helvetica Neue,Helvetica,Trebuchet MS,Arial,sans-serif',
  },
  [PACKAGES_DOHOP_WHITELABEL]: {
    sansSerif: 'DohopText, Arial, sans-serif',
    displaySubtle: 'DohopText, Arial, sans-serif',
    display: 'DohopText, Arial, sans-serif',
  },
  [RESEGUIDEN_WHITELABEL]: {
    sansSerif: 'OpenSans, sans-serif',
    displaySubtle: 'FiraSans, sans-serif',
    display: 'FiraSans, sans-serif',
  },
  [RESEGUIDEN_NO_WHITELABEL]: {
    sansSerif: 'OpenSans, sans-serif',
    displaySubtle: 'FiraSans, sans-serif',
    display: 'FiraSans, sans-serif',
  },
  [CY_HOLIDAY_DEALS]: {
    sansSerif: 'PF Centro Sans Pro Regular,sans-serif',
    displaySubtle: 'PF Centro Sans Pro Regular,sans-serif',
    display: 'PF Centro Sans Pro Regular,sans-serif',
  },
  [HILLMAN_TRAVEL_EU_WHITELABEL]: {
    sansSerif: 'Evolventa, sans-serif',
    displaySubtle: 'Evolventa, sans-serif',
    display: 'Evolventa, sans-serif',
  },
  [HILLMAN_TRAVEL_NL_WHITELABEL]: {
    sansSerif: 'Evolventa, sans-serif',
    displaySubtle: 'Evolventa, sans-serif',
    display: 'Evolventa, sans-serif',
  },
  [AMANO_HOLIDAYS_WHITELABEL]: {
    sansSerif: 'Gotham-Light,sans-serif',
    displaySubtle: 'Gotham-Medium,sans-serif',
    display: 'Gotham-Medium,sans-serif',
  },

  [MATKAT_OFFERILLA_WHITELABEL]: {
    sansSerif: 'SourceSansPro-Regular,sans-serif',
    displaySubtle: 'PlayfairDisplay-Regular,sans-serif',
    display: 'PlayfairDisplay-Regular,sans-serif',
  },
  [HOLIDAY_PIRATES_WHITELABEL]: {
    sansSerif: 'OpenSans, sans-serif',
    displaySubtle: 'OpenSans, sans-serif',
    display: 'OpenSans, sans-serif',
  },
  [WAKACJE_WHITELABEL]: {
    serif: '"Poppins", sans-serif',
    sansSerif: '"Poppins", sans-serif',
    displaySubtle: '"Poppins", sans-serif',
    display: '"Poppins", sans-serif',
    montserrat: '"Poppins", sans-serif',
  },
  [NOVATURAS_LT]: {
    sansSerif: 'ESRebondGrotesqueRegular, sans-serif',
    displaySubtle: 'ESRebondGrotesqueRegular, sans-serif',
    display: 'ESRebondGrotesqueRegular, sans-serif',
    fontSize: '55%',
  },
  [NOVATURAS_LV]: {
    sansSerif: 'ESRebondGrotesqueRegular, sans-serif',
    displaySubtle: 'ESRebondGrotesqueRegular, sans-serif',
    display: 'ESRebondGrotesqueRegular, sans-serif',
    fontSize: '55%',
  },
  [NOVATURAS_ET]: {
    sansSerif: 'ESRebondGrotesqueRegular, sans-serif',
    displaySubtle: 'ESRebondGrotesqueRegular, sans-serif',
    display: 'ESRebondGrotesqueRegular, sans-serif',
    fontSize: '55%',
  },
  [HOLIDAYMIX_INVIA_WHITELABEL]: {
    sansSerif: 'Roboto, sans-serif',
    displaySubtle: 'Roboto, sans-serif',
    display: 'Roboto, sans-serif',
  },
};

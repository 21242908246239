import React from 'react';
import styled from 'styled-components';
import htmlParser from 'utils/html/htmlParser';
import { mediaQueries } from 'styles/utils/mediaQueries';

function TopMessageComponent({ severity, text }) {
  return <Root severity={severity}>{htmlParser(text)}</Root>;
}

export default TopMessageComponent;

const Root = styled.div`
  position: relative;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  padding: 5px 25px;
  background-color: ${({ theme, severity }) => theme.colors.background.level[severity][0]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.default};
  font-size: 1.8em;
  color: ${({ theme, severity }) => theme.colors.background.level[severity][1]};
  line-height: 1.4em;
  text-align: center;

  ${mediaQueries.fromAndBelow.desktop`
    font-size: 1.1em;
  `}
`;

import { getAllCountries } from 'httpClient/requests/countries';
import * as fetchingStatus from 'constants/status/fetchingStatus';
import { setFetching, setWebsites } from './actions';

export default function fetchWebsites() {
  return async (dispatch, getState) => {
    const { fetching } = getState().webSitePicker;
    const websiteId = getState().settings.value.websiteId;
    if (fetching === fetchingStatus.IN_PROGRESS) {
      return;
    }

    dispatch(setFetching(fetchingStatus.IN_PROGRESS));

    try {
      const { data } = await getAllCountries(websiteId);

      dispatch(setWebsites(data));
      dispatch(setFetching(fetchingStatus.SUCCEEDED));
    } catch (error) {
      dispatch(setFetching(fetchingStatus.FAILED));
      // console.error(error);
    }
  };
}

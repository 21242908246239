import axios from 'axios';
import _difference from 'lodash/difference';
import { getDestinationOrigins } from 'httpClient/requests/selectOrigins';
import updateAvailableDestinations from 'state/selectDestinations/operations/updateAvailableDestinations';
import getSelectedDestinationsValues from 'state/selectDestinations/selectors/getSelectedDestinationsValues';
import updateSelectedOrigins from './updateSelectedOrigins';
import { setAvailableOrigins, setAvailableOriginsMap } from '../actions';
import getAvailableOrigins from '../selectors/getAvailableOrigins';
import { HOTEL_ONLY } from 'constants/selectValues';

export default function updateAvailableOriginsForSelectedDestinations() {
  return (dispatch, getState) => {
    const { settings, selectOrigins, selectDestinations } = getState();
    const { availableOriginsMap } = selectOrigins;
    const { selectedDestinations } = selectDestinations;
    const lastSelectedDestination = selectedDestinations[selectedDestinations.length - 1];

    if (lastSelectedDestination && lastSelectedDestination.hotel) {
      return;
    }

    const availableDestinationOrigins = Object.keys(availableOriginsMap);
    const selectedDestinationsCodes = getSelectedDestinationsValues(selectedDestinations);
    const missingDestinationOrigins = _difference(selectedDestinationsCodes, availableDestinationOrigins);

    if (missingDestinationOrigins.length > 0) {
      const requests = missingDestinationOrigins.map((destination) => getDestinationOrigins(destination));

      axios
        .all(requests)
        .then((response) => {
          const mappedDestinationOrigins = response.reduce((accumulator, currentValue, index) => {
            accumulator[missingDestinationOrigins[index]] = currentValue.data;
            return accumulator;
          }, {});
          const _availableOriginsMap = {
            ...availableOriginsMap,
            ...mappedDestinationOrigins,
          };
          const selectedDestinations = getState().selectDestinations.selectedDestinations;
          const selectedDestinationsCodes = getSelectedDestinationsValues(selectedDestinations);
          const availableOrigins = getAvailableOrigins(_availableOriginsMap, selectedDestinationsCodes);
          const forceSingleValue = !settings.value.survivalMode
            ? selectedDestinationsCodes.length > 0 && availableOriginsMap.length === 0
            : true;
          dispatch(setAvailableOriginsMap(availableOrigins, _availableOriginsMap, forceSingleValue));
          dispatch(updateSelectedOriginsBasedOnAvailableOrigins());
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const availableOrigins = getAvailableOrigins(availableOriginsMap, selectedDestinationsCodes);
      const forceSingleValue = !settings.value.survivalMode
        ? selectedDestinationsCodes.length > 0 && availableOriginsMap.length === 0
        : true;

      dispatch(setAvailableOrigins(availableOrigins, forceSingleValue));
      dispatch(updateSelectedOriginsBasedOnAvailableOrigins());
    }
  };
}

function updateSelectedOriginsBasedOnAvailableOrigins() {
  return (dispatch, getState) => {
    const { availableOrigins, selectedOrigins } = getState().selectOrigins;

    if (availableOrigins.length > 0 && selectedOrigins.length > 0) {
      const filteredSelectedOrigins = selectedOrigins.filter(
        ({ value }) => availableOrigins.indexOf(value) !== -1 || value === HOTEL_ONLY
      );

      if (selectedOrigins.length !== filteredSelectedOrigins.length) {
        dispatch(updateSelectedOrigins(filteredSelectedOrigins));
        dispatch(updateAvailableDestinations(filteredSelectedOrigins.map(({ value }) => value)));
      }
    }
  };
}

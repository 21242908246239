import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFetching(fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      fetching,
    },
  };
}

export function setInputValue(input) {
  return {
    type: types.SET_INPUT_VALUE,
    payload: {
      input,
    },
  };
}

export function setResults(results) {
  return {
    type: types.SET_RESULTS,
    payload: {
      results,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

import React from 'react';
import styled from 'styled-components';
import SearchSummaryContent from './components/SearchSummaryContent';
import SearchIconButton from '../SearchIconButton';
import { Margin } from 'styled-components-spacing';
import SearchButton from '../../../search/components/SearchButton/SearchButton';

function SearchSummary({ transitionState, onClick, transitionStyles, transitionRule }) {
  return (
    <Root transitionState={transitionState} transitionStyles={transitionStyles} transitionRule={transitionRule}>
      <SearchSummaryContent onClick={onClick} />
      <Margin right='0.5' inline />
      <SearchButton content={<SearchIconButton />} styles={{ minHeight: '46px' }} />
    </Root>
  );
}

export default SearchSummary;

const Root = styled.div`
  ${({ transitionState, transitionStyles }) => transitionStyles[transitionState]};
  transition: ${({ transitionRule }) => transitionRule};
  padding: 12px 18px;
  background-color: ${({ theme }) => theme.colors.background.grey};
  width: 100%;
  display: flex;
`;

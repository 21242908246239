import React from 'react';
import styled from 'styled-components';
import { PERIOD_CALENDAR } from 'components/inputs/SelectDates/constants/calendarSwitch';
import { FormattedMessage } from 'i18n';
import { Margin } from 'styled-components-spacing';

import BaseCheckbox from 'components/inputs/Checkbox/CheckboxComponent';

const FlexibleDatesCheckbox = ({ flexibleDatesDisabled, calendarOption, onChangeCheckbox, isCheckboxChecked }) => {
  if (flexibleDatesDisabled || calendarOption === PERIOD_CALENDAR) {
    return null;
  }

  return (
    <Root>
      <Checkbox onChange={onChangeCheckbox} isChecked={isCheckboxChecked} />
      <Margin right='0.5' inline />
      <Message>
        <FormattedMessage
          id='selectDates.component.footer.checkbox.label'
          description='I am flexible. Search +/- 2 days on from and to date'
          defaultMessage='I am flexible. Search +/- 2 days on from and to date'
          tagName={'span'}
        />
      </Message>
    </Root>
  );
};

export default FlexibleDatesCheckbox;

const Root = styled.div`
  display: flex;
  margin-right: 4px;
  align-items: center;
  margin-bottom: 3px;
`;

const Message = styled.div``;

const Checkbox = styled(BaseCheckbox)`
  margin-bottom: 18px;
`;

export default function transformMenuResponse(item) {
  const { title, url, target, menu_order: menuOrder } = item;
  return {
    title,
    slug: getSlugFromUrl(item.url, item.target, item.type),
    url,
    target,
    menuOrder,
  };
}

function getSlugFromUrl(url, target, type) {
  let slug;

  if (type !== 'custom' && target !== '_blank') {
    url = url.replace('https://', '');
    url = url.replace(/[^/;]*/, '');
    slug = url.replace('/', '');
  }

  return slug;
}

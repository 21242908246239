import * as SEVERITY from 'constants/severity';
import * as inputNames from '../constants/inputNames';
import * as passengerTypes from '../constants/passengerTypes';
import { updateTravelerInputValueAndValidate, setValidity, setNamesSpelledCorrectly } from '../actions';
import validateInputValue from '../validations/validateInputValue';
import validateDateOfBirth from '../validations/validateDateOfBirth';
import compareChildrenAgeByRoom from '../validations/compareChildrenAgeByRoom';
import { getValidationData } from 'state/checkoutPage/customerDetails/selectors';
import { setDriverToSelectedVehicle } from 'state/checkoutPage/carRentalDriver/operations/setDriverToSelectedVehicle';

export default function validateTravelersData() {
  return (dispatch, getState) => {
    let valid = true;
    let duplicateNames = false;
    let duplicateRewardNumber = false;
    let nameToLong = false;
    let validateDateOfBirthAdult = false;
    let validateDateOfBirthChild = false;
    let validateDateOfBirthInfant = false;
    // Set this property in case there are no incuded children or infants.
    let isChild = false;
    let isInfant = false;

    const {
      checkoutPageTravelers,
      settings,
      checkoutPageTravelSummary,
      checkoutPageCarRentalDriver,
      templatesHotelSearchParameters,
    } = getState();
    const customSettings = getState().settings.value.customSettings;
    const shouldForceLatinLetters = customSettings && getState().settings.value.customSettings.forceLatinLetters;
    const { travelers, namesSpelledCorrectly } = checkoutPageTravelers;
    const { selectedOtherExtras } = checkoutPageTravelSummary;
    const vehicleRentals = selectedOtherExtras && selectedOtherExtras.vehicleRentals;
    const selectedVehicleRentals = vehicleRentals && vehicleRentals.length > 0;
    const selectedDriver = checkoutPageCarRentalDriver.driver;
    const isMoreInfantsThanAdults = templatesHotelSearchParameters.selectRooms.rooms.some(
      (room) => room.isMoreInfantsThanAdults
    );
    const returnDate = templatesHotelSearchParameters.selectDates.selectedDates.to;

    if (isMoreInfantsThanAdults) {
      dispatch(
        setValidity({
          valid: false,
          message: {
            id: 'selectRooms.component.moreInfantsThanAdults',
            description: 'Number of infants can not be larger than adults',
            defaultMessage: 'Number of infants can not be larger than adults',
            severity: SEVERITY.ERROR,
          },
        })
      );
      return false;
    }

    const driverIsNotSelected = selectedVehicleRentals && !selectedDriver;
    if (selectedVehicleRentals && selectedDriver) {
      dispatch(setDriverToSelectedVehicle());
    }

    if (driverIsNotSelected) {
      dispatch(
        setValidity({
          valid: false,
          message: {
            id: 'carRentalDriver.notSelected',
            description: 'Please select a driver',
            defaultMessage: 'Please select a driver',
            severity: SEVERITY.ERROR,
          },
        })
      );
      return false;
    }

    if (!dispatch(compareChildrenAgeByRoom(travelers))) {
      dispatch(
        setValidity({
          valid: false,
          message: {
            id: 'invalidChildrenAge.message',
            description: 'Child age is not the same as the one requested in the search.',
            defaultMessage: 'Child age is not the same as the one requested in the search.',
            severity: SEVERITY.ERROR,
          },
        })
      );
      return false;
    }
    const { airlineWhitelabel } = settings.value;
    const travelersNames = [];
    const rewardNumbers = [];
    // Validate all input fields for all travelers.
    travelers.forEach((room, roomIndex) => {
      for (const passengerType in room) {
        let _valid = true;
        let _duplicateNames = false;
        let _duplicateRewardNumber = false;
        let _nameToLong = false;
        let _validateDateOfBirthAdult = false;
        let _validateDateOfBirthChild = false;
        let _validateDateOfBirthInfant = false;
        let _isChild = false;
        let _isInfant = false;

        room[passengerType].forEach((traveler, travelerIndex) => {
          for (const inputName in traveler) {
            if (inputName === 'passengerType') {
              continue;
            }

            const validationData = getValidationData(traveler, inputName);

            const additionalData = {
              airlineWhitelabel,
              validationData,
              shouldForceLatinLetters,
              returnDate,
            };

            const validity = validateInputValue(
              inputName,
              traveler[inputName].value,
              inputName !== inputNames.REWARD_NUMBER,
              additionalData
            );
            if (!validity.valid) {
              dispatch(
                updateTravelerInputValueAndValidate(
                  inputName,
                  traveler[inputName].value,
                  roomIndex,
                  travelerIndex,
                  passengerType,
                  validity,
                  additionalData
                )
              );
              _valid = false;
            }
          }

          // Check for duplicate names.
          if (!_duplicateNames) {
            const travelerName = `${traveler[inputNames.FIRST_NAME].value}${traveler[inputNames.LAST_NAME].value}`;
            if (travelersNames.indexOf(travelerName) !== -1) {
              _duplicateNames = true;
            } else {
              travelersNames.push(travelerName);
            }
          }

          // Check for duplicate reward numbers.
          if (!_duplicateRewardNumber) {
            const travelerRewardNumber = traveler[inputNames.REWARD_NUMBER].value;
            if (travelerRewardNumber) {
              if (rewardNumbers.indexOf(travelerRewardNumber) !== -1) {
                _duplicateRewardNumber = true;
              } else {
                rewardNumbers.push(travelerRewardNumber);
              }
            }
          }

          // Validation for ADULT + INFANT name cannot exceed 41 characters.
          if (passengerType === passengerTypes.INFANT) {
            const infantFullName = `${traveler[inputNames.FIRST_NAME].value} ${traveler[inputNames.LAST_NAME].value}`;
            const adultFullName = `${room[passengerTypes.ADULT][travelerIndex][inputNames.FIRST_NAME].value} ${
              room[passengerTypes.ADULT][travelerIndex][inputNames.LAST_NAME].value
            }`;
            const boardingPassName = `${infantFullName} ${adultFullName}`;

            if (boardingPassName.length > 41) {
              _nameToLong = { roomIndex: roomIndex + 1, travelerIndex: travelerIndex + 1 };
            }
          }

          if (!_validateDateOfBirthAdult || (_validateDateOfBirthAdult && _validateDateOfBirthAdult.valid)) {
            if (passengerType === passengerTypes.ADULT) {
              _validateDateOfBirthAdult = dispatch(
                validateDateOfBirth(null, null, null, traveler[inputNames.DATE_OF_BIRTH].value)
              );
            }
          }

          if (!_validateDateOfBirthChild || (_validateDateOfBirthChild && _validateDateOfBirthChild.valid)) {
            if (passengerType === passengerTypes.CHILD) {
              _isChild = true;
              _validateDateOfBirthChild = dispatch(
                validateDateOfBirth(passengerType, roomIndex, travelerIndex, traveler[inputNames.DATE_OF_BIRTH].value)
              );
            }
          }

          if (!_validateDateOfBirthInfant || (_validateDateOfBirthInfant && _validateDateOfBirthInfant.valid)) {
            if (passengerType === passengerTypes.INFANT) {
              _isInfant = true;
              _validateDateOfBirthInfant = dispatch(
                validateDateOfBirth(passengerType, roomIndex, travelerIndex, traveler[inputNames.DATE_OF_BIRTH].value)
              );
            }
          }
        });

        if (!_valid) {
          valid = false;
        }

        if (_duplicateNames) {
          duplicateNames = true;
        }

        if (_duplicateRewardNumber) {
          duplicateRewardNumber = true;
        }

        if (_nameToLong) {
          nameToLong = _nameToLong;
        }

        if (_validateDateOfBirthAdult) {
          validateDateOfBirthAdult = _validateDateOfBirthAdult;
        }

        if (_validateDateOfBirthChild) {
          validateDateOfBirthChild = _validateDateOfBirthChild;
        }

        if (_validateDateOfBirthInfant) {
          validateDateOfBirthInfant = _validateDateOfBirthInfant;
        }

        if (_isChild) {
          isChild = _isChild;
        }

        if (_isInfant) {
          isInfant = _isInfant;
        }
      }
    });

    // Reset old validity.
    dispatch(setValidity(null));

    // If input fields data is valid, check if there are duplicate names.
    if (valid && duplicateNames) {
      valid = false;
      dispatch(
        setValidity({
          valid: false,
          message: {
            id: 'duplicateNames.message',
            description:
              'We have detected that some travelers you entered have the same name and surname. Kindly review your travelers details.',
            defaultMessage:
              'We have detected that some travelers you entered have the same name and surname. Kindly review your travelers details.',
            severity: SEVERITY.ERROR,
          },
        })
      );
    }

    if (valid && duplicateRewardNumber) {
      valid = false;
      dispatch(
        setValidity({
          valid: false,
          message: {
            id: 'duplicateRewardNumbers.message',
            description:
              'We have detected that some travelers you entered have the same reward number. Kindly review your travelers details.',
            defaultMessage:
              'We have detected that some travelers you entered have the same reward number. Kindly review your travelers details.',
            severity: SEVERITY.ERROR,
          },
        })
      );
    }

    if (valid && nameToLong) {
      valid = false;
      dispatch(
        setValidity({
          valid: false,
          message: {
            id: 'adultAndInfantNameToLong.message',
            description:
              'The infant firstname + infant lastname + adult firstname + adult lastname cannot be more than 41 characters. (Room {roomIndex}, Adult {travelerIndex})',
            defaultMessage:
              'The infant firstname + infant lastname + adult firstname + adult lastname cannot be more than 41 characters. (Room {roomIndex}, Adult {travelerIndex})',
            values: nameToLong,
            severity: SEVERITY.ERROR,
          },
        })
      );
    }

    // Force the user to confirm that names are spelled correctly.
    if (valid && namesSpelledCorrectly === null) {
      valid = false;
      dispatch(setNamesSpelledCorrectly(false));
    }

    if (valid && !validateDateOfBirthAdult.valid) {
      valid = false;
      dispatch(setValidity(validateDateOfBirthAdult));
    }

    if (valid && !validateDateOfBirthChild.valid && isChild) {
      valid = false;
      dispatch(setValidity(validateDateOfBirthChild));
    }

    if (valid && !validateDateOfBirthInfant.valid && isInfant) {
      valid = false;
      dispatch(setValidity(validateDateOfBirthInfant));
    }

    return valid;
  };
}

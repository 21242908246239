import * as types from './types';

export function setFetching(fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      fetching,
    },
  };
}

export function setDestinations(destinations) {
  return {
    type: types.SET_DESTINATIONS,
    payload: {
      destinations,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

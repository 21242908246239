import React from 'react';
import styled from 'styled-components';

export default function ContainerWrapper({ children, ...props }) {
  return <Root {...props}>{children}</Root>;
}

const Root = styled('div')({
  display: 'flex',
  width: '1280px',
  alignItems: 'center',
});

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import InfiniteCircleDots from 'components/spinners/InfiniteCircleDots';
import PriceBarsMonthGroup from './components/PriceBarsMonthGroup';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';
import Sliders from './components/Sliders';
import ContainerWrapper from '../../../common/ContainerWrapper';
import PriceBarsSpinner from './components/PriceBarsSpinner';

const propTypes = {
  priceBars: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string.isRequired,
          dow: PropTypes.string.isRequired,
          month: PropTypes.string.isRequired,
          selected: PropTypes.bool.isRequired,
          price: PropTypes.number.isRequired,
          days: PropTypes.number.isRequired,
          percentage: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  minMax: PropTypes.arrayOf(PropTypes.number),
  rolledUp: PropTypes.bool.isRequired,
  showLoaderLeft: PropTypes.bool.isRequired,
  showLoaderRight: PropTypes.bool.isRequired,
  onClickPriceBar: PropTypes.func.isRequired,
  onScrollGroupsContainer: PropTypes.func.isRequired,
};

const SearchResultsPriceBarsComponent = forwardRef(function SearchResultsPriceBarsComponent(
  {
    priceBars,
    minMax,
    selectedBarRef,
    showLoaderLeft,
    showLoaderRight,
    onClickPriceBar,
    onScrollGroupsContainer,
    airlineWhitelabel,
    handleOnScroll,
    isAtEndRightScroll,
    isAtEndLeftScroll,
    isInProgress,
    dates,
  },
  ref
) {
  return (
    <>
      <Root>
        {isInProgress && <PriceBarsSpinner />}
        <Container>
          {!isAtEndLeftScroll && <Sliders type='left' handleOnScroll={handleOnScroll} />}
          <GroupsContainer ref={ref} onScroll={onScrollGroupsContainer}>
            {showLoaderLeft ? (
              <PriceBarsLoadingWrapLeft>
                <InfiniteCircleDots />
              </PriceBarsLoadingWrapLeft>
            ) : null}
            {priceBars.map((item) => (
              <PriceBarsMonthGroup
                airlineWhitelabel={airlineWhitelabel}
                key={item.key}
                monthTag={item.tag}
                items={item.items}
                minMax={minMax}
                onClickPriceBar={onClickPriceBar}
                selectedBarRef={selectedBarRef}
                isInProgress={isInProgress}
                dates={dates}
              />
            ))}
            {showLoaderRight ? (
              <PriceBarsLoadingWrap>
                <InfiniteCircleDots />
              </PriceBarsLoadingWrap>
            ) : null}
          </GroupsContainer>
          {!isAtEndRightScroll && <Sliders type='right' handleOnScroll={handleOnScroll} />}
        </Container>
      </Root>
    </>
  );
});

SearchResultsPriceBarsComponent.propTypes = propTypes;

export default SearchResultsPriceBarsComponent;

const Root = styled(ContainerWrapper)`
  position: relative;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.greyV6};
  width: 1280px;
  padding-bottom: 10px;

  ${mediaQueries.below.l`
    padding: 0 18px;
    width: 100%;
  `}
`;

const Container = styled.div`
  display: flex;
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  user-select: none;
`;

const GroupsContainer = styled.div`
  ${flexbox({})}
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
    background-color: white;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary.default};
    border-radius: 10px;
  }
`;

const PriceBarsLoadingWrap = styled.div`
  display: inline-block;
  margin-left: 70px;
  margin-right: 70px;
`;

const PriceBarsLoadingWrapLeft = styled.div`
  display: inline-block;
  margin-left: 70px;
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectDestinationsComponent from 'components/inputs/SelectDestinations/SelectDestinations';

class SelectDestinations extends Component {
  render() {
    const {
      selectDestinations,
      selectedOriginsLength,
      missingValue,
      isFrontpage,
      onFocus,
      inputValue,
      className,
      isDisabled,
    } = this.props;
    const { destinations, selectedDestinations, availableDestinations, destinationToCountryMap, forceSingleValue } =
      selectDestinations;

    return (
      <SelectDestinationsComponent
        destinations={destinations}
        selectedDestinations={selectedDestinations}
        availableDestinations={availableDestinations}
        destinationToCountryMap={destinationToCountryMap}
        selectedOriginsLength={selectedOriginsLength}
        forceSingleValue={forceSingleValue}
        tooltipIsVisible={missingValue}
        onFocus={onFocus}
        isFrontpage={isFrontpage}
        inputValue={inputValue}
        instanceId={'select-destinations-header'}
        className={className}
        isDisabled={isDisabled}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    selectDestinations: state.selectDestinations,
    selectedOriginsLength: state.selectOrigins.selectedOrigins.length,
    missingValue: state.searchConditions.missingValues.selectDestinations,
  };
}

export default connect(mapStateToProps, null)(SelectDestinations);

import React from 'react';
import LoadingBar from 'react-redux-loading-bar';
import styled from 'styled-components';
import IntlProvider from 'i18n';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import MediaContextProvider from './MediaContextProvider';
import Header from './components/header/Header';
import Head from './components/head/Head';
// import Footer from './components/footer/Footer';
import AgentTopBar from './components/agentTopBar/AgentTopBar';
import TopMessage from './components/topMessage/TopMessage';
import ActionBar from './components/actionBar/ActionBar';
import CookieConsent from './components/cookieConsent/CookieConsent';
import GlobalStyles from './components/globalStyles/GlobalStylesComponent';
import Logger from './components/logger/Logger';
import WebsitePicker from './components/websitePicker/WebsitePicker';
import './faIcons';
import Footer from './components/footer/Footer';

const toastContainerProps = {
  position: 'top-right',
  autoClose: 10000,
  closeButton: true,
  hideProgressBar: false,
  draggable: false,
  limit: 3,
  containerId: 'base-toast-container',
};

function getLoadingBarStyle(theme) {
  return {
    position: 'fixed',
    backgroundColor: theme.colors.adjacent.default,
    zIndex: '999',
  };
}

function LayoutComponent({ theme, pageData, children, isStaging }) {
  return (
    <IntlProvider namespace='app'>
      <ThemeProvider theme={theme}>
        <MediaContextProvider>
          <Root>
            <Logger />
            <Head pageData={pageData} />
            <GlobalStyles />
            <LoadingBar style={getLoadingBarStyle(theme)} />
            {isStaging && <WebsitePicker />}
            <AgentTopBar />
            <TopMessage />
            <Header />
            {children}
            <FooterWrap>
              <Footer />
            </FooterWrap>
            <ActionBar />
            <CookieConsent />
            <ToastContainer {...toastContainerProps} />
          </Root>
        </MediaContextProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default LayoutComponent;

const Root = styled.div`
  display: block;
  position: relative;
  min-height: 100vh;
`;
const FooterWrap = styled.div`
  width: 100%;
`;

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setPromotionCode(promotionCode) {
  return {
    type: types.SET_PROMOTION_CODE,
    payload: {
      promotionCode,
    },
  };
}

export function requestPromotionCodeValidity(validating) {
  return {
    type: types.REQUEST_PROMOTION_CODE_VALIDITY,
    payload: {
      validating,
    },
  };
}

export function setPromotionCodeValidity(validity) {
  return {
    type: types.SET_PROMOTION_CODE_VALIDITY,
    payload: {
      validity,
    },
  };
}

export function resetPromotionCode() {
  return {
    type: types.RESET_PROMOTION_CODE,
  };
}

import getCookie from 'utils/cookie/getCookie';
import setCookie from 'utils/cookie/setCookie';
import { MASTER_DOMAIN_ABANDONED_CART } from 'constants/cookiesKeys';

export const insertOrUpdateAbandonedCartCookie = (data, masterDomain, abandonedCartLimit = 4) => {
  const cookieValue = getCookie(MASTER_DOMAIN_ABANDONED_CART);
  const persistedAbandonedCartList = cookieValue ? JSON.parse(cookieValue) : [];
  const persistedAbandonedCartIndex = persistedAbandonedCartList.findIndex((item) => item.uuid === data.uuid);

  if (persistedAbandonedCartIndex !== -1) {
    persistedAbandonedCartList[persistedAbandonedCartIndex] = data;
  } else {
    persistedAbandonedCartList.push(data);
  }

  if (abandonedCartLimit < persistedAbandonedCartList.length) {
    persistedAbandonedCartList.splice(0, persistedAbandonedCartList.length - abandonedCartLimit);
  }
  setCookie(MASTER_DOMAIN_ABANDONED_CART, JSON.stringify(persistedAbandonedCartList), 15, masterDomain);
};

export const removeAbandonedCartByUuid = (uuid, masterDomain) => {
  const cookieValue = getCookie(MASTER_DOMAIN_ABANDONED_CART);
  const persistedAbandonedCartList = cookieValue ? JSON.parse(cookieValue) : [];

  if (persistedAbandonedCartList && persistedAbandonedCartList.length > 0) {
    const abandonedCartIndex = persistedAbandonedCartList.findIndex((abandonedCart) => abandonedCart.uuid === uuid);

    if (abandonedCartIndex !== -1) {
      const updatedAbandonedCartList = [
        ...persistedAbandonedCartList.slice(0, abandonedCartIndex),
        ...persistedAbandonedCartList.slice(abandonedCartIndex + 1),
      ];

      setCookie(MASTER_DOMAIN_ABANDONED_CART, JSON.stringify(updatedAbandonedCartList), 15, masterDomain);
    }

    return abandonedCartIndex;
  }

  return null;
};

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SearchIconButton({ className }) {
  return (
    <Root className={className}>
      <FontAwesomeIcon icon='search' />
    </Root>
  );
}

export default SearchIconButton;

const Root = styled.div`
  background-color: ${({ theme }) => theme.colors.adjacent.default};
  padding: 10px;
  color: ${({ theme }) => theme.colors.text.white};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  width: 34px;
  height: 34px;

  svg {
    position: relative;
    top: 50%;
    transform: translate(0px, -50%);
  }
`;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import OverflowMenuComponent from './OverflowMenuComponent';

class OverflowMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
    };

    this.menuListRef = React.createRef();

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnMouseDown = this.handleOnMouseDown.bind(this);
    this.handleOnClickMenuLink = this.handleOnClickMenuLink.bind(this);
    this.handleOnBlurMenu = this.handleOnBlurMenu.bind(this);
  }

  componentDidUpdate() {
    if (this.state.isOpened) {
      ReactDOM.findDOMNode(this.menuListRef.current).focus();
    }
  }

  handleOnClick() {
    this.setState((state) => ({ isOpened: !state.isOpened }));
  }

  handleOnMouseDown(event) {
    if (this.state.isOpened) {
      event.preventDefault();
    }
  }

  handleOnBlurMenu() {
    this.setState({ isOpened: false });
  }

  handleOnMouseDownMenu(event) {
    event.preventDefault();
  }

  handleOnClickMenuLink() {
    this.setState({ isOpened: false });
  }

  render() {
    return (
      <OverflowMenuComponent
        content={this.props.content}
        isOpened={this.state.isOpened}
        handleOnClick={this.handleOnClick}
        handleOnMouseDown={this.handleOnMouseDown}
        handleOnBlurMenu={this.handleOnBlurMenu}
        handleOnMouseDownMenu={this.handleOnMouseDownMenu}
        handleOnClickMenuLink={this.handleOnClickMenuLink}
        menuListRef={this.menuListRef}
        isHotelPage={this.props.isHotelPage}
        isCheckoutPage={this.props.isCheckoutPage}
      />
    );
  }
}

export default OverflowMenu;

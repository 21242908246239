import React from 'react';
import { components } from 'react-select';
import axis from 'axis.js';
import { FormattedMessage } from 'i18n';
import styled from 'styled-components';
import truncate from 'styles/utils/truncate';

function SingleValueComponent({ children, ...props }) {
  // To be able to filter the options by aliases we need to include them in the label.
  // Then we override the react-select Option and SingleValue component to display only the city.name.
  let label = '';
  if (props.data && props.data.label) {
    label = axis.isString(props.data.label) ? (
      props.data.label.slice(0, props.data.label.indexOf('-'))
    ) : (
      <FormattedMessage {...props.data.label} />
    );
  }

  if (props.data.hotel) {
    label = `${label}, ${props.data.destination}`;
  }

  return (
    <components.SingleValue {...props}>
      <Destination>{label}</Destination>
    </components.SingleValue>
  );
}

const Destination = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.darkGrey};
  padding-top: 8px;
  ${truncate()}
`;

export default SingleValueComponent;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import updateSelectedRoomsInLocalStorage from 'state/selectRooms/operations/updateSelectedRoomsInLocalStorage';
import {
  addRoom,
  removeRoomAtIndex,
  updateNumberOfAdults,
  updateNumberOfChildren,
  updateChildAge,
} from 'state/selectRooms/actions';
import MenuListClass from 'components/inputs/SelectRooms/components/menuList/MenuList';

class MenuList extends Component {
  componentDidUpdate() {
    updateSelectedRoomsInLocalStorage(this.props.selectRooms.rooms);
  }

  render() {
    const {
      selectRooms: { rooms },
      onClickUpdateNumberOfAdults,
      onClickUpdateNumberOfChildren,
      onClickUpdateChildAge,
      onClickAddRoom,
      onClickRemoveRoom,
      handleOnCloseClick,
    } = this.props;
    const eventHandlers = {
      onClickUpdateNumberOfAdults,
      onClickUpdateNumberOfChildren,
      onClickUpdateChildAge,
      onClickAddRoom,
      onClickRemoveRoom,
      handleOnCloseClick,
    };
    const config = {
      rooms,
      eventHandlers,
    };

    return <MenuListClass {...config} />;
  }
}

function mapStateToProps(state) {
  return {
    selectRooms: state.selectRooms,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onClickAddRoom() {
      dispatch(addRoom());
    },
    onClickRemoveRoom(event) {
      dispatch(removeRoomAtIndex(parseInt(event.currentTarget.dataset.roomIndex)));
    },
    onClickUpdateNumberOfAdults(event) {
      const { updateValue, roomIndex } = event.currentTarget.dataset;
      dispatch(updateNumberOfAdults(parseInt(updateValue), parseInt(roomIndex)));
    },
    onClickUpdateNumberOfChildren(event) {
      const { updateValue, roomIndex, childIndex, childAge } = event.currentTarget.dataset;
      dispatch(
        updateNumberOfChildren(parseInt(updateValue), parseInt(roomIndex), parseInt(childIndex), parseInt(childAge))
      );
    },
    onClickUpdateChildAge(event) {
      const { updateValue, roomIndex, childIndex } = event.currentTarget.dataset;
      dispatch(updateChildAge(parseInt(updateValue), parseInt(roomIndex), parseInt(childIndex)));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuList);

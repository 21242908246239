import getAvailableDestinations from '../selectors/getAvailableDestinations';
import { setAvailableDestinations } from '../actions';

export default function updateAvailableDestinations(selectedOriginsCodes) {
  return (dispatch, getState) => {
    const { settings, selectDestinations } = getState();
    const { availableDestinationsMap } = selectDestinations;
    const availableDestinations = getAvailableDestinations(availableDestinationsMap, selectedOriginsCodes);
    const forceSingleValue = !settings.value.survivalMode
      ? selectedOriginsCodes.length > 0 && availableDestinations.length === 0
      : true;

    dispatch(setAvailableDestinations(availableDestinations, forceSingleValue));
  };
}

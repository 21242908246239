import { css } from 'styled-components';

export default css`
  #scheme {
    .adyen-checkout__helper-text,
    .adyen-checkout__label__text {
      font-size: 1.4em;
    }
  }
  .adyen-checkout__dropdown__element__text {
    font-size: 12px;
  }
  .adyen-checkout__dropdown__list {
    ::-webkit-scrollbar {
      width: 4px;
      background-color: white;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.primary.default};
      border-radius: 10px;
    }
  }
  .adyen-checkout__blik__helper {
    font-size: 12px;
    font-weight: 500;
  }
  .adyen-checkout__label__text {
    font-size: 10px;
  }
  #onlineBanking_PL {
    margin-bottom: -10px;
    .adyen-checkout__button {
      display: none;
    }
  }
`;

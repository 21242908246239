import { getDestinations } from 'httpClient/requests/selectDestinations';
import fetchDestinationsTripx from 'state/destinations/operations/fetchDestinations';
import { setDestinations } from '../actions';

export default function fetchDestinations() {
  return async (dispatch, getState) => {
    const { isFetching } = getState().selectDestinations;

    if (isFetching) {
      return;
    }

    try {
      const response = await getDestinations();
      dispatch(setDestinations(response.data));

      await dispatch(fetchDestinationsTripx());
    } catch (error) {
      console.error(error);
    }
  };
}

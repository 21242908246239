import '../polyfills';
import '../styles';
import React from 'react';
import App from 'next/app';
import { Provider } from 'react-redux';
import withReduxStore from 'state/storeProvider';
import Layout from 'routes/_components/layout/Layout';
import ThirdPartyHeader from 'components/thirdPartyHeader/ThirdPartyHeader';
import ErrorBoundary from './errorBoundary';
import BasicAuth from 'components/basicAuth/BasicAuth';

class MyApp extends App {
  render() {
    const { Component, pageProps, store, pageData } = this.props;

    return (
      <Provider store={store}>
        <ThirdPartyHeader />
        <Layout pageData={pageData}>
          <ErrorBoundary>
            <BasicAuth />
            <Component {...pageProps} />
          </ErrorBoundary>
        </Layout>
      </Provider>
    );
  }
}

export default withReduxStore(MyApp);

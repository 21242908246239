import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
/**
 * Renders a span with the overflow counter .
 *
 * Example: '+3'
 *
 * @param {Object} overflowCounter - the overflow counter value
 * @param {Object} styles - the styles for the component
 * @return {JSX.Element} the rendered overflow counter component
 */

function OverflowCounter({ overflowCounter, styles }) {
  const { customSettings } = useSelector((state) => state.settings.value);
  const isHolidayPirates = customSettings && customSettings.isHolidayPirates;

  return <Root styles={styles} isHolidayPirates={isHolidayPirates}>{`+ ${overflowCounter}`}</Root>;
}

const Root = styled.span`
  display: flex;
  width: 27px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: ${({ theme, isHolidayPirates }) => (isHolidayPirates ? theme.colors.text.darkGrey : theme.colors.text.white)};
  background-color: ${({ theme }) => theme.colors.secondary.light};
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin-left: 8px;

  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `};
`;

export default OverflowCounter;

import moment from 'moment';
import localStorageBase from '../localStorageBase';

class BannerConfigLocalStorage {
  constructor(bannerKey) {
    this.key = `banner_config_${bannerKey}`;
  }

  isDismissed() {
    const config = localStorageBase.get(this.key);

    if (config) {
      const secondsSinceDismiss = moment().unix() - config.dismissedOn;
      const duration = moment.duration(secondsSinceDismiss, 'seconds');
      const numberOfDays = duration.asDays();
      const lessThenADay = numberOfDays < 1;

      if (!lessThenADay) {
        localStorageBase.remove(this.key);
      }

      return lessThenADay;
    }

    return false;
  }

  setDismissedOn() {
    localStorageBase.set(this.key, { dismissedOn: moment().unix() });
  }
}

export default BannerConfigLocalStorage;

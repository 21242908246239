import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { FRONTPAGE as FRONTPAGE_PAGE } from 'constants/pages';
import { dohopUrl } from './constanst/constants';
import { PACKAGES_DOHOP } from 'constants/whitelabels';
import getLogoImage from './getLogoImage';

export default function getLogoLink(
  airlineWhitelabel,
  images,
  isFrontpage,
  mobile,
  whitelabel,
  pathToFrontpage,
  checkoutOnlyWhitelabel,
  websiteId,
  customSettings
) {
  if (whitelabel === PACKAGES_DOHOP) {
    return (
      <Anchor href={dohopUrl} target='_blank' rel='noopener noreferrer'>
        {images.map((image) =>
          getLogoImage(airlineWhitelabel, image.src, image.padding, isFrontpage, mobile, websiteId, customSettings)
        )}
      </Anchor>
    );
  }

  if (checkoutOnlyWhitelabel) {
    return (
      <Anchor multipleImages={images.length > 1}>
        {images.map((image) =>
          getLogoImage(airlineWhitelabel, image.src, image.padding, isFrontpage, mobile, websiteId, customSettings)
        )}
      </Anchor>
    );
  }

  return (
    <Link href={FRONTPAGE_PAGE} as={pathToFrontpage}>
      <Anchor multipleImages={images.length > 1}>
        {images.map((image) =>
          getLogoImage(airlineWhitelabel, image.src, image.padding, isFrontpage, mobile, websiteId, customSettings)
        )}
      </Anchor>
    </Link>
  );
}

const Anchor = styled.a`
  cursor: pointer;

  ${({ multipleImages }) =>
    multipleImages &&
    `
    display: flex;
    align-items: center;
  `}

  > *:not(:last-child) {
    margin-right: 24px;
  }
`;

import * as types from './types';

export function setCookieBanner(displayCookieBanner) {
  return {
    type: types.SET_COOKIE_BANNER,
    payload: {
      displayCookieBanner,
    },
  };
}

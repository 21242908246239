import React, { Component } from 'react';
import { PRODUCTION as RUNTIME_HOST_ENV_PRODUCTION } from 'constants/env/runtimeHostEnv';
import transformConfigToOptions from './utils/transformConfigToOptions';
import getWebsitePickerValueFromQuery from './utils/getWebsitePickerValueFromQuery';
import WebsitePickerComponent from './WebsitePickerComponent';
// DEPRECATED import config from './config';
import { WEB_CODE, WEB_COUNTRY } from './constants';
import { connect } from 'react-redux';
import fetchAllCountries from 'state/webSitePicker/operations';

class WebsitePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      options: null,
    };

    this.enabled = false;
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    const { fetchAllCountries } = this.props;
    fetchAllCountries();
    const value = getWebsitePickerValueFromQuery();

    if (value) {
      this.setState({ value });
    }

    this.enabled = localStorage.getItem('webpick') === 'true';
  }
  componentDidUpdate(prevProps) {
    const { webSitePickerItems } = this.props;
    const { webSitePickerItems: prevWebSitePickerItems } = prevProps;
    if (webSitePickerItems !== prevWebSitePickerItems) {
      this.setState({
        options: transformConfigToOptions(webSitePickerItems),
      });
    }
  }
  handleOnChange(event) {
    const [code, cnt] = event.target.value.split('-');
    let query = `${WEB_COUNTRY}=${cnt.toLowerCase()}`;

    if (code !== 'null') {
      query = query + `&${WEB_CODE}=${code.toLowerCase()}`;
    }

    Object.assign(document.createElement('a'), {
      target: '_blank',
      href: `${window.location.origin}?${query}`,
    }).click();
  }

  render() {
    const { value, options } = this.state;

    if (process.env.RUNTIME_HOST_ENV === RUNTIME_HOST_ENV_PRODUCTION || !this.enabled || !options) {
      return null;
    }

    return <WebsitePickerComponent options={this.state.options} value={value} onChange={this.handleOnChange} />;
  }
}
function mapStateToProps(state) {
  return {
    webSitePickerItems: state.webSitePicker.items,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllCountries() {
      dispatch(fetchAllCountries());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(WebsitePicker);

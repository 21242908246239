import { resetState as packagesSearchResetState } from 'state/checkoutPage/packagesSearch/actions';
import { resetState as checkoutBookingResetState } from 'state/checkoutPage/booking/actions';
import { resetState as travelSummaryResetState } from 'state/checkoutPage/travelSummary/actions';
import { resetState as cancellationProtectionsResetState } from 'state/checkoutPage/cancellationProtections/actions';
import { resetCustomerDetails as checkoutCustomerDetailsResetCustomerDetails } from 'state/checkoutPage/customerDetails/actions';
import { resetFlights as checkoutFlightsResetFlights } from 'state/checkoutPage/flights/actions';
import { resetFlightsDetails as checkoutFlightsDetailsResetFlightsDetails } from 'state/checkoutPage/flightsDetails/actions';
import { resetFlightFilters as checkoutFlightsFiltersResetFlightsFilters } from 'state/checkoutPage/flightsFilters/actions';
import { resetInsurances as checkoutInsurancesResetInsurances } from 'state/checkoutPage/insurances/actions';
import { resetState as checkoutPaymentAdyenResetState } from 'state/checkoutPage/paymentAdyen/actions';
import { resetPaymentKlarna as checkoutPaymentKlarnaResetPaymentKlarna } from 'state/checkoutPage/paymentKlarna/actions';
import { resetPaymentNetsEasy as checkoutPaymentNetsEasyResetPaymentNetsEasy } from 'state/checkoutPage/paymentNetsEasy/actions';
import { resetState as checkoutPaymentResetPaymentOptions } from 'state/checkoutPage/paymentOptions/actions';
import { resetPromotionCode as checkoutPromotionCodeResetPromotionCode } from 'state/checkoutPage/promotionCode/actions';
import { resetTransfers as checkoutTransfersResetTransfers } from 'state/checkoutPage/transfers/actions';
import { resetTravelers as checkoutTravelersResetTravelers } from 'state/checkoutPage/travelers/actions';
import { resetState as vehicleRentalResetState } from 'state/checkoutPage/vehicleRental/actions';
import { setTransitionToCheckout } from 'state/templates/hotel/actions';
import { resetCheckoutWizard } from '../actions';

export default function resetCheckoutState(includeHotelsSearch) {
  return (dispatch) => {
    dispatch(travelSummaryResetState());
    dispatch(checkoutBookingResetState());
    dispatch(cancellationProtectionsResetState());
    dispatch(checkoutCustomerDetailsResetCustomerDetails());
    dispatch(checkoutFlightsResetFlights());
    dispatch(checkoutFlightsDetailsResetFlightsDetails());
    dispatch(checkoutFlightsFiltersResetFlightsFilters());
    dispatch(checkoutInsurancesResetInsurances());
    dispatch(checkoutPaymentAdyenResetState());
    dispatch(checkoutPaymentKlarnaResetPaymentKlarna());
    dispatch(checkoutPaymentNetsEasyResetPaymentNetsEasy());
    dispatch(checkoutPaymentResetPaymentOptions());
    dispatch(checkoutPromotionCodeResetPromotionCode());
    dispatch(checkoutTransfersResetTransfers());
    dispatch(checkoutTravelersResetTravelers());
    dispatch(vehicleRentalResetState());
    dispatch(resetCheckoutWizard());

    if (includeHotelsSearch) {
      dispatch(packagesSearchResetState());
      dispatch(setTransitionToCheckout(false));
    }
  };
}

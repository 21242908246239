import React from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import parse from 'html-react-parser';
import { mediaQueries } from 'styles/utils/mediaQueries';

function PromoHeroComponent({ heading, text, image, url, isFrontpage }) {
  if (isFrontpage && image) {
    return (
      <Root noPadding={image}>
        <a href={url}>
          <Image img={image}>
            {text ? (
              <ParsedText>
                <Text>{parse(text)}</Text>
              </ParsedText>
            ) : null}
          </Image>
        </a>
      </Root>
    );
  }

  return (
    <Root>
      <Heading>{heading}</Heading>
      <Margin top='0.5' />
      <Text>{parse(text)}</Text>
    </Root>
  );
}

export default PromoHeroComponent;

const ParsedText = styled.div`
  position: absolute;
`;

const Image = styled.div(({ img }) => ({
  position: 'relative',
  backgroundImage: `url(${img})`,
  height: '100%',
  width: '100%',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
}));

const Root = styled.div`
  padding: ${({ noPadding }) => (noPadding ? '0px' : '16px')};
  height: ${({ noPadding }) => (noPadding ? '250px' : 'inherit')};

  a {
    color: ${({ theme }) => theme.colors.text.white};
    text-decoration: underline;
  }
`;

const Heading = styled.div`
  font-size: 2em;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.white};
  text-transform: uppercase;
  text-align: center;

  ${mediaQueries.fromAndBelow.tablet`
    font-size: 1.4em;
  `}
`;

const Text = styled.div`
  font-size: 1.6em;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text.white};
  line-height: 1.2;

  p {
    color: ${({ theme }) => theme.colors.text.white};

    ${mediaQueries.fromAndBelow.tablet`
    font-size: 0.8em;
  `}
  }
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { searchContainerActions } from 'state/searchContainer';
import SearchComponent from './SearchComponent';

const propTypes = {
  isFrontpage: PropTypes.bool.isRequired,
};

class SearchFrontPage extends Component {
  componentWillUnmount() {
    this.props.resetInputsHiddenOnMobile();
  }

  render() {
    const { isFrontpage, isHotelPage, isSearchPage, inputsHidden, onClickClose, airlineWhitelabel, customSettings } =
      this.props;

    return (
      <SearchComponent
        isFrontpage={isFrontpage}
        isHotelPage={isHotelPage}
        isSearchPage={isSearchPage}
        inputsHidden={inputsHidden}
        isBorderLess={customSettings && customSettings.borderLessSearch}
        onClickClose={onClickClose}
        airlineWhitelabel={airlineWhitelabel}
      />
    );
  }
}

SearchFrontPage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    customSettings: state.settings.value.customSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetInputsHiddenOnMobile: function () {
      dispatch(searchContainerActions.resetInputsHiddenOnMobile());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFrontPage);

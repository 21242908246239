import React from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import CheckoutWizzard from 'routes/checkoutPage/components/checkoutWizard/CheckoutWizard';
import Logo from '../logo/Logo';
import { mediaQueries } from 'styles/utils/mediaQueries';
import SearchSummary from './components/SearchSummary.Mobile/SearchSummary.Mobile';
import { FormattedMessage } from 'i18n';

const transitionDuration = 300;
const transitionRule = `right ${transitionDuration}ms ease-in-out`;
const transitionStyles = {
  entering: { right: '0' },
  entered: { right: '0' },
  exiting: { right: '-60%' },
  exited: { right: '-60%' },
};

function DropdownSearchTrigger({
  logoUrl,
  isCheckoutPage,
  isSearchPage,
  airlineWhitelabel,
  transitionToCheckout,
  checkoutOnlyWhitelabel,
  hasSearchContainer,
  hasCheckoutWizard,
  showDropdownSearch,
  onClickDropdownSearchTrigger,
  hideLogo,
  isHotelPage,
  menuContent,
}) {
  const logoImages = [{ src: logoUrl }];

  return (
    <Container
      showDropdownSearch={showDropdownSearch}
      airlineWhitelabel={airlineWhitelabel}
      isCheckoutPage={isCheckoutPage}
      checkoutOnlyWhitelabel={checkoutOnlyWhitelabel}
      isSearchPage={isSearchPage}
    >
      {hideLogo && !(isHotelPage || isCheckoutPage) ? null : (
        <LogoWrap>
          <Logo
            config={{
              images: logoImages,
            }}
          />
        </LogoWrap>
      )}
      {hasSearchContainer && (
        <Transition in={!transitionToCheckout} timeout={transitionDuration} unmountOnExit>
          {(transitionState) =>
            isSearchPage ? (
              <SearchSummary
                transitionState={transitionState}
                onClick={onClickDropdownSearchTrigger}
                transitionStyles={transitionStyles}
                transitionRule={transitionRule}
              />
            ) : (
              <Button transitionState={transitionState} onClick={onClickDropdownSearchTrigger}>
                <FormattedMessage
                  id={'search.component.search.button.label'}
                  description={'Search'}
                  defaultMessage={'Search'}
                />
              </Button>
            )
          }
        </Transition>
      )}
      {hasCheckoutWizard && (
        <WizardWrap>
          <CheckoutWizzard hideForMedia={['above', 'desktop']} hideLabels />
        </WizardWrap>
      )}
    </Container>
  );
}

export default DropdownSearchTrigger;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isSearchPage }) => (isSearchPage ? 'column' : 'row')};
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.background.grayV3};
  top: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.default};
  transition: all 0.3s;
  z-index: 2;

  > div.fresnel-container {
    display: flex;
    width: ${({ isCheckoutPage, checkoutOnlyWhitelabel, airlineWhitelabel }) =>
      checkoutOnlyWhitelabel
        ? '100%'
        : isCheckoutPage
        ? airlineWhitelabel && airlineWhitelabel.isAirBaltic
          ? '48%'
          : '32%'
        : '50%'};

    ${mediaQueries.fromAndBelow.tablet`
      justify-content: ${({ airlineWhitelabel }) =>
        airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 'space-around' : 'normal'};
    `};
  }

  ${mediaQueries.fromAndAbove.l`
    display: none;
  `}
`;

const Button = styled.button`
  width: 60%;
  position: relative;
  margin: 6px;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.adjacent.default};
  border: 0;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.white};
  letter-spacing: 0.2px;
  cursor: pointer;
  ${({ transitionState }) => transitionStyles[transitionState]};
  transition: ${transitionRule};
`;

const WizardWrap = styled.div`
  position: relative;
  width: 160px;
`;

const LogoWrap = styled.div`
  height: 72px;
  padding: 16px 8px;
`;

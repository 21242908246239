import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const propTypes = {
  onClick: PropTypes.func,
  styles: PropTypes.object,
};

const Backdrop = ({ onClick, styles = {} }) => <Root onClick={onClick} styles={styles}></Root>;

Backdrop.propTypes = propTypes;

export default Backdrop;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 4;
  background: rgb(0 0 0 / 20%);
  ${({ styles }) => styles};
`;

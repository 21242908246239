import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setDirect(direct) {
  return {
    type: types.SET_DIRECT,
    payload: {
      direct,
    },
  };
}

export function toggleDirect() {
  return {
    type: types.TOGGLE_DIRECT,
  };
}

export function setDepartureMinTimeValue(min) {
  return {
    type: types.SET_DEPARTURE_MIN_TIME_VALUE,
    payload: {
      min,
    },
  };
}

export function setDepartureMaxTimeValue(max) {
  return {
    type: types.SET_DEPARTURE_MAX_TIME_VALUE,
    payload: {
      max,
    },
  };
}

export function setDepartureTimeValue(value) {
  return {
    type: types.SET_DEPARTURE_TIME_VALUE,
    payload: {
      value,
    },
  };
}

export function setReturnMinTimeValue(min) {
  return {
    type: types.SET_RETURN_MIN_TIME_VALUE,
    payload: {
      min,
    },
  };
}

export function setReturnMaxTimeValue(max) {
  return {
    type: types.SET_RETURN_MAX_TIME_VALUE,
    payload: {
      max,
    },
  };
}

export function setReturnTimeValue(value) {
  return {
    type: types.SET_RETURN_TIME_VALUE,
    payload: {
      value,
    },
  };
}

export function setSortByValue(value) {
  return {
    type: types.SET_SORT_BY_VALUE,
    payload: {
      value,
    },
  };
}

export function setDepartureAirportsValue(value) {
  return {
    type: types.SET_DEPARTURE_AIRPORTS_VALUE,
    payload: {
      value,
    },
  };
}

export function setArrivalAirportsValue(value) {
  return {
    type: types.SET_ARRIVAL_AIRPORTS_VALUE,
    payload: {
      value,
    },
  };
}

export function toggleIsSameAirports() {
  return {
    type: types.TOGGLE_IS_SAME_AIRPORTS_VALUE,
  };
}

export function triggerShowCheaperFlights() {
  return {
    type: types.TRIGGER_SHOW_CHEAPER_FLIGHTS_VALUE,
  };
}

export function clearFlightsFilters() {
  return {
    type: types.CLEAR_FLIGHTS_FILTERS,
  };
}

export function resetFlightFilters() {
  return {
    type: types.RESET_FLIGHT_FILTERS,
  };
}

import { useEffect, useState, useRef } from 'react';

export function useCircleScroll() {
  const scrollableContentRef = useRef(null);
  const initialY = useRef(0);
  const initialTop = useRef(0);
  const thumbRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const handleThumbMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    initialY.current = e.clientY;
    initialTop.current = thumbRef.current.getBoundingClientRect().top;
  };

  const handleThumbMouseUp = () => {
    setIsDragging(false);
  };

  const handleThumbTouchStart = (e) => {
    e.preventDefault();
    const touch = e.changedTouches[0];
    initialY.current = touch.clientY;
    initialTop.current = thumbRef.current.getBoundingClientRect().top;
  };

  const handleThumbTouchEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (!isDragging) {
        const { scrollHeight, clientHeight, scrollTop } = scrollableContentRef.current;

        setScrollTop((scrollTop / (scrollHeight - clientHeight)) * 100);
      }
    };

    const handleThumbTouchMove = (e) => {
      if (isDragging) {
        e.preventDefault();
        const touch = e.changedTouches[0];
        const deltaY = touch.clientY - initialY.current;
        const containerRect = scrollableContentRef.current.getBoundingClientRect();
        const thumbRect = thumbRef.current.getBoundingClientRect();
        const thumbTop = initialTop.current + deltaY;

        if (thumbTop >= containerRect.top && thumbTop <= containerRect.bottom - thumbRect.height) {
          const scrollableHeight = containerRect.height - thumbRect.height;
          const thumbPosition = (thumbTop - containerRect.top) / scrollableHeight;
          setScrollTop(thumbPosition * 100);
          scrollableContentRef.current.scrollTop = thumbPosition * scrollableContentRef.current.scrollHeight;
        }
      }
    };

    const handleThumbMouseMove = (e) => {
      if (isDragging) {
        e.preventDefault();
        const deltaY = e.clientY - initialY.current;
        const containerRect = scrollableContentRef.current.getBoundingClientRect();
        const thumbRect = thumbRef.current.getBoundingClientRect();
        const thumbTop = initialTop.current + deltaY;

        if (thumbTop >= containerRect.top && thumbTop <= containerRect.bottom - thumbRect.height) {
          const scrollableHeight = containerRect.height - thumbRect.height;
          const thumbPosition = (thumbTop - containerRect.top) / scrollableHeight;
          setScrollTop(thumbPosition * 100);
          scrollableContentRef.current.scrollTop = thumbPosition * scrollableContentRef.current.scrollHeight;
        }
      }
    };

    const handleTrackClick = (e) => {
      const trackRect = scrollableContentRef.current.getBoundingClientRect();
      const clickPosition = e.clientY - trackRect.top;

      const scrollableHeight = trackRect.height - thumbRef.current.clientHeight;
      const thumbPosition = clickPosition / scrollableHeight;
      const newScrollTop = thumbPosition * (scrollableContentRef.current.scrollHeight - trackRect.height);

      setScrollTop((newScrollTop / scrollableContentRef.current.scrollHeight) * 100);
      scrollableContentRef.current.scrollTop = newScrollTop;
    };

    const currentContentRef = scrollableContentRef.current;
    const currentThumbRef = thumbRef.current;
    currentThumbRef.addEventListener('mousedown', handleThumbMouseDown);
    currentThumbRef.addEventListener('touchstart', handleThumbTouchStart);
    document.addEventListener('mousemove', handleThumbMouseMove);
    document.addEventListener('touchmove', handleThumbTouchMove, { passive: false });
    document.addEventListener('mouseup', handleThumbMouseUp);
    document.addEventListener('touchend', handleThumbTouchEnd);
    currentContentRef.addEventListener('scroll', handleScroll);
    currentContentRef.addEventListener('click', handleTrackClick);
    return () => {
      currentContentRef.removeEventListener('scroll', handleScroll);
      currentContentRef.removeEventListener('click', handleTrackClick);
      currentThumbRef.removeEventListener('mousedown', handleThumbMouseDown);
      currentThumbRef.removeEventListener('touchstart', handleThumbTouchStart);
      document.removeEventListener('mousemove', handleThumbMouseMove);
      document.removeEventListener('touchmove', handleThumbTouchMove);
      document.removeEventListener('mouseup', handleThumbMouseUp);
      document.removeEventListener('touchend', handleThumbTouchEnd);
    };
  }, [isDragging]);

  return { scrollTop, scrollableContentRef, thumbRef };
}

import React from 'react';
import styled from 'styled-components';
import ConfirmButton from 'components/buttons/confirmButton/ConfirmButton';
import { mediaQueries } from 'styles/utils/mediaQueries';

/**
 * Renders the FooterComponent for mobile react-select  with a ConfirmButton and handles onRequestClose event.
 *
 * @param {function} onRequestClose - The callback function to handle close event
 * @return {JSX.Element} The rendered FooterComponent
 */

function FooterComponent({ onRequestClose, className }) {
  return (
    <Root className={className}>
      <ConfirmButton onClick={onRequestClose} />
    </Root>
  );
}

export default FooterComponent;

const Root = styled.div`
  background-color: ${({ theme }) => theme.colors.background.light};
  padding: 27px 21px 27px 21px;
  z-index: 3;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.background.greyV3};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${mediaQueries.fromAndAbove.tablet`
     display: none;
  `};
`;

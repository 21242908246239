import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFlightsData(flights, airportNamesMap, carrierNamesMap) {
  return {
    type: types.SET_FLIGHTS_DATA,
    payload: {
      flights,
      airportNamesMap,
      carrierNamesMap,
    },
  };
}

export function setFilteredFlights(filteredFlights) {
  return {
    type: types.SET_FILTERED_FLIGHTS,
    payload: {
      filteredFlights,
    },
  };
}

export function sortFilteredFlights(sortedFilteredFlights) {
  return {
    type: types.SORT_FILTERED_FLIGHTS,
    payload: {
      sortedFilteredFlights,
    },
  };
}

export function setCheapestFilteredFlight(value) {
  return {
    type: types.SET_CHEAPEST_FILTERED_FLIGHT,
    payload: {
      value,
    },
  };
}

export function setBestFilteredFlight(value) {
  return {
    type: types.SET_BEST_FILTERED_FLIGHT,
    payload: {
      value,
    },
  };
}

export function resetFlights() {
  return {
    type: types.RESET_FLIGHTS,
  };
}

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFetching(menuName, fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      menuName,
      fetching,
    },
  };
}

export function setMenu(menuName, value) {
  return {
    type: types.SET_MENU,
    payload: {
      menuName,
      value,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

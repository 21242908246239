import styled from 'styled-components';
import SelectCommonFrontpage from '../components/SelectCommonFrontpageComponent';

export default styled(SelectCommonFrontpage)`
  .styled-select {
    &__control {
      position: relative;
      height: 66px;
      padding: 0.8rem 0;
      border-width: 0 1px 1px 0;
      border-top-right-radius: 4px;

      ::-webkit-scrollbar {
        width: 4px;
        background-color: white;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.primary.default};
        border-radius: 10px;
      }

      @media (max-width: 1024px) {
        border-width: 0 0 1px 0;
      }

      @media (max-width: 768px) {
        min-height: 41px;
        padding: 5px 5px 3px 18px;
        border-top-right-radius: unset;
      }
    }

    &__placeholder {
      padding-top: 12px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.text.placeholder};
    }

    &__value-container {
      overflow: ${({ value }) => (value && value.length === 0 ? 'visible' : 'hidden')};
    }

    &__input {
      input {
        line-height: unset;

        @media (max-width: 768px) {
          caret-color: transparent;
        }
      }
    }

    &__menu {
      top: 100%;
      right: 0;
      width: 100%;
      background: ${({ theme }) => theme.colors.background.greyV5};

      @media (max-width: 768px) {
        background: ${({ theme }) => theme.colors.background.greyLight};
        z-index: 4;
      }
    }

    &__menu-list {
      min-height: 100vh;
    }
  }
`;

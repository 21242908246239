import React from 'react';
import PropTypes from 'prop-types';
import SingleValue from './components/singleValue/SingleValueComponent';
import styled from 'styled-components';
import Backdrop from 'components/common/backdrop/Backdrop';
import { mediaQueries } from 'styles/utils/mediaQueries';
import useOnOutsideClick from 'hooks/useOnOutsideClick';
import { isMobile } from 'utils/isMobile';

const propTypes = {
  selectedRooms: PropTypes.shape({
    numberOfRooms: PropTypes.number.isRequired,
    numberOfAdults: PropTypes.number.isRequired,
    numberOfChildren: PropTypes.number.isRequired,
  }).isRequired,
};

function SelectRoomsComponent({
  selectedRooms,
  MenuList,
  handleOnClick,
  isOpen,
  isFrontPage,
  isHotelPage,
  thirdPartyHeaderHeight,
  headerHeight,
}) {
  const { ref } = useOnOutsideClick({
    handleOnClick,
    isOpen,
    skip: isMobile(),
  });

  return (
    <Root ref={ref} isFrontPage={isFrontPage} isHotelPage={isHotelPage}>
      <Wrap onClick={handleOnClick}>
        <SingleValue data={{ ...selectedRooms }} />
      </Wrap>
      {isOpen && (
        <>
          <Backdrop
            onClick={handleOnClick}
            styles={{
              top: isFrontPage || isHotelPage ? '0' : thirdPartyHeaderHeight + headerHeight,
              background: 'rgb(0 0 0 / 50%)',
            }}
          />
          <MenuWrap isFrontPage={isFrontPage} isHotelPage={isHotelPage}>
            <MenuList handleOnCloseClick={handleOnClick} />
          </MenuWrap>
        </>
      )}
    </Root>
  );
}

SelectRoomsComponent.propTypes = propTypes;

export default SelectRoomsComponent;

const Root = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background.light};
  flex: 1;
  padding: ${({ isFrontPage, isHotelPage }) =>
    isHotelPage ? '8px 15px 0px 20px' : isFrontPage ? '8px 15px 8px 20px' : '10px 10px 10px 10px'};
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${({ isHotelPage }) => (!isHotelPage ? '0px 0px 4px 0px' : '0px')};
  width: ${({ isFrontPage }) => (isFrontPage ? '100%' : '220px')};

  ${mediaQueries.fromAndBelow.desktop`
    width: 100%;
    padding-left: ${({ isFrontPage, isHotelPage }) => (!isFrontPage || !isHotelPage) && '20px'};
  `}

  ${mediaQueries.fromAndBelow.tablet`
   border-radius: ${({ isHotelPage }) => (!isHotelPage ? '0 0 4px 4px' : '0px')} ;
  `};

  @media (max-width: 1060px) {
    width: ${({ isFrontPage }) => (isFrontPage ? '100%' : '200px')};
  }
`;

const Wrap = styled.div`
  width: 100%;
`;

const MenuWrap = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  width: ${({ isFrontPage, isHotelPage }) => (isFrontPage || isHotelPage ? '100%' : '350px')};
  background-color: ${({ theme }) => theme.colors.background.light};
  z-index: 5;
  min-width: fit-content;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 6px rgba(190, 189, 189, 0.15);
`;

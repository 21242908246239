import React from 'react';
import { components } from 'react-select';
import { FormattedMessage } from 'i18n';

function MultiValueLabel(props) {
  const { data } = props.data;

  if (data && data.translation) {
    props.data.label = (
      <FormattedMessage
        id={data.translation.id}
        description={data.translation.description}
        defaultMessage={data.translation.defaultMessage}
      >
        {(text) => text}
      </FormattedMessage>
    );
  }

  return <components.MultiValueLabel {...props}>{props.data.label}</components.MultiValueLabel>;
}

export default MultiValueLabel;

import React, { Component } from 'react';
import MenuListComponent from './MenuListComponent';
import { setSelectedChildren } from 'components/inputs/utils';

function getShowCountriesAndDestinations(options, destinations, isFiltering, selectedOptionIsHotel) {
  if (options.length === 1) {
    if (selectedOptionIsHotel) {
      return true;
    }

    return false;
  }

  return !isFiltering || (isFiltering && destinations.length > 0);
}

class MenuList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: undefined,
    };

    this.handleOnClickCountry = this.handleOnClickCountry.bind(this);
  }

  componentDidMount() {
    this.preSelectCountry();
  }

  handleOnClickCountry(event) {
    event.currentTarget.dataset.disabled === 'false' &&
      this.setState({
        selectedCountry: event.currentTarget.dataset.countryValue,
      });
  }

  preSelectCountry() {
    const { selectProps } = this.props;
    const { data } = selectProps;
    const countries = data && data.countries;
    const highlightedCountries = data && data.highlightedCountries;
    const allCountries = highlightedCountries ? [...countries, ...highlightedCountries] : countries;

    if (allCountries.length === 1) {
      if (allCountries[0] && !allCountries[0].disabled) {
        this.setState({
          selectedCountry: allCountries[0].value,
        });
      }
    }
  }

  render() {
    const { selectedCountry } = this.state;
    const { selectProps, options, children } = this.props;
    const { data, inputValue } = selectProps;

    const countries = data && data.countries;
    const highlightedCountries = data && data.highlightedCountries;
    const isFiltering = !!inputValue;
    const setChildren = setSelectedChildren(children);

    const notSelectedOptions = Boolean(setChildren && setChildren.notSelectedOptions.length)
      ? setChildren.notSelectedOptions
      : [];
    const selectedOptions = Boolean(setChildren && setChildren.selectedOptions.length)
      ? setChildren.selectedOptions
      : [];

    let destinations =
      notSelectedOptions.length > 0
        ? notSelectedOptions.filter(({ props }) => props.data && !props.data.hotel && !props.data.allForCountry)
        : [];
    let hotels =
      notSelectedOptions.length > 0 ? notSelectedOptions.filter(({ props }) => props.data && props.data.hotel) : [];
    let availableCountriesFromFiltering = [];

    const filteredCountries = countries.filter((country) =>
      destinations.some((destination) => destination.props.data.country === country.value)
    );

    if (isFiltering && notSelectedOptions.length > 0) {
      destinations.forEach((destination) => {
        if (availableCountriesFromFiltering.indexOf(destination.props.data.country) === -1) {
          availableCountriesFromFiltering.push(destination.props.data.country);
        }
      });
    } else if (selectedCountry !== undefined && notSelectedOptions && notSelectedOptions.filter) {
      destinations = notSelectedOptions.filter(({ props }) => props.data && props.data.country === selectedCountry);
    } else {
      destinations = [];
    }

    const showCountriesAndDestinations = getShowCountriesAndDestinations(
      options,
      destinations,
      isFiltering,
      selectProps.value && selectProps.value[0] && selectProps.value[0].hotel
    );
    const showNoOptionsMessage = options.length === 1 ? false : !showCountriesAndDestinations && hotels.length === 0;

    return (
      <MenuListComponent
        countries={filteredCountries}
        highlightedCountries={highlightedCountries}
        availableCountriesFromFiltering={availableCountriesFromFiltering}
        destinations={destinations}
        hotels={hotels}
        showCountriesAndDestinations={showCountriesAndDestinations}
        showNoOptionsMessage={showNoOptionsMessage}
        handleOnClickCountry={this.handleOnClickCountry}
        selectedOptions={selectedOptions}
        isFiltering={isFiltering}
      />
    );
  }
}

export default MenuList;

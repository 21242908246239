import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectRooms from 'components/inputs/SelectRooms/SelectRooms';
import hydrateSelectedRooms from 'state/selectRooms/operations/hydrateSelectedRooms';
import MenuList from './components/menuList/MenuList';

class SelectRoomConfigurations extends Component {
  render() {
    const { selectRooms, hydrateSelectedRooms, airlineWhitelabel, isFrontPage, isHotelPage } = this.props;

    return (
      <SelectRooms
        selectRooms={selectRooms}
        menuList={MenuList}
        hydrateSelectedRooms={hydrateSelectedRooms}
        airlineWhitelabel={airlineWhitelabel}
        isFrontPage={isFrontPage}
        isHotelPage={isHotelPage}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    selectRooms: state.selectRooms,
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hydrateSelectedRooms() {
      dispatch(hydrateSelectedRooms());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectRoomConfigurations);

import setTripxCacheKey from 'httpClient/aws/headers/setTripxCacheKey';
import HttpClient from 'httpClient';
import { urlBuilder } from 'httpClient/aws/getAwsEndpoint';

const methodProxyCache = '/pc';
const countriesMethod = 'websites/countries';
const allCountriesMethod = 'websites/allCountries';

export function getCountries(websiteId) {
  const config = getCountriesCacheConfig({ method: countriesMethod, websiteId });
  const url = urlBuilder({ method: methodProxyCache, queryParam: null });

  return HttpClient.post(url, { httpMethod: 'GET', timeout: 20, version: 3, apiMethod: countriesMethod }, config);
}

export function getAllCountries(websiteId) {
  const config = getCountriesCacheConfig({ method: allCountriesMethod, websiteId });
  const url = urlBuilder({ method: methodProxyCache, queryParam: null });

  return HttpClient.post(url, { httpMethod: 'GET', timeout: 20, version: 3, apiMethod: allCountriesMethod }, config);
}

function getCountriesCacheConfig(websiteId) {
  return { headers: setTripxCacheKey(websiteId) };
}

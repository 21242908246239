import React from 'react';
import { FormattedMessage } from 'i18n';
import styled from 'styled-components';
import MessageBox from 'components/messageBox/MessageBoxComponent';
import * as SEVERITY from 'constants/severity';
import { shake } from 'styles/utils/animations';

function MoreInfantsThanAdults({ isMoreInfantsThanAdults }) {
  if (!isMoreInfantsThanAdults) {
    return null;
  }
  return (
    <MessageBoxWrap>
      <MessageBox severity={SEVERITY.ERROR} filled>
        <FormattedMessage
          id={'selectRooms.component.moreInfantsThanAdults'}
          description={'Number of infants can not be larger than adults'}
          defaultMessage={'Number of infants can not be larger than adults'}
        />
      </MessageBox>
    </MessageBoxWrap>
  );
}

export default MoreInfantsThanAdults;

const MessageBoxWrap = styled.div`
  flex: 1 0 auto;
  text-align: center;
  margin: 1rem;
  animation: ${shake} 0.72s ease-out;
`;

import { Component } from 'react';
import { connect } from 'react-redux';
import executeLog from 'state/logger/operations/executeLog';

// This component was created in order not to re-render the Layout component each time we update the logger reducer.

class Logger extends Component {
  componentDidMount() {
    const { logger, executeLog } = this.props;

    logger.queue.forEach(executeLog);
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    logger: state.logger,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    executeLog(item) {
      dispatch(executeLog(item));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logger);

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DropdownSearchComponent from './DropdownSearchComponent';
import { resetInputsHiddenOnMobile } from 'state/searchContainer/actions';

export default function DropdownSearch({
  isSearchPage,
  isHotelPage,
  hasSearchContainer,
  inputsHidden,
  headerRef,
  isMobile,
  menuContent,
}) {
  const inputsHiddenOnMobile = useSelector((state) => state.searchContainer.inputsHiddenOnMobile);
  const logoUrl = useSelector((state) => state.settings.value.style.logoUrl);
  const airlineWhitelabel = useSelector((state) => state.settings.value.airlineWhitelabel);
  const transitionToCheckout = useSelector((state) => state.templates.hotel.transitionToCheckout);
  const headerHeight = useSelector((state) => state.refs.headerHeight);
  const hideLogo = useSelector(
    (state) => state.settings.value.customSettings && state.settings.value.customSettings.hideLogo
  );
  const { isCheckoutPage, isNotFoundPage } = useSelector((state) => state.currentPageComponent);
  const customSettings = useSelector((state) => state.settings.value.customSettings);

  const dispatch = useDispatch();

  const handleOnClickClose = () => {
    if (!inputsHiddenOnMobile) {
      dispatch(resetInputsHiddenOnMobile());
    }
  };

  return (
    <DropdownSearchComponent
      isSearchPage={isSearchPage}
      isHotelPage={isHotelPage}
      hasSearchContainer={hasSearchContainer}
      showDropdownSearch={!inputsHiddenOnMobile}
      inputsHidden={inputsHidden}
      logoUrl={logoUrl}
      airlineWhitelabel={airlineWhitelabel}
      transitionToCheckout={transitionToCheckout}
      headerRef={headerRef}
      onClickClose={handleOnClickClose}
      headerHeight={headerHeight}
      isMobile={isMobile}
      isCheckoutPage={isCheckoutPage}
      hideLogo={hideLogo}
      menuContent={menuContent}
      isNotFoundPage={isNotFoundPage}
      customSettings={customSettings}
    />
  );
}

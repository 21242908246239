import moment from 'moment';
import getUnixFromUuid from 'utils/dateTime/getUnixFromUuid';
import localStorageBase from '../localStorageBase';

const abandonedCartLimit = 1;

const abandonedCartLocalStorage = (function (ls) {
  const key = 'abandoned_cart_list';

  function getAbandonedCartList() {
    const persistedAbandonedCartList = ls.get(key);

    return persistedAbandonedCartList;
  }

  function getAbandonedCartByUuid(uuid) {
    const persistedCheckoutState = getPersistedAbandonedCart(uuid);

    if (persistedCheckoutState && persistedCheckoutState.uuid === uuid) {
      return persistedCheckoutState.data;
    }

    return null;
  }

  function getPersistedAbandonedCart(uuid) {
    const persistedAbandonedCartList = ls.get(key);

    if (persistedAbandonedCartList && persistedAbandonedCartList.length > 0) {
      const persistedAbandonedCart = persistedAbandonedCartList.find((abandonedCart) => abandonedCart.uuid === uuid);

      if (persistedAbandonedCart) {
        const timeOfPersistence = getUnixFromUuid(persistedAbandonedCart.uuid);
        const secondsSincePersisted = Date.now() / 1000 - timeOfPersistence;
        const daysSincePersisted = secondsSincePersisted / 3600 / 24;
        const validPackage = daysSincePersisted < 14;
        const validDepartureDate = moment().isBefore(
          persistedAbandonedCart.data.hotelsSearchRequestParameters.departureDate
        );

        if (!validPackage || !validDepartureDate) {
          removeAbandonedCartByUuid(uuid);
          return null;
        }

        return persistedAbandonedCart;
      }
    }

    return null;
  }

  function insertOrUpdateAbandonedCart(data) {
    const persistedAbandonedCartList = ls.get(key) || [];
    const persistedAbandonedCartIndex = persistedAbandonedCartList.findIndex((item) => item.uuid === data.uuid);

    if (persistedAbandonedCartIndex !== -1) {
      persistedAbandonedCartList[persistedAbandonedCartIndex] = data;
    } else {
      persistedAbandonedCartList.push(data);
    }

    if (abandonedCartLimit < persistedAbandonedCartList.length) {
      persistedAbandonedCartList.splice(0, persistedAbandonedCartList.length - abandonedCartLimit);
    }

    ls.set(key, persistedAbandonedCartList);
  }

  function removeAbandonedCartByUuid(uuid) {
    const persistedAbandonedCartList = ls.get(key);

    if (persistedAbandonedCartList && persistedAbandonedCartList.length > 0) {
      const abandonedCartIndex = persistedAbandonedCartList.findIndex((abandonedCart) => abandonedCart.uuid === uuid);

      if (abandonedCartIndex !== -1) {
        const updatedAbandonedCartList = [
          ...persistedAbandonedCartList.slice(0, abandonedCartIndex),
          ...persistedAbandonedCartList.slice(abandonedCartIndex + 1),
        ];

        ls.set(key, updatedAbandonedCartList);
      }

      return abandonedCartIndex;
    }

    return null;
  }

  return {
    get: getAbandonedCartList,
    getByUuid: getAbandonedCartByUuid,
    insertOrUpdate: insertOrUpdateAbandonedCart,
    removeByUuid: removeAbandonedCartByUuid,
  };
})(localStorageBase);

export default abandonedCartLocalStorage;

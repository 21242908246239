import React from 'react';
import { Margin } from 'styled-components-spacing';
import { FormattedMessage } from 'i18n';
import ButtonPrimary from 'components/buttons/ButtonPrimaryComponent';
import ButtonPrimaryNorwegian from 'components/buttons/ButtonPrimaryComponent.Norwegian';

const SearchButtonComponentFrontPage = ({ onClick, airlineWhitelabel }) => (
  <>
    <Margin top={{ tiny: 1 }} />
    {!airlineWhitelabel || (airlineWhitelabel && !airlineWhitelabel.isNorwegian) ? (
      <ButtonPrimary onClick={onClick} isAirBaltic={airlineWhitelabel && airlineWhitelabel.isAirBaltic}>
        <FormattedMessage id='search.component.search.button.label' description='Search' defaultMessage='Search' />
      </ButtonPrimary>
    ) : (
      <ButtonPrimaryNorwegian onClick={onClick}>
        <FormattedMessage id='search.component.search.button.label' description='Search' defaultMessage='Search' />
      </ButtonPrimaryNorwegian>
    )}
  </>
);

export default SearchButtonComponentFrontPage;

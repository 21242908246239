import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFetching(fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      fetching,
    },
  };
}

export function setPaymentOptionsValue(value) {
  return {
    type: types.SET_PAYMENT_OPTIONS_VALUE,
    payload: {
      value,
    },
  };
}

export function setSelectedPaymentOption(selectedPaymentOption) {
  return {
    type: types.SET_SELECTED_PAYMENT_OPTION,
    payload: {
      selectedPaymentOption,
    },
  };
}

export function setPartialPaymentOptionsRequestProperties(partialPaymentOptionsRequestProperties) {
  return {
    type: types.PARTIAL_PAYMET_OPTIONS_REQUEST_PROPERTIES,
    payload: {
      partialPaymentOptionsRequestProperties,
    },
  };
}

export function setAllowedPaymentOptions(allowedPaymentOptions) {
  return {
    type: types.SET_ALLOWED_PAYMENT_OPTIONS,
    payload: {
      allowedPaymentOptions,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

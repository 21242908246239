import queryString from 'query-string';
import { WEB_CODE, WEB_COUNTRY } from '../constants';

function getWebsitePickerValueFromQuery() {
  const query = queryString.parse(window.location.search);
  let value;

  if (query[WEB_CODE]) {
    value = `${query[WEB_CODE]}`;
  } else {
    value = `null`;
  }

  if (query[WEB_COUNTRY]) {
    value = value + `-${query[WEB_COUNTRY].toUpperCase()}`;
  }

  if (value === 'null') {
    value = 'http://www.tripx.se-SE';
  }
  return value;
}

export default getWebsitePickerValueFromQuery;

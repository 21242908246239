import React from 'react';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Margin } from 'styled-components-spacing';
import { FormattedMessage } from 'i18n';
import Container from 'components/utils/Container';
import MenuList from 'components/menus/menuList/MenuListComponent';
import LocaleFlags from './components/localeFlags/LocaleFlagsComponent';
import ContactInfo from './components/ContactInfoComponent';
import LogosPayment from './components/LogosPayment';

// const TripXWhiteLogo = 'https://tripx-wp.imgix.net/3/2019/05/tripx_logo_vector_nobg.svg?auto=format';
const defaultMessage =
  'Arrangör av denna resa är vår partner TripX Travel AB som svarar för bekräftelse, biljetter och support. Som resenär omfattas du av den svenska resegarantilagen';

const copyRightDesc = 'Copyright text';
const copyRightDefaultMessage =
  '© Copyright 2023 — TripX Travel AB — Org.nr 556813-0099 — Mva reg nr: SE556813009901 — Stora Trädgårdsgatan 30, 211 28 Malmö — info@tripx.se';

function FooterComponent({
  menuContent,
  menuContent2,
  phoneNumber,
  openingHours,
  isAffiliate,
  companyFullName,
  hostname,
  checkoutOnlyWhitelabel,
  isVolaRo,
  isHolidayPirates,
}) {
  return (
    <Root isVolaRo={isVolaRo}>
      {checkoutOnlyWhitelabel && (
        <CheckoutOnlyNote>
          <FormattedMessage
            id='footer.checkoutOnlyWhitelabel.message'
            description={defaultMessage}
            defaultMessage={defaultMessage}
          />
        </CheckoutOnlyNote>
      )}
      <div className='footer'>
        <Container>
          <Grid style={{ justifyContent: 'center' }}>
            <Grid.Unit size={{ tiny: 7 / 12, phone: 6 / 12, desktop: 3 / 12 }}>
              <Margin top={{ tiny: 2, desktop: 4 }}>
                <h2>
                  <FormattedMessage id='footer.component.menu.title.faq' description='FAQ' defaultMessage='FAQ' />
                </h2>
                <MenuList items={menuContent} config={{ vertical: true }} />
              </Margin>
            </Grid.Unit>
            <Grid.Unit size={{ tiny: 5 / 12, phone: 6 / 12, desktop: 3 / 12 }}>
              <Margin top={{ tiny: 2, desktop: 4 }}>
                <h2>
                  <FormattedMessage
                    id='footer.component.menu.title.other'
                    description='Other Menu'
                    defaultMessage='Other Menu'
                  />
                </h2>
                <MenuList items={menuContent2} config={{ vertical: true }} />
                <LocaleFlags hostname={hostname} />
              </Margin>
            </Grid.Unit>
            {phoneNumber && <ContactInfo phoneNumber={phoneNumber} openingHours={openingHours} />}
          </Grid>
          <Margin top={{ tiny: 3 }}>
            {/*<Logos>*/}
            {/*  { checkoutOnlyWhitelabel && <Logo src={TripXWhiteLogo} alt='logo-tripx' checkoutOnlyWhitelabel tripxLogo /> }*/}
            {/*  <Logo src={logoWhiteUrl} alt='logo' checkoutOnlyWhitelabel />*/}
            {/*</Logos>*/}
            <Margin top={{ tiny: 1 }}>
              <p className='companyInfo'>
                {isAffiliate ? (
                  isHolidayPirates ? (
                    <FormattedMessage
                      id='footer.component.copyright.affiliate.HPR'
                      description={copyRightDesc}
                      defaultMessage={copyRightDefaultMessage}
                    />
                  ) : (
                    <FormattedMessage
                      id='footer.component.copyright.affiliate'
                      description={copyRightDesc}
                      defaultMessage={
                        '© Copyright 2023 - Teknisk produsert for {companyFullName} av TripX Travel AB - Org.nr 556813-0099 (Mva reg nr: SE556813009901)- Stora Trädgårdsgatan 30, 211 28 Malmö - info@tripx.se'
                      }
                      values={{
                        companyFullName,
                      }}
                    />
                  )
                ) : (
                  <FormattedMessage
                    id='footer.component.copyright'
                    description={copyRightDesc}
                    defaultMessage={copyRightDefaultMessage}
                  />
                )}
              </p>
            </Margin>
          </Margin>
        </Container>
        {!isVolaRo && <LogosPayment />}
      </div>
    </Root>
  );
}

export default FooterComponent;

const Root = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;

  .footer {
    background-color: ${({ theme }) =>
      theme.colors.footer.background ? theme.colors.footer.background : theme.colors.secondary.default};
    position: relative;

    * {
      color: ${({ theme }) => theme.colors.footer.text};
    }
    input {
      color: ${({ theme }) => theme.colors.text.default};
    }

    h2 {
      font-size: 20px;
      margin-bottom: 0.5rem;
    }
    ul {
      li {
        font-size: 15px;
        line-height: 28px;
        font-weight: 500;
      }
    }
  }

  .companyInfo {
    text-align: center;
    padding-bottom: ${({ isVolaRo }) => (isVolaRo ? '14px' : 'initial')};
  }
`;

const CheckoutOnlyNote = styled.div`
  padding: 24px;
  font-size: 1.4em;
  text-align: center;
  color: #3faab4;
`;

// const Logos = styled.div`
//   display: flex;
//   justify-content: center;
// `;

// const Logo = styled.img`
//   height: 30px;
//   display: block;
//   margin: ${({ checkoutOnlyWhitelabel }) => (checkoutOnlyWhitelabel ? '0' : '0 auto')};
//   margin-right: ${({ tripxLogo }) => (tripxLogo ? '16px' : '0')};
// `;

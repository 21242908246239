import React from 'react';
import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import { Margin } from 'styled-components-spacing';

import { FormattedMessage, FormattedCurrency } from 'i18n';

const PriceBarTooltip = ({ tooltipData, children }) => (
  <Tooltip
    placement='top'
    trigger={['hover']}
    mouseLeaveDelay={0}
    destroyTooltipOnHide={true}
    prefixCls={'price-bar-tooltip'}
    overlay={<PriceBarTooltipOverlay {...tooltipData} />}
  >
    {children}
  </Tooltip>
);

export default PriceBarTooltip;

const PriceBarTooltipOverlay = ({ days, price }) => (
  <PriceBarTooltipOverlayStyled>
    <Days>
      {days}
      <Margin right='0.5' inline={true} />
      <FormattedMessage id='priceBarTooltip.component.days.label' description='days' defaultMessage='days' />
    </Days>
    <Price>
      <FormattedCurrency value={price} />
    </Price>
  </PriceBarTooltipOverlayStyled>
);

const PriceBarTooltipOverlayStyled = styled.div`
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.adjacent.default};
  border: none;
  border-radius: 3px;
  text-align: center;
`;

const Days = styled.div`
  margin-bottom: 5px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.white};
`;

const Price = styled.div`
  font-size: 15px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text.white};
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuList from 'components/menus/menuList/MenuListComponent';
import NumberedHeart from './components/numberedHeart/NumberedHeart';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  menuContent: PropTypes.arrayOf(
    PropTypes.shape({
      menuOrder: PropTypes.number,
      slug: PropTypes.string,
      target: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  isActive: PropTypes.bool.isRequired,
};

function HeaderMenuComponent({ menuContent, isActive, onClickClose }) {
  return (
    <Root isActive={isActive}>
      <MenuList items={menuContent} onClick={onClickClose} />
      <NumberedHeart />
      <CloseButton onClick={onClickClose} />
    </Root>
  );
}

HeaderMenuComponent.propTypes = propTypes;

export default HeaderMenuComponent;

const Root = styled.div`
  display: block;
  width: calc(100vw - 31px);
  right: ${({ isActive }) => (isActive ? '0' : 'calc(-100vw - 31px)')};
  position: fixed;
  bottom: 0;
  top: 0;
  margin-left: 31px;
  padding: 80px 26px;
  background-color: ${({ theme }) => theme.colors.primary.light};
  border-left: 1px solid ${({ theme }) => theme.colors.primary.default};
  z-index: 4;
  transition: right 0.3s ease-out;

  a {
    display: block;
    padding: 8px 0;
    color: ${({ theme }) => theme.colors.primary.dark};
    font-size: 28px;
    font-weight: bold;
    text-shadow: none;
  }

  ${mediaQueries.fromAndAbove.l`
    display: none;
  `}
`;

const CloseButton = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(-50%);
  margin-bottom: 50px;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primary.default};
  border: none;
  border-radius: 50%;
  cursor: pointer;

  &:before {
    content: '\\d7';
    position: absolute;
    top: 34%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ theme }) => theme.colors.primary.dark};
    font-size: 50px;
  }
`;

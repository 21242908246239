import React from 'react';
import styled from 'styled-components';
import { useCircleScroll } from './hooks/useCircleScroll';

function CircleScroll({ children }) {
  const { scrollTop, scrollableContentRef, thumbRef } = useCircleScroll();
  return (
    <Root>
      <Track ref={scrollableContentRef}>
        <Content>{children}</Content>
      </Track>
      <Scrollbar>
        <Thumb scrollTop={scrollTop} ref={thumbRef} />
      </Scrollbar>
    </Root>
  );
}

export default CircleScroll;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Scrollbar = styled.div`
  position: absolute;
  top: 10%;
  right: 25px;
  width: 2px;
  height: 80%;
  background: ${({ theme }) => theme.colors.background.light};
`;

const Track = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
`;

const Thumb = styled.div`
  position: relative;
  top: ${({ scrollTop }) => `${scrollTop}%`};
  right: 5px;
  width: 12px;
  height: 12px;
  background: ${({ theme }) => theme.colors.adjacent.default};
  border-radius: 50%;
  transition: top 0.5s;
  cursor: grab;
`;

const Content = styled.div`
  padding-right: 15%;
  box-sizing: content-box;
`;

import React, { Component } from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';

export default function withControlledReactSelect() {
  return function (WrappedComponent) {
    return class extends Component {
      constructor(props) {
        super(props);

        this.state = {
          inputValue: '',
          menuIsOpen: false,
        };

        this.handleOnChangeInputValue = this.handleOnChangeInputValue.bind(this);
        this.handleOnClickForegroundElement = this.handleOnClickForegroundElement.bind(this);
        this.handleOnRequestClose = this.handleOnRequestClose.bind(this);
      }

      handleOnChangeInputValue(event) {
        this.setState({
          inputValue: event.currentTarget.value,
        });
      }

      handleOnClickForegroundElement(event) {
        this.setState({
          menuIsOpen: true,
        });
      }

      handleOnRequestClose() {
        this.setState({
          menuIsOpen: false,
          inputValue: '',
        });
      }

      render() {
        return (
          <Wrap>
            <WrappedComponent
              {...this.props}
              {...this.state}
              onChangeInputValue={this.handleOnChangeInputValue}
              onRequestClose={this.handleOnRequestClose}
            />
            <Foreground onClick={this.handleOnClickForegroundElement} />
          </Wrap>
        );
      }
    };
  };
}

const Wrap = styled.div`
  position: relative;
  width: 100%;
`;

const Foreground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  ${mediaQueries.fromAndAbove.tablet`
    display: none;
  `}
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import flexbox from 'styles/utils/flexbox';
import { bounceSimple } from 'styles/utils/animations';

const propTypes = {
  translateAmount: PropTypes.number,
  fontSize: PropTypes.number,
};

const defaultProps = {
  translateAmount: 20,
  fontSize: 13,
};

function ScrollIndicatorComponent({ translateAmount, fontSize, icon = 'chevron-down' }) {
  return (
    <Root translateAmount={translateAmount} fontSize={fontSize}>
      <FontAwesomeIcon icon={icon} />
      <FontAwesomeIcon icon={icon} />
    </Root>
  );
}

ScrollIndicatorComponent.propTypes = propTypes;

ScrollIndicatorComponent.defaultProps = defaultProps;

export default ScrollIndicatorComponent;

const bounceSimpleRule = (translateAmount) => css`
  animation-name: ${bounceSimple(translateAmount)};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
`;

const Root = styled.div`
  ${flexbox({
    flexDirection: 'column',
  })}

  font-size: ${({ fontSize }) => fontSize}px;
  color: ${({ theme }) => theme.colors.primary.default};
  z-index: 3000;

  ${({ translateAmount }) => css`
    ${bounceSimpleRule(translateAmount)};
  `}
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NOT_FOUND as TRACKING_EVENT_NOT_FOUND } from 'managers/gtmEventTracking/constants/eventsNames';
import { FRONTPAGE as ROUTE_KEY_FRONTPAGE } from 'constants/routesKeys';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import { getRoutePathByRouteKey } from 'state/routes/operations';
import NotFoundComponent from 'routes/notFound/NotFoundComponent';
import { setCurrentPageComponent } from 'state/currentPageComponent/actions';
import { NOT_FOUND_PAGE } from 'constants/pagesComponentsKeys';

class NotFound extends Component {
  constructor(props) {
    super(props);

    this.pathToFrontpage = props.getRoutePathByRouteKey(ROUTE_KEY_FRONTPAGE);
  }

  componentDidMount() {
    const { executeTrackingEvent, setCurrentPageComponent } = this.props;
    setCurrentPageComponent(NOT_FOUND_PAGE);
    executeTrackingEvent(TRACKING_EVENT_NOT_FOUND);
  }

  render() {
    return <NotFoundComponent pathToFrontpage={this.pathToFrontpage} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getRoutePathByRouteKey(routeKey) {
      return dispatch(getRoutePathByRouteKey(routeKey));
    },
    executeTrackingEvent(eventName, eventData) {
      dispatch(executeTrackingEvent(eventName, eventData));
    },

    setCurrentPageComponent(currentPage) {
      dispatch(setCurrentPageComponent(currentPage));
    },
  };
}

export default connect(null, mapDispatchToProps)(NotFound);

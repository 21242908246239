import getTransferByProductToken from 'state/checkoutPage/transfers/selectors/getTransferByProductToken';
import getVehicleRentalsByProductToken from 'state/checkoutPage/vehicleRental/selectors/getVehicleRentalsByProductTokens';
import { getInsuranceByProductToken } from 'state/checkoutPage/insurances/selectors';
import getCancellationProtectionByProductToken from 'state/checkoutPage/cancellationProtections/selectors/getCancellationProtectionByProductToken';

export default function getSelectedOtherExtrasObservedProps(
  selectedOtherExtras,
  transfers,
  vehicleRentals,
  insurances,
  cancellationProtections
) {
  const { transfer, vehicleRentals: _vehicleRentals, insurance, cancellationProtection } = selectedOtherExtras;
  const selectedTransfer = getTransferByProductToken(transfers, transfer);
  const selectedVehicleRentals = getVehicleRentalsByProductToken(vehicleRentals, _vehicleRentals);
  const selectedInsurance = getInsuranceByProductToken(insurances, insurance);
  const selectedCancellationProtection = getCancellationProtectionByProductToken(
    cancellationProtections,
    cancellationProtection
  );
  const observedTransfer = selectedTransfer && {
    type: selectedTransfer.type,
    techPlatform: selectedTransfer.techPlatform,
    techType: selectedTransfer.techType,
  };
  const observedVehicleRentals = selectedVehicleRentals && selectedVehicleRentals.map(getVehicleRentalsObservedProps);
  const observedInsurance = selectedInsurance && {
    type: selectedInsurance.type,
    provider: selectedInsurance.provider,
  };
  const observedCancellationprotection = selectedCancellationProtection && {
    type: selectedCancellationProtection.type,
  };

  return {
    transfer: observedTransfer,
    vehicleRentals: observedVehicleRentals,
    insurance: observedInsurance,
    cancellationProtection: observedCancellationprotection,
  };
}

function getVehicleRentalsObservedProps(vehicleRental) {
  return {
    airCondition: vehicleRental.model.airCondition,
    baggageCapacity: vehicleRental.model.baggageCapacity,
    passengerCapacity: vehicleRental.model.passengerCapacity,
    transmissionType: vehicleRental.model.transmissionType,
  };
}

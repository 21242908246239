import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaQuery from 'react-responsive';

import Portal from 'components/portal/Portal';
import SelectModal from 'components/inputs/select/selectModal/SelectModalComponent';
import Content from './components/content/ContentComponent';
import FooterComponent from 'components/inputs/select/components/menuListMobile/components/footer/FooterComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';
import { breakpoints } from 'styles/themes/main';

const propTypes = {
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      numberOfAdults: PropTypes.number.isRequired,
      childrenAges: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ),
  eventHandlers: PropTypes.shape({
    onClickUpdateNumberOfAdults: PropTypes.func.isRequired,
    onClickUpdateNumberOfChildren: PropTypes.func.isRequired,
    onClickUpdateChildAge: PropTypes.func.isRequired,
    onClickAddRoom: PropTypes.func.isRequired,
    onClickRemoveRoom: PropTypes.func.isRequired,
  }),
};

const selectModalConfig = { content: { offset: 0 } };

function MenuListComponent({
  rooms,
  eventHandlers,
  open,
  filtersBarHeight,
  onRequestClose,
  numberOfAdults,
  numberOfChildren,
}) {
  selectModalConfig.content.offset = filtersBarHeight;

  return (
    <MediaQuery maxWidth={breakpoints.above.desktop}>
      {(matches) => (
        <Portal usePortal={matches}>
          <SelectModal open={open} config={{ ...selectModalConfig, preventHtmlScroll: matches }}>
            <Root>
              <Header>
                <Title>
                  <FormattedMessage
                    id='roomsConfiguration.label'
                    description='Rooms Configuration'
                    defaultMessage='Rooms Configuration'
                  />
                </Title>
                <Close onClick={onRequestClose}>
                  <FontAwesomeIcon icon='chevron-left' />
                </Close>
              </Header>
              <Content
                rooms={rooms}
                eventHandlers={eventHandlers}
                numberOfAdults={numberOfAdults}
                numberOfChildren={numberOfChildren}
              />
              <FooterComponent onRequestClose={onRequestClose} />
            </Root>
          </SelectModal>
        </Portal>
      )}
    </MediaQuery>
  );
}

MenuListComponent.propTypes = propTypes;

export default MenuListComponent;

const Root = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  height: -webkit-fill-available;
  background: ${({ theme }) => theme.colors.background.greyV2};

  ${mediaQueries.fromAndAbove.desktop`
    user-select: initial;
    display: initial;
    flex-direction: initial;
    width: initial;
    background: initial;
  `}
`;

const Header = styled.div`
  z-index: 2;
  min-height: 48px;
  position: sticky;
  top: 0;
  padding: 18px;
  background: ${({ theme }) => theme.colors.background.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.greyV3};
  display: flex;
  justify-content: center;
  align-items: center;
  ${mediaQueries.fromAndAbove.desktop`
    display: none;
  `}
`;

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.darkGrey};
`;

const Close = styled.div`
  display: flex;
  height: 100%;
  color: ${({ theme }) => theme.colors.adjacent.default};
  position: absolute;
  top: 18px;
  left: 18px;
  font-size: 20px;
`;

import moment from 'moment';
import { updateSelectedDates } from './updateSelectedDates';

export default function calculateAndUpdateSelectedDates(fromDate, numberOfDays) {
  return (dispatch) => {
    const startDate = moment(fromDate);
    const daysToAdd = parseInt(numberOfDays) - 1;

    dispatch(
      updateSelectedDates({
        startDate: startDate,
        endDate: moment(fromDate).add(daysToAdd, 'days'),
        flexibleDates: false,
      })
    );
  };
}

import React from 'react';
import { components } from 'react-select';
import axis from 'axis.js';
import { FormattedMessage } from 'i18n';

function MultiValueLabel(props) {
  // To be able to filter the options by aliases we need to include them in the label.
  // Then we override the react-select Option and MultiValueLabel component to display only the city.name.

  let label = '';
  if (props.data && props.data.label) {
    label = axis.isString(props.data.label) ? (
      props.data.label.slice(0, props.data.label.indexOf('-'))
    ) : (
      <FormattedMessage {...props.data.label} />
    );
  }

  return <components.MultiValueLabel {...props}>{label}</components.MultiValueLabel>;
}

export default MultiValueLabel;

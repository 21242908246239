import { setValidity } from '../actions';

export default function validateCancellationProtectionsData() {
  return (dispatch, getState) => {
    const { fetching, cancellationProtections, validity } = getState().checkoutPageCancellationProtections;

    if (fetching) {
      return false;
    }

    if (
      !cancellationProtections ||
      (cancellationProtections && cancellationProtections.length === 0) ||
      validity.valid
    ) {
      return true;
    }

    dispatch(
      setValidity({
        valid: false,
        message: {
          id: 'requiredField.message',
          description: 'Please select one of the given options',
          defaultMessage: 'Please select one of the given options',
        },
      })
    );

    return false;
  };
}

import React from 'react';
import styled from 'styled-components';

export default function CalendarDay({ children, ...props }) {
  return <Day {...props}>{children}</Day>;
}

const Day = styled('span')(({ isDisabled, theme }) => ({
  position: 'relative',
  background: isDisabled ? theme.colors.primary.light : 'transparent',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    color: isDisabled ? theme.colors.text.disabled : 'inherit',
    backgroundColor: isDisabled ? theme.colors.background.disabled : 'inherit',
  },
}));

export const CrossLine = styled('div')(() => ({
  position: 'absolute',
  top: '75%',
  right: '2px',
  width: '30px',
  borderTop: '1px solid black' /* Adjust line thickness and color as needed */,
  zIndex: 1,
  transform: 'rotate(-45deg)',
  transformOrigin: 'top left',
  opacity: 0.3,
}));

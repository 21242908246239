import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StageCountries from './components/stageCountries/StageCountriesComponent';
import StageDestinations from './components/stageDestinations/StageDestinationsComponent';
import StageHotelResults from './components/stageHotelResults/StageHotelResults';
import NoOptions from './components/noOptions/NoOptions';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';
import ScrollIndicatorComponent from 'components/indicators/scrollIndicator/ScrollIndicatorComponent';

const propTypes = {
  countries: PropTypes.array.isRequired,
  destinations: PropTypes.array.isRequired,
  hotels: PropTypes.array,
  showCountriesAndDestinations: PropTypes.bool.isRequired,
  showNoOptionsMessage: PropTypes.bool.isRequired,
  handleOnClickCountry: PropTypes.func.isRequired,
};

function MenuListComponent({
  countries,
  highlightedCountries,
  destinations,
  hotels,
  showCountriesAndDestinations,
  showNoOptionsMessage,
  handleOnClickCountry,
  selectedOptions,
  isFiltering,
}) {
  return (
    <Root>
      {selectedOptions.length ? (
        <SelectedRoot>
          <SelectedOptions>{selectedOptions}</SelectedOptions>
          {selectedOptions.length > 12 ? (
            <ScrollIndicatorWrap>
              <ScrollIndicatorComponent />
            </ScrollIndicatorWrap>
          ) : null}
        </SelectedRoot>
      ) : null}
      {showCountriesAndDestinations && (
        <BottomColumn>
          <StageCountries
            hotels={hotels}
            countries={countries}
            highlightedCountries={highlightedCountries}
            handleOnClickCountry={handleOnClickCountry}
            isDestinationOpen={Boolean(destinations.length)}
            isFiltering={isFiltering}
          />
          {destinations.length ? <StageDestinations destinations={destinations} /> : null}
        </BottomColumn>
      )}
      {showNoOptionsMessage && <NoOptions />}
      <StageHotelResults
        results={hotels}
        countriesAndDestinationsVisible={showCountriesAndDestinations}
        showCountriesAndDestinations={showCountriesAndDestinations}
      />
    </Root>
  );
}

MenuListComponent.propTypes = propTypes;

export default MenuListComponent;

const Root = styled.div`
  ${flexbox({
    flexDirection: 'column',
  })}
  position: relative;
  max-height: 600px;
  gap: 8px;
  padding: 4px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary.default};
    border-radius: 10px;
  }
`;

const BottomColumn = styled.div`
  ${flexbox({})}
  flex: 1 0 auto;
  gap: 8px;
  height: 250px;
`;

const SelectedRoot = styled.div`
  position: relative;
`;

const SelectedOptions = styled.div`
  display: grid;
  border-radius: 8px;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(140px, auto));
  max-height: 160px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary.default};
    border-radius: 10px;
  }

  ${mediaQueries.fromAndBelow.desktop`
    max-height: 240px;
  `}
`;

const ScrollIndicatorWrap = styled.div`
  position: absolute;
  right: 4px;
  bottom: 5px;
  display: flex;
  justify-content: flex-end;
`;

import React from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import { FormattedMessage } from 'i18n';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';

function AgentTopBarComponent({ agentId, onClickLogout }) {
  return (
    <Root>
      <Message>
        <FormattedMessage
          id={'youAreLoggedInAsAgent.label'}
          description={'You are logged in as agent'}
          defaultMessage={'You are logged in as agent'}
        >
          {(message) => `${message}: ${agentId}`}
        </FormattedMessage>
      </Message>
      <Margin right={'1'} inline />
      <LogoutButton onClick={onClickLogout}>
        <FormattedMessage id={'logout.label'} description={'Logout'} defaultMessage={'Logout'} />
      </LogoutButton>
    </Root>
  );
}

export default AgentTopBarComponent;

const Root = styled.div`
  ${flexbox({
    justifyContent: 'space-between',
  })}

  height: 30px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  padding: 5px 25px;
  background-color: ${({ theme }) => theme.colors.background.level.info[0]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.default};

  ${mediaQueries.fromAndAbove.desktop`
    ${flexbox({
      justifyContent: 'flex-end',
    })}
  `}
`;

const Message = styled.div`
  font-size: 1.4em;
  text-align: center;
  line-height: 1.4em;
  color: ${({ theme }) => theme.colors.background.level.info[1]};
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  font-size: 1.4em;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.buttons.red};
  cursor: pointer;
`;

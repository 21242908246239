import { updateUpgradesValidity } from '../actions';

export default function validateFlightsDetails() {
  return (dispatch, getState) => {
    const { checkoutPageFlightsDetails, checkoutPagePackagesSearch } = getState();
    const { fetching, value, validity, upgradesValidity } = checkoutPageFlightsDetails;
    const { hotelOnly } = checkoutPagePackagesSearch.value;

    if (hotelOnly) {
      return true;
    }

    if (fetching) {
      return false;
    }

    if (value !== null && validity.valid) {
      let upgradesAreValid = true;

      Object.keys(upgradesValidity).forEach((serviceKey) => {
        serviceKey &&
          Object.keys(upgradesValidity[serviceKey]).forEach((direction) => {
            direction &&
              Object.keys(upgradesValidity[serviceKey][direction]).forEach((passengerType) => {
                passengerType &&
                  Object.keys(upgradesValidity[serviceKey][direction][passengerType]).forEach((passengerIndex) => {
                    if (upgradesValidity[serviceKey][direction][passengerType][passengerIndex]) {
                      if (!upgradesValidity[serviceKey][direction][passengerType][passengerIndex].valid) {
                        upgradesAreValid = false;
                        dispatch(
                          updateUpgradesValidity(serviceKey, direction, passengerType, passengerIndex, {
                            valid: false,
                            message: { id: 'requiredField.message' },
                          })
                        );
                      }
                    }
                  });
              });
          });
      });

      return upgradesAreValid;
    }

    return false;
  };
}

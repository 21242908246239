import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFloatingPackagePriceClicked() {
  return {
    type: types.SET_FLOATING_PACKAGE_PRICE_CLICKED,
  };
}

export function setFloatingPackagePriceVisibility(visible) {
  return {
    type: types.SET_FLOATING_PACKAGE_PRICE_VISIBILITY,
    payload: {
      visible,
    },
  };
}

export function setFloatingPackagePriceStyle(style) {
  return {
    type: types.SET_FLOATING_PACKAGE_PRICE_STYLE,
    payload: {
      style,
    },
  };
}

export function setTransitionToCheckout(transitionToCheckout) {
  return {
    type: types.SET_TRANSITION_TO_CHECKOUT,
    payload: {
      transitionToCheckout,
    },
  };
}

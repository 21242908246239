import React from 'react';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const transitionClassNames = 'banner-component';
const transitionDuration = 300;

function BannerComponent({
  rootRef,
  content,
  hasClose,
  styles = {},
  dismissed,
  bannerHeight,
  onClickClose,
  onTransitionExited,
  bgColor,
}) {
  return (
    <CSSTransition
      in={!dismissed}
      timeout={transitionDuration}
      classNames={transitionClassNames}
      unmountOnExit
      onExited={onTransitionExited}
    >
      <Root ref={(ref) => (rootRef.current = ref)} styles={styles.root} bannerHeight={bannerHeight} bgColor={bgColor}>
        {hasClose && (
          <CloseIcon styles={styles.closeIcon} onClick={onClickClose}>
            <FontAwesomeIcon icon='times' />
          </CloseIcon>
        )}
        {content}
      </Root>
    </CSSTransition>
  );
}

export default BannerComponent;

const Root = styled.div`
  position: relative;
  background-color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.colors.adjacent.default)};
  border-radius: 4px;

  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `}

  &.${transitionClassNames}-exit {
    opacity: 1;
    height: ${({ bannerHeight }) => (bannerHeight ? `${bannerHeight}px` : 'inherit')};
  }

  &.${transitionClassNames}-exit-active {
    opacity: 0;
    height: ${({ bannerHeight }) => (bannerHeight ? '0px' : 'inherit')};
    transition: opacity ${transitionDuration}ms, height ${transitionDuration}ms;
  }

  &.${transitionClassNames}-exit-done {
    display: none;
  }
`;

const CloseIcon = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 14px;
  background: none;
  border: none;
  font-size: 16px;
  color: ${({ styles, theme }) =>
    styles && styles.lightBackground ? theme.colors.background.placeholder : theme.colors.text.white};
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

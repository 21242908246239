import getInitialVisibleMonth from '../selectors/getInitialVisibleMonth';
import { setCalendarHighlightsForReactDates, setAirlineCalendarAvailableDates } from '../actions';
import _groupBy from 'lodash/groupBy';
import _merge from 'lodash/merge';
import evalResetSelectedDates from 'state/selectDates/operations/evalResetSelectedDates';

function prepareDaysData(days, direction) {
  direction = direction.toLowerCase();

  for (const item in days) {
    if (direction == 'departure') days[item].leave = true;
    else days[item].return = true;
  }
  return days;
}

function parseDates(dates) {
  return dates.map((d) => ({
    year: parseInt(d.split('-')[0], 10),
    month: parseInt(d.split('-')[1], 10) - 1,
    day: parseInt(d.split('-')[2], 10),
  }));
}

function getAirlineHighlightsData(data) {
  let filteredData = [];
  if (data) {
    filteredData = data.map((item) => {
      const newDates = Object.fromEntries(Object.entries(item.dates).filter(([year, { isDirect }]) => isDirect));
      return { ...item, dates: newDates };
    });
  }
  return filteredData;
}

function transformCalendarData(data = [], destinations = [], shouldGetWhitelabelHighlights) {
  let result = {};

  if (destinations.length === 0) {
    return result;
  }

  data.forEach((ch) => {
    const dates = Object.keys(ch.dates);

    const parsedDates = parseDates([...new Set(dates)]);

    const group = _groupBy(parsedDates, 'year');

    Object.keys(group).forEach((yearKey) => {
      group[yearKey] = _groupBy(group[yearKey], 'month');
      Object.keys(group[yearKey]).forEach((monthKey) => {
        group[yearKey][monthKey] = prepareDaysData(_groupBy(group[yearKey][monthKey], 'day'), ch.direction);
      });
    });

    result = _merge(result, group);
  });

  return result;
}

export default function updateCalendarHighlightsForReactDates() {
  return async (dispatch, getState) => {
    const { selectDestinations, selectDates } = getState();
    const { airlineWhitelabel } = getState().settings.value;
    const selectedDestinationsValues = selectDestinations.selectedDestinations.map(({ value }) => value);
    const { calendarHighlights } = selectDates;

    const calendarDataForReactDates = transformCalendarData(
      calendarHighlights.highlights,
      selectedDestinationsValues,
      airlineWhitelabel
    );
    const initialVisibleMonth = getInitialVisibleMonth(calendarDataForReactDates);
    if (airlineWhitelabel) {
      const airlineHighlightsData = getAirlineHighlightsData(calendarHighlights.highlights);

      const calendarDataForAirlineHighlights = transformCalendarData(
        airlineHighlightsData,
        selectedDestinationsValues,
        airlineWhitelabel
      );
      dispatch(setAirlineCalendarAvailableDates(calendarDataForReactDates));
      await dispatch(setCalendarHighlightsForReactDates(calendarDataForAirlineHighlights, initialVisibleMonth));
    } else {
      await dispatch(setCalendarHighlightsForReactDates(calendarDataForReactDates, initialVisibleMonth));
    }
    dispatch(evalResetSelectedDates());
  };
}

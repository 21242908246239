import executeLog from './executeLog';
import { pushToQueue } from '../actions';

export default function log(item) {
  return (dispatch) => {
    try {
      if (!process.browser) {
        dispatch(pushToQueue(item));
      } else {
        dispatch(executeLog(item));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

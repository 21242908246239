import axios from 'axios';
import _difference from 'lodash/difference';
import { getOriginDestinations } from 'httpClient/requests/selectDestinations';
import updateAvailableOrigins from 'state/selectOrigins/operations/updateAvailableOrigins';
import updateSelectedDestinations from './updateSelectedDestinations';
import getAvailableDestinations from '../selectors/getAvailableDestinations';
import { setAvailableDestinations, setAvailableDestinationsMap } from '../actions';

export default function updateAvailableDestinationsForSelectedOrigins() {
  return (dispatch, getState) => {
    const { settings, selectOrigins, selectDestinations } = getState();
    const survivalMode = settings.value.survivalMode;
    const { availableDestinationsMap } = selectDestinations;
    const selectedOriginsCodes = selectOrigins.selectedOrigins.map(({ value }) => value);
    const availableOriginDestinations = Object.keys(availableDestinationsMap);
    const missingOriginDestinations = _difference(selectedOriginsCodes, availableOriginDestinations);

    if (missingOriginDestinations.length > 0) {
      const requests = missingOriginDestinations.map((origin) => getOriginDestinations(origin));

      axios
        .all(requests)
        .then((response) => {
          const mappedOriginDestinations = response.reduce((accumulator, currentValue, index) => {
            accumulator[missingOriginDestinations[index]] = currentValue.data;
            return accumulator;
          }, {});
          const _availableDestinationsMap = {
            ...availableDestinationsMap,
            ...mappedOriginDestinations,
          };
          const selectedOriginsCodes = getState().selectOrigins.selectedOrigins.map(({ value }) => value);
          const availableDestinations = getAvailableDestinations(_availableDestinationsMap, selectedOriginsCodes);
          const forceSingleValue = !survivalMode
            ? selectedOriginsCodes.length > 0 && availableDestinations.length === 0
            : true;

          dispatch(setAvailableDestinationsMap(availableDestinations, _availableDestinationsMap, forceSingleValue));
          dispatch(updateSelectedDestinationsBasedOnAvailableDestinations());
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      const availableDestinations = getAvailableDestinations(availableDestinationsMap, selectedOriginsCodes);
      const forceSingleValue = !survivalMode
        ? selectedOriginsCodes.length > 0 && availableDestinations.length === 0
        : true;

      dispatch(setAvailableDestinations(availableDestinations, forceSingleValue));
      dispatch(updateSelectedDestinationsBasedOnAvailableDestinations());
    }
  };
}

function updateSelectedDestinationsBasedOnAvailableDestinations() {
  return (dispatch, getState) => {
    const { availableDestinations, selectedDestinations } = getState().selectDestinations;

    if (availableDestinations.length > 0 && selectedDestinations.length > 0) {
      let filteredSelectedDestinations = selectedDestinations.filter(
        ({ value }) => availableDestinations.indexOf(value) !== -1
      );
      // There can be only one selected destination with value allForCountry
      const selectedAllForCountry = selectedDestinations
        .filter(({ allForCountry }) => allForCountry)
        .flatMap(({ destinations }) => destinations);
      const filteredFromAllForCountry =
        selectedAllForCountry.length &&
        selectedAllForCountry.filter(({ value }) => availableDestinations.indexOf(value) !== -1);

      if (filteredFromAllForCountry && filteredFromAllForCountry.length > 0) {
        filteredSelectedDestinations = filteredSelectedDestinations.concat(filteredFromAllForCountry);
      }

      if (selectedDestinations.length !== filteredSelectedDestinations.length) {
        dispatch(updateSelectedDestinations(filteredSelectedDestinations));
        dispatch(updateAvailableOrigins(filteredSelectedDestinations.map(({ value }) => value)));
      }
    }
  };
}

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFetchingBookingVerify(fetching) {
  return {
    type: types.SET_FETCHING_BOOKING_VERIFY,
    payload: {
      fetching,
    },
  };
}

export function setFetchingBookingCreate(fetching) {
  return {
    type: types.SET_FETCHING_BOOKING_CREATE,
    payload: {
      fetching,
    },
  };
}

export function setBookingVerifyValue(valid, notValidProducts, rewardPoints) {
  return {
    type: types.SET_BOOKING_VERIFY_VALUE,
    payload: {
      value: {
        valid,
        notValidProducts,
        rewardPoints,
      },
    },
  };
}

export function setBookingCreateValue(booking) {
  return {
    type: types.SET_BOOKING_CREATE_VALUE,
    payload: {
      value: booking,
    },
  };
}

export function resetBookingVerify() {
  return {
    type: types.RESET_BOOKING_VERIFY,
  };
}

export function resetBookingCreate() {
  return {
    type: types.RESET_BOOKING_CREATE,
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

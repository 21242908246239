import React, { Component } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import { MAIN_MENU as MENU_NAME_MAIN_MENU } from 'state/menus/constants/menuNames';
import { FRONTPAGE as ROUTE_KEY_FRONTPAGE } from 'constants/routesKeys';
import { HEADER_FULL } from 'constants/headerTypes';
import fetchMenu from 'state/menus/operations/fetchMenu';
import { getRoutePathByRouteKey } from 'state/routes/operations';
import { setActiveAction } from 'state/actionBar/actions';
import { setHeaderHeight } from 'state/refs/actions';
import { toggleInputsHiddenOnMobile } from 'state/searchContainer/actions';
import HeaderComponent from './HeaderComponent';
import HeaderComponentFrontpage from './HeaderComponent.Frontpage';
import websiteIds from 'constants/website.config';
import fetchCountries from 'state/countryPicker/operations';

class Header extends Component {
  constructor(props) {
    super(props);

    this.handleOnClickLogoutFromUserBookings = this.handleOnClickLogoutFromUserBookings.bind(this);
    this.headerRef = React.createRef();
    this.hideHeader = props.modalIframe && props.modalIframe === '1';
  }

  componentDidMount() {
    const { fetchCountries, countryPickerItems, currentPageComponent } = this.props;
    const { isFrontpage } = currentPageComponent;
    this.checkAndFetchMenu();
    if (!countryPickerItems && isFrontpage) {
      fetchCountries();
    }
  }

  componentDidUpdate() {
    this.checkAndUpdateHeaderHeight();
    this.checkAndFetchMenu();
  }

  handleOnClickLogoutFromUserBookings() {
    const { resetUserBookings } = this.props;
    resetUserBookings();
  }

  checkAndFetchMenu() {
    const { currentPageComponent, menus, selectedCountry, airlineWhitelabel, fetchMenu } = this.props;
    const { isCountrySelection } = currentPageComponent;

    if (!isCountrySelection && ((airlineWhitelabel && !_isEmpty(selectedCountry)) || !airlineWhitelabel)) {
      if (!menus[MENU_NAME_MAIN_MENU].value.length > 0) {
        fetchMenu(MENU_NAME_MAIN_MENU);
      }
    }
  }

  checkAndUpdateHeaderHeight() {
    const { headerHeight, setHeaderHeight } = this.props;

    if (this.headerRef.current && this.headerRef.current.clientHeight !== headerHeight) {
      setHeaderHeight(this.headerRef.current.clientHeight);
    }
  }

  getSpacing(isWhiteLabelOrHillman, isAccountLogin, isAgentLogin, isAccountBooking, isWakacje) {
    let topSpacing = !isWhiteLabelOrHillman ? '3' : undefined;

    if (isAccountLogin || isAccountLogin) {
      topSpacing = !isWhiteLabelOrHillman ? '1' : undefined;
    }

    topSpacing = isAccountBooking ? undefined : topSpacing;

    const advertsPosition = isWhiteLabelOrHillman ? -425 : isWakacje ? -600 : -450;

    const spacing = {
      topSpacing,
      advertsPosition,
      betweenContentSpacing: isWhiteLabelOrHillman && !isAccountBooking ? '3' : undefined,
      onlyHeaderContent: isAccountLogin || isAccountBooking || isAgentLogin,
    };
    return spacing;
  }

  getContentProps(isAccountBooking, isWhiteLabelOrHillman) {
    return {
      width: isWhiteLabelOrHillman || isAccountBooking ? '100%' : undefined,
      height: isWhiteLabelOrHillman ? '70px' : undefined,
      logoWidth: isAccountBooking ? 'calc(50vw - 380px)' : 'initial',
    };
  }

  getHeaderStyleProps(isWhiteLabelOrHillman, isAccountBooking) {
    let background = isAccountBooking ? ['primary', 'light'] : false;

    if (isWhiteLabelOrHillman) {
      background = ['background', 'light'];
    }

    return {
      isNorwegian: isWhiteLabelOrHillman,
      isAccountBooking,
      background: background,
    };
  }

  getLogoProps(
    isWhiteLabelOrHillman,
    isAccountBooking,
    logoUrl,
    logoWhiteUrl,
    airlineWhitelabel,
    isFrontpage,
    customSettings
  ) {
    return {
      config: {
        images: [
          {
            src: isWhiteLabelOrHillman || isAccountBooking ? logoUrl : logoWhiteUrl,
            padding: !airlineWhitelabel && isAccountBooking ? '3px' : undefined, // used to adjust the size of the logo
          },
        ],
        container: {
          width: isAccountBooking ? 'unset' : undefined,
        },
      },
      isFrontpage,
      customSettings,
    };
  }

  getHamburgerButtonProps(isWhiteLabelOrHillman, isAccountBooking) {
    return {
      forMedia: ['below', 'tablet'],
      onClick: this.props.setHeaderAsActiveAction,
      dark: isWhiteLabelOrHillman || isAccountBooking,
      margin: isWhiteLabelOrHillman ? '0 5px 0 0' : 'unset',
    };
  }

  getHeaderMenuProps(isWhiteLabelOrHillman, isAccountBooking, menuContent) {
    return {
      menuContent,
      isHamburger: false,
      isLightBackground: isWhiteLabelOrHillman || isAccountBooking,
      showForMedia: ['above', 'desktop'],
    };
  }

  render() {
    const {
      headerHeight,
      inputsHiddenOnMobile,
      inputsHidden,
      airlineWhitelabel,
      currentPageComponent,
      menus,
      logoUrl,
      logoWhiteUrl,
      dealBooking,
      checkoutOnlyWhitelabel,
      isHillmanTravel,
      isSweetDeal,
      isHolidayPirates,
      customSettings,
      centerHeaderMenu,
      customHeaderHeight,
      setActiveAction,
      isWakacje,
    } = this.props;
    const {
      isAccountBooking,
      isAccountLogin,
      isAgentLogin,
      isCheckoutPage,
      isCountrySelection,
      isDeal,
      isDeals,
      isFrontpage,
      isHotelPage,
      isTemplate,
      isThankYou,
      isSavedPackages,
      isSearchResults,
      isPromoPage,
      isLastMinute,
      isSuccessBooking,
    } = currentPageComponent;

    const pathToFrontpage = this.props.getRoutePathByRouteKey(ROUTE_KEY_FRONTPAGE);

    const isHeaderFull = customSettings && customSettings.headerType && customSettings.headerType === HEADER_FULL;
    const isAirlineWhiteLabelCheck =
      airlineWhitelabel &&
      (airlineWhitelabel.isNorwegian ||
        airlineWhitelabel.isAirBaltic ||
        airlineWhitelabel.isAirMalta ||
        airlineWhitelabel.isCyprus);

    const isWhiteLabelOrHillman = isAirlineWhiteLabelCheck || isHillmanTravel || isHeaderFull;

    if (this.hideHeader || isCountrySelection || isThankYou || isSuccessBooking) {
      return null;
    }

    if (isFrontpage || isAccountLogin || isAccountBooking || isAgentLogin) {
      const spacing = this.getSpacing(isWhiteLabelOrHillman, isAccountLogin, isAgentLogin, isAccountBooking, isWakacje);

      const contentProps = this.getContentProps(isAccountBooking, isWhiteLabelOrHillman);

      const headerStyleProps = this.getHeaderStyleProps(isWhiteLabelOrHillman, isAccountBooking);

      const headerLeftProps = { justifyContent: isAccountBooking ? 'space-between' : 'initial' };

      const logoProps = this.getLogoProps(
        isWhiteLabelOrHillman,
        isAccountBooking,
        logoUrl,
        logoWhiteUrl,
        airlineWhitelabel,
        isFrontpage,
        customSettings
      );

      const hamburgerButtonProps = this.getHamburgerButtonProps(isWhiteLabelOrHillman, isAccountBooking);

      const headerMenuProps = this.getHeaderMenuProps(
        isWhiteLabelOrHillman,
        isAccountBooking,
        menus[MENU_NAME_MAIN_MENU].value
      );

      const logoutProps = {
        visible: isAccountBooking,
        pathToFrontpage,
        onClickLogout: this.handleOnClickLogoutFromUserBookings,
      };

      return (
        <HeaderComponentFrontpage
          headerRef={this.headerRef}
          airlineWhitelabel={airlineWhitelabel}
          isAccountBooking={isAccountBooking}
          spacing={spacing}
          contentProps={contentProps}
          headerStyleProps={headerStyleProps}
          headerLeftProps={headerLeftProps}
          logoProps={logoProps}
          hamburgerButtonProps={hamburgerButtonProps}
          headerMenuProps={headerMenuProps}
          logoutProps={logoutProps}
          isHillmanTravel={isHillmanTravel}
          isFrontpage={isFrontpage}
          isSweetDeal={isSweetDeal}
          isHolidayPirates={isHolidayPirates}
          centerHeaderMenu={centerHeaderMenu}
          customHeaderHeight={customHeaderHeight}
          customSettings={customSettings}
        />
      );
    }

    const hasSearchContainer =
      (isSearchResults || isTemplate || isSavedPackages || isDeal || isDeals || isPromoPage || isLastMinute) &&
      !checkoutOnlyWhitelabel;

    const dropdownSearchTriggerProps = {
      logoUrl,
      isCheckoutPage,
      hasSearchContainer,
      showDropdownSearch: !inputsHiddenOnMobile,
      onClickDropdownSearchTrigger: this.props.toggleInputsHiddenOnMobile,
    };

    const dropdownSearchProps = {
      hasSearchContainer,
      isSearchPage: isSearchResults,
      isHotelPage,
      showDropdownSearch: !inputsHiddenOnMobile,
      inputsHidden: isHotelPage || isDeal ? inputsHidden : false,
      headerRef: this.headerRef,
    };

    const showTravelSummary = isCheckoutPage || isHotelPage || (isDeal && dealBooking !== null);

    return (
      <HeaderComponent
        headerRef={this.headerRef}
        headerHeight={headerHeight}
        menuContent={menus[MENU_NAME_MAIN_MENU].value}
        isSearchResults={isSearchResults}
        isCheckoutPage={isCheckoutPage}
        isHotelPage={isHotelPage}
        isDeal={isDeal}
        dropdownSearchTriggerProps={dropdownSearchTriggerProps}
        dropdownSearchProps={dropdownSearchProps}
        airlineWhitelabel={airlineWhitelabel}
        showTravelSummary={showTravelSummary}
        isFrontpage={isFrontpage}
        customHeaderHeight={customHeaderHeight}
        setActiveAction={setActiveAction}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCountry: state.routes.selectedCountry,
    currentPageComponent: state.currentPageComponent,
    menus: state.menus,
    headerHeight: state.refs.headerHeight,
    inputsHiddenOnMobile: state.searchContainer.inputsHiddenOnMobile,
    inputsHidden: state.searchContainer.inputsHidden,
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    checkoutOnlyWhitelabel: state.settings.value.checkoutOnlyWhitelabel,
    logoUrl: state.settings.value.style.logoUrl,
    logoWhiteUrl: state.settings.value.style.logoWhiteUrl,
    modalIframe: state.urlSearchQuery.value.modal_iframe,
    dealBooking: state.deal.booking,
    isHillmanTravel: [
      websiteIds['Hillman Travel EU'].ID,
      websiteIds['Hillman Travel NL'].ID,
      websiteIds['BE Hillman Travel FR'].ID,
      websiteIds['BE Hillman Travel NL'].ID,
      websiteIds['Hillman Travel FR'].ID,
    ].includes(state.settings.value.websiteId),
    isSweetDeal: [websiteIds['Sweetdeal Rejser'].ID].includes(state.settings.value.websiteId),
    countryPickerItems: state.countryPicker.items,
    customSettings: state.settings.value.customSettings,
    isHolidayPirates: state.settings.value.customSettings && state.settings.value.customSettings.isHolidayPirates,
    centerHeaderMenu: state.settings.value.customSettings && state.settings.value.customSettings.centerHeaderMenu,
    customHeaderHeight: state.settings.value.customSettings && state.settings.value.customSettings.headerHeight,
    isWakacje: websiteIds.Wakacje.ID === state.settings.value.websiteId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMenu(menuName) {
      dispatch(fetchMenu(menuName));
    },
    setHeaderAsActiveAction() {
      dispatch(setActiveAction('header-menu'));
    },
    setHeaderHeight(height) {
      dispatch(setHeaderHeight(height));
    },
    toggleInputsHiddenOnMobile() {
      dispatch(toggleInputsHiddenOnMobile());
    },
    getRoutePathByRouteKey(routeKey) {
      return dispatch(getRoutePathByRouteKey(routeKey));
    },
    resetUserBookings() {
      window.location.search = '';
    },
    fetchCountries() {
      dispatch(fetchCountries());
    },
    setActiveAction(action) {
      dispatch(setActiveAction(action));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);

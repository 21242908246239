/**
 * Custom React hook to prevent scrolling of the html by setting overflowY to 'hidden' on the HTML element.
 * This is used to prevent scrolling when a  overlay/modal is active.
 *
 * @param {boolean} isOverlayVisible - A boolean indicating whether the overlay or portal is currently visible.
 * @returns {void}.
 */

import { useEffect } from 'react';
import { isMobile } from 'utils/isMobile';

export const usePreventHtmlScroll = (isOverlayVisible) => {
  useEffect(() => {
    if (isOverlayVisible) {
      try {
        const htmlElement = document.querySelector('html');
        if (!htmlElement) {
          throw new Error('HTML element not found');
        }

        htmlElement.style.overflowY = 'hidden';
        if (!isMobile()) {
          htmlElement.style.scrollbarGutter = 'stable';
        }
        return () => {
          htmlElement.style.overflowY = 'auto';
          if (!isMobile()) {
            htmlElement.style.scrollbarGutter = 'unset';
          }
        };
      } catch (error) {
        console.error('Error in usePreventHtmlScroll:', error);
      }
    }
  }, [isOverlayVisible]);
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Banner from '../../Banner';
import PromoHeroComponent from './PromoHeroComponent';

class PromoHero extends Component {
  render() {
    const { bannerConfig, promoHero, currentPageComponent } = this.props;

    if (!promoHero) {
      return null;
    }

    const { isFrontpage } = currentPageComponent;

    if (promoHero) {
      const { heading, text, image, url, bgColor } = promoHero;

      if (!text && bannerConfig && bannerConfig.hideIfNoText) {
        return null;
      }

      return (
        <Banner bannerKey={'promo_hero'} config={{ ...bannerConfig, bgColor }}>
          <PromoHeroComponent heading={heading} text={text} image={image} url={url} isFrontpage={isFrontpage} />
        </Banner>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    currentPageComponent: state.currentPageComponent,
    promoHero: state.settings.value.promoHero,
  };
}

export default connect(mapStateToProps)(PromoHero);

import moment from 'moment';
import getSelectedHotelsRoomsWithSelectedOption from 'state/checkoutPage/packagesSearch/selectors/getSelectedHotelsRoomsWithSelectedOption';
import compareArrays from 'utils/array/compareArrays';

export default function compareChildrenAgeByRoom(travelers) {
  return (dispatch, getState) => {
    const { checkOut } = getState().checkoutPagePackagesSearch.value.hotels[0];
    const selectedRooms = dispatch(getSelectedHotelsRoomsWithSelectedOption({ firstHotel: true }));
    const returnDate = moment(checkOut);

    const childrenAgeByRoom = travelers.map((room) => {
      const childrenAge = room.CHD.map((child) => {
        return returnDate.diff(child.dateOfBirth.value, 'years');
      });
      const infantAge = room.INF.map((child) => {
        return returnDate.diff(child.dateOfBirth.value, 'years');
      });
      return [...infantAge, ...childrenAge].sort();
    });
    const childrenAgeBySelectedRooms = selectedRooms.map((room) => {
      return room.childages.sort();
    });

    return compareArrays(childrenAgeByRoom, childrenAgeBySelectedRooms);
  };
}

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import { StyledButtonPrimary } from '../ButtonPrimaryComponent';

function ConfirmButton({ onClick, styles }) {
  return (
    <Root onClick={onClick} styles={styles}>
      <FormattedMessage id='confirm.label' description='Confirm' defaultMessage='Confirm' />
    </Root>
  );
}

export default ConfirmButton;

const Root = styled(StyledButtonPrimary)`
  font-size: 16px;
  padding: 12px 20px;
  box-shadow: none;
  ${({ styles }) => styles};
`;

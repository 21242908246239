import styled, { css } from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';
import flexbox from 'styles/utils/flexbox';
import {
  LogoWraperAirMalta,
  LogoWraperCommon,
  LogoWraperCommonNorwegian,
  LogoWrapperCommonAirBaltic,
  LogoWrapperCommonHillmanTravel,
  LogoWrapperCommonCyprus,
} from '../../HeaderComponentCommon';

export const Container = styled(LogoWraperCommon)`
  ${flexbox({
    justifyContent: 'flex-start',
    alignItems: 'center',
  })}
  width: ${({ width }) => (width ? width : '100%')};

  ${({ hideForMedia }) =>
    hideForMedia &&
    css`
      ${mediaQueries[hideForMedia[0]][hideForMedia[1]]`
      display: none;
    `}
    `}
`;

export const ContainerNorwegian = styled(LogoWraperCommonNorwegian)`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.adjacent.default};

  a {
    height: 100%;
  }

  ${({ hideForMedia }) =>
    hideForMedia &&
    css`
      ${mediaQueries[hideForMedia[0]][hideForMedia[1]]`
      display: none;
    `}
    `}
`;

export const ContainerHillmanTravel = styled(LogoWrapperCommonHillmanTravel)`
  height: 100%;

  a {
    height: 100%;
  }

  img {
    height: 80%;
  }

  ${({ hideForMedia }) =>
    hideForMedia &&
    css`
      ${mediaQueries[hideForMedia[0]][hideForMedia[1]]`
    display: none;
  `}
    `}
`;

export const ContainerAirBaltic = styled(LogoWrapperCommonAirBaltic)`
  height: 100%;

  a {
    height: 100%;
  }

  img {
    height: 100%;
    width: 100%;
  }

  ${({ hideForMedia }) =>
    hideForMedia &&
    css`
      ${mediaQueries[hideForMedia[0]][hideForMedia[1]]`
      display: none;
    `}
    `}
`;

export const ContainerAirMalta = styled(LogoWraperAirMalta)`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.adjacent.default};

  a {
    height: 100%;

    img {
      padding: 0 20px;
    }
  }

  ${({ hideForMedia }) =>
    hideForMedia &&
    css`
      ${mediaQueries[hideForMedia[0]][hideForMedia[1]]`
      display: none;
    `}
    `}
`;

export const ContainerCyprus = styled(LogoWrapperCommonCyprus)`
  height: 100%;

  a {
    height: 100%;
  }

  img {
    height: 62%;
  }

  ${({ hideForMedia }) =>
    hideForMedia &&
    css`
      ${mediaQueries[hideForMedia[0]][hideForMedia[1]]`
    display: none;
  `}
    `}
`;

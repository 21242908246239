import React, { useMemo } from 'react';
import styled from 'styled-components';

import { setSelectedChildren } from 'components/inputs/utils';
import NoOptions from 'components/inputs/SelectDestinations/components/menuList/components/noOptions/NoOptions';
import ScrollIndicator from 'components/indicators/scrollIndicator/ScrollIndicatorComponent';

export default function MenuList({ children }) {
  const options = useMemo(() => setSelectedChildren(children), [children]);

  return (
    <Container>
      {Boolean(options && options.selectedOptions.length) && (
        <OptionsRoot>
          <SelectedOptions>{options.selectedOptions}</SelectedOptions>
          {options.selectedOptions.length > 10 ? (
            <ScrollIndicatorWrap>
              <ScrollIndicator />
            </ScrollIndicatorWrap>
          ) : null}
        </OptionsRoot>
      )}
      {Boolean(options && options.notSelectedOptions.length) ? (
        <OptionsRoot>
          <ChildrenContainer>{options.notSelectedOptions}</ChildrenContainer>
          {options.notSelectedOptions.length > 8 ? (
            <ScrollIndicatorWrap>
              <ScrollIndicator />
            </ScrollIndicatorWrap>
          ) : null}
        </OptionsRoot>
      ) : (
        <EmptyOptions>
          <NoOptions />
        </EmptyOptions>
      )}
    </Container>
  );
}

const Container = styled('div')(() => ({
  minHeight: '50px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '4px',
  borderRadius: '8px',
  position: 'relative',
  maxHeight: '600px',
}));

const ChildrenContainer = styled.div`
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: 8px;
  max-height: 300px;
  overflow: auto;
  position: relative;
  box-shadow: 0px 0px 4px 1px rgb(0, 0, 0, 0.07);

  ::-webkit-scrollbar {
    width: 4px;
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary.default};
    border-radius: 10px;
  }

  .styled-select__group-heading {
    color: ${({ theme }) => theme.colors.text.grey};
    font-style: italic;
    font-weight: 400;
  }
`;

const EmptyOptions = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

const OptionsRoot = styled.div`
  position: relative;
`;

const SelectedOptions = styled.div`
  display: grid;
  border-radius: 8px;
  padding: 4px;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  overflow: auto;
  max-height: 240px;
  position: relative;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary.default};
    border-radius: 10px;
  }

  .styled-select__group-heading {
    color: ${({ theme }) => theme.colors.text.grey};
    font-style: italic;
    font-weight: 400;
  }
`;

const ScrollIndicatorWrap = styled.div`
  position: absolute;
  right: 10px;
  bottom: 5px;
  display: flex;
  justify-content: flex-end;
  z-index: 99;
`;

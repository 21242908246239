import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import { Margin } from 'styled-components-spacing';
import { mediaQueries } from 'styles/utils/mediaQueries';

const DateHighlightInfo = ({ airlineWhitelabel, company }) => (
  <Root>
    <CalendarDateHighlight />
    <Margin right='0.5' inline />
    {!airlineWhitelabel ? (
      <FormattedMessage
        id='selectDates.component.footer.label'
        description='{company} recommends'
        defaultMessage='{company} recommends'
        values={{
          company,
        }}
      />
    ) : (
      <FormattedMessage
        id='airlineWhitelabel.selectDates.component.footer.label'
        description='Direct flights'
        defaultMessage='Direct flights'
      />
    )}
  </Root>
);

export default DateHighlightInfo;

const CalendarDateHighlight = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: ${(props) => props.theme.colors.secondary.default};
  border-radius: 50%;
  vertical-align: middle;
`;

const Root = styled.div`
  display: flex;
  align-items: center;
  ${mediaQueries.fromAndAbove.tablet`
    margin-right:auto;
  `};
`;

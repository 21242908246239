import validateSelectedFlights from 'state/checkoutPage/travelSummary/validations/validateSelectedFlights';
import validateFlightsDetails from 'state/checkoutPage/flightsDetails/operations/validateFlightsDetails';
import validateCancellationProtectionsData from 'state/checkoutPage/cancellationProtections/operations/validateCancellationProtectionsData';
import { validateCustomerData } from 'state/checkoutPage/customerDetails/operations';
import { validateInsurancesData } from 'state/checkoutPage/insurances/operations';
import validateTransfersData from 'state/checkoutPage/transfers/operations/validateTransfersData';
import validateTravelersData from 'state/checkoutPage/travelers/operations/validateTravelersData';
import persistAbandonedCart from 'state/checkoutPage/abandonedCart/operations/persistAbandonedCart';
import updateCurrentlyOpenSummariesByStepId from 'state/checkoutPage/travelSummary/operations/updateCurrentlyOpenSummariesByStepId';
import { setScrollToTimeStamp } from 'state/checkoutPage/base/actions';
import { resetState as checkoutBookingResetState, resetBookingVerify } from 'state/checkoutPage/booking/actions';
import { setTransitionToCheckout } from 'state/templates/hotel/actions';
import * as stepsKeys from '../constants/stepsKeys';
import { setCurrentStepById } from '../actions';
import hybridRedirect from './hybridRedirect';

export default function moveToNewStep(stepId) {
  return (dispatch, getState) => {
    const { checkoutPageWizard, settings } = getState();
    const hybridStepIndex = settings.value.customSettings && settings.value.customSettings.hybridStepIndex;
    const { steps, currentStep } = checkoutPageWizard;
    const { checkoutOnlyWhitelabel } = settings.value;

    if (steps[currentStep].id === stepsKeys.PAY_AND_BOOK) {
      const booking = getState().checkoutPageBooking.bookingCreate.value;

      if (booking !== null) {
        dispatch(checkoutBookingResetState());
      }
    }

    if (stepId && stepId === stepsKeys.SELECT_ROOMS) {
      if (!checkoutOnlyWhitelabel) {
        dispatch(setTransitionToCheckout(false));
      }
    }

    if (steps[currentStep].id === stepsKeys.SELECT_ROOMS) {
      if (!checkoutOnlyWhitelabel) {
        dispatch(setTransitionToCheckout(true));
      }
    }

    const { namesSpelledCorrectly } = getState().checkoutPageTravelers;
    let nextStepIndex = !stepId
      ? currentStep + 1 > steps.length - 1
        ? steps.length - 1
        : currentStep + 1
      : steps.findIndex(({ id }) => id === stepId);
    let nextStepId = stepId ? stepId : steps[nextStepIndex].id;
    let block = false;

    for (let i = currentStep + 1; i <= nextStepIndex; i++) {
      const validateStepId = steps[i].id;

      switch (validateStepId) {
        case stepsKeys.SELECT_FLIGHT_EXTRAS:
          if (!dispatch(validateSelectedFlights())) {
            block = true;
          }
          break;

        case stepsKeys.SELECT_OTHER_EXTRAS:
          if (!dispatch(validateFlightsDetails())) {
            block = true;
          }
          break;

        case stepsKeys.PERSONAL_DATA:
          const transfersAreValid = dispatch(validateTransfersData());
          const insurancesAreValid = dispatch(validateInsurancesData());
          const cpAreValid = dispatch(validateCancellationProtectionsData());

          if (!transfersAreValid || !insurancesAreValid || !cpAreValid) {
            dispatch(setScrollToTimeStamp());
            block = true;
          }
          break;

        case stepsKeys.PAY_AND_BOOK:
          if (!dispatch(validateCustomerData()) || !dispatch(validateTravelersData()) || !namesSpelledCorrectly) {
            dispatch(setScrollToTimeStamp());
            block = true;
          }
          break;

        default:
          break;
      }

      if (block) {
        nextStepIndex = i - 1;
        nextStepId = steps[nextStepIndex].id;
        break;
      }
      if (nextStepIndex === hybridStepIndex) {
        dispatch(hybridRedirect());
        return;
      }
    }

    if (!block && !steps[nextStepIndex].disabled) {
      if (nextStepIndex < currentStep && steps[currentStep].id === stepsKeys.PAY_AND_BOOK) {
        dispatch(resetBookingVerify());
      }
    }

    if (nextStepIndex > currentStep) {
      dispatch(persistAbandonedCart());
    }

    dispatch(setCurrentStepById(nextStepId));
    !block && dispatch(updateCurrentlyOpenSummariesByStepId(nextStepId));
  };
}

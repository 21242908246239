import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PERIOD_CALENDAR, SPECIFIC_DATES_CALENDAR } from '../../../constants/calendarSwitch';
import styled from 'styled-components';
import { setCalendarOption } from 'state/selectDates/actions';
import { FormattedMessage } from 'i18n';
import truncate from 'styles/utils/truncate';
import { updateSelectedDates } from 'state/selectDates/operations/updateSelectedDates';
import { toggleFlexibleDates } from 'state/selectDates/actions';
import { setNumberOfDaysValue } from 'state/searchResultsFilters/actions';

const switchButtons = [{ id: SPECIFIC_DATES_CALENDAR }, { id: PERIOD_CALENDAR }];

function CalendarSwitch({ resetInputFocus }) {
  const { calendarOption } = useSelector((state) => state.selectDates);
  const { flexibleDates } = useSelector((state) => state.selectDates.dates);

  const dispatch = useDispatch();

  const toggleCalendarOption = (event, id) => {
    event.stopPropagation();
    dispatch(setCalendarOption(id));

    //reset selected dates to null
    dispatch(updateSelectedDates({ startDate: null, endDate: null }));

    // remove flexible dates if period calendar
    if (id === PERIOD_CALENDAR && flexibleDates) {
      dispatch(toggleFlexibleDates());
    }

    //remove numberOfDays value if specific dates calendar
    if (id === SPECIFIC_DATES_CALENDAR) {
      dispatch(setNumberOfDaysValue([], true));
    }

    //Always focus start date on calendar switch
    resetInputFocus('startDate');
  };

  return (
    <Root>
      {switchButtons.map(({ id }) => (
        <SwitchButton key={id} onClick={(event) => toggleCalendarOption(event, id)} isSelected={calendarOption === id}>
          <FormattedMessage id={`calendarOptions.${id}.label`} description={`${id}`} defaultMessage={`${id}`} />
        </SwitchButton>
      ))}
    </Root>
  );
}

export default CalendarSwitch;

const Root = styled.div`
  display: flex;
  flex: 1;
  z-index: 3;
  padding: 4px;
  background: ${({ theme }) => theme.colors.background.grey};
  border-radius: 8px;
  font-size: 14px;
`;
const SwitchButton = styled.div`
  ${truncate()}
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.background.light : theme.colors.background.grey};
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text.darkGrey};
  overflow: hidden;
  border-radius: 4px;
  &:first-child {
    margin-right: 3px;
  }
`;

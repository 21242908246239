import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Margin, Padding } from 'styled-components-spacing';

import Logo from './components/logo/Logo';
import SearchFrontPage from './components/search/SearchFrontPage';
import HeaderMenu from './components/headerMenu/HeaderMenu';
import Logout from './components/logout/LogoutComponent';
import { HeaderStyledCommon, HeaderLeftCommon, HamburgerButtonCommon } from './HeaderComponentCommon';
import { mediaQueries } from 'styles/utils/mediaQueries';
import CountryPicker from './components/headerMenu/components/countryPicker/CountryPicker';
import Banners from './components/banners';

function HeaderComponentFrontpage({
  headerRef,
  airlineWhitelabel,
  isAccountBooking,
  spacing,
  contentProps,
  headerStyleProps,
  headerLeftProps,
  logoProps,
  hamburgerButtonProps,
  headerMenuProps,
  logoutProps,
  isHillmanTravel,
  isFrontpage,
  isSweetDeal,
  isHolidayPirates,
  centerHeaderMenu,
  customHeaderHeight,
  customSettings,
}) {
  const { topSpacing, advertsPosition, betweenContentSpacing } = spacing;

  return (
    <Fragment>
      {topSpacing && <Padding top={topSpacing} />}
      <Content
        {...contentProps}
        airlineWhitelabel={airlineWhitelabel}
        isAccountBooking={isAccountBooking}
        isHillmanTravel={isHillmanTravel}
        centerHeaderMenu={centerHeaderMenu}
      >
        <HeaderStyledCommon
          ref={headerRef}
          {...headerStyleProps}
          centerHeaderMenu={centerHeaderMenu}
          customHeaderHeight={customHeaderHeight}
        >
          {!centerHeaderMenu && (
            <HeaderLeftCommon {...headerLeftProps} className='header-left-common'>
              {logoutProps.visible && <Logout {...logoutProps} />}
              <Logo {...logoProps} />
            </HeaderLeftCommon>
          )}
          <HeaderMenu {...headerMenuProps} airlineWhitelabel={airlineWhitelabel} isFrontpage={true} />
          <HamburgerButtonCommon {...hamburgerButtonProps} />
          <CountryPicker isFrontpage={isFrontpage} />
        </HeaderStyledCommon>
      </Content>

      {betweenContentSpacing && <Padding top={betweenContentSpacing} />}

      {!spacing.onlyHeaderContent && (
        <Content>
          {customSettings && customSettings.headerText && (
            <HeaderText>
              <HeaderTitle>{customSettings.headerText.title}</HeaderTitle>
              <HeaderSubTitle>{customSettings.headerText.subtitle}</HeaderSubTitle>
            </HeaderText>
          )}
          <Margin top={{ tiny: '2' }} bottom={{ tiny: '2' }} left={{ tiny: '0' }} style={{ width: 'initial' }}>
            <SearchFrontPage isFrontpage={true} />
          </Margin>
          <ContentSpacer
            advertsPosition={advertsPosition}
            isSweetDeal={isSweetDeal}
            isHillmanTravel={isHillmanTravel}
            isHolidayPirates={isHolidayPirates}
          >
            <div id='sn-a1340f2f-cc5e-4eb0-bd23-bfae7e3b19c0'></div>
            <Banners />
          </ContentSpacer>
        </Content>
      )}
    </Fragment>
  );
}

export default HeaderComponentFrontpage;

const Content = styled.div`
  width: ${({ width, theme }) => (width ? `${width}` : `${theme.containerWidths.desktop}px`)};
  height: ${({ height }) => (height ? `${height}` : 'unset')};
  margin: 0 auto;
  padding: 0;
  z-index: 3;

  div.header-left-common {
    > div.fresnel-container {
      display: flex;
      height: 100%;
      width: ${({ logoWidth }) => logoWidth};
    }
  }

  ${mediaQueries.fromAndBelow.desktop`
    width: 100%;
    padding: ${({ airlineWhitelabel, isAccountBooking, isHillmanTravel, centerHeaderMenu }) =>
      (airlineWhitelabel && airlineWhitelabel.isAirMalta) ||
      (airlineWhitelabel && airlineWhitelabel.isAirBaltic) ||
      (airlineWhitelabel && airlineWhitelabel.isNorwegian) ||
      (airlineWhitelabel && airlineWhitelabel.isCyprus) ||
      isHillmanTravel ||
      isAccountBooking ||
      centerHeaderMenu
        ? '0'
        : '0 2rem'};
  `}
`;

const ContentSpacer = styled.div`
  min-height: ${({ advertsPosition, isSweetDeal, isHillmanTravel, isHolidayPirates }) =>
    isSweetDeal || isHillmanTravel || isHolidayPirates
      ? `calc(75vh + ${advertsPosition}px)`
      : `calc(100vh + ${advertsPosition}px)`};
  margin-bottom: ${({ isHillmanTravel }) => (isHillmanTravel ? '8px' : '16px')};
`;

const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 40px;
  padding-left: 4px;
`;

const HeaderTitle = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: white;
`;

const HeaderSubTitle = styled.h2`
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0;
  color: white;
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import DropdownSearchTriggerComponent from './DropdownSearchTriggerComponent';

class DropdownSearchTrigger extends Component {
  render() {
    const {
      logoUrl,
      isCheckoutPage,
      airlineWhitelabel,
      transitionToCheckout,
      checkoutOnlyWhitelabel,
      hasSearchContainer,
      hasCheckoutWizard,
      showDropdownSearch,
      onClickDropdownSearchTrigger,
      isSearchPage,
      hideLogo,
      isHotelPage,
      menuContent,
    } = this.props;

    return (
      <DropdownSearchTriggerComponent
        logoUrl={logoUrl}
        isCheckoutPage={isCheckoutPage}
        airlineWhitelabel={airlineWhitelabel}
        transitionToCheckout={transitionToCheckout}
        checkoutOnlyWhitelabel={checkoutOnlyWhitelabel}
        hasSearchContainer={hasSearchContainer}
        hasCheckoutWizard={hasCheckoutWizard}
        showDropdownSearch={showDropdownSearch}
        onClickDropdownSearchTrigger={onClickDropdownSearchTrigger}
        isSearchPage={isSearchPage}
        hideLogo={hideLogo}
        isHotelPage={isHotelPage}
        menuContent={menuContent}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    transitionToCheckout: state.templates.hotel.transitionToCheckout,
    checkoutOnlyWhitelabel: state.settings.value.checkoutOnlyWhitelabel,
    isSearchPage: state.currentPageComponent.isSearchResults,
    hideLogo: state.settings.value.customSettings && state.settings.value.customSettings.hideLogo,
    isHotelPage: state.currentPageComponent.isHotelPage,
  };
}

export default connect(mapStateToProps)(DropdownSearchTrigger);

import { createGlobalStyle, css } from 'styled-components';
import { fadeInRotateAndScale } from 'styles/utils/animations';
import AdyenComponent from './styles/AdyenComponents';
import InputTooltip from './styles/InputTooltip';
import ExportsConfirmationPage from './styles/exports/confirmationPage';
import ReactModalCustom from './styles/reactModal/custom';
import ReactModalCentered from './styles/reactModal/centered';
import ReactModalSidePanel from 'components/sidePanel/modalStyles';
import iphone from './styles/iphone';
import DateRangePickerPortal from './styles/DateRangePickerPortal';
import ReactSlick from './styles/ReactSlick';
import { packagePulsating } from 'styles/utils/animations';

const annimationRule = css`
  200ms 100ms ${fadeInRotateAndScale} ease-in forwards;
`;

const annimationRuleInputTooltip = css`
  200ms 100ms ${fadeInRotateAndScale} ease-in forwards;
`;

const GlobalStyle = createGlobalStyle`
  ${AdyenComponent}
  ${InputTooltip}
  ${ReactModalCustom}
  ${ReactModalCentered}
  ${ReactModalSidePanel}
  ${iphone}
  ${ExportsConfirmationPage}
  ${DateRangePickerPortal}
  ${ReactSlick}

  html, body {
	overflow-x:clip;
}
  
  body {
    height: initial !important;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${({ theme }) => theme.fonts.fontSize} !important;

    &.ReactModal__Body--open {
      overflow: hidden;
    }

    * {
      font-family: ${({ theme }) => theme.fonts.sansSerif};
}

    p, li, input, button {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.text.default};
    }

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.secondary.default};
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: 700;
    }

    ul {
      list-style: none;
    }
  }

  .price-bar-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    visibility: visible;
    opacity: 0;

    .price-bar-tooltip-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      bottom: -5px;
      margin-left: -6px;
      border-width: 6px 6px 0;
      border-top-color: ${({ theme }) => theme.colors.adjacent.default};
      left: 50%;
    }

    .price-bar-tooltip-inner {
      text-decoration: none;
      border-radius: 3px;
      min-height: 34px;
    }

    animation: ${annimationRule};
  }

  .input-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    visibility: visible;
    opacity: 0;
    box-shadow: 0px 0px 20px -4px rgba(0,0,0,0.5);
    border-radius: 3px;

    .input-tooltip-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      top: -6px;
      margin-left: -6px;
      border-width: 0 6px 6px;
      border-bottom-color: ${({ theme }) => theme.colors.adjacent.default};
      left: 50%;
    }

    .input-tooltip-inner {
      text-decoration: none;
      border-radius: 3px;
      min-height: 34px;
    }

    animation: ${annimationRuleInputTooltip};
  }

  #botxo-chat- {
    right: calc(16px + 56px) !important;
    bottom: calc(16px + 56px) !important;
  }
  .highlighted-Package{
    animation: ${packagePulsating};
    animation-duration: 1.6s;
    animation-iteration-count: 3;
  }
  #hp-header > div:first-child {
    z-index: 3 !important;
  }
  #hp-header > div:first-child > *:first-child{
      position:absolute;
      top:0;
      transition: none;
      transform: none;
  }
`;

export default GlobalStyle;

import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HEADER_PARTNER } from './constants';
import { setThirdPartyHeaderHeight } from 'state/refs/actions';
import _throttle from 'lodash/throttle';

function ThirdPartyHeader() {
  const { customSettings, tld } = useSelector((state) => state.settings.value);
  const { isHotelPage, isCheckoutPage } = useSelector((state) => state.currentPageComponent);
  const thirdPartyHeaderHeight = useSelector((state) => state.refs.thirdPartyHeaderHeight);

  const thirdPartyHeaderRef = useRef(null);

  const dispatch = useDispatch();

  const shouldRenderHeader = customSettings && customSettings.thirdPartyHeader && !isHotelPage && !isCheckoutPage;

  const thirdPartyHeaderScript = customSettings && customSettings.thirdPartyHeaderScript;
  const thirdPartyHeaderScriptId = customSettings && customSettings.thirdPartyHeaderScriptId;
  const thirdPartyHeaderScriptElement = customSettings && customSettings.thirdPartyHeaderScriptElement;

  const [showHeader, setShowHeader] = useState(shouldRenderHeader);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [timeoutExecuted, setTimeoutExecuted] = useState(false);

  useEffect(() => {
    let timeout;
    setShowHeader(shouldRenderHeader);

    const handleSetThirdPartyHeaderHeight = () => {
      if (thirdPartyHeaderRef.current && thirdPartyHeaderRef.current.clientHeight !== thirdPartyHeaderHeight) {
        dispatch(setThirdPartyHeaderHeight(thirdPartyHeaderRef.current.clientHeight));
        setTimeoutExecuted(true);
      }
    };

    if (shouldRenderHeader && !scriptLoaded) {
      const script = document.createElement('script');
      script.src = thirdPartyHeaderScript;
      script.async = true;
      script.id = thirdPartyHeaderScriptId;
      document.head.appendChild(script);
      script.onload = () => {
        setScriptLoaded(true);
        //Call handleSetThirdPartyHeaderHeight after a 3 sec delay to ensure that the script had time to execute
        timeout = setTimeout(handleSetThirdPartyHeaderHeight, 3000);
      };
    }

    return () => {
      const script = document.getElementById(thirdPartyHeaderScriptId);
      if (script) {
        script.onload = null;
        document.head.removeChild(script);
      }
      if (timeout && timeoutExecuted) {
        clearTimeout(timeout);
      }
    };
  }, [
    shouldRenderHeader,
    thirdPartyHeaderScript,
    thirdPartyHeaderHeight,
    dispatch,
    scriptLoaded,
    timeoutExecuted,
    thirdPartyHeaderScriptId,
  ]);

  useEffect(() => {
    const handleScroll = _throttle(() => {
      if (thirdPartyHeaderRef.current) {
        const elementRect = thirdPartyHeaderRef.current.getBoundingClientRect();
        const elementIsInView = elementRect.top < window.innerHeight && elementRect.bottom >= 0;
        if (elementIsInView) {
          const visibleHeight = Math.min(elementRect.bottom, window.innerHeight) - Math.max(elementRect.top, 0);
          if (visibleHeight !== thirdPartyHeaderHeight) {
            dispatch(setThirdPartyHeaderHeight(visibleHeight));
          }
        } else {
          if (thirdPartyHeaderHeight !== 0) {
            dispatch(setThirdPartyHeaderHeight(0));
          }
        }
      }
    }, 500);
    if (scriptLoaded) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scriptLoaded) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [dispatch, thirdPartyHeaderHeight, scriptLoaded]);

  if (!showHeader) {
    return null;
  }

  return (
    <div id={thirdPartyHeaderScriptElement} data-partner={HEADER_PARTNER} data-market={tld} ref={thirdPartyHeaderRef} />
  );
}

export default ThirdPartyHeader;

import searchPropertiesKeys from 'state/_constants/searchPropertiesKeys';
import searchQueryLS from 'managers/localStorage/searchQuery/searchQueryLS';
import evalForceSingleValueForOriginsAndDestinations from 'state/settings/operations/evalForceSingleValueForOriginsAndDestinations';
import { setHeaderHeight } from 'state/refs/actions';
import { setSelectedOrigins } from 'state/selectOrigins/actions';
import { setSelectedDestinations } from '../actions';

export default function updateSelectedDestinations(selectedDestinations, skipReset) {
  return (dispatch, getState) => {
    const lastSelectedDestination = selectedDestinations[selectedDestinations.length - 1];
    const lastSelectedDestinationIsHotel = lastSelectedDestination && lastSelectedDestination.hotel;
    const lastSelectedDestinationIsAllForCountry = lastSelectedDestination && lastSelectedDestination.allForCountry;
    const allForCountryExists = selectedDestinations.findIndex(({ allForCountry }) => allForCountry) > -1;

    if (lastSelectedDestinationIsHotel) {
      selectedDestinations = [lastSelectedDestination];
      const selectedOrigins = getState().selectOrigins.selectedOrigins;

      if (selectedOrigins.length > 1) {
        dispatch(setSelectedOrigins([selectedOrigins.shift()]));
      }
    } else if (lastSelectedDestinationIsAllForCountry) {
      const countryDestinations = lastSelectedDestination.destinations.map(({ value }) => value);
      const destinationsNotInLastSelectedCountry = ({ value }) => countryDestinations.indexOf(value) === -1;
      selectedDestinations = selectedDestinations.filter(destinationsNotInLastSelectedCountry);

      const storageValue = selectedDestinations
        .map(({ destinations, value }) => {
          if (destinations) return destinations.map(({ value }) => value);
          else return value;
        })
        .flat();
      searchQueryLS.update(searchPropertiesKeys.SELECTED_DESTINATIONS_KEY, storageValue);
    } else if (allForCountryExists) {
      const isNotTheSameCountryLikeTheCountryInTheLastSelectedDestination = ({ allForCountry, destinations }) =>
        !allForCountry ||
        (allForCountry && destinations.map(({ value }) => value).indexOf(lastSelectedDestination.value) === -1);
      selectedDestinations = selectedDestinations.filter(isNotTheSameCountryLikeTheCountryInTheLastSelectedDestination);
    }

    dispatch(setSelectedDestinations(selectedDestinations));
    dispatch(evalForceSingleValueForOriginsAndDestinations(lastSelectedDestinationIsHotel));

    if (
      !lastSelectedDestinationIsHotel &&
      selectedDestinations.length > 0 &&
      selectedDestinations[0] &&
      !selectedDestinations[0].allForCountry
    ) {
      const storageValue = selectedDestinations.filter(({ allForCountry }) => !allForCountry).map(({ value }) => value);
      searchQueryLS.update(searchPropertiesKeys.SELECTED_DESTINATIONS_KEY, storageValue);
    } else if (!selectedDestinations.length) {
      searchQueryLS.update(searchPropertiesKeys.SELECTED_DESTINATIONS_KEY, []);
    }

    // Depending on the available space header height can change when adding/removing origins/destinations.
    // The header height is required to correctly place some absolutley positioned elements affected by the header.
    // Use this action to force update of the header height in redux.
    dispatch(setHeaderHeight(0));
  };
}

// seconds between October 15 1582 and January 1st 1970
const secTo1970 = 12219292800;

export default function getUnixFromUuid(uuid) {
  const uuidParts = uuid.split('-');
  const timestampHex = `${uuidParts[2].slice(1)}${uuidParts[1]}${uuidParts[0]}`;
  const timestampDecimalNanoseconds = parseInt(timestampHex, 16);
  const timestampDecimalSeconds = parseInt(timestampDecimalNanoseconds / 10000000);
  const unix = timestampDecimalSeconds - secTo1970;

  return unix;
}

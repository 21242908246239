import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function pushToQueue(item) {
  return {
    type: types.PUSH_TO_QUEUE,
    payload: {
      item,
    },
  };
}

export function shiftQueue() {
  return {
    type: types.SHIFT_QUEUE,
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

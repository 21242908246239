import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { mediaQueries } from 'styles/utils/mediaQueries';

export default function CustomPromoHero() {
  const customPromoHero = useSelector(
    (state) => state.settings.value.customSettings && state.settings.value.customSettings.promoHero
  );
  const isFrontpage = useSelector((state) => state.currentPageComponent.isFrontpage);

  if (!customPromoHero || !isFrontpage) {
    return null;
  }

  return (
    <Root>
      <SvgImage customPromoHero={customPromoHero} alt='banner' />
    </Root>
  );
}

const Root = styled.div``;

const SvgImage = styled.div`
  background-image: ${({ customPromoHero }) => `url(${customPromoHero.desktop})`};
  height: 250px;
  background-repeat: no-repeat;
  background-position: center;

  ${mediaQueries.fromAndBelow.tablet`
    background-image: url(${({ customPromoHero }) => customPromoHero.tablet});
    height: 210px;
  `}

  ${mediaQueries.fromAndBelow.phone`
    background-image: url(${({ customPromoHero }) => customPromoHero.mobile});
    height: 100px;
  `}
`;

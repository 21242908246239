import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as SEVERITY from 'constants/severity';
import { backgroundBlink } from 'styles/utils/animations';
import { darken } from 'polished';

const severityValues = [SEVERITY.SUCCESS, SEVERITY.INFO, SEVERITY.WARNING, SEVERITY.ERROR];
const defaultIcons = {
  [SEVERITY.INFO]: 'exclamation-circle',
};
const propTypes = {
  severity: PropTypes.oneOf(severityValues),
  filled: PropTypes.bool,
  useIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};
const defaultProps = {
  severity: SEVERITY.ERROR,
  filled: false,
  useIcon: false,
};

function MessageBoxComponent({ severity, filled, useIcon, children }) {
  return (
    <Root severity={severity} filled={filled} useIcon={useIcon}>
      {useIcon && <Icon icon={defaultIcons[severity]} />}
      {children}
    </Root>
  );
}

MessageBoxComponent.propTypes = propTypes;
MessageBoxComponent.defaultProps = defaultProps;
export default MessageBoxComponent;

const backgroundBlinkRule = function (backgroundColor) {
  return css`
    animation-name: ${backgroundBlink(backgroundColor)};
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  `;
};

const Root = styled.div`
  padding: 16px;
  background: ${({ theme, severity, filled }) =>
    !filled ? theme.colors.background.light : theme.colors.severity[severity][1]};
  /* border: ${({ theme, severity, filled }) =>
    !filled ? 'none' : `1px solid ${theme.colors.severity[severity][0]}`}; */
  font-size: 1.6em;
  font-weight: 500;
  line-height: 1.3;
  color: ${({ theme, severity, filled }) => (filled ? theme.colors.text.default : theme.colors.severity[severity][0])};
  ${({ theme, severity, filled }) =>
    !filled &&
    css`
      ${backgroundBlinkRule(theme.colors.severity[severity][0])};
    `}

  ${({ useIcon }) =>
    useIcon &&
    `
    display: flex;
    align-items: center;
  `}
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-left: 5px solid
    ${({ theme, severity, filled }) =>
      !filled ? theme.colors.background.light : darken('0.20', theme.colors.severity[severity][1])};
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 16px;
`;

import React from 'react';
import { components } from 'react-select';

export default function IndicatorsContainer({ children, ...props }) {
  const menuIsOpen = props.selectProps.menuIsOpen;

  if (menuIsOpen) {
    return null;
  }

  return <components.IndicatorsContainer {...props}>{children}</components.IndicatorsContainer>;
}

export default function wizardSvgPathGenerator(mX, mY, radius, tunnelHeight, numberOfSteps, containerWidth) {
  const tunnelLength = getTunnelLength(mX, radius, numberOfSteps, containerWidth);
  const dropletsWizardResult = generatePath(mX, mY, radius, tunnelHeight, tunnelLength, numberOfSteps, containerWidth);
  const dropletsWizardBoxResult = generateBoxPath(
    dropletsWizardResult.path,
    dropletsWizardResult.pathDimensions.width,
    dropletsWizardResult.pathDimensions.height,
    dropletsWizardResult.finalPoint,
    radius
  );

  return {
    path: dropletsWizardResult.path,
    boxPath: dropletsWizardBoxResult,
    pathDimensions: dropletsWizardResult.pathDimensions,
  };
}

const bezierPointCircleRadiusMultiplier = 1.5;

function getTunnelLength(mX, radius, numberOfSteps, containerWidth) {
  const widthCorrectionMultiplier = 1.13;
  const dropletsTotalWidth = radius * 2 * numberOfSteps;
  const wizardWidthWithoutTunnels = Math.floor((mX + dropletsTotalWidth) * widthCorrectionMultiplier);
  const allowedTunnelsTotalWidth = Math.floor((containerWidth - wizardWidthWithoutTunnels) / widthCorrectionMultiplier);

  return Math.floor(allowedTunnelsTotalWidth / (numberOfSteps - 1));
}

function generatePath(mX, mY, radius, tunnelHeight, tunnelLength, numberOfSteps, containerWidth) {
  let path = `M ${mX},${mY} `;

  let result = joinCurveHalf(mX, mY, radius, true);
  path += result.path + ' ';

  for (var i = 1; i < numberOfSteps; i++) {
    result = generatePathSegment(result, radius, tunnelHeight, tunnelLength, true);
    path += result.path + ' ';
  }

  const pathDimensions = calculatePathDimensions(mX, result.finalPoint.x, radius, containerWidth);

  result = joinCurveHalf(result.finalPoint.x, result.finalPoint.y, radius, false);
  path += result.path + ' ';

  for (var j = 1; j < numberOfSteps; j++) {
    result = generatePathSegment(result, radius, tunnelHeight, tunnelLength, false);
    path += result.path + ' ';
  }

  path += 'Z';

  return {
    finalPoint: result.finalPoint,
    path,
    pathDimensions,
  };
}

function generateBoxPath(currentPath, currentPathWidth, currentPathHeight, finalPoint, radius) {
  const leftX = finalPoint.x - radius - 5;
  const rightX = finalPoint.x - radius + currentPathWidth + 5;
  const topY = finalPoint.y - currentPathHeight;
  const bottomY = finalPoint.y;

  currentPath += `L ${rightX},${bottomY} `;
  currentPath += `L ${rightX},${topY} `;
  currentPath += `L ${leftX},${topY} `;
  currentPath += `L ${leftX},${bottomY} `;
  currentPath += `L ${finalPoint.x},${finalPoint.y} Z`;

  return currentPath;
}

function generatePathSegment(previousResult, radius, tunnelHeight, tunnelLength, isTop) {
  let path = '';

  const firstCurveQuarter = joinCurveQuarter(
    previousResult.finalPoint.x,
    previousResult.finalPoint.y,
    radius,
    tunnelHeight,
    isTop,
    isTop ? false : true
  );

  path += firstCurveQuarter.path + ' ';

  const lineEndX = isTop
    ? firstCurveQuarter.finalPoint.x + tunnelLength
    : firstCurveQuarter.finalPoint.x - tunnelLength;
  const lineEndY = firstCurveQuarter.finalPoint.y;

  path += `L ${lineEndX},${lineEndY} `;

  const lastCurveQuarter = joinCurveQuarter(lineEndX, lineEndY, radius, tunnelHeight, isTop, isTop ? true : false);

  path += lastCurveQuarter.path;

  return {
    finalPoint: lastCurveQuarter.finalPoint,
    path,
  };
}

function joinCurveHalf(cX, cY, radius, isLeft) {
  const bezierPoint1X = isLeft
    ? cX - radius * bezierPointCircleRadiusMultiplier
    : cX + radius * bezierPointCircleRadiusMultiplier;
  const bezierPoint1Y = cY;
  const bezierPoint2X = bezierPoint1X;
  const bezierPoint2Y = isLeft ? cY - radius * 2 : cY + radius * 2;
  const finalPointX = cX;
  const finalPointY = bezierPoint2Y;

  const path = `C ${bezierPoint1X},${bezierPoint1Y} ${bezierPoint2X},${bezierPoint2Y} ${finalPointX},${finalPointY}`;

  return {
    finalPoint: {
      x: finalPointX,
      y: finalPointY,
    },
    path,
  };
}

function joinCurveQuarter(cX, cY, radius, tunnelHeight, isTop, isLeft) {
  const bezierPoint1X = isTop ? cX + radius : cX - radius;
  const bezierPoint1Y = cY;
  const bezierPoint2X = bezierPoint1X;
  const bezierPoint2Y = isLeft ? cY - radius + tunnelHeight / 2 : cY + radius - tunnelHeight / 2;
  const finalPointX = isTop ? bezierPoint1X + radius : bezierPoint1X - radius;
  const finalPointY = bezierPoint2Y;

  const path = `C ${bezierPoint1X},${bezierPoint1Y} ${bezierPoint2X},${bezierPoint2Y} ${finalPointX},${finalPointY}`;

  return {
    finalPoint: {
      x: finalPointX,
      y: finalPointY,
    },
    path,
  };
}

function calculatePathDimensions(mX, furthestXPoint, radius, containerWidth) {
  const width = furthestXPoint - mX + 2 * radius;
  const height = radius * 2;
  const correctedWidth = width + 10;

  // Width difference after which the foreground and background are visible from behind the svg.
  const widthDifferenceThreshold = 85;
  const widthDifferenceCorrectionFactors = ((correctedWidth - containerWidth) / widthDifferenceThreshold)
    .toFixed(1)
    .split('.');
  const widthDifferenceCorrectionValue =
    parseInt(widthDifferenceCorrectionFactors[0]) > 0 ? parseInt(widthDifferenceCorrectionFactors[1]) + 1 : 0;

  const correctedHeight = height - widthDifferenceCorrectionValue;

  return {
    width,
    height,
    correctedWidth,
    correctedHeight,
  };
}

import Router from 'next/router';

export default function isWorldSearch() {
  if (!process.browser) {
    return null;
  }
  if (Router.query && Router.query.query) {
    return (
      (Router.query.query['world'] === 'true' ? true : false) ||
      (Router.router && Router.router.asPath && Router.router.asPath.includes('destination/world/city'))
    );
  }
  return null;
}

import { getDestinations } from 'httpClient/requests/destinations';
import * as fetchingStatus from 'constants/status/fetchingStatus';
import { setFetching, setDestinations } from '../actions';

export default function fetchDestinations() {
  return async (dispatch, getState) => {
    const { fetching } = getState().destinations;
    const websiteId = getState().settings.value.websiteId;

    if (fetching === fetchingStatus.IN_PROGRESS) {
      return;
    }

    dispatch(setFetching(fetchingStatus.IN_PROGRESS));

    try {
      const { data } = await getDestinations(websiteId);

      dispatch(setDestinations(data));
      dispatch(setFetching(fetchingStatus.SUCCEEDED));
    } catch (error) {
      dispatch(setFetching(fetchingStatus.FAILED));
      console.error(`Error on fetchDestinations`, error);
      window.Rollbar.error(`Error on fetchDestinations`, error);
    }
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PriceBarTooltip from './PriceBarTooltip';
import flexbox from 'styles/utils/flexbox';

const propTypes = {
  index: PropTypes.number.isRequired,
  dateDay: PropTypes.string.isRequired,
  weekDay: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  tooltipData: PropTypes.shape({
    date: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    days: PropTypes.number.isRequired,
  }).isRequired,
};

const PriceBarsMonthGroupBar = ({
  index,
  dateDay,
  weekDay,
  percentage,
  selected,
  tooltipData,
  onClickPriceBar,
  selectedBarRef,
}) => (
  <PriceBarsMonthGroupBarStyled selected={selected} ref={selected ? selectedBarRef : null}>
    <PriceBarTooltip tooltipData={tooltipData}>
      <PriceBar
        selected={selected}
        onClick={onClickPriceBar}
        data-date={tooltipData.date}
        data-number-of-days={tooltipData.days}
      >
        <PercentageWrap>
          <Percentage index={index} percentage={percentage} />
        </PercentageWrap>
        <Footer index={index}>
          <DateDay selected={selected}>{dateDay}</DateDay>
          <WeekDay selected={selected}>{weekDay}</WeekDay>
        </Footer>
      </PriceBar>
    </PriceBarTooltip>
  </PriceBarsMonthGroupBarStyled>
);

PriceBarsMonthGroupBar.propTypes = propTypes;

export default PriceBarsMonthGroupBar;

// I had to define these two up here because they are referenced in PriceBarsMonthGroupBarStyled
const Percentage = styled.div`
  width: 16px;
  height: ${({ percentage }) => percentage + 3}%;
  background-color: ${({ theme }) => theme.colors.priceBars.background};
`;

const Footer = styled.div`
  ${flexbox({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  })}

  width: 100%;
  height: 28px;
  font-size: 10px;
  line-height: 13px;
`;

const PriceBarsMonthGroupBarStyled = styled.div`
  ${flexbox({
    alignItems: 'flex-end',
  })}
  width: 16px;
  height: 100%;
  margin-right: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.hover};
  }
`;

const PriceBar = styled.div`
  ${flexbox({
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  })}

  height: 100%;
  cursor: pointer;

  ${Percentage} {
    ${({ selected, theme }) =>
      selected &&
      `
      background-color: ${theme.colors.adjacent.default};
    `}
  }
`;

const PercentageWrap = styled.div`
  ${flexbox({
    alignItems: 'flex-end',
  })}

  height: calc(100% - 28px); // Height of the Footer
`;

const DateDay = styled.div`
  font-weight: 700;
`;

const WeekDay = styled.div`
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
`;

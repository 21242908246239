import React, { Component } from 'react';
import { connect } from 'react-redux';
import { savedPackagesOperations } from 'state/savedPackages';
import { routesOperations } from 'state/routes';
import NumberedHeartComponent from './NumberedHeartComponent';
import { SAVED_PACKAGES } from 'constants/routesKeys';

class NumberedHeart extends Component {
  componentDidMount() {
    if (this.props.count === 0) {
      this.props.hydrateSavedPackages();
    }
  }

  render() {
    const { count, isFrontpage, isHotelPage, isCheckoutPage } = this.props;
    const pathToSavedPackages = this.props.getRoutePathByRouteKey(SAVED_PACKAGES);

    return (
      <NumberedHeartComponent
        number={count}
        pathToSavedPackages={pathToSavedPackages}
        isFrontpage={isFrontpage}
        isHotelPage={isHotelPage}
        isCheckoutPage={isCheckoutPage}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    count: state.savedPackages.count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hydrateSavedPackages: function () {
      dispatch(savedPackagesOperations.hydrateSavedPackages());
    },
    getRoutePathByRouteKey: function (routeKey) {
      return dispatch(routesOperations.getRoutePathByRouteKey(routeKey));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberedHeart);

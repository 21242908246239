import React, { Component } from 'react';
import { connect } from 'react-redux';
import moveToNewStep from 'state/checkoutPage/wizard/operations/moveToNewStep';
import Wizard from 'components/wizard/Wizard';

class CheckoutWizard extends Component {
  constructor(props) {
    super(props);

    this.handleOnClickStep = this.handleOnClickStep.bind(this);

    this.configuration = {
      radius: 21,
      tunnelHeight: 8,
      strokeWidth: 3,
    };
  }

  handleOnClickStep(event) {
    const { stepId, stepDisabled } = event.currentTarget.dataset;

    if (stepDisabled === 'true') {
      return;
    }

    this.props.moveToNewStep(stepId);
  }

  render() {
    const { checkoutPageWizard, hideForMedia, hideLabels } = this.props;

    return (
      <Wizard
        configuration={{
          ...this.configuration,
          steps: checkoutPageWizard.steps,
        }}
        events={{
          onClickStep: this.handleOnClickStep,
        }}
        styles={{
          hideForMedia,
          hideLabels,
        }}
        currentStep={checkoutPageWizard.currentStep}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    checkoutPageWizard: state.checkoutPageWizard,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    moveToNewStep(nextStepId) {
      dispatch(moveToNewStep(nextStepId));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutWizard);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IN_PROGRESS as FETCHING_STATUS_IN_PROGRESS } from 'constants/status/fetchingStatus';
import MenuListComponent from './MenuListComponent';
import { setSelectedChildren } from 'components/inputs/utils';

class MenuList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleOnClickClose = this.handleOnClickClose.bind(this);
  }

  componentDidMount() {
    this.setState({ open: true });

    const {
      selectProps: { eventHandlers },
    } = this.props;

    if (eventHandlers && eventHandlers.onFocusMobile) {
      eventHandlers.onFocusMobile();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onRequestClose } = this.props.selectProps.eventHandlers;
    const { open } = this.state;
    const { open: prevOpen } = prevState;

    if (!open && prevOpen) {
      setTimeout(() => {
        document.activeElement.blur();
        onRequestClose();
      }, 300);
    }
  }

  componentWillUnmount() {
    this.setState({ open: false });
  }

  handleOnClickClose() {
    this.setState({ open: false });
  }

  handleOnMouseDown(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
  }

  handleOnTouchEnd(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.target.focus();
  }

  render() {
    const { selectProps, hotelQuerySearchFetching, children } = this.props;
    const { inputValue, eventHandlers, data } = selectProps;
    const { open } = this.state;
    const options = setSelectedChildren(children);

    return (
      <MenuListComponent
        open={open}
        inputValue={inputValue}
        onClickClose={this.handleOnClickClose}
        onMouseDown={this.handleOnMouseDown}
        onTouchEnd={this.handleOnTouchEnd}
        onChangeInputValue={eventHandlers.onChangeInputValue}
        selectedOptions={options ? options.selectedOptions : []}
        notSelectedOptions={options ? options.notSelectedOptions : []}
        placeholder={data.title}
        inputPlaceholder={data.inputPlaceholder || data.title}
        hotelQuerySearchFetching={hotelQuerySearchFetching}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    hotelQuerySearchFetching: state.hotelQuerySearch.fetching === FETCHING_STATUS_IN_PROGRESS,
  };
}

export default connect(mapStateToProps)(MenuList);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Router from 'next/router';
import Modal from 'react-modal';
import queryString from 'query-string';
import {
  KAYAKCLICKID as COOKIE_KEY_KAYAKCLICKID,
  TDUID as COOKIE_KEY_TDUID,
  TRIPX_AFFILIATE as COOKIE_KEY_TRIPX_AFFILIATE,
  EXTERNAL_REFERENCE_ID as COOKIE_EXTERNAL_REFERENCE_ID,
} from 'constants/cookiesKeys';
import devEnvsQueryLS from 'managers/localStorage/devEnvsQuery/devEnvsQueryLocalStorage';
import checkoutFlowQueryLS from 'managers/localStorage/checkoutFlowQuery/checkoutFlowQueryLS';
import loadFonts from 'state/theme/operations/loadFonts';
import { updateTouchDevice } from 'state/theme/actions';
import addWheelListener from 'utils/events/addWheelListener';
import getCookie from 'utils/cookie/getCookie';
import setCookie from 'utils/cookie/setCookie';
import deleteCookie from 'utils/cookie/deleteCookie';
import setCookieProxy from 'utils/cookie/setCookieProxy';
import { WEB_CODE, WEB_COUNTRY } from './components/websitePicker/constants';
import LayoutComponent from './LayoutComponent';
import { PRODUCTION as RUNTIME_HOST_ENV_PRODUCTION } from 'constants/env/runtimeHostEnv';
import { lastClickAffiliates } from 'constants/lastClickAffiliates';

class Layout extends Component {
  constructor(props) {
    super(props);

    if (process.browser) {
      addWheelListener(window, document);
    }
  }

  componentDidMount() {
    console.warn('Window Inner Width: ', window.innerWidth);
    console.warn('Window Inner Height: ', window.innerHeight);

    const { loadFonts, updateTouchDevice } = this.props;
    loadFonts();
    updateTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
    this.updateCookies();
    this.updateLocalStorage();

    Router.events.on('routeChangeComplete', (url) => url.indexOf('keep_scroll=1') === -1 && window.scrollTo(0, 0));

    // required for users of screenreaders that other page content be hidden while the modal is open
    const appElement = document.getElementById('__next');
    Modal.setAppElement(appElement);
  }

  updateCookies() {
    const { forcedAffiliateId, setCookieProxy } = this.props;

    if (forcedAffiliateId) {
      setCookieProxy(COOKIE_KEY_TRIPX_AFFILIATE, forcedAffiliateId, 15);
    }

    this.updateKayakClickIdCookie();
    this.updateTDUIDCookie();
    this.updateAffiliateCookie();

    const { referenceId } = queryString.parse(window.location.search);

    if (referenceId) {
      setCookie(COOKIE_EXTERNAL_REFERENCE_ID, referenceId);
    }
  }

  updateKayakClickIdCookie() {
    const kayakclickid = queryString.parse(window.location.search).kayakclickid;

    if (kayakclickid) {
      setCookie(COOKIE_KEY_KAYAKCLICKID, kayakclickid, 30, window.location.hostname.replace('www.', ''));
      setCookie(COOKIE_EXTERNAL_REFERENCE_ID, kayakclickid, 30, window.location.hostname.replace('www.', ''));
    }
  }

  updateTDUIDCookie() {
    const tduid = queryString.parse(window.location.search).tduid;

    if (tduid) {
      setCookie(COOKIE_KEY_TDUID, tduid, 1, window.location.hostname.replace('www.', ''));
    }
  }

  updateAffiliateCookie() {
    const query = queryString.parse(window.location.search);

    if (query.ca === '1') {
      deleteCookie(COOKIE_KEY_TRIPX_AFFILIATE);
      return;
    }

    const affiliateCookie = getCookie(COOKIE_KEY_TRIPX_AFFILIATE);
    const affiliateCookieMissing = !affiliateCookie || affiliateCookie === 'null';

    const queryAffiliateId = query && query.affiliateId;

    //With this we check if the affiliateId is included in lastClickAffiliates and it's different with already set cookie
    const setLastClickAffiliateId =
      lastClickAffiliates.includes(queryAffiliateId) && queryAffiliateId !== affiliateCookie;

    if (affiliateCookieMissing || setLastClickAffiliateId) {
      const cookieValue = queryAffiliateId || 'tripx';
      const cookieExpiration = queryAffiliateId && queryAffiliateId === '9' ? 30 : 15;

      this.props.setCookieProxy(COOKIE_KEY_TRIPX_AFFILIATE, cookieValue, cookieExpiration);
    }
  }

  updateLocalStorage() {
    const query = queryString.parse(window.location.search);
    this.updateDevelopmentEnvironmentsQueriesLocalStorage(query);
    this.updateCheckoutFlowQueryLocalStorage(query);
  }

  updateDevelopmentEnvironmentsQueriesLocalStorage(query) {
    let queryToStore = '';

    if (query[WEB_CODE]) {
      queryToStore = queryToStore + `${WEB_CODE}=${query[WEB_CODE]}`;
    }

    if (query[WEB_COUNTRY]) {
      queryToStore = queryToStore + `&${WEB_COUNTRY}=${query[WEB_COUNTRY]}`;
    }

    devEnvsQueryLS.set(queryToStore);
  }

  updateCheckoutFlowQueryLocalStorage(query) {
    const { promocode, rewardno } = query;

    if (promocode) {
      checkoutFlowQueryLS.update('promocode', promocode);
    }

    if (rewardno) {
      checkoutFlowQueryLS.update('rewardno', rewardno);
    }
  }

  render() {
    const { pageData, theme, children } = this.props;
    const isStaging = process.env.RUNTIME_HOST_ENV !== RUNTIME_HOST_ENV_PRODUCTION;

    return (
      <LayoutComponent theme={theme} pageData={pageData} isStaging={isStaging}>
        {children}
      </LayoutComponent>
    );
  }
}

function mapStateToProps(state) {
  return {
    theme: state.theme,
    forcedAffiliateId: state.settings.value.forcedAffiliateId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadFonts() {
      dispatch(loadFonts());
    },
    setCookieProxy(cname, cvalue, exdays) {
      dispatch(setCookieProxy(cname, cvalue, exdays));
    },
    updateTouchDevice(touchDevice) {
      dispatch(updateTouchDevice(touchDevice));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

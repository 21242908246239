import { setSelectedVehicleRentals } from 'state/checkoutPage/travelSummary/actions';

export function setDriverToSelectedVehicle() {
  return (dispatch, getState) => {
    const selectedOtherExtras = getState().checkoutPageTravelSummary.selectedOtherExtras;
    const vehicleRentals = selectedOtherExtras && selectedOtherExtras.vehicleRentals;
    const selectedDriver = getState().checkoutPageCarRentalDriver.driver;
    const firstName = selectedDriver.firstName.value;
    const lastName = selectedDriver.lastName.value;
    const dateOfBirth = selectedDriver.dateOfBirth.value.format('YYYY-MM-DD');
    const productToken = vehicleRentals[0].productToken;
    const searchToken = vehicleRentals[0].searchToken;

    const driver = {
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dateOfBirth,
    };

    dispatch(setSelectedVehicleRentals([{ searchToken, productToken, driver }]));
  };
}

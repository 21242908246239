import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Router from 'next/router';
import fetchTemplateData from 'state/template/operations/fetchTemplateData';
import { getPageFromPath } from 'state/routes/operations';
import devEnvsQueryLocalStorage from 'managers/localStorage/devEnvsQuery/devEnvsQueryLocalStorage';
import LinkInterceptComponent from './LinkInterceptComponent';

const propTypes = {
  to: PropTypes.string.isRequired, // Slug for navigation.
  target: PropTypes.string, // Value for the `target` property of the anchor element.
  onClick: PropTypes.func, // Additional onClick handler when the user clicks the anchor element.
  config: PropTypes.shape({
    skipOnClickHandler: PropTypes.bool,
    external: PropTypes.bool, // Set to `true` when link navigates outside of our website.
    style: PropTypes.object, // Style object for the anchor element.
  }),
};

const defaultProps = {
  to: '/',
  target: '_self',
  onClick: undefined,
  config: {
    skipOnClickHandler: false,
    external: false,
    style: undefined,
  },
};

class LinkIntercept extends Component {
  constructor(props) {
    super(props);

    const { to, target, config, selectedCountry, checkoutOnlyWhitelabel } = props;
    const { external } = config;
    this.urlAs = selectedCountry ? `/${selectedCountry}${to}` : to;
    this.href = getHref(to, selectedCountry, external, devEnvsQueryLocalStorage.get());
    this.target = checkoutOnlyWhitelabel ? '_blank' : target;
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  async handleOnClick(event) {
    const { to, target, onClick, config, fetchTemplateData, getPageFromPath, onClickTrackEvent } = this.props;
    const { skipOnClickHandler, external } = config;
    onClickTrackEvent && onClickTrackEvent();

    if (skipOnClickHandler || external || target === '_blank' || this.target === '_blank') {
      if (typeof onClick === 'function') {
        onClick();
      }

      return;
    }

    event.preventDefault();

    try {
      const response = await fetchTemplateData(to);

      if (response) {
        const page = getPageFromPath(this.urlAs);
        Router.push(page, this.urlAs);
      }

      if (typeof onClick === 'function') {
        onClick();
      }
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { config, children } = this.props;
    const { style } = config;

    return (
      <LinkInterceptComponent
        to={this.href}
        style={style}
        onClick={this.handleOnClick}
        target={this.target}
        children={children}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCountry: state.routes.selectedCountry,
    checkoutOnlyWhitelabel: state.settings.value.checkoutOnlyWhitelabel,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTemplateData(pathname) {
      return dispatch(fetchTemplateData(pathname));
    },
    getPageFromPath(requestPath) {
      return dispatch(getPageFromPath(requestPath));
    },
  };
}

LinkIntercept.propTypes = propTypes;
LinkIntercept.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(LinkIntercept);

function getHref(to, selectedCountry, external, devEnvsQuery) {
  let _to = !external ? `${selectedCountry}${to}` : to;

  if (!external && _to.charAt(0) !== '/') {
    _to = `/${_to}`;
  }

  if (devEnvsQuery && _to.indexOf(devEnvsQuery) === -1) {
    if (_to.indexOf('?') === -1) {
      _to = `${_to}?${devEnvsQuery}`;
    } else {
      _to = `${_to}&${devEnvsQuery}`;
    }
  }

  return _to;
}

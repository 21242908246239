import { breakpoints } from 'styles/themes/main';

/**
 * Check if the current environment is a mobile device.
 *
 * @return {boolean} true if the current environment is a mobile device, false otherwise
 */

export function isMobile() {
  return typeof window !== 'undefined' && window.innerWidth <= breakpoints.below.desktop;
}

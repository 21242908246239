import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

function WebsitePickerComponent({ options, value, onChange }) {
  return (
    <Root>
      <select name='websitePicker' id='websitePicker' value={value} onChange={onChange}>
        {options.map(({ label, value }, index) => (
          <option key={`website-picker-${index}`} value={value}>
            {label}
          </option>
        ))}
      </select>
    </Root>
  );
}

WebsitePickerComponent.propTypes = propTypes;

export default WebsitePickerComponent;

const Root = styled.div`
  position: fixed;
  z-index: 999;
`;

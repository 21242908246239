import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CalendarOptionsComponent from './CalendarOptions/CalendarOptionsComponent';

import CalendarPriceInfo from './CalendarPriceInfo/CalendarPriceInfo';
import CalendarLoading from './CalendarLoading/CalendarLoading';
import CalendarDescription from './CalendarDescription/CalendarDescription';
import ConfirmButton from 'components/buttons/confirmButton/ConfirmButton';
import { mediaQueries } from 'styles/utils/mediaQueries';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const propTypes = {
  company: PropTypes.string.isRequired,
  onChangeCheckbox: PropTypes.func,
  isCheckboxChecked: PropTypes.bool,
  flexibleDatesDisabled: PropTypes.bool.isRequired,
  airlineWhitelabel: PropTypes.object,
  isLoading: PropTypes.bool,
  calendarPriceInfo: PropTypes.object,
  handleCloseButtonClick: PropTypes.func,
  matches: PropTypes.bool,
};

const CalendarInfo = ({
  company,
  onChangeCheckbox,
  isCheckboxChecked,
  flexibleDatesDisabled,
  airlineWhitelabel,
  isLoading,
  calendarPriceInfo,
  calendarOption,
  isHotelPage,
  handleCloseButtonClick,
  matches,
  resetInputFocus,
}) => (
  <>
    {!isHotelPage && (
      <GroupTop>
        {matches && (
          <Close onClick={handleCloseButtonClick}>
            <FontAwesomeIcon icon='chevron-left' />
          </Close>
        )}
        <CalendarOptionsComponent resetInputFocus={resetInputFocus} />
      </GroupTop>
    )}
    <GroupBottom>
      <>
        {calendarPriceInfo ? (
          <CalendarPriceInfo calendarPriceInfo={calendarPriceInfo} />
        ) : (
          <>
            <CalendarDescription
              airlineWhitelabel={airlineWhitelabel}
              company={company}
              flexibleDatesDisabled={flexibleDatesDisabled}
              calendarOption={calendarOption}
              onChangeCheckbox={onChangeCheckbox}
              isCheckboxChecked={isCheckboxChecked}
            />
          </>
        )}
        {matches && <ConfirmButton onClick={handleCloseButtonClick} styles={{ borderRadius: '8px' }} />}
      </>
    </GroupBottom>
    {isLoading && <CalendarLoading />}
  </>
);

CalendarInfo.propTypes = propTypes;

export default CalendarInfo;

const GroupTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  background-color: ${({ theme }) => theme.colors.background.greyLight};
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  ${mediaQueries.fromAndBelow.tablet`
    background-color: ${({ theme }) => theme.colors.background.light};
    padding-bottom:18px;
  `};
`;

const GroupBottom = styled.div`
  position: absolute;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.greyLight};
  height: 43px;
  bottom: -43px;
  padding: 10px 21px;
  font-size: 14px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.darkGrey};
  ${mediaQueries.fromAndBelow.tablet`
    background-color: ${({ theme }) => theme.colors.background.light};
    padding-bottom:18px;
    position: -webkit-fixed;
    position: fixed;
    bottom: 0;
    z-index: 11;
    height: fit-content;
    min-height:16vh;
    min-height:16svh;
    border-top:1px solid ${({ theme }) => theme.colors.background.greyV3};
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    -webkit-transform: translateZ(0);
  `};
`;

const Close = styled.div`
  display: flex;
  height: 100%;
  color: ${({ theme }) => theme.colors.adjacent.default};
  position: absolute;
  top: 34px;
  left: 18px;
  font-size: 20px;
`;

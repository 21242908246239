import React, { Component } from 'react';
import { connect } from 'react-redux';
import _throttle from 'lodash/throttle';
import WizardComponent from './WizardComponent';
import getSvgProps from './utils/getSvgProps';
import getForegroundAndBackgroundProps from './utils/getForegroundAndBackgroundProps';

class Wizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      containerWidth: window.innerWidth,
    };

    this.onResize = this.handleOnResize();
    this.rootRef = React.createRef();
  }

  componentDidMount() {
    if (this.rootRef && this.rootRef.current) {
      // @TODO It works but is this the correct way to pass ref?
      if (this.props.refs && this.props.refs.rootRef) {
        this.props.refs.rootRef.current = this.rootRef.current;
      }

      this.setState({ containerWidth: this.rootRef.current.clientWidth });
    }

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  handleOnResize() {
    return _throttle(() => {
      this.setState(({ containerWidth }) => {
        if (containerWidth !== this.rootRef.current.clientWidth) {
          return {
            containerWidth: this.rootRef.current.clientWidth,
          };
        }
      });
    }, 200);
  }

  render() {
    const { configuration, events, currentStep, styles = {}, colors } = this.props;
    const { hideForMedia, hideLabels } = styles;
    const { containerWidth } = this.state;
    const svgProps = getSvgProps(configuration, containerWidth);
    const foregroundAndBackgroundProps = getForegroundAndBackgroundProps(
      configuration,
      svgProps.result.pathDimensions.correctedHeight
    );
    const { result, svgViewBox, strokeWidth } = svgProps;
    const { offset, height } = foregroundAndBackgroundProps;
    const { radius, steps } = configuration;
    const foregroundWidth =
      currentStep === 0
        ? `${result.pathDimensions.correctedHeight}px`
        : currentStep === steps.length - 1
        ? '100%'
        : `calc((((100% - ${result.pathDimensions.correctedHeight}px) / ${steps.length - 1}) * ${currentStep}) + ${
            result.pathDimensions.correctedHeight
          }px)`;

    return (
      <WizardComponent
        rootRef={this.rootRef}
        path={result.path}
        boxPath={result.boxPath}
        pathDimensions={result.pathDimensions}
        svgViewBox={svgViewBox}
        strokeWidth={strokeWidth}
        foregroundWidth={foregroundWidth}
        offset={offset}
        height={height}
        steps={steps}
        currentStep={currentStep}
        dropletWidth={radius * 2}
        hideForMedia={hideForMedia}
        hideLabels={hideLabels}
        colors={colors}
        onClickStep={events.onClickStep}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    colors: state.theme.colors,
  };
}

export default connect(mapStateToProps)(Wizard);

import styled from 'styled-components';
import SelectCommonSingleValue from '../components/SelectCommonSingleValueComponent';

export default styled(SelectCommonSingleValue)`
  .styled-select {
    &__control {
      height: ${({ data }) => (data.isFrontpage ? '66px' : '40px')};
      position: relative;

      @media (max-width: 1024px) {
        border-width: 0;
        min-width: 100%;
        height: 40px;
        padding: 0;
        border-right-style: none;
        display: flex;
        padding: 2px 4px 2px 18px;
        justify-content: flex-end;
        align-items: center;
      }
    }

    &__value-container {
      padding: ${({ data }) => data.isHotelPage && '0 0 0 16px'};

      @media (max-width: 768px) {
        height: 100%;
        padding: ${({ data }) => !data.isHotelPage && 0};
      }
    }

    &__placeholder {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.background.greyV4};
    }

    &__menu {
      width: 500px;
      font-size: 16px;
      background: ${({ theme }) => theme.colors.background.greyV5};

      @media (max-width: 768px) {
        background: ${({ theme }) => theme.colors.background.greyLight};
        z-index: 4;
      }
    }

    &__input {
      padding-top: 8px;

      input {
        line-height: unset;

        @media (max-width: 768px) {
          caret-color: transparent;
        }
      }
    }

    &__indicators {
      position: absolute;
      right: 0;
      top: 2px;

      @media (max-width: 768px) {
        right: 4px;
        top: 6px;
      }
    }
  }
`;

import { shiftQueue } from '../actions';

export default function executeLog(item) {
  return (dispatch) => {
    try {
      const { loggerType, logType, message, args } = item;

      if (process.browser && window) {
        if (window[loggerType] && typeof window[loggerType][logType] === 'function') {
          window[loggerType][logType](message, args);
        } else {
          window.Rollbar.error(`Incorrect logger object. LoggerType: ${loggerType}, LogType: ${logType}.`);
          window.Rollbar.error(message, args);
        }

        dispatch(shiftQueue());
      }
    } catch (error) {
      console.error(error);
    }
  };
}

import styled from 'styled-components';
import SelectCommonNarrow from '../components/SelectCommonNarrowComponent';

export default styled(SelectCommonNarrow)`
  .styled-select {
    &__control {
      height: 40px;

      @media (max-width: 1024px) {
        border-width: 0;
        min-width: 100%;
        height: 42px;
        padding: 0;
        display: flex;
        padding: 2px 4px 2px 18px;
        justify-content: flex-end;
        align-items: center;
      }
    }

    &__value-container {
      @media (max-width: 1024px) {
        height: 100%;
        padding: 0;
        margin-left: 0;
      }
    }

    &__placeholder {
      padding-top: 12px;
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.background.greyV4};
    }

    &__menu {
      top: calc(100% + 10px);
      width: 511px;
      min-width: 250px;
      border-radius: 8px;
      background: ${({ theme }) => theme.colors.background.greyV5};
      left: ${({ isFrontpage }) => (isFrontpage ? 'unset' : '0')};

      @media (max-width: 1024px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        width: 100%;
        background: ${({ theme }) => theme.colors.background.greyLight};
        z-index: 4;
      }
    }

    &__menu-list {
      min-height: 158px;
      max-height: ${({ data }) => `calc(100vh - ${data.headerHeight + 54 + 32}px)`};

      @media (max-width: 768px) {
        min-height: 100vh;
      }
    }

    &__input {
      padding-top: 8px;
      input {
        display: flex;
        @media (max-width: 768px) {
          caret-color: transparent;
        }
      }
    }
  }
`;

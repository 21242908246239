import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleFlexibleDates } from 'state/selectDates/actions';
import { updateSelectedDates } from 'state/selectDates/operations/updateSelectedDates';
import hydrateSelectedDates from 'state/selectDates/operations/hydrateSelectedDates';
import SelectDatesComponent from 'components/inputs/SelectDates/DateRangePickerWrapper';

const selectDatesInputIds = { startDateId: 'selectDateFromHeader', endDateId: 'selectDateEndHeader' };

class SelectDates extends Component {
  render() {
    const {
      selectDates,
      missingValue,
      hotelOnly,
      isFrontpage,
      setCalendarDates,
      toggleFlexibleDates,
      hydrateSelectedDates,
      isSearchPage,
    } = this.props;

    selectDates.flexibleDatesDisabled = hotelOnly;

    return (
      <SelectDatesComponent
        ref={this.dateRangePickerRef}
        selectDates={selectDates}
        selectDatesInputIds={selectDatesInputIds}
        tooltipIsVisible={missingValue}
        isFrontpage={isFrontpage}
        setCalendarDates={setCalendarDates}
        toggleFlexibleDates={toggleFlexibleDates}
        hydrateSelectedDates={hydrateSelectedDates}
        isSearchPage={isSearchPage}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    selectDates: state.selectDates,
    missingValue: state.searchConditions.missingValues.selectDates,
    hotelOnly: state.selectOrigins.hotelOnly,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCalendarDates: function (calendarDates) {
      dispatch(updateSelectedDates(calendarDates));
    },
    toggleFlexibleDates() {
      dispatch(toggleFlexibleDates());
    },
    hydrateSelectedDates: function () {
      dispatch(hydrateSelectedDates());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDates);

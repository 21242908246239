import React from 'react';
import styled from 'styled-components';
import formatDataAttributes from 'utils/object/formatDataAttributes';
import { FormattedMessage } from 'i18n';

const CustomOption = (props) => {
  return (
    <CustomOptionButton
      onClick={(event) => {
        props.onClickUpdateNumberOfChildren(event);
        props.handleOptionClick();
      }}
      data-update-value={1}
      {...formatDataAttributes({ ...props.customData, childAge: props.value })}
    >
      <FormattedMessage id={'yearsOld.label'} description={'{ age } years old'} values={{ age: props.value }} />
    </CustomOptionButton>
  );
};

const CustomOptionButton = styled.div`
  display: flex;
  align-items: center;
  background: none;
  width: 100%;
  padding: 4px 15px;
  border: none;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.dark};
  cursor: pointer;
  transition: opacity 0.3s;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default CustomOption;

import React from 'react';
import styled, { keyframes } from 'styled-components';

import { mediaQueries } from 'styles/utils/mediaQueries';

/**
 *
 * @param {boolean} isOpen //Which animation should execute on open or close
 * @param {function} onAnimationEnd // Execute clean up or somekind of callback when the animation end
 * @param {children} JSX.Elements //Than need to execute animation on opening closing
 * @param {rest} any;
 * @returns {JSX.Element}
 */

export default function Transition({ isOpen, onAnimationEnd, type, children, time, ...rest }) {
  return (
    <Root data-state={isOpen ? 'open' : 'closed'} onAnimationEnd={onAnimationEnd} type={type} time={time} {...rest}>
      {children}
    </Root>
  );
}

const slideInY = keyframes({
  from: {
    transform: 'translateY(-100%)',
  },
  to: {
    transform: 'translateY(0)',
  },
});

const slideOutY = keyframes({
  from: {
    transform: 'translateY(0%)',
  },
  to: {
    transform: 'translateY(-100%)',
  },
});

const slideIn = keyframes({
  from: {
    transform: 'translateY(100%)',
  },
  to: {
    transform: 'translateY(0)',
  },
});

const slideOut = keyframes({
  from: {
    transform: 'translateY(0)',
  },
  to: {
    transform: 'translateY(100%)',
  },
});

const slideLeft = keyframes({
  from: {
    transform: 'translateX(0)',
  },
  to: {
    transform: 'translateX(-100%)',
  },
});

const slideRight = keyframes({
  from: {
    transform: 'translateX(-100%)',
  },
  to: {
    transform: 'translateX(0)',
  },
});

const Root = styled('div')`
  &[data-state='open'] {
    animation: ${({ type }) => (type === 'vertical' ? slideInY : slideRight)} ${({ time }) => (time ? time : '300ms')}
      ease-in;
    ${mediaQueries.below.desktop`
    animation: ${slideIn} ${({ time }) => (time ? time : '300ms')}  ease-in;
    bottom: 0;
    top:unset;
    height: 70vh;
    width: 100vw;
  `}
  }

  &[data-state='closed'] {
    animation: ${({ type }) => (type === 'vertical' ? slideOutY : slideLeft)} ${({ time }) => (time ? time : '300ms')}
      ease-in-out;
    ${mediaQueries.below.desktop`
    animation: ${slideOut}  ${({ time }) => (time ? time : '300ms')} ease-in-out;
    bottom: 0;
    top:unset;
    height: 70vh;
    width: 100vw;
  `}
  }
`;

import {
  Container,
  ContainerNorwegian,
  ContainerAirBaltic,
  ContainerAirMalta,
  ContainerHillmanTravel,
  ContainerCyprus
} from './LogoContainerComponents';
import {
  NORWEGIAN_HOLIDAYS as NORWEGIAN_HOLIDAYS_AIRLINE,
  AIRBALTIC_HOLIDAYS as AIRBALTIC_HOLIDAYS_AIRLINE,
  AIRMALTA_HOLIDAYS as AIRMALTA_HOLIDAYS_AIRLINE,
  CY_HOLIDAY_DEALS as CY_HOLIDAY_DEALS_AIRLINE,
} from 'constants/airlines';

export default function getLogoContainer(airlineWhitelabel, isHillmanTravel) {
  if (isHillmanTravel) {
    return ContainerHillmanTravel;
  }

  if (airlineWhitelabel) {
    switch (airlineWhitelabel.hostname) {
      case NORWEGIAN_HOLIDAYS_AIRLINE:
        return ContainerNorwegian;
      case AIRBALTIC_HOLIDAYS_AIRLINE:
        return ContainerAirBaltic;
      case AIRMALTA_HOLIDAYS_AIRLINE:
        return ContainerAirMalta;
        case CY_HOLIDAY_DEALS_AIRLINE:
          return ContainerCyprus;
      default:
        return Container;
    }
  }

  return Container;
}

import React from 'react';
import { components } from 'react-select';
import OverflowCounter from './OverflowCounter';
import axis from 'axis.js';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import { mediaQueries } from 'styles/utils/mediaQueries';
import Backdrop from 'components/common/backdrop/Backdrop';
import { useSelector } from 'react-redux';

/**
 * Custom ValueContainer for react-select (currently on origins & destinations).
 * With this component we are allowing to display MultiValue mobile and desktop.
 *
 * @param {Object} children - the children of the component
 * @param {boolean} hasValue - indicates whether the component has a value
 * @param {...any} props - additional props for the component
 * @return {JSX.Element} the rendered MultiValueContainer component
 */

const styles = {
  marginTop: '6px',

  '@media (max-width: 1024px)': {
    marginTop: '10px',
  },
};

function ValueContainer({ children, hasValue, ...props }) {
  const [selectedOptions, otherChildren] = children;
  const { thirdPartyHeaderHeight, headerHeight } = useSelector((state) => state.refs);

  const label = ['select-origins-header', 'select-origin-hotel-page'].includes(props.selectProps.instanceId)
    ? { id: 'origin.label', description: 'Origin', defaultMessage: 'Origin' }
    : { id: 'destination.label', description: 'Destination', defaultMessage: 'Destination' };

  const isFrontpage = props.selectProps.data.isFrontpage;
  const isHotelPage = props.selectProps.data.isHotelPage;

  const description = (
    <Description isFrontpage={isFrontpage}>
      <FormattedMessage {...label} />
    </Description>
  );

  if (props.selectProps.menuIsOpen && !props.selectProps.data.isMobile) {
    return (
      <components.ValueContainer {...props}>
        {!isFrontpage && (
          <Backdrop
            styles={{
              top: isHotelPage ? 0 : thirdPartyHeaderHeight + headerHeight,
              background: 'rgb(0 0 0 / 50%)',
            }}
          />
        )}
        {description}
        {otherChildren}
      </components.ValueContainer>
    );
  }

  if (!hasValue || !axis.isArray(selectedOptions)) {
    return (
      <components.ValueContainer {...props}>
        {description}
        {children}
      </components.ValueContainer>
    );
  }

  const overflowCounter = selectedOptions.slice(1).length;
  const firstSelectedOption = selectedOptions[0];

  //Return only the first selected option and the overflow counter if it is greater than 0;

  return (
    <components.ValueContainer {...props}>
      {description}

      <>
        {firstSelectedOption}
        {overflowCounter > 0 && <OverflowCounter overflowCounter={overflowCounter} styles={styles} />}
        <HideInput>{otherChildren}</HideInput>
      </>
    </components.ValueContainer>
  );
}

export default ValueContainer;

const Description = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => (theme.colors.text.label ? theme.colors.text.label : theme.colors.adjacent.default)};
  position: absolute;
  top: 2px;
  z-index: 2;

  ${mediaQueries.fromAndBelow.tablet`
    top: ${({ isFrontpage }) => (isFrontpage ? 10 : 2)}px;
  `}
`;

const HideInput = styled.div`
  visibility: hidden;
  width: 0;
  padding: 0;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import { mediaQueries } from 'styles/utils/mediaQueries';
import CloseButtonComponent from 'components/buttons/closeButton/CloseButtonComponent';
import { useSelector } from 'react-redux';
import truncate from 'styles/utils/truncate';

const propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    data: PropTypes.object,
  }).isRequired,
};

function Option(props) {
  const { customSettings } = useSelector((state) => state.settings.value);
  const isHolidayPirates = customSettings && customSettings.isHolidayPirates;
  const { data, label, disabled } = props.data;
  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  const selectedCount = props.selectProps.data.selectedCount;

  const message =
    data && data.translation ? (
      <FormattedMessage
        id={data.translation.id}
        description={data.translation.description}
        defaultMessage={data.translation.defaultMessage}
      >
        {(text) => text}
      </FormattedMessage>
    ) : (
      label
    );

  const suffix = !(data && data.hideValue) && (
    <DestinationCode disabled={disabled}>, {props.data.value}</DestinationCode>
  );

  return (
    <Root isHolidayPirates={isHolidayPirates}>
      <components.Option {...newProps} onClick={(props) => {}}>
        <Origins>
          <LabelWrap>
            <OriginIcon icon={`${data && data.hideValue ? 'building' : 'plane'}`} />
            <Label isSelected={props.isSelected} multiple={selectedCount > 1}>
              {message}
            </Label>
          </LabelWrap>
          {suffix}
        </Origins>
        {props.isSelected && <CloseButtonComponent styles={{ padding: 0, opacity: 0.5 }} />}
      </components.Option>
    </Root>
  );
}

Option.propTypes = propTypes;

export default Option;

// Styled Components
const Label = styled.div`
  ${truncate()}
  max-width: ${({ isSelected, multiple }) => (isSelected && multiple ? '130px' : '100%')};
  padding: 2px 0px;

  ${mediaQueries.fromAndBelow.tablet`
    max-width: 100%;
    white-space: normal; 
    font-size: 14px;
 `}
`;

const LabelWrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
});

const Root = styled.div`
  .styled-select__option {
    background-color: ${({ theme }) => theme.colors.background.light};
    padding: 9px 12px;
    background-color: initial;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    color: ${({ theme, disabled }) => (disabled ? theme.colors.text.disabled : theme.colors.text.darkGrey)};

    &--is-focused {
      @media (max-width: 768px) {
        background-color: unset;
      }
    }

    &--is-disabled {
      color: ${({ theme }) => theme.colors.text.disabled};
      cursor: default;
    }

    &--is-selected {
      border-radius: 8px;
      background-color: ${({ theme }) => theme.colors.secondary.light};
      color: ${({ theme, isHolidayPirates }) =>
        isHolidayPirates ? theme.colors.text.darkGrey : theme.colors.text.white};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;
const DestinationCode = styled.span`
  font-weight: 800;
`;

const Origins = styled.div`
  display: flex;
  align-items: center;

  ${mediaQueries.fromAndBelow.tablet`
    line-height:1.2;
  `};
`;

const OriginIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
`;

import localStorageBase from '../localStorageBase';

const checkoutFlowQueryLocalStorage = (function (ls) {
  const key = 'checkout_flow_query';

  function getQueryValue(property) {
    const lsValue = ls.get(key);

    if (lsValue !== null && lsValue !== undefined) {
      const propertyData = lsValue[property];

      if (propertyData !== null && propertyData !== undefined) {
        const { unix, value } = propertyData;
        const daysSinceStored = (Date.now() - unix) / 1000 / 3600 / 24;

        if (daysSinceStored > 1) {
          updateQueryValue(property, null);
          return null;
        }

        return value;
      }
    }

    return null;
  }

  function updateQueryValue(property, updatedValue) {
    let query = ls.get(key);

    if (query === null) {
      query = {};
    }

    query[property] = {
      unix: Date.now(),
      value: updatedValue,
    };

    ls.set(key, query);
  }

  return {
    get: getQueryValue,
    update: updateQueryValue,
  };
})(localStorageBase);

export default checkoutFlowQueryLocalStorage;

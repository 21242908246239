function sortCountries(countries, selectedCountry) {
  let sortedCountries = [];
  sortedCountries.push(selectedCountry);

  countries.sort((a, b) => a.countryTranslated.localeCompare(b.countryTranslated));

  countries.map((country) => {
    if (!country.isActive === true) {
      sortedCountries.push(country);
    }
  });
  return sortedCountries;
}

export default async function filterCountries(countries) {
  const filterCountries = countries
    .map((country) => {
      const flagImage = require(`static/images/flags/flag-${country.countryCode.toLowerCase()}.svg`);
      return {
        ...country,
        flagImage: flagImage,
      };
    })
    .filter((country) => country.url !== '' || country.url !== null);

  const selectedCountry = filterCountries.find((country) => country.isActive === true);

  const sortedCountries = sortCountries(filterCountries, selectedCountry);

  if (sortedCountries.length <= 1) {
    return null;
  }
  return { sortedCountries, selectedCountry };
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import IncrementDecrement from './components/incrementDecrement/IncrementDecrementComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';
import MoreInfantsThanAdults from 'components/inputs/SelectRooms/components/MoreInfantsThanAdults';
import ChildAgeComponent from './components/incrementDecrement/ChildAgeComponent';
import AddChildSelect from './components/AddChildSelect/AddChildSelect';
import { Margin } from 'styled-components-spacing';

const propTypes = {
  numberOfAdults: PropTypes.number.isRequired,
  childrenAges: PropTypes.arrayOf(PropTypes.number).isRequired,
  roomIndex: PropTypes.number.isRequired,
  numberOfRooms: PropTypes.number.isRequired,
  eventHandlers: PropTypes.shape({
    onClickUpdateNumberOfAdults: PropTypes.func.isRequired,
    onClickUpdateNumberOfChildren: PropTypes.func.isRequired,
    onClickRemoveRoom: PropTypes.func.isRequired,
  }).isRequired,
};

const labelAdults = <FormattedMessage id='adults.label' description='Adults' defaultMessage='Adults' />;

function RoomConfiguration({
  numberOfAdults,
  childrenAges,
  roomIndex,
  numberOfRooms,
  isMoreInfantsThanAdults,
  eventHandlers: { onClickUpdateNumberOfAdults, onClickUpdateNumberOfChildren, onClickRemoveRoom },
  totalPax,
}) {
  const hasChildren = childrenAges.length > 0;
  const isLastRoom = numberOfRooms - 1 === roomIndex;

  return (
    <Root>
      <Header>
        <Title>
          <FormattedMessage
            id='selectRooms.component.roomConfiguration.title'
            description='Room {number}'
            defaultMessage='Room {number}'
            values={{ number: roomIndex + 1 }}
          />
        </Title>
        {roomIndex !== 0 && (
          <Remove onClick={onClickRemoveRoom} data-room-index={roomIndex}>
            <FormattedMessage id='removeRoom' description='Remove Room' defaultMessage='Remove Room' />
          </Remove>
        )}
      </Header>
      <Buttons hasChildren={hasChildren}>
        <IncrementDecrement
          label={labelAdults}
          value={numberOfAdults}
          decrement={{
            onClick: onClickUpdateNumberOfAdults,
            disabled: getAdultsDecrementDisabled(roomIndex, numberOfAdults, numberOfRooms, childrenAges),
          }}
          increment={{ onClick: onClickUpdateNumberOfAdults, disabled: numberOfAdults === 5 || totalPax >= 9 }}
          data={{ roomIndex }}
        />
        {hasChildren && (
          <Buttons wrapped hasChildren>
            {childrenAges.map((age, index) => (
              <ChildAgeComponent
                key={`room-${roomIndex}-child-${index}-age`}
                age={age}
                data={{ roomIndex, childIndex: index }}
                onClick={onClickUpdateNumberOfChildren}
              />
            ))}
          </Buttons>
        )}
        {childrenAges.length === 3 || totalPax >= 9 || isMoreInfantsThanAdults || !isLastRoom ? null : (
          <>
            {!hasChildren && <Margin top='2' />}
            <AddChildSelect onClickUpdateNumberOfChildren={onClickUpdateNumberOfChildren} roomIndex={roomIndex} />
          </>
        )}
      </Buttons>

      <MoreInfantsThanAdults isMoreInfantsThanAdults={isMoreInfantsThanAdults} />
    </Root>
  );
}

RoomConfiguration.propTypes = propTypes;

export default RoomConfiguration;

function getAdultsDecrementDisabled(roomNumber, numberOfAdults, numberOfRooms, childrenAges) {
  return roomNumber === 0
    ? numberOfAdults === 1
    : roomNumber !== numberOfRooms - 1
    ? (childrenAges.length === 0 && numberOfAdults <= 1) || numberOfAdults === 0
    : numberOfAdults === 0;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.grey};

  &:first-child {
    padding: 0 0 18px 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
  position: relative;
  padding: 8px 0;
`;

const Title = styled.label`
  font-size: 1.6em;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.darkGrey};

  ${mediaQueries.fromAndAbove.desktop`
    font-size: 1.4em;
  `}
`;

const Remove = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.buttons.red};
  cursor: pointer;

  &:active {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.1;
    }
  }

  > * {
    color: ${({ theme }) => theme.colors.buttons.red};
  }

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const Buttons = styled.div``;

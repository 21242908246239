import React from 'react';
import styled, { css } from 'styled-components';
import { Transition } from 'react-transition-group';
import Logo from '../logo/Logo';
import { mediaQueries } from 'styles/utils/mediaQueries';
import { slideDown, fadeIn } from 'styles/utils/animations';
import Backdrop from 'components/common/backdrop/Backdrop';
import Search from '../search/SearchSearchPage';

const transitionDuration = 300;
const transitionRule = `left ${transitionDuration}ms ease-in-out`;
const transitionStyles = {
  entering: {},
  entered: {},
  exiting: { left: '-1160px' },
  exited: { left: '-1160px' },
};

function DropdownSearch({
  isSearchPage,
  isHotelPage,
  isCheckoutPage,
  hasSearchContainer,
  showDropdownSearch,
  inputsHidden,
  logoUrl,
  airlineWhitelabel,
  transitionToCheckout,
  headerRef,
  onClickClose,
  headerHeight,
  isMobile,
  hideLogo,
  menuContent,
  isNotFoundPage,
  customSettings,
}) {
  const logoImages = [{ src: logoUrl }];

  return (
    <>
      {hideLogo && !(isHotelPage || isCheckoutPage) ? null : (
        <LogoContainer>
          <Logo
            config={{
              images: logoImages,
              container: { hideForMedia: ['below', 'l'] },
              width: '140px',
            }}
            customSettings={customSettings}
          />
        </LogoContainer>
      )}
      <Wrap>
        {showDropdownSearch && <BackdropWrap styles={{ zIndex: 0 }} onClick={onClickClose} />}
        <Container
          showDropdownSearch={showDropdownSearch}
          airlineWhitelabel={airlineWhitelabel}
          headerHeight={headerHeight}
          hideLogo={hideLogo}
        >
          {hasSearchContainer && !isNotFoundPage && (
            <Transition in={!transitionToCheckout} timeout={transitionDuration} unmountOnExit>
              {(transitionState) => (
                <SearchContainerWrap
                  showDropdownSearch={showDropdownSearch}
                  inputsHidden={inputsHidden}
                  transitionState={transitionState}
                >
                  <Search
                    isFrontpage={false}
                    isSearchPage={isSearchPage}
                    isHotelPage={isHotelPage}
                    inputsHidden={inputsHidden}
                    headerRef={headerRef}
                    onClickClose={onClickClose}
                    isMobile={isMobile}
                    menuContent={menuContent}
                  />
                </SearchContainerWrap>
              )}
            </Transition>
          )}
        </Container>
      </Wrap>
    </>
  );
}

export default DropdownSearch;

const animationRule = css`
  animation: 400ms 250ms ${slideDown(95)} ease-out forwards, ${fadeIn} 1ms 400ms linear forwards;
`;

const Wrap = styled.div`
  width: 100%;
  position: relative;
  height: 100%;

  ${mediaQueries.fromAndBelow.desktop` 
   position: absolute;
   height: 100%;
   width: 100%;
   top: 0;
`}
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  > div.fresnel-container {
    display: flex;
    height: 100%;
    z-index: 2;
  }

  ${mediaQueries.fromAndAbove.desktop`
    height: 100%;
  `}

  ${mediaQueries.fromAndBelow.desktop`
    position: absolute;
    bottom: ${({ headerHeight }) => headerHeight}px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background.greyV3};
    opacity: 0;
    transition: all 0.2s;
    border-radius: 0px 0px 26px 26px;
    padding: 13px 18px 18px 18px;
    height: auto;

    ${({ showDropdownSearch }) =>
      showDropdownSearch &&
      `
      top: 0px;
      bottom: unset;
      z-index: 99;
    `}

     ${({ showDropdownSearch }) => showDropdownSearch && animationRule};
  `}
`;

const SearchContainerWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;

  ${mediaQueries.fromAndAbove.desktop`
    position: relative;
    ${({ transitionState }) => transitionStyles[transitionState]};
    transition: ${transitionRule};
  `};

  ${mediaQueries.fromAndBelow.desktop`
    display: ${({ showDropdownSearch }) => (showDropdownSearch ? 'flex' : 'none')};
  `}
`;

const BackdropWrap = styled(Backdrop)`
  ${mediaQueries.fromAndAbove.desktop`
    display:none;
  `}
`;

const LogoContainer = styled.div`
  position: absolute;
  height: 100%;
  align-content: center;
  max-height: 70px;
  cursor: pointer;
  z-index: 1;

  ${mediaQueries.below.l`
    display: none;
  `}
`;

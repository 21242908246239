import React from 'react';
import styled from 'styled-components';
import ScrollIndicator from 'components/indicators/scrollIndicator/ScrollIndicatorComponent';
import DestinationsStage from '../common/DestinationsStageComponent';
import truncate from 'styles/utils/truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function StageCountriesComponent({
  countries,
  highlightedCountries,
  handleOnClickCountry,
  hotels,
  isDestinationOpen,
  isFiltering,
}) {
  return (
    <Root isDestinationOpen={isDestinationOpen}>
      <StageWrap>
        {highlightedCountries &&
          highlightedCountries.map(({ value, label, disabled }) => (
            <OptionWrap
              key={value}
              disabled={disabled}
              onClick={handleOnClickCountry}
              data-country-value={value}
              data-disabled={disabled}
              isFiltering={isFiltering}
            >
              <OptionLabel disabled={disabled} isFiltering={isFiltering}>
                <OptionFlag icon='flag-alt' />
                {label}
              </OptionLabel>
            </OptionWrap>
          ))}
      </StageWrap>
      <StageWrap>
        {countries.map(({ value, label, disabled }) => (
          <OptionWrap
            key={value}
            disabled={disabled}
            onClick={handleOnClickCountry}
            data-country-value={value}
            data-disabled={disabled}
            isFiltering={isFiltering}
          >
            <OptionLabel disabled={disabled} isFiltering={isFiltering}>
              <OptionFlag icon='flag-alt' />
              {label}
            </OptionLabel>
          </OptionWrap>
        ))}
      </StageWrap>
      {countries.length > 10 && hotels.length === 0 && (
        <ScrollIndicatorWrap>
          <ScrollIndicator />
        </ScrollIndicatorWrap>
      )}
    </Root>
  );
}

export default StageCountriesComponent;

const Root = styled(DestinationsStage)`
  position: relative;
  width: ${({ isDestinationOpen }) => (isDestinationOpen ? '60%' : '100%')};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.background.light};
  box-shadow: 0px 0px 4px 1px rgb(0, 0, 0, 0.07);
`;

const StageWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionWrap = styled.div`
  ${truncate()}
  flex: 1 0 auto;
  padding: 8px 12px;
  color: ${({ disabled, theme }) => disabled && theme.colors.background.disabled};
  cursor: ${({ disabled, isFiltering }) => (isFiltering || disabled ? 'default' : 'pointer')};

  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.primary.light : 'initial')};

  &:hover {
    background-color: ${({ theme, isFiltering }) => (isFiltering ? 'transparent' : theme.colors.primary.default)};
  }
`;

const OptionFlag = styled(FontAwesomeIcon)`
  font-size: 14px;
`;

const OptionLabel = styled.label`
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: ${({ disabled, isFiltering }) => (isFiltering || disabled ? 'default' : 'pointer')};
`;

const ScrollIndicatorWrap = styled.div`
  position: sticky;
  right: 0%;
  bottom: 5px;
  top: 0;
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
  width: 20px;
`;

import React from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StringHighlight from 'components/stringHighlight/StringHighlight';

const propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

function OptionComponentHotel(props) {
  return (
    <Root>
      <components.Option {...props}>
        <Container>
          <DestinationContainer>
            <FontAwesomeIcon icon='building' />
            <Margin right='0.75' inline />
            <Destinations>
              <StringHighlight stringValue={props.data.label} highlightValue={props.selectProps.inputValue} />,
              <Margin right='0.5' inline />
              <Destination>{props.data.destination}</Destination>
            </Destinations>
          </DestinationContainer>
          {props.isSelected && <Icon icon={'check-circle'} />}
        </Container>
      </components.Option>
    </Root>
  );
}

OptionComponentHotel.propTypes = propTypes;

export default OptionComponentHotel;

const Root = styled.div`
  .styled-select__option {
    color: ${({ theme }) => theme.colors.text.darkGrey};
    background-color: initial;
    border-radius: initial;

    &--is-selected {
      background-color: ${({ theme }) => theme.colors.secondary.light};
      border-radius: 8px;
    }

    &:active,
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;

const DestinationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Destination = styled.span`
  font-style: italic;
`;

const Destinations = styled.div`
  align-items: baseline;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.inputs.radio.backgroundChecked};
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import hydrateSelectedOrigins from 'state/selectOrigins/operations/hydrateSelectedOrigins';
import updateSelectedOrigins from 'state/selectOrigins/operations/updateSelectedOrigins';
import SelectOriginsComponent from 'components/inputs/SelectOrigins/SelectOrigins';

class SelectOrigins extends Component {
  render() {
    const {
      isFrontpage,
      onFocus,
      selectOrigins,
      missingValue,
      updateSelectedOrigins,
      hydrateSelectedOrigins,
      inputValue,
      className,
      isDisabled,
    } = this.props;
    const { origins, selectedOrigins, availableOrigins, forceSingleValue } = selectOrigins;

    return (
      <SelectOriginsComponent
        origins={origins}
        selectedOrigins={selectedOrigins}
        availableOrigins={availableOrigins}
        tooltipIsVisible={missingValue}
        onFocus={onFocus}
        isFrontpage={isFrontpage}
        isMulti={!forceSingleValue}
        setSelectedOrigins={updateSelectedOrigins}
        hydrateSelectedOrigins={hydrateSelectedOrigins}
        inputValue={inputValue}
        instanceId={'select-origins-header'}
        className={className}
        isDisabled={isDisabled}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    selectOrigins: state.selectOrigins,
    missingValue: state.searchConditions.missingValues.selectOrigins,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSelectedOrigins(selectedOrigins) {
      dispatch(updateSelectedOrigins(selectedOrigins));
    },
    hydrateSelectedOrigins() {
      dispatch(hydrateSelectedOrigins());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectOrigins);

import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import MenuListMobile from 'components/inputs/select/components/menuListMobile/MenuList';
import Placeholder from './components/Placeholder';
import Option from './components/Option';
import SingleValue from './components/SingleValue';
import MultiValueLabel from './components/MultiValueLabel';
import SelectOriginsFrontpage from './SelectOriginsStyled.Frontpage';
import SelectOriginsNarrow from './SelectOriginsStyled.Narrow';
import SelectOriginsSingleValue from './SelectOriginsStyled.SingleValue';
import { deviceWidths } from 'styles/utils/mediaQueries';
import { ClearIndicator as CustomClearIndicator } from '../selectComponents/ClearIndicator';
import ValueContainer from '../selectComponents/ValueContainer';
import MenuList from './components/MenuList';
import IndicatorsContainer from '../selectComponents/IndicatorsContainer';

const propTypes = {
  originsOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOrigins: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isFrontpage: PropTypes.bool.isRequired,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  headerHeight: PropTypes.number.isRequired,
};

function SelectOriginsComponent({
  originsOptions,
  selectedOrigins,
  onChange,
  onFocus,
  onFocusMobile,
  airlineWhitelabel,
  isFrontpage,
  isHotelPage,
  isMulti,
  isDisabled,
  tooltipIsVisible,
  headerHeight,
  onCloseMenu,
  instanceId,
  className,
  inputValue,
  menuIsOpen,
  onChangeInputValue,
  onRequestClose,
}) {
  const _props = {
    instanceId,
    className,
    tooltipIsVisible,
    messageId: 'selectOrigins.missingValue',
    classNamePrefix: 'styled-select',
    options: originsOptions,
    value: selectedOrigins,
    onChange,
    onFocus,
    components: {
      Option,
      Placeholder,
      SingleValue,
      MultiValueLabel,
      ClearIndicator: isHotelPage ? null : CustomClearIndicator,
      ValueContainer: ValueContainer,
      MenuList: MenuList,
      IndicatorsContainer,
    },
    isMulti,
    isSearchable: true,
    isDisabled,
    captureMenuScroll: false,
    isOptionDisabled,
    data: {
      airlineWhitelabel,
      isFrontpage,
      headerHeight,
      onCloseMenu,
      isNarrow: !isFrontpage && isMulti,
      isCombinedFilter: isMulti,
      isMobile: false,
      isHotelPage,
      selectedCount: selectedOrigins.length,
    },
    hideSelectedOptions: false,
    backspaceRemovesValue: false,
    isClearable: true,
  };

  return (
    <MediaQuery maxWidth={deviceWidths.tablet}>
      {(matches) => {
        if (matches) {
          _props.isSearchable = false;
          _props.isClearable = !isHotelPage;
          _props.backspaceRemovesValue = false;
          _props.components.MenuList = MenuListMobile;
          _props.inputValue = inputValue;
          _props.menuIsOpen = menuIsOpen;
          _props.hideSelectedOptions = false;
          _props.data.isMobile = true;
          _props.data.title = {
            id: 'search.component.input.placeholder.origins',
            description: 'From',
            defaultMessage: 'From',
          };
          _props.data.inputPlaceholder = {
            id: 'search.component.input.placeholder.origins.input.placeholder',
            description: 'Enter city or airport',
            defaultMessage: 'Enter city or airport',
          };
          _props.eventHandlers = {
            onFocusMobile,
            onChangeInputValue,
            onRequestClose,
          };
        }

        if (isFrontpage) {
          return <SelectOriginsFrontpage {..._props} id='5e4d8bc6' />;
        }

        if (!isMulti) {
          return <SelectOriginsSingleValue {..._props} />;
        }

        return <SelectOriginsNarrow {..._props} />;
      }}
    </MediaQuery>
  );
}

SelectOriginsComponent.propTypes = propTypes;

export default SelectOriginsComponent;

function isOptionDisabled({ disabled }) {
  return disabled;
}

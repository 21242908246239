import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';

function NoOptionsComponent() {
  return (
    <Root>
      <FormattedMessage
        id={'destinations.noOptions.label'}
        description={'No options available'}
        defaultMessage={'No options available'}
      />
    </Root>
  );
}

export default NoOptionsComponent;

const Root = styled.div`
  padding: 8px 12px;
  cursor: default;
  font-style: italic;
  color: ${({ theme }) => theme.colors.primary.dark};
`;

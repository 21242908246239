import { css } from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';

export default css`
  .custom-modal {
    &.ReactModal__Overlay {
      height: 100vh;
      position: fixed;
      z-index: 999;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &--after-open {
        opacity: 1;
      }

      &--before-close {
        opacity: 0;
      }
    }

    .ReactModal__Content {
      height: 100%;
      max-height: 100vh;
      position: fixed;
      left: 0;
      right: 0;
      bottom: -100vh;
      overflow: auto;
      outline: none;
      transition: bottom 0.3s ease-out;

      &--after-open {
        bottom: 0;
      }

      &--before-close {
        bottom: -100vh;
      }

      ${mediaQueries.fromAndAbove.desktop`
        top: 64px;
        right: auto;
        bottom: 64px;
        left: 50%;
        borderRadius: 3px;
        transform: translate(-50%, 0px);
      `}
    }
  }
`;

import React, { Component } from 'react';
import abandonedCartLS from 'managers/localStorage/abandonedCart/abandonedCartLS';
import AbandonedCartsComponent from './AbandonedCartsComponent';

class AbandonedCarts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      abandonedCarts: null,
    };
  }

  componentDidMount() {
    const abandonedCartList = abandonedCartLS.get();

    if (abandonedCartList) {
      this.setState({
        abandonedCarts: abandonedCartList.map(({ uuid, hotelInfo, data }) => ({ uuid, hotelInfo, data })),
      });
    }
  }

  render() {
    const { abandonedCarts } = this.state;

    if (!abandonedCarts) {
      return null;
    }

    return <AbandonedCartsComponent abandonedCarts={abandonedCarts} />;
  }
}

export default AbandonedCarts;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setNumberOfDaysValue } from 'state/searchResultsFilters/actions';
import NumberOfDaysComponent from 'routes/_components/layout/components/header/components/filtersBar/components/filters/numberOfDays/NumberOfDaysComponent';
import styled, { keyframes } from 'styled-components';
import { PERIOD_CALENDAR } from '../../../constants/calendarSwitch';
import { FormattedMessage } from 'i18n';
import { mediaQueries } from 'styles/utils/mediaQueries';
import { updateSelectedDates } from 'state/selectDates/operations/updateSelectedDates';

function CalendarPeriodNumberOfDays({ resetInputFocus }) {
  const { calendarOption } = useSelector((state) => state.selectDates);
  const { numberOfDays } = useSelector((state) => state.searchResultsFilters);
  const isHotelPage = useSelector((state) => state.currentPageComponent.isHotelPage);

  const dispatch = useDispatch();

  const handleOnChange = (value) => {
    dispatch(setNumberOfDaysValue(value, true));
    dispatch(updateSelectedDates({ startDate: null, endDate: null }));
    resetInputFocus('startDate');
  };

  if (isHotelPage || calendarOption !== PERIOD_CALENDAR) {
    return null;
  }

  const _numberOfDays = numberOfDays.value.length !== 2 ? { ...numberOfDays, value: [4, 6] } : numberOfDays;
  return (
    <Root>
      <Group>
        <Message>
          <FormattedMessage
            id='periodCalendar.howManyDays.label'
            description={`Stay for`}
            defaultMessage={`Stay for`}
          />
        </Message>
      </Group>
      <Group>
        <NumberOfDaysComponent numberOfDays={_numberOfDays} onChange={handleOnChange} isOnCalendar={true} />
      </Group>

      <Group>
        <Message>
          <FormattedMessage
            id='periodCalendar.betweenWhichDates.label'
            description={`Between which dates:`}
            defaultMessage={`Between which dates:`}
          />
        </Message>
      </Group>
    </Root>
  );
}

export default CalendarPeriodNumberOfDays;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  animation: ${fadeIn} 0.3s ease;
  ${mediaQueries.fromAndBelow.tablet`
    font-weight:400;
    gap:27px;
    padding-top:18px;
  `};
`;

const Group = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${mediaQueries.fromAndBelow.tablet`
   flex-direction:column;
   align-items:center;
  `}
`;
const Message = styled.div`
  padding-bottom: 2px;
  font-size: 15px;
  &:last-child {
    margin-top: 1rem;
  }
  ${mediaQueries.fromAndBelow.tablet`
  &:last-child {
    margin-top: 0;
  }
  font-size:16px;
  `}
`;

import wizardSvgPathGenerator from './wizardSvgPathGenerator';

export default function getSvgProps(configuration, containerWidth) {
  const { steps, radius, tunnelHeight, strokeWidth } = configuration;
  const mX = radius + 5;
  const mY = radius * 2;
  const numberOfSteps = steps.length;
  const result = wizardSvgPathGenerator(mX, mY, radius, tunnelHeight, numberOfSteps, containerWidth);
  const svgViewBox = `0 0 ${result.pathDimensions.correctedWidth} ${radius * 2}`;

  return {
    result,
    svgViewBox,
    strokeWidth,
  };
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  result: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      highlighted: PropTypes.bool.isRequired,
    })
  ),
};

function StringHighlightComponent({ result }) {
  return (
    <Root>
      {result.map(({ value, highlighted }) =>
        !highlighted ? <Fragment key={value}>{value}</Fragment> : <b key={value}>{value}</b>
      )}
    </Root>
  );
}

StringHighlightComponent.propTypes = propTypes;

export default StringHighlightComponent;

const Root = styled.span`
  b {
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function requestCustomer(fetchingCustomer) {
  return {
    type: types.REQUEST_CUSTOMER,
    payload: {
      fetchingCustomer,
    },
  };
}

export function setFetchingPayment(fetchingPayment) {
  return {
    type: types.SET_FETCHING_PAYMENT,
    payload: {
      fetchingPayment,
    },
  };
}

export function setCustomer(customer) {
  return {
    type: types.SET_CUSTOMER,
    payload: {
      customer,
    },
  };
}

export function setPayment(payment) {
  return {
    type: types.SET_PAYMENT,
    payload: {
      payment,
    },
  };
}

export function setAndValidateInputValue(name, value, tld) {
  return {
    type: types.SET_AND_VALIDATE_INPUT_VALUE,
    payload: {
      value,
      name,
      tld,
    },
  };
}

export function setInputFieldValidity(name, validity) {
  return {
    type: types.SET_INPUT_FIELD_VALIDITY,
    payload: {
      name,
      validity,
    },
  };
}

export function resetPaymentKlarna() {
  return {
    type: types.RESET_PAYMENT_KLARNA,
  };
}

import { getOrigins } from 'httpClient/requests/selectOrigins';
import { setOrigins } from '../actions';
import { retryFetch } from 'utils/retryFetch/retryFetch';
import { retryFetchNames } from 'utils/retryFetch/constants';

export default function fetchOrigins() {
  return async (dispatch, getState) => {
    const { isFetching } = getState().selectOrigins;

    if (isFetching) {
      return;
    }

    try {
      const data = await retryFetch({
        fetchFunction: getOrigins,
        isDataValid: (value) => value.length > 0,
        fetchMethodName: retryFetchNames.getOrigins,
      });

      data && dispatch(setOrigins(data));
    } catch (error) {
      console.error(`Error on ${retryFetchNames.getOrigins}`, error);
      window.Rollbar.error(`Error on ${retryFetchNames.getOrigins}`, error);
    }
  };
}

import { HOTEL_ONLY } from 'constants/selectValues';

export default function getPackagesSearchRequestParameters(searchParameters, packageInfo, data) {
  const { selectDates, selectOrigin, selectRooms } = searchParameters;
  const { primaryHotelIds, hotDealDepartureId, currency } = data;

  const withFlight = selectOrigin.selectedOrigin ? selectOrigin.selectedOrigin.value !== HOTEL_ONLY : false;
  const departureAirport = withFlight ? selectOrigin.selectedOrigin.value : null;
  const departureDate = selectDates.selectedDates.from ? selectDates.selectedDates.from.format('YYYY-MM-DD') : null;
  const returnDate = selectDates.selectedDates.to ? selectDates.selectedDates.to.format('YYYY-MM-DD') : null;
  const rooms = selectRooms.rooms.length;

  const body = {
    departureAirport,
    departureDate,
    returnDate,
    rooms,
    primaryHotelIds,
    withFlight: withFlight.toString(),
    hotDealDepartureId,
  };

  selectRooms.rooms.forEach(({ numberOfAdults, childrenAges }, index) => {
    if (numberOfAdults) {
      body[`room${index + 1}adults`] = numberOfAdults;
    }
    if (childrenAges.length > 0) {
      body[`room${index + 1}childages`] = childrenAges.toString();
    }
  });

  if (packageInfo && packageInfo.packagePrice) {
    body.packagePrice = packageInfo.packagePrice.toString();
    body.packagePriceCurrency = currency.toUpperCase();
    body.packageRoomId = packageInfo.packageRoomId && parseInt(packageInfo.packageRoomId);
    body.packageBoardCode = packageInfo.packageBoardCode;
    body.packageHotelId = primaryHotelIds;
  }

  return body;
}

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const propTypes = {
  onClick: PropTypes.func,
};

function ButtonPrimaryComponentNorwegian({ onClick, disabled, children }) {
  return <StyledButtonPrimary onClick={onClick}>{children}</StyledButtonPrimary>;
}

ButtonPrimaryComponentNorwegian.propTypes = propTypes;

export default ButtonPrimaryComponentNorwegian;

export const StyledButtonPrimary = styled.button`
  position: relative;
  width: 100%;
  padding: 12px 16px;
  background-color: ${({ theme, disabled }) =>
    !disabled ? theme.colors.adjacent.default : theme.colors.text.disabled};
  border: 0;
  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text.white};
  cursor: pointer;
  transition: all 150ms ease-out;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: -7px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.secondary.default};
  }

  &:hover {
    transform: ${({ disabled }) => !disabled && 'translateY(-1px)'};
  }

  &:active {
    transition: all 50ms ease-in;
    transform: ${({ disabled }) => !disabled && 'translateY(-1px)'};
  }
`;

import { getOrCreateStore } from 'state/storeProvider';
import getSelectedFlightsCombinations from 'state/checkoutPage/flights/selectors/getSelectedFlightsCombinations';

export default function getSelectedFlightsObservedProps() {
  const { dispatch } = getOrCreateStore();
  const selectedFlightsCombinations = dispatch(getSelectedFlightsCombinations());

  if (
    selectedFlightsCombinations === null ||
    (selectedFlightsCombinations !== null && selectedFlightsCombinations.length === 0)
  ) {
    return null;
  }

  return selectedFlightsCombinations[0].legs
    .map(({ segments }) => segments)
    .flat()
    .map(({ carrier, flightNumber }) => ({ carrier, flightNumber }));
}

import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import MenuListMobile from 'components/inputs/select/components/menuListMobile/MenuList';
import Placeholder from './components/Placeholder';
import SingleValue from './components/singleValue/SingleValueComponent';
import MultiValueLabel from './components/MultiValueLabel';
import MenuList from './components/menuList/MenuList';
import Option from './components/option/OptionComponent';
import SelectDestinationsFrontpage from './SelectDestinationsStyled.Frontpage';
import SelectDestinationsNarrow from './SelectDestinationsStyled.Narrow';
import { deviceWidths } from 'styles/utils/mediaQueries';
import { ClearIndicator } from '../selectComponents/ClearIndicator';
import IndicatorsContainer from '../selectComponents/IndicatorsContainer';
import ValueContainer from '../selectComponents/ValueContainer';

const propTypes = {
  destinationOptions: PropTypes.shape({
    cities: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            defaultMessage: PropTypes.string.isRequired,
            values: PropTypes.object,
          }),
        ]).isRequired,
        disabled: PropTypes.bool.isRequired,
        country: PropTypes.string.isRequired,
      })
    ).isRequired,
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
      })
    ).isRequired,
    grouped: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        options: PropTypes.array,
        disabled: PropTypes.bool.isRequired,
      })
    ).isRequired,
  }).isRequired,
  selectedDestinations: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  isFrontpage: PropTypes.bool.isRequired,
  headerHeight: PropTypes.number.isRequired,
};

function SelectDestinationsComponent({
  destinationOptions,
  selectedDestinations,
  isMulti,
  tooltipIsVisible,
  onChange,
  onFocus,
  onFocusMobile,
  onInputChange,
  airlineWhitelabel,
  isFrontpage,
  headerHeight,
  instanceId,
  className,
  isDisabled,
  inputValue,
  menuIsOpen,
  onChangeInputValue,
  onRequestClose,
}) {
  const _props = {
    instanceId,
    className,
    isDisabled,
    tooltipIsVisible,
    messageId: 'selectDestinations.missingValue',
    classNamePrefix: 'styled-select',
    value: selectedDestinations,
    onChange,
    onFocus,
    onInputChange,
    components: {
      Placeholder,
      SingleValue,
      MultiValueLabel,
      MenuList,
      Option,
      ClearIndicator,
      ValueContainer: ValueContainer,
      IndicatorsContainer,
    },
    captureMenuScroll: false,
    isMulti,
    isSearchable: true,
    isOptionDisabled,
    data: {
      airlineWhitelabel,
      isFrontpage,
      headerHeight,
      isNarrow: !isFrontpage,
      isCombinedFilter: true,
      menuIsOpen,
      isMobile: false,
    },
    hideSelectedOptions: false,
    backspaceRemovesValue: false,
    isClearable: true,
  };

  return (
    <MediaQuery maxWidth={deviceWidths.tablet}>
      {(matches) => {
        if (matches) {
          _props.options = destinationOptions.grouped.concat(destinationOptions.hotels);
          _props.isSearchable = false;
          _props.isClearable = true;
          _props.backspaceRemovesValue = false;
          _props.inputValue = inputValue;
          _props.menuIsOpen = menuIsOpen;
          _props.components.MenuList = MenuListMobile;
          _props.selectedDestination = selectedDestinations[0];
          _props.hideSelectedOptions = false;
          _props.data.isMobile = true;
          _props.data.isCombinedFilter = true;
          _props.data.title = {
            id: 'search.component.input.placeholder.destinations',
            description: 'To',
            defaultMessage: 'To',
          };
          _props.data.inputPlaceholder = {
            id: 'search.component.input.placeholder.destinations.input.placeholder',
            description: 'Enter country, city or hotel',
            defaultMessage: 'Enter country, city or hotel',
          };
          _props.eventHandlers = {
            onFocusMobile,
            onChangeInputValue,
            onRequestClose,
          };
        }

        if (!matches) {
          _props.options = destinationOptions.cities.concat(destinationOptions.hotels);
          _props.data.countries = destinationOptions.countries;
          _props.data.highlightedCountries = destinationOptions.highlightedCountries;
        }

        if (isFrontpage) {
          return <SelectDestinationsFrontpage {..._props}  id='a7b9f8d1' />;
        }

        return <SelectDestinationsNarrow {..._props} />;
      }}
    </MediaQuery>
  );
}

SelectDestinationsComponent.propTypes = propTypes;

export default SelectDestinationsComponent;

function isOptionDisabled({ disabled }) {
  return disabled;
}

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { resetActiveAction } from 'state/actionBar/actions';
import HeaderMenuComponent from './HeaderMenuComponent';
import HeaderMenuComponentMobile from './HeaderMenuComponent.Mobile';

class HeaderMenu extends Component {
  render() {
    const {
      menuContent,
      isHamburger,
      isLightBackground,
      actionBar,
      transitionToCheckout,
      showForMedia,
      airlineWhitelabel,
      thinMenu,
      isFrontpage,
      isHotelPage,
      isCheckoutPage,
    } = this.props;

    const isActive = actionBar.activeAction === actionBar.actions[0].id;

    return (
      <Fragment>
        <HeaderMenuComponent
          menuContent={menuContent}
          isHamburger={isHamburger}
          isLightBackground={isLightBackground}
          transitionToCheckout={transitionToCheckout}
          showForMedia={showForMedia}
          airlineWhitelabel={airlineWhitelabel}
          thinMenu={thinMenu}
          isFrontpage={isFrontpage}
          isHotelPage={isHotelPage}
          isCheckoutPage={isCheckoutPage}
        />
        <HeaderMenuComponentMobile
          menuContent={menuContent}
          isActive={isActive}
          onClickClose={this.props.resetActiveAction}
          isFrontpage={isFrontpage}
          isHotelPage={isHotelPage}
          isCheckoutPage={isCheckoutPage}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    actionBar: state.actionBar,
    thinMenu: state.settings.value.customSettings && state.settings.value.customSettings.thinMenu,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetActiveAction() {
      dispatch(resetActiveAction());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);

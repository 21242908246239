import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuListComponent from './MenuListComponent';
import getNumberOfAdultsAndChildren from 'state/selectRooms/selectors/getNumberOfAdultsAndChildren';
class MenuList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
    this.handleOnRequestClose = this.handleOnRequestClose.bind(this);
  }

  componentDidMount() {
    this.setState({ open: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { open } = this.state;
    const { open: prevOpen } = prevState;

    if (!open && prevOpen) {
      setTimeout(() => {
        document.activeElement.blur();
      }, 300);
    }
  }

  componentWillUnmount() {
    this.setState({ open: false });
  }

  handleOnRequestClose() {
    const {
      eventHandlers: { handleOnCloseClick },
    } = this.props;

    this.setState({ open: false });
    handleOnCloseClick && handleOnCloseClick();
  }

  render() {
    const { rooms, eventHandlers, filtersBarHeight, selectRooms, isSearchPage } = this.props;
    const { open } = this.state;
    const { numberOfAdults, numberOfChildren } = getNumberOfAdultsAndChildren(selectRooms.rooms);

    const _filtersBarHeight = isSearchPage ? filtersBarHeight : 0;

    return (
      <MenuListComponent
        rooms={rooms}
        eventHandlers={eventHandlers}
        open={open}
        filtersBarHeight={_filtersBarHeight}
        onRequestClose={this.handleOnRequestClose}
        numberOfAdults={numberOfAdults}
        numberOfChildren={numberOfChildren}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    filtersBarHeight: state.refs.filtersBarHeight,
    selectRooms: state.selectRooms,
    isSearchPage: state.currentPageComponent.isSearchResults,
  };
}

export default connect(mapStateToProps)(MenuList);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import {
  FOOTER_MENU_COL_1 as MENU_NAME_FOOTER_MENU_COL_1,
  FOOTER_MENU_COL_2 as MENU_NAME_FOOTER_MENU_COL_2,
} from 'state/menus/constants/menuNames';
import fetchMenu from 'state/menus/operations/fetchMenu';
import FooterComponent from './FooterComponent';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.hideFooter = props.modalIframe && props.modalIframe === '1';
  }

  componentDidMount() {
    this.checkAndFetchMenu();
  }

  componentDidUpdate() {
    this.checkAndFetchMenu();
  }

  checkAndFetchMenu() {
    const { currentPageComponent, menus, selectedCountry, airlineWhitelabel, fetchMenu } = this.props;
    const { isCountrySelection } = currentPageComponent;

    if (!isCountrySelection && ((airlineWhitelabel && !_isEmpty(selectedCountry)) || !airlineWhitelabel)) {
      if (!menus[MENU_NAME_FOOTER_MENU_COL_1].value.length > 0) {
        fetchMenu(MENU_NAME_FOOTER_MENU_COL_1);
      }

      if (!menus[MENU_NAME_FOOTER_MENU_COL_2].value.length > 0) {
        fetchMenu(MENU_NAME_FOOTER_MENU_COL_2);
      }
    }
  }

  render() {
    const {
      currentPageComponent,
      menus,
      phoneNumber,
      openingHours,
      isAffiliate,
      companyFullName,
      logoWhiteUrl,
      hostname,
      checkoutOnlyWhitelabel,
      isVolaRo,
      isHolidayPirates,
    } = this.props;
    const {
      isSearchResults,
      isCountrySelection,
      isAccountLogin,
      isAccountBooking,
      isAgentLogin,
      isThankYou,
      isSuccessBooking,
    } = currentPageComponent;

    if (
      this.hideFooter ||
      isSearchResults ||
      isCountrySelection ||
      isAccountLogin ||
      isAccountBooking ||
      isAgentLogin ||
      isThankYou ||
      isSuccessBooking
    ) {
      return null;
    }

    return (
      <FooterComponent
        menuContent={menus[MENU_NAME_FOOTER_MENU_COL_1].value}
        menuContent2={menus[MENU_NAME_FOOTER_MENU_COL_2].value}
        phoneNumber={phoneNumber}
        openingHours={openingHours}
        isAffiliate={isAffiliate}
        companyFullName={companyFullName}
        logoWhiteUrl={logoWhiteUrl}
        hostname={hostname}
        checkoutOnlyWhitelabel={checkoutOnlyWhitelabel}
        isVolaRo={isVolaRo}
        isHolidayPirates={isHolidayPirates}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    currentPageComponent: state.currentPageComponent,
    menus: state.menus,
    selectedCountry: state.routes.selectedCountry,
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    hostname: state.settings.value.hostname,
    phoneNumber: state.settings.value.customerService.phoneNumber,
    openingHours: state.settings.value.openingHours,
    isAffiliate: state.settings.value.affiliateId !== null,
    companyFullName: state.settings.value.company.fullName,
    logoWhiteUrl: state.settings.value.style.logoWhiteUrl,
    modalIframe: state.urlSearchQuery.value.modal_iframe,
    translationsLoaded: state.flags.translationsLoaded,
    checkoutOnlyWhitelabel: state.settings.value.checkoutOnlyWhitelabel,
    isVolaRo: state.settings.value.websiteId === 64,
    isHolidayPirates: state.settings.value.customSettings && state.settings.value.customSettings.isHolidayPirates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchMenu(menuName) {
      dispatch(fetchMenu(menuName));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

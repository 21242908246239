import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IN_PROGRESS as FETCHING_STATUS_IN_PROGRESS } from 'constants/status/fetchingStatus';
import NoOptionsComponent from './NoOptionsComponent';

class NoOptions extends Component {
  render() {
    const { hotelQuerySearchFetching } = this.props;

    if (hotelQuerySearchFetching) {
      return null;
    }

    return <NoOptionsComponent />;
  }
}

function mapStateToProps(state) {
  return {
    hotelQuerySearchFetching: state.hotelQuerySearch.fetching === FETCHING_STATUS_IN_PROGRESS,
  };
}

export default connect(mapStateToProps)(NoOptions);

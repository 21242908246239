'use strict';
Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'car-door';
var width = 20;
var height = 16.6;
var ligatures = [];
var unicode = 'tf001';
var svgPathData =
  'M20,1.9c0,0.2,0,0.4,0,0.5c0,4.7,0,9.4,0,14.1c0,1.4-0.6,2-2,2c-4.6,0-9.3,0-13.9,0c-1.4,0-2.2-0.6-2.6-1.9 c-0.5-1.5-0.9-3.1-1.4-4.6C-0.2,10.8,0,9.7,0.9,8.8c1.5-1.7,3-3.4,4.5-5C6.5,2.6,8,1.9,9.6,1.9c3.4,0,6.8,0,10.1,0 C19.8,1.9,19.9,1.9,20,1.9z M18.3,3.6c-0.1,0-0.2,0-0.2,0c-2.8,0-5.7,0-8.5,0c-1.2,0-2.2,0.5-3,1.4C5.4,6.3,4.2,7.6,3,8.9 C2.9,9,2.8,9.1,2.7,9.3c0.2,0,0.2,0,0.3,0c5,0,10,0,15,0c0.3,0,0.4-0.1,0.4-0.4c0-1.7,0-3.3,0-5C18.3,3.8,18.3,3.7,18.3,3.6z M15,11.1c0,0.6,0,1.1,0,1.6c1.1,0,2.2,0,3.3,0c0-0.5,0-1.1,0-1.6C17.2,11.1,16.1,11.1,15,11.1z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

exports.tripxFaCarDoor = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = ligatures;
exports.unicode = unicode;
exports.svgPathData = svgPathData;

import { css } from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';
import { CLASS_NAME_PANEL_LEFT, CLASS_NAME_PANEL_RIGHT } from './constants/modalClassNames';

export default css`
  .side-panel-modal-overlay {
    &.ReactModal__Overlay {
      height: 100vh;
      position: fixed;
      z-index: 4;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background-color: rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &--after-open {
        opacity: 1;
      }

      &--before-close {
        opacity: 0;
      }
    }
  }

  .side-panel-modal {
    &.ReactModal__Content {
      max-height: 100vh;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -100vh;
      overflow: auto;
      outline: none;
      transition: bottom 0.3s ease-out;

      &--after-open {
        bottom: 0;
      }

      &--before-close {
        bottom: -100vh;
      }

      ${mediaQueries.fromAndAbove.desktop`
        height: 100vh;
        max-height: unset;
        left: unset;
        right: unset;
        transition: unset;
        top: 0;
        bottom: 0;

        &--after-open {
          bottom: unset;
        }
    
        &--before-close {
          bottom: unset;
        }
      `}
    }

    ${mediaQueries.fromAndAbove.desktop`
      &.${CLASS_NAME_PANEL_LEFT} {
        &.ReactModal__Content {
          left: -100%;
          transition: left 0.3s ease-in-out;

          &--after-open {
            left: 0;
          }

          &--before-close {
            left: -100%;
          }
        }
      }

      &.${CLASS_NAME_PANEL_RIGHT} {
        &.ReactModal__Content {
          right: -100%;
          transition: right 0.3s ease-in-out;

          &--after-open {
            right: 0;
          }

          &--before-close {
            right: -100%;
          }
        }
      }
    `}
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'i18n';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  path: PropTypes.string.isRequired,
  boxPath: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  dropletWidth: PropTypes.number.isRequired,
  svgViewBox: PropTypes.string.isRequired,
  currentStep: PropTypes.number.isRequired,
};

function WizardComponent({
  rootRef,
  path,
  boxPath,
  pathDimensions,
  svgViewBox,
  strokeWidth,
  foregroundWidth,
  offset,
  height,
  steps,
  currentStep,
  dropletWidth,
  hideForMedia,
  hideLabels,
  colors,
  onClickStep,
}) {
  return (
    <Root ref={(ref) => (rootRef.current = ref)} hideForMedia={hideForMedia}>
      <SliderWrapper>
        <SliderBackground calculatedHeight={height} calculatedOffset={offset} />
        <SliderForeground calculatedHeight={height} calculatedOffset={offset} foregroundWidth={foregroundWidth} />
        <svg width={'100%'} height={pathDimensions.correctedHeight} viewBox={svgViewBox}>
          <path stroke='none' strokeWidth='0' fill='#FFFFFF' d={boxPath} />
          <path stroke={colors.background.greyLight} strokeWidth={strokeWidth} fill='none' d={path} />
        </svg>
        <SliderNumbers calculatedHeight={height} calculatedOffset={offset}>
          {steps.map(({ id, disabled }, index, array) => (
            <SliderNumber
              key={`number-${id}`}
              calcWidth={dropletWidth}
              selected={currentStep >= index}
              disabled={disabled}
              data-step-id={id}
              data-step-disabled={disabled}
              onClick={onClickStep}
            >
              {index + 1}
              <SliderNumberLabel
                key={`label-${id}`}
                first={index === 0}
                last={index === array.length - 1}
                selected={currentStep === index}
                hideLabels={hideLabels}
              >
                <FormattedMessage
                  id={`checkoutWizard.stepLabel.${id}`}
                  description={`Label for ${id}`}
                  defaultMessage={id}
                />
              </SliderNumberLabel>
            </SliderNumber>
          ))}
        </SliderNumbers>
      </SliderWrapper>
    </Root>
  );
}

WizardComponent.propTypes = propTypes;

export default WizardComponent;

const Root = styled.div`
  width: 990px;
  ${({ hideForMedia }) =>
    hideForMedia &&
    css`
      ${mediaQueries[hideForMedia[0]][hideForMedia[1]]`
      display: none;
    `}
    `}
`;

const SliderWrapper = styled.div`
  position: relative;

  svg {
    position: relative;
  }
`;

const SliderBackground = styled.div`
  position: absolute;
  top: ${({ calculatedOffset }) => calculatedOffset}px;
  width: 100%;
  height: ${({ calculatedHeight }) => calculatedHeight}px;
  background-color: ${({ theme }) => theme.colors.background.greyLight};
`;

const SliderForeground = styled.div`
  position: absolute;
  top: ${({ calculatedOffset }) => calculatedOffset}px;
  width: ${({ foregroundWidth }) => foregroundWidth};
  height: ${({ calculatedHeight }) => calculatedHeight}px;
  background-color: ${({ theme }) => theme.colors.primary.dark};
  background-color: ${({ theme }) =>
    theme.colors.wizard.foreground ? theme.colors.wizard.foreground : theme.colors.primary.dark};
  border-radius: 40px;
  transition: width 0.3s;
`;

const SliderNumbers = styled.div`
  ${flexbox({
    justifyContent: 'space-between',
    alignItems: 'center',
  })}
  position: absolute;
  top: ${({ calculatedOffset }) => calculatedOffset}px;
  height: ${({ calculatedHeight }) => calculatedHeight}px;
  width: 100%;
  user-select: none;

  ${mediaQueries.fromAndAbove.tablet`
    padding: 0 2px;
  `}

  ${mediaQueries.fromAndAbove.desktop`
    padding: 0 4px;
  `}
`;

const SliderNumber = styled.span`
  display: inline-block;
  width: ${({ calcWidth }) => calcWidth}px;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  color: ${({ selected, theme }) => (selected ? theme.colors.text.white : theme.colors.primary.dark)};
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  transition: color 0.3s;
`;

const SliderNumberLabel = styled.div`
  display: ${({ hideLabels }) => (hideLabels ? 'none' : 'block')};
  width: 89px;
  position: absolute;
  bottom: -15px;
  left: 0;
  transform: translate(-25%, 100%);
  font-size: 16px;
  font-weight: ${({ selected }) => (selected ? 'bold' : '500')};
  color: ${({ theme }) => theme.colors.primary.dark};

  ${mediaQueries.fromAndBelow.tablet`
    max-width: 90px;
  `}
`;

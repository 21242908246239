import React from 'react';
import OptionComponentDestination from './OptionComponentDestination';
import OptionComponentHotel from './OptionComponentHotel';

function OptionComponent(props) {
  if (props.data.hotel) {
    return <OptionComponentHotel {...props} />;
  }

  return <OptionComponentDestination {...props} />;
}

export default OptionComponent;

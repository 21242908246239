import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFetchingPayment(fetchingPayment) {
  return {
    type: types.SET_FETCHING_PAYMENT,
    payload: {
      fetchingPayment,
    },
  };
}

export function setPaymentData(paymentData) {
  return {
    type: types.SET_PAYMENT_DATA,
    payload: {
      paymentData,
    },
  };
}

export function setPaymentResponse(paymentResponse) {
  return {
    type: types.SET_PAYMENT_RESPONSE,
    payload: {
      paymentResponse,
    },
  };
}

export function setAdditionalDetails(additionalDetails) {
  return {
    type: types.SET_ADDITIONAL_DETAILS,
    payload: {
      additionalDetails,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

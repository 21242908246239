import * as fetchingStatus from 'constants/status/fetchingStatus';
import { setHybridCheckoutFetching } from '../actions';
import { toast } from 'react-toastify';
import React from 'react';
import HybridCheckoutFailed from 'routes/checkoutPage/components/loader/toasts/HybridCheckoutFailed';
import { hybridCheckout } from 'httpClient/requests/checkoutPage/hybridCheckout/hybridCheckout';
import getBookingVerifyRequestParameters from 'state/checkoutPage/booking/transforms/getBookingVerifyRequestParameters';
import generateMockTravelers from 'state/checkoutPage/travelers/selectors/generateMockTravelers';
import getSelectedHotelsRoomsWithSelectedOption from 'state/checkoutPage/packagesSearch/selectors/getSelectedHotelsRoomsWithSelectedOption';
import getSelectedFlightsCombinations from 'state/checkoutPage/flights/selectors/getSelectedFlightsCombinations';
import { INFO as SEVERITY_TRIPX_API_INFO } from 'constants/severityTripXAPI';

export default function hybridRedirect() {
  return async (dispatch, getState) => {
    const { fetching } = getState().checkoutPageWizard.hybridCheckoutFlow;

    if (fetching === fetchingStatus.IN_PROGRESS) {
      return;
    }

    const {
      checkoutPagePackagesSearch,
      checkoutPageTravelSummary,
      checkoutPageTransfers,
      checkoutPagePaymentOptions,
      checkoutPagePromotionCode,
      deal,
    } = getState();

    const body = getHybridCheckoutData(
      dispatch,
      checkoutPagePackagesSearch,
      checkoutPageTravelSummary,
      checkoutPagePaymentOptions,
      checkoutPagePromotionCode,
      deal,
      checkoutPageTransfers
    );

    dispatch(setHybridCheckoutFetching(fetchingStatus.IN_PROGRESS));

    try {
      const response = await hybridCheckout(body);

      dispatch(setHybridCheckoutFetching(fetchingStatus.SUCCEEDED));

      window.location.href = response.data.redirectUrl;
    } catch (error) {
      toast.info(<HybridCheckoutFailed />);
      dispatch(setHybridCheckoutFetching(fetchingStatus.FAILED));
      console.error(`Error on hybrid redirect:${error}`);
    }
  };
}

function getHybridCheckoutData(
  dispatch,
  checkoutPagePackagesSearch,
  checkoutPageTravelSummary,
  checkoutPagePaymentOptions,
  checkoutPagePromotionCode,
  deal,
  checkoutPageTransfers
) {
  const selectedRooms = dispatch(getSelectedHotelsRoomsWithSelectedOption());
  const checkOutDate = checkoutPagePackagesSearch.value.hotels[0].checkIn;
  const travelers = generateMockTravelers(selectedRooms, checkOutDate);
  const customerDetails = null;
  const { selectedHotels, selectedFlights, selectedFlightsExtras, selectedOtherExtras } = checkoutPageTravelSummary;
  const selectedFlightsCombinations = dispatch(getSelectedFlightsCombinations());
  const paymentOptions = checkoutPagePaymentOptions.value;
  const selectedPaymentOption = checkoutPagePaymentOptions.selectedPaymentOption;
  const promotionCode =
    checkoutPagePromotionCode.validity && checkoutPagePromotionCode.validity.severity === SEVERITY_TRIPX_API_INFO
      ? checkoutPagePromotionCode.promotionCode
      : null;
  const hotDealDepartureId = deal.selectedDepartureId;

  const verifyRequest = getBookingVerifyRequestParameters(
    checkoutPagePackagesSearch.value.searchToken,
    checkoutPagePackagesSearch.value.hotelOnly,
    customerDetails,
    travelers,
    selectedHotels,
    selectedRooms,
    selectedFlights,
    selectedFlightsExtras,
    selectedOtherExtras,
    selectedFlightsCombinations,
    paymentOptions,
    selectedPaymentOption,
    checkoutPageTransfers.transfers,
    promotionCode,
    undefined,
    undefined,
    hotDealDepartureId
  );

  const backUrl = typeof window !== 'undefined' && window.location.href;

  return { verifyRequest, token: checkoutPagePackagesSearch.value.searchToken, backUrl, sessionTime: 1800 };
}

import { css } from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';

const borderRadius = '0.25rem';

export default css`
  .DateRangePicker {
    &_input {
      height: 100%;

      &__focused {
        border-bottom: 2px solid transparent;
      }
    }

    #selectDateFromHeader,
    #selectDateEndHeader {
      padding-left: 18px;
      font-size: 1.8em;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.text.darkGrey};
    }

    & #selectDateFromHeader {
      border-bottom-left-radius: ${borderRadius};
    }
    &_closeButton {
      display: none;
    }
  }

  ${mediaQueries.fromAndBelow.tablet`
    .DateRangePicker_picker {
      z-index: 10;
      height:84vh;
      height:84svh;
      overflow: unset;

      .DayPicker_transitionContainer__verticalScrollable{
        position:relative;
        overflow:scroll;
        padding-top:0px;
        height:70vh;
        height:70svh;
      }
      .DayPicker_weekHeaders{
       height:43px;
      }
      .DayPicker_weekHeader__verticalScrollable{
        border-bottom:1px solid ${({ theme }) => theme.colors.background.greyV3};
        padding: 13px 13px 0 !important;
      }
      .DayPicker_weekHeader_li {
        font-size: 18px;
        font-weight: 600;
        height:34px;
        color: ${({ theme }) => theme.colors.text.darkGrey};
      }

      .DayPickerNavigation_button__disabled{
        border:none;
      }
      .DayPicker{
        &__withBorder {
         box-shadow:none;
        }
      }

      .CalendarMonth {
        padding: 16px 13px !important;
      }

      .CalendarDay {
        position: relative;
        vertical-align: middle;
        border-color: transparent;
        font-size: 18px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.text.darkGrey};

        &:focus {
          outline: none;
        }

        &__blocked_calendar {
          background: #ffffff;
          border: 1px solid transparent;
          color: ${({ theme }) => theme.colors.text.disabled};
        }

        &__highlighted_calendar {
          background: white;
          &:after {
            content: '';
            position: absolute;
            right: 2px;
            top: 2px;
            width: 5px;
            height: 5px;
            background: ${({ theme }) => theme.colors.secondary.default};
            border-radius: 50%;
          }
        }

        &__hovered_span,
          __hovered_span: hover {
          background: ${(props) => props.theme.colors.primary.light};
          color: ${(props) => props.theme.colors.primary.dark};
        }

        &__selected,
          __selected: active,
          __selected: hover {
          background: ${(props) => props.theme.colors.primary.dark};
          color: ${(props) => props.theme.colors.primary.light};
        }

        &__selected,
        __selected_start,
        __selected_end {
          background: ${({ theme }) => theme.colors.primary.dark} !important;
          color: ${({ theme }) => theme.colors.primary.light} !important;

          span {
            &.flexible_calendar {
              background: ${({ theme }) => theme.colors.primary.dark} !important;
              color: ${({ theme }) => theme.colors.primary.light} !important;
            }
          }
        }
        &__selected_start {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &__selected_end {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &__selected_span {
          background: ${(props) => props.theme.colors.primary.light};
          color: ${(props) => props.theme.colors.primary.dark};
        }

        &__blocked_out_of_range,
        __blocked_out_of_range:active,
        __blocked_out_of_range:hover {
          background: ${(props) => props.theme.colors.background.light};
          border: 1px solid transparent;
          color: ${(props) => props.theme.colors.text.disabled};
        }

        &__default:hover {
          background: ${(props) => props.theme.colors.background.disabled};
          border: 1px solid transparent;
          color: ${(props) => props.theme.colors.primary.dark};
        }

        span {
          &.flexible_calendar {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(
              135deg,
              ${({ theme }) => theme.colors.primary.default} 25%,
              ${({ theme }) => theme.colors.primary.light} 25%,
              ${({ theme }) => theme.colors.primary.default} 50%,
              ${({ theme }) => theme.colors.primary.light} 50%,
              ${({ theme }) => theme.colors.primary.default} 75%,
              ${({ theme }) => theme.colors.primary.light} 75%,
              ${({ theme }) => theme.colors.primary.default} 100%
            );
            background-size: 8px 8px;
            line-height: 2;
          }
        }
      }
    }
      
    .DateRangePicker {
      div {
        display: flex
      }

      .DateRangePickerInput {
        width: 100%

        input {
          color: ${({ theme }) => theme.colors.text.darkGrey};
          position: relative;
          bottom: 2px;
          margin-top: 2px;
        }
      }

      .DateInput {
        border-bottom-left-radius: 0;
        width: 100%
      }
     }
    }
  `};
`;

import React from 'react';
import styled from 'styled-components';
import Grid from 'styled-components-grid';
import { Margin } from 'styled-components-spacing';
import { FormattedMessage } from 'i18n';
import { mediaQueries } from 'styles/utils/mediaQueries';

function ContactInfoComponent({ phoneNumber, openingHours }) {
  const { line1, line2, line3 } = openingHours;
  return (
    <Grid.Unit size={{ phone: 6 / 12, desktop: 3 / 12 }}>
      <Margin top={{ tiny: 2, desktop: 4 }}>
        <div>
          <Phone>
            <a href={`tel:${phoneNumber}`}>
              <FormattedMessage
                id='footer.component.contact.phone'
                description='{phoneNumber}'
                defaultMessage='{phoneNumber}'
                values={{
                  phoneNumber,
                }}
              />
            </a>
          </Phone>
          <Hours>
            <li>
              <span>{line1.label}</span>
              <b>{line1.value}</b>
            </li>
            <li>
              <span>{line2.label}</span>
              <b>{line2.value}</b>
            </li>
            <li>
              <span>{line3.label}</span>
              <b>{line3.value}</b>
            </li>
          </Hours>
        </div>
      </Margin>
    </Grid.Unit>
  );
}

export default ContactInfoComponent;

const Phone = styled.div`
  padding: 32px 16px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0 0;
  font-size: 22px;
  font-weight: 700;
  text-align: center;

  ${mediaQueries.fromAndBelow.tablet`
    padding: 2rem 1rem;
    font-size: 20px;
  `}

  ${mediaQueries.fromAndBelow.phone`
    font-size: 22px;
  `}
`;

const Hours = styled.ul`
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 0 0 8px 8px;

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-size: 20px !important;
  }
`;

import React from 'react';
import {
  LogoBasic,
  LogoBasicNorwegian,
  LogoBasicRSP,
  LogoBasicCyprus,
  LogoBasicLarge,
  LogoBasicNovaturas,
} from './LogoComponents';
import {
  NORWEGIAN_HOLIDAYS as NORWEGIAN_HOLIDAYS_AIRLINE,
  CY_HOLIDAY_DEALS as CY_HOLIDAY_DEALS_AIRLINE,
} from 'constants/airlines';
import websiteIds from 'constants/website.config';

export default function getLogoImage(airlineWhitelabel, src, padding, isFrontpage, mobile, websiteId, customSettings) {
  const props = { src, alt: 'logo', padding, isFrontpage, websiteId };

  if (airlineWhitelabel) {
    switch (airlineWhitelabel.hostname) {
      case NORWEGIAN_HOLIDAYS_AIRLINE:
        return <LogoBasicNorwegian key={src} {...props} />;
      case CY_HOLIDAY_DEALS_AIRLINE:
        return <LogoBasicCyprus key={src} {...props} />;
      default:
        break;
    }
  }

  if (websiteId === websiteIds['Amano Holidays'].ID || websiteId === websiteIds['Offerilla Holidays'].ID) {
    return <LogoBasicLarge key={src} {...props} />;
  }

  if (websiteId === websiteIds['Rejsespejder ApS'].ID) {
    return <LogoBasicRSP key={src} {...props} />;
  }

  if (customSettings && customSettings.isNovaturas) {
    return <LogoBasicNovaturas key={src} {...props} />;
  }

  return <LogoBasic key={src} {...props} />;
}

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFetching(fetching) {
  return {
    type: types.SET_FETCHING,
    payload: {
      fetching,
    },
  };
}

export function setPackagesSearchValue(value) {
  return {
    type: types.SET_PACKAGES_SEARCH_VALUE,
    payload: {
      value,
    },
  };
}

export function setValidation(validation) {
  return {
    type: types.SET_VALIDATION,
    payload: {
      validation,
    },
  };
}

export function setHotelDestinationCode(hotelId, destinationCode) {
  return {
    type: types.SET_HOTEL_DESTINATION_CODE,
    payload: {
      hotelId,
      destinationCode,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

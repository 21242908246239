import fetchCalendarHighlightsForOriginAndDestination from './fetchCalendarHighlightsForOriginAndDestination';
import evalUpdateCalendarSpecialDates from './evalUpdateCalendarSpecialDates';

export default function evalFetchCorrectCalendar() {
  return (dispatch, getState) => {
    const selectedOriginFromHotelTemplates = getState().templatesHotelSearchParameters.selectOrigin.selectedOrigin;
    let { selectedOrigins } = getState().selectOrigins;
    const { isHotelPage } = getState().currentPageComponent;
    const { selectedDestinations } = getState().selectDestinations;

    if (isHotelPage && selectedOriginFromHotelTemplates) {
      selectedOrigins = [selectedOriginFromHotelTemplates];
    }

    const selectedOriginsValues = selectedOrigins.map(({ value }) => value);
    const selectedDestinationsValues = selectedDestinations.map(
      ({ value, hotel, destinationCode, allForCountry, destinations }) =>
        !hotel ? (allForCountry ? destinations.map((d) => d.value).join() : value) : destinationCode
    );

    const shouldFetch = selectedOriginsValues.length !== 0 && selectedDestinationsValues.length !== 0;

    if (shouldFetch) {
      dispatch(
        fetchCalendarHighlightsForOriginAndDestination(selectedOriginsValues.join(), selectedDestinationsValues.join())
      );
    } else {
      dispatch(evalUpdateCalendarSpecialDates());
    }
  };
}

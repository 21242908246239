import React from 'react';
import styled from 'styled-components';
import Tooltip from 'rc-tooltip';
import { FormattedMessage } from 'i18n';

function InputTooltipComponent({ children, visible, messageId }) {
  return (
    <Tooltip
      visible={visible}
      placement='bottom'
      trigger={[]}
      mouseLeaveDelay={0}
      destroyTooltipOnHide={true}
      prefixCls={'input-tooltip'}
      overlay={<InputTooltipOverlay messageId={messageId} />}
      align={{
        offset: [0, -5],
      }}
    >
      {children}
    </Tooltip>
  );
}

export default InputTooltipComponent;

function InputTooltipOverlay({ messageId }) {
  return (
    <Root>
      <FormattedMessage id={`tooltip.input.${messageId}`} description={`tooltip.input.${messageId}`} />
    </Root>
  );
}

const Root = styled.div`
  padding: 9px;
  background-color: ${({ theme }) => theme.colors.adjacent.default};
  border: none;
  border-radius: 3px;
  text-align: center;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.white};
`;

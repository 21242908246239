import React from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import ScrollIndicator from 'components/indicators/scrollIndicator/ScrollIndicatorComponent';
import Spinner from 'components/spinners/InfiniteCircleDots';
import { FormattedMessage } from 'i18n';

function StageHotelResultsComponent({
  fetching,
  results,
  countriesAndDestinationsVisible,
  showCountriesAndDestinations,
}) {
  const scrollIndicatorVisible = countriesAndDestinationsVisible ? results.length > 5 : results.length > 12;

  return (
    <Root
      scrollIndicatorVisible={scrollIndicatorVisible}
      countriesAndDestinationsVisible={countriesAndDestinationsVisible}
      noResults={results.length === 0}
      display={!fetching && results.length === 0 && showCountriesAndDestinations ? 'none' : 'flex'}
      fetching={fetching}
    >
      {fetching && results.length === 0 && (
        <Loader>
          <Margin right='0.5' inline />
          <SpinnerWrap>
            <Spinner tiny />
          </SpinnerWrap>
          <Margin right='0.5' inline />
          <FormattedMessage
            id={'loadingHotels.label'}
            description={'Loading hotels...'}
            defaultMessage={'Loading hotels...'}
            tagName={'span'}
          />
        </Loader>
      )}
      {!fetching && results.length === 0 && !showCountriesAndDestinations && (
        <Placeholder>
          <FormattedMessage
            id={'hotelResults.destinations.placeholder.label'}
            description={'Keep typing for hotel results'}
            defaultMessage={'Keep typing for hotel results'}
          />
        </Placeholder>
      )}
      <Results>{results}</Results>
      {scrollIndicatorVisible && (
        <ScrollIndicatorWrap>
          <ScrollIndicator />
        </ScrollIndicatorWrap>
      )}
    </Root>
  );
}

export default StageHotelResultsComponent;

const Root = styled.div`
  flex: ${({ countriesAndDestinationsVisible }) => (countriesAndDestinationsVisible ? 'initial' : '1 0 auto')};
  height: ${({ noResults, scrollIndicatorVisible }) => (!scrollIndicatorVisible || noResults ? 'initial' : '40%')};
  display: ${({ display }) => display};
  position: relative;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.background.light};
`;

const Loader = styled.div`
  span {
    font-style: italic;
    color: ${({ theme }) => theme.colors.primary.dark};
    animation: react-placeholder-pulse 1.5s infinite;
  }
`;

const Placeholder = styled.div`
  padding: 8px 12px;
  cursor: default;
  font-style: italic;
  color: ${({ theme }) => theme.colors.primary.dark};
`;

const SpinnerWrap = styled.div`
  display: inline-block;
  height: 100%;
  vertical-align: middle;

  div {
    width: 5px;
    height: 5px;
    margin: 20px;
    font-size: 7px;
  }
`;

const Results = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: white;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.primary.default};
    border-radius: 10px;
  }
`;

const ScrollIndicatorWrap = styled.div`
  position: absolute;
  right: 8px;
  bottom: 5px;
`;

import HttpClient from 'httpClient';
import isWorldSearch from 'validators/isWorldSearch';
import proxy from 'httpClient/requests';

export function getDestinations() {
  const worldSearch = isWorldSearch();
  if (worldSearch) {
    return proxy({
      httpMethod: 'GET',
      timeout: 60,
      version: 3,
      apiMethod: 'destinations/world',
    });
  }

  return HttpClient.get('/wp-json/tripx/v2/destinations');
}

export function getOriginDestinations(origin) {
  return HttpClient.get(`/wp-json/tripx/v1/origin-destinations/${origin}`);
}

import React from 'react';
import Head from 'next/head';

function HeadComponent({ meta, preventIndexing }) {
  return (
    <Head>
      <title key='title'>{meta.title}</title>
      <link key='favicon' rel='shortcut icon' href={meta.favicon} />
      <meta key='language' name='language' content={meta.locale} />
      <meta key='description' name='description' content={meta.description} />
      <meta key='og:locale' property='og:locale' content={meta.locale} />
      <meta key='og:title' property='og:title' content={meta.title} />
      <meta key='og:description' property='og:description' content={meta.description} />
      <meta key='og:site_name' property='og:site_name' content={meta.siteName} />
      <meta key='og:image' property='og:image' content={meta.shareImageUrl} />
      <meta key='og:image:secure_url' property='og:image:secure_url' content={meta.shareImageUrl} />
      <meta key='twitter:description' name='twitter:description' content={meta.description} />
      <meta key='twitter:title' name='twitter:title' content={meta.title} />
      <meta key='twitter:image' name='twitter:image' content={meta.shareImageUrl} />
      {preventIndexing && <meta key='robots' name="robots" content="noindex" />}
    </Head>
  );
}

export default HeadComponent;

import React, { useState } from 'react';
import CustomOption from './CustomOption';
import { FormattedMessage } from 'react-intl';
import { getChildAgeOptions } from 'utils/array/getChildAgeOptions';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircleScroll from 'components/scrolls/CircleScroll';

function AddChildSelect({ onClickUpdateNumberOfChildren, roomIndex }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = () => {
    setIsOpen(false);
  };

  return (
    <Root isOpen={isOpen}>
      <Toggle onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <>
            <FormattedMessage
              id='selectRooms.component.roomConfiguration.childrensAges'
              description='Children Ages'
              defaultMessage='Children Ages'
            />
          </>
        ) : (
          <>
            <FormattedMessage
              id='selectRooms.component.roomConfiguration.addChild'
              description='Add child'
              defaultMessage='Add child'
            />
            <FontAwesomeIcon icon='chevron-down' />
          </>
        )}
      </Toggle>
      {isOpen && (
        <CircleScroll>
          <Options>
            {getChildAgeOptions().map((option) => (
              <CustomOption
                key={option.value}
                onClickUpdateNumberOfChildren={onClickUpdateNumberOfChildren}
                handleOptionClick={handleOptionClick}
                customData={{ roomIndex }}
                label={option.label}
                value={option.value}
              >
                {option}
              </CustomOption>
            ))}
          </Options>
        </CircleScroll>
      )}
    </Root>
  );
}

export default AddChildSelect;

const Root = styled.div`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.background.greyV3};
  border: none;
  padding-bottom: ${({ isOpen }) => (isOpen ? '10px' : '0')};
`;

const Toggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.darkGrey};
  font-size: 14px;
  font-weight: 400;
  padding: 11px 18px;
  height: 46px;
`;

const Options = styled.div`
  height: 170px;
`;

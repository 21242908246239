import React from 'react';
import styled from 'styled-components';
import Handle from 'rc-slider/lib/Handles/Handle';
import { mediaQueries } from 'styles/utils/mediaQueries';

export default function createMobileHandle(key, label) {
  return function ({ props }) {
    const { tabIndex } = props;
    return (
      <Handle key={`${key}-${tabIndex}`} {...props}>
        <HandleContentWrap>{(typeof label === 'function' && label(props['aria-valuenow'])) || label}</HandleContentWrap>
      </Handle>
    );
  };
}

const HandleContentWrap = styled.div`
  display: inline-block;

  ${mediaQueries.fromAndAbove.l`
    display: none;
  `}
`;

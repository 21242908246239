import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import { FormattedMessage } from 'i18n';
import InputTooltip from 'components/inputs/components/inputTooltip/InputTooltipComponent';
import CustomMonthNav from './components/CustomMonthNav';
import { CustomCloseIcon } from './components/CustomCloseIcon';
import CalendarInfo from './components/CalendarInfo/CalendarInfo';
import Backdrop from 'components/common/backdrop/Backdrop';

import { deviceWidths } from 'styles/utils/mediaQueries';
import boxShadow from 'styles/utils/boxShadow';
import { mediaQueries } from 'styles/utils/mediaQueries';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { PERIOD_CALENDAR } from './constants/calendarSwitch';

const MemoizedDateRangePicker = React.memo(DateRangePicker);

const propTypes = {
  isFrontpage: PropTypes.bool,
  isHotelPage: PropTypes.bool,
  flexibleDates: PropTypes.bool.isRequired,
  hideCalendarInfo: PropTypes.bool,
  initialVisibleMonth: PropTypes.func.isRequired,
  from: PropTypes.instanceOf(moment),
  to: PropTypes.instanceOf(moment),
  focusedInput: PropTypes.string,
  translations: PropTypes.shape({
    startDatePlaceholder: PropTypes.string.isRequired,
    endDatePlaceholder: PropTypes.string.isRequired,
  }),
  onDatesChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  isDayHighlighted: PropTypes.func.isRequired,
  toggleFlexibleDates: PropTypes.func,
  companyShortName: PropTypes.string,
};

function DateRangePickerWrapperComponent({
  airlineWhitelabel,
  isFrontpage,
  isHotelPage,
  flexibleDates,
  flexibleDatesDisabled,
  hideCalendarInfo,
  tooltipIsVisible,
  initialVisibleMonth,
  selectDatesInputIds,
  from,
  to,
  focusedInput,
  translations,
  onDatesChange,
  onFocusChange,
  isDayHighlighted,
  isDayBlocked,
  storeFirstVisibleMonth,
  toggleFlexibleDates,
  companyShortName,
  minDate,
  maxDate,
  renderDayContents,
  isLoading,
  calendarPriceInfo,
  calendarOption,
  dateRangePickerRef,
  handleCloseButtonClick,
  thirdPartyHeaderHeight,
  headerHeight,
  resetInputFocus,
}) {
  return (
    <InputTooltip visible={tooltipIsVisible} messageId={'selectDates.missingValue'}>
      <Container
        className={'date-range-picker-container'}
        selectDatesInputIds={selectDatesInputIds}
        isNorwegian={airlineWhitelabel && airlineWhitelabel.isNorwegian}
        isFrontpage={isFrontpage}
        isHotelPage={isHotelPage}
        isFlexibleDays={flexibleDates}
        isAirBaltic={airlineWhitelabel && airlineWhitelabel.isAirBaltic}
        isPeriodCalendar={calendarOption === PERIOD_CALENDAR}
        startInputPadding={!isFrontpage && !isHotelPage && !!from}
        endInputPadding={!isFrontpage && !isHotelPage && !!to}
        id={isFrontpage ? '3c2b1aef' : ''}
      >
        <MediaQuery maxWidth={deviceWidths.tablet}>
          {(matches) => (
            <MemoizedDateRangePicker
              ref={dateRangePickerRef}
              minDate={minDate}
              maxDate={maxDate}
              startDate={from}
              endDate={to}
              focusedInput={focusedInput}
              onDatesChange={(props) => onDatesChange(props, matches)}
              onFocusChange={(props) => onFocusChange(props, matches)}
              isDayHighlighted={isDayHighlighted}
              isDayBlocked={isDayBlocked}
              onNextMonthClick={storeFirstVisibleMonth}
              onPrevMonthClick={storeFirstVisibleMonth}
              startDateId={selectDatesInputIds.startDateId}
              endDateId={selectDatesInputIds.endDateId}
              displayFormat='D MMMM'
              startDatePlaceholderText={translations && translations.startDatePlaceholder}
              endDatePlaceholderText={translations && translations.endDatePlaceholder}
              noBorder
              withPortal={matches}
              withFullScreenPortal={matches}
              orientation={matches ? 'verticalScrollable' : undefined}
              daySize={45}
              renderDayContents={renderDayContents}
              verticalSpacing={3}
              numberOfMonths={matches ? 13 : 2}
              initialVisibleMonth={initialVisibleMonth(matches)}
              firstDayOfWeek={1}
              disableScroll={matches}
              hideKeyboardShortcutsPanel
              customArrowIcon={<CustomArrowIcon />}
              keepOpenOnDateSelect={matches}
              navPrev={<CustomMonthNav isNavPrev matches={matches} />}
              navNext={<CustomMonthNav isNavNext matches={matches} />}
              customInputIcon={
                <CustomDateInput startDate={from} endDate={to} isFrontpage={isFrontpage} isHotelPage={isHotelPage} />
              }
              renderCalendarInfo={() =>
                !hideCalendarInfo && (
                  <CalendarInfo
                    company={companyShortName}
                    onChangeCheckbox={toggleFlexibleDates}
                    isCheckboxChecked={flexibleDates}
                    flexibleDatesDisabled={flexibleDatesDisabled}
                    airlineWhitelabel={airlineWhitelabel}
                    isLoading={isLoading}
                    calendarPriceInfo={calendarPriceInfo}
                    calendarOption={calendarOption}
                    isHotelPage={isHotelPage}
                    handleCloseButtonClick={handleCloseButtonClick}
                    matches={matches}
                    resetInputFocus={resetInputFocus}
                  />
                )
              }
              calendarInfoPosition='top'
              readOnly={true}
              showClearDates={true}
              reopenPickerOnClearDates={!matches}
              customCloseIcon={<CustomCloseIcon isFrontpage={isFrontpage} />}
            />
          )}
        </MediaQuery>
        {!flexibleDatesDisabled && flexibleDates && (
          <FlexibleDatesIndicator isFrontpage={isFrontpage}>
            <FormattedMessage
              id={'selectDates.component.flexibleDays.indicator.label'}
              description={'+/- 2'}
              defaultMessage={'+/- 2'}
            />
          </FlexibleDatesIndicator>
        )}
        {focusedInput && (
          <Backdrop
            styles={{
              top: isFrontpage || isHotelPage ? 0 : thirdPartyHeaderHeight + headerHeight,
              background: (!isFrontpage || !isHotelPage) && 'rgb(0 0 0 / 50%)',
            }}
          />
        )}
      </Container>
    </InputTooltip>
  );
}

DateRangePickerWrapperComponent.propTypes = propTypes;

export default DateRangePickerWrapperComponent;

// Styled components
const borderRadius = '0.25rem';

// TODO: react-dates now supports a better way for theming
// https://github.com/airbnb/react-dates#theming
const Container = styled.div`
  display: inline-block;
  position: relative;
  height: auto;
  width: ${({ isFrontpage }) => (isFrontpage ? '50%' : '320px')};
  border: ${({ theme, isFrontpage }) => (isFrontpage ? `1px solid ${theme.colors.primary.default}` : 0)};
  min-width: ${({ isFrontpage }) => (isFrontpage ? 'initial' : '100%')};
  border-width: 0px 1px 0px 0px;
  border-bottom-left-radius: ${borderRadius};
  vertical-align: middle;

  ${mediaQueries.fromAndBelow.tablet` 
    border-radius: ${({ isFrontpage, isHotelPage }) => (!isFrontpage && !isHotelPage ? '8px' : '0px')};
    padding: 2px; 
    background: white;
  `};

  .DateRangePicker {
    width: 100%;
    height: 100%;

    > div {
      height: 100%;
    }

    .DateInput {
      width: calc(50%);
      height: 100%;
      border-bottom-left-radius: ${borderRadius};
      padding: 1px;

      &_input {
        height: 100%;
        padding-top: ${({ isFrontpage }) => (isFrontpage ? '18px' : '26px')};
        padding-bottom: ${({ isFrontpage }) => isFrontpage && '0px '};

        &__focused {
          border-bottom: 2px solid transparent;
          /* border-radius: ${({ isFrontpage, isHotelPage }) => (isFrontpage || isHotelPage ? 0 : '8px')}; */
          background-color: ${({ theme }) => theme.colors.primary.default};
        }
      }

      &_input::placeholder {
        font-size: 16px;
        font-weight: ${({ isFrontpage, isAirBaltic }) => (isFrontpage && !isAirBaltic ? 600 : 400)};
        color: ${({ theme, isFrontpage, isHotelPage }) =>
          isFrontpage || isHotelPage ? theme.colors.primary.dark : theme.colors.background.greyV4} !important;
      }

      ${({ selectDatesInputIds, isFrontpage, isHotelPage }) => `

          & #${selectDatesInputIds.endDateId} {
            padding-left: ${isFrontpage ? '5px' : '10px'};
            font-size: 16px;
            font-weight: 600;
             @media (max-width: 1024px) {
              padding-left: 18px;
             }
          }
          
          & #${selectDatesInputIds.startDateId} {
            padding-left: ${isFrontpage ? '18px' : '10px'};
            font-size: 16px;
            font-weight: 600;
            border-radius: ${isFrontpage || isHotelPage ? 0 : '8px 0px 0px 8px;'};

            @media (max-width: 1024px) {
              padding-left: 16px;
             }
          }
        `}

      @media (max-width: 768px) {
        border-bottom-left-radius: 0;
        width: 100%;
      }
    }

    .DateRangePickerInput {
      width: 100%;
      height: 100%;
      display: flex;
      border-bottom-left-radius: 16px;

      @media (max-width: 768px) {
        border-bottom-left-radius: 0;
      }

      &_calendarIcon {
        position: absolute;
        top: 0;
        margin: 0;
        padding: ${({ isFrontpage, isHotelPage }) =>
          isFrontpage ? '10px 18px' : isHotelPage ? '8px 10px' : '2px 10px'};
        font-size: 10px;
        color: ${({ theme }) => (theme.colors.text.label ? theme.colors.text.label : theme.colors.adjacent.default)};

        @media (max-width: 768px) {
          top: ${({ isFrontpage, isHotelPage }) => (isFrontpage || isHotelPage ? '0' : '2px')};
        }
      }
    }

    & #selectDateFromHeader,
    #selectDateFromHotelPage {
      font-size: 16px;

      @media (max-width: 1024px) {
        padding: ${({ startInputPadding }) => startInputPadding && '16px 11px 2px 18px;'};
      }
    }

    & #selectDateFromHotelPage {
      font-weight: 600;

      @media (max-width: 1024px) {
        padding-left: 14px;
      }
    }

    & #selectDateEndHotelPage {
      padding-top: 24px;

      @media (max-width: 1024px) {
        padding-left: 16px;
      }
    }

    & #selectDateEndHeader {
      padding-top: ${({ isFrontpage }) => (isFrontpage ? '18px' : '24px')};
      padding-bottom: ${({ isFrontpage }) => isFrontpage && '0px !important'};
      border-radius: ${({ isFrontpage, isHotelPage }) => (isFrontpage || isHotelPage ? 0 : '0px 8px 8px 0px;')};

      @media (max-width: 1024px) {
        padding: ${({ endInputPadding }) => endInputPadding && '16px 11px 2px 18px;'};
      }
    }

    .DateRangePicker_picker {
      top: ${({ isFrontpage }) => (isFrontpage ? '51px !important' : '50px !important')};
      left: -1px;
      z-index: 5;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      display: flex;

      .DayPicker {
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;
        &_weekHeader {
          top: 52px;
          &_li {
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.primary.darkGrey};
          }
        }
        transition: all 0.3s ease;
        &__withBorder {
          ${boxShadow()}
        }
      }

      .CalendarDay {
        position: relative;
        vertical-align: middle;
        border: 1px solid transparent;
        font-size: 18px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.primary.darkGrey};

        &:focus {
          outline: none;
        }

        &__highlighted_calendar {
          background: white;
          &:after {
            content: '';
            position: absolute;
            right: 2px;
            top: 2px;
            width: 5px;
            height: 5px;
            background: ${({ theme }) => theme.colors.secondary.default};
            border-radius: 50%;
          }
        }

        &__selected_span {
          background: ${(props) => props.theme.colors.primary.light};
          color: ${(props) => props.theme.colors.primary.dark};
        }

        &__selected,
        __selected: active,
        __selected: hover {
          background: ${(props) => props.theme.colors.primary.dark};
          color: ${(props) => props.theme.colors.primary.light};
        }

        &__selected,
        __selected_start,
        __selected_end {
          background: ${({ theme }) => theme.colors.primary.dark} !important;
          color: ${({ theme }) => theme.colors.primary.light} !important;

          span {
            &.flexible_calendar {
              background: ${({ theme }) => theme.colors.primary.dark} !important;
              color: ${({ theme }) => theme.colors.primary.light} !important;
            }
          }
        }

        &__blocked_calendar {
          background: #ffffff;
          border: 1px solid transparent;
          color: ${({ theme }) => theme.colors.text.disabled};
        }

        &__hovered_span,
        __hovered_span: hover {
          background: ${(props) => props.theme.colors.primary.light};
          color: ${(props) => props.theme.colors.primary.dark};
        }

        &__selected_start {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        &__selected_end {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &__blocked_out_of_range,
        __blocked_out_of_range:active,
        __blocked_out_of_range:hover {
          background: ${(props) => props.theme.colors.background.light};
          color: ${(props) => props.theme.colors.text.disabled};
        }

        &__default:hover {
          background: ${(props) => props.theme.colors.background.disabled};
          border: 1px solid transparent;
          color: ${(props) => props.theme.colors.primary.dark};
        }

        span {
          &.flexible_calendar {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: linear-gradient(
              135deg,
              ${({ theme }) => theme.colors.primary.default} 25%,
              ${({ theme }) => theme.colors.primary.light} 25%,
              ${({ theme }) => theme.colors.primary.default} 50%,
              ${({ theme }) => theme.colors.primary.light} 50%,
              ${({ theme }) => theme.colors.primary.default} 75%,
              ${({ theme }) => theme.colors.primary.light} 75%,
              ${({ theme }) => theme.colors.primary.default} 100%
            );
            background-size: 8px 8px;
            line-height: 2;
          }
        }
      }

      .CalendarMonthGrid__horizontal {
        left: 0px;
      }

      .CalendarMonth {
        padding: 0 !important;
      }

      .CalendarMonth_caption {
        background-color: ${({ theme }) => theme.colors.background.greyLight};
        margin-bottom: 10px;
        color: ${({ theme }) => theme.colors.text.dark};
        > strong {
          font-weight: 600;
        }
      }

      .CalendarMonth_table {
        margin: 0 17px;
      }
    }
  }

  .DateRangePickerInput__showClearDates {
    padding-right: 0px;
  }

  .DateRangePickerInput_clearDates {
    transform: translateY(-53%);
    &_svg {
      fill: ${({ theme }) => theme.colors.text.darkGrey};
      stroke: ${({ theme }) => theme.colors.text.darkGrey};
      height: 10px;
      width: 14px;
    }
    padding: ${({ isFrontpage, isHotelPage }) =>
      isFrontpage ? '6px 18px' : isHotelPage ? '0px 4px' : '4px 7px 0px 0px'};
    margin: 0;

    ${mediaQueries.below.desktop`
     background-color: ${({ theme }) => theme.colors.background.light};
    `}
  }

  .DayPickerNavigation_button__disabled {
    border: none;
  }

  .DateRangePickerInput_arrow {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${mediaQueries.below.l`
    width:${({ isFrontpage }) => (isFrontpage ? '50%' : '100%')};
    max-width: initial;
  `}

  ${mediaQueries.fromAndBelow.tablet`
     width:100%;
     border-width: ${({ isFrontpage, isHotelPage }) => (isHotelPage || isFrontpage ? '0px 0px 1px 0px' : '0px')};
     height: 50px;
  `};
`;

function CustomArrowIcon() {
  return <VerticalLine />;
}

function CustomDateInput({ startDate, endDate, isFrontpage, isHotelPage }) {
  const datesLabel = {
    id: 'dates.label',
    description: 'Dates',
    defaultMessage: 'Dates',
  };

  return (
    <CustomDateInputTitles>
      <CustomDateInputCheckIn startDate={!!startDate} isFrontpage={isFrontpage} isHotelPage={isHotelPage}>
        <FormattedMessage {...datesLabel} />
      </CustomDateInputCheckIn>
      {/* <CustomDateInputCheckOut
        endDate={!!endDate}
        isFrontpage={isFrontpage}
        isHotelPage={isHotelPage}
      ></CustomDateInputCheckOut> */}
    </CustomDateInputTitles>
  );
}

const VerticalLine = styled.div`
  width: 1px;
  height: 75%;
  margin: auto 2px;
  background-color: ${({ theme }) => theme.colors.background.grey};
`;

const CustomDateInputTitles = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: start;
  justify-content: space-between;
`;

const CustomDateInputCheckIn = styled.div`
  display: flex;
  width: 50%;

  ${mediaQueries.fromAndBelow.desktop`
   padding-left: ${({ isFrontpage, isHotelPage }) => (isFrontpage ? '0px' : isHotelPage ? '6px' : '8px')} ;
  `}
`;

// const CustomDateInputCheckOut = styled.div`
//   display: flex;
//   width: 50%;
//   padding-left: ${({ isFrontpage }) => (isFrontpage ? '0px' : '4px')};

//   ${mediaQueries.fromAndBelow.desktop`
//    padding-left: 18px;
//   `}
// `;

const FlexibleDatesIndicator = styled.div`
  display: inline;
  position: absolute;
  z-index: 2;
  top: 100%;
  right: 6px;
  transform: translate(0, -50%);
  padding: 2px 4px;
  background: ${({ theme }) => theme.colors.secondary.default};
  font-size: 12px;
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.text.white};

  ${mediaQueries.fromAndBelow.desktop`
    ${({ isFrontpage }) =>
      !isFrontpage &&
      `
      top: 0;
      right: 4px;
    `}
  `}
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuListComponent from 'components/menus/menuList/MenuListComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  content: PropTypes.array.isRequired,
  isOpened: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnMouseDown: PropTypes.func.isRequired,
  handleOnBlurMenu: PropTypes.func.isRequired,
  handleOnMouseDownMenu: PropTypes.func.isRequired,
  menuListRef: PropTypes.oneOfType([
    // PropTypes.shape({ current: PropTypes.instanceOf(Element) }), // TODO: Doesn't work on SSR
    PropTypes.object,
  ]).isRequired,
};

const OverflowMenuComponent = ({
  content,
  isOpened,
  handleOnClick,
  handleOnMouseDown,
  handleOnBlurMenu,
  handleOnMouseDownMenu,
  handleOnClickMenuLink,
  menuListRef,
  isHotelPage,
  isCheckoutPage,
}) => (
  <OverflowMenuStyled>
    <HamburgerButton
      onClick={handleOnClick}
      onMouseDown={handleOnMouseDown}
      isHotelPage={isHotelPage}
      isCheckoutPage={isCheckoutPage}
    >
      <FontAwesomeIcon icon='bars' />
    </HamburgerButton>
    {isOpened ? (
      <MenuList ref={menuListRef} onBlur={handleOnBlurMenu} onMouseDown={handleOnMouseDownMenu} tabIndex={0}>
        <MenuListComponent items={content} onClick={handleOnClickMenuLink} />
      </MenuList>
    ) : null}
  </OverflowMenuStyled>
);

OverflowMenuComponent.propTypes = propTypes;

export default OverflowMenuComponent;

// Styled components
const OverflowMenuStyled = styled.div`
  position: relative;
  height: 100%;
  gap: 10px;

  ${mediaQueries.fromAndBelow.phone`
    position: initial;
  `};
`;

const HamburgerButton = styled.button`
  height: ${({ isHotelPage, isCheckoutPage }) => (isCheckoutPage || isHotelPage ? '34px' : '48px')};
  width: ${({ isHotelPage, isCheckoutPage }) => (isCheckoutPage || isHotelPage ? '34px' : '42px')};
  background: none;
  border: none;
  font-size: ${({ isHotelPage, isCheckoutPage }) => (isCheckoutPage || isHotelPage ? '14px' : '18px')};
  cursor: pointer;
  color: ${({ theme }) =>
    theme.colors.hamburgerMenu ? theme.colors.hamburgerMenu.default : theme.colors.adjacent.default};
  box-shadow: 0 0 0 1px
    ${({ theme }) => (theme.colors.hamburgerMenu ? theme.colors.hamburgerMenu.default : theme.colors.adjacent.default)};
  border-radius: 8px;
`;

const MenuList = styled.div`
  position: absolute;
  right: 2px;
  top: calc(100% + 10px);
  padding: 4px 0;
  background-color: ${(props) => props.theme.colors.background.light};
  border-radius: 0.25rem;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);

  &:focus {
    outline: none;
  }

  a {
    display: block;
    min-width: 116px;
    width: 100%;
    padding: 8px 12px;
    background-color: ${(props) => props.theme.colors.background.light};
    user-select: none;
    box-sizing: border-box;
  }

  ${mediaQueries.fromAndBelow.phone`
    width: 100vw;
  `}
`;

import React, { Component } from 'react';
import StringHighlightComponent from './StringHighlightComponent';

class StringHighlight extends Component {
  render() {
    const { stringValue, highlightValue } = this.props;
    const result = [];
    const start = stringValue.toLowerCase().indexOf(highlightValue.toLowerCase());
    const end = start + highlightValue.length;

    if (start === -1) {
      return stringValue;
    }

    if (start !== 0) {
      result.push({ value: stringValue.slice(0, start), highlighted: false });
    }

    result.push({ value: stringValue.slice(start, end), highlighted: true });

    if (end !== stringValue.length) {
      result.push({ value: stringValue.slice(end), highlighted: false });
    }

    return <StringHighlightComponent result={result} />;
  }
}

export default StringHighlight;

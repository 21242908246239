import React, { Component } from 'react';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';

const LogosPaymentComponent = dynamic(() => import('./LogosPaymentComponent'), {
  ssr: false,
});

class LogosPayment extends Component {
  render() {
    const { airlineWhitelabel } = this.props;
    const isHillman = airlineWhitelabel && airlineWhitelabel.isHillman;

    return <LogosPaymentComponent airlineWhitelabel={airlineWhitelabel} isHillman={isHillman} />;
  }
}

function mapStateToProps(state) {
  return {
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
  };
}

export default connect(mapStateToProps)(LogosPayment);

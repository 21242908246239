import styled from 'styled-components';
import SelectCommonNarrow from '../components/SelectCommonNarrowComponent';

export default styled(SelectCommonNarrow)`
  .styled-select {
    &__control {
      position: relative;
      height: 40px;

      @media (max-width: 1024px) {
        border-width: 0;
        min-width: 100%;
        height: 42px;
        padding: 0;
        border-right-style: none;
        display: flex;
        padding: 2px 4px 2px 18px;
        justify-content: flex-end;
        align-items: center;
      }
    }

    &__value-container {
      @media (max-width: 1024px) {
        height: 100%;
        padding: 0;
        margin-left: 0;
      }
    }

    &__placeholder {
      padding-top: 10px;
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.background.greyV4};

      @media (max-width: 768px) {
        padding-top: 14px;
      }
    }

    &__menu {
      background: ${({ theme }) => theme.colors.background.greyV5};
      width: 500px;
      min-width: 250px;

      @media (max-width: 1024px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        background: ${({ theme }) => theme.colors.background.greyLight};
        z-index: 4;
      }
    }

    &__input {
      padding-top: 8px;

      input {
        line-height: unset;
        display: flex;
        @media (max-width: 768px) {
          caret-color: transparent;
        }
      }
    }
  }
`;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BannerComponent from './BannerComponent';
import BannerConfigLS from 'managers/localStorage/bannerConfig/bannerConfigLS';

const defaultProps = {
  config: {
    hasClose: false,
    hideIfNoText: false,
    styles: {},
  },
};

const propTypes = {
  bannerKey: PropTypes.string.isRequired,
  config: PropTypes.shape({
    hasClose: PropTypes.bool,
    styles: PropTypes.shape({
      root: PropTypes.object,
      closeIcon: PropTypes.object,
    }),
    bgColor: PropTypes.string,
  }),
};

class Banner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dismissed: false,
    };

    this.handleOnClickClose = this.handleOnClickClose.bind(this);

    this.rootRef = React.createRef();
    this.bannerConfigLocalStorage = new BannerConfigLS(props.bannerKey);
    this.hideBanner = props.config.hasClose && this.bannerConfigLocalStorage.isDismissed();
  }

  handleOnClickClose() {
    this.setState({
      dismissed: true,
      bannerHeight: this.rootRef.current && this.rootRef.current.clientHeight,
    });
    this.bannerConfigLocalStorage.setDismissedOn();
  }

  render() {
    const { config, children } = this.props;
    const { dismissed, bannerHeight } = this.state;
    const { hasClose, styles, bgColor } = config;

    if (this.hideBanner || !children || !process.browser) {
      return null;
    }

    return (
      <BannerComponent
        rootRef={this.rootRef}
        content={children}
        hasClose={hasClose}
        styles={styles}
        dismissed={dismissed}
        bannerHeight={bannerHeight}
        onClickClose={this.handleOnClickClose}
        bgColor={bgColor}
      />
    );
  }
}

Banner.defaultProps = defaultProps;
Banner.propTypes = propTypes;

export default Banner;

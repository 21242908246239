import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';

function HybridCheckoutFailed() {
  return (
    <Root>
      <FormattedMessage
        id={'hybridCheckoutFiled.label'}
        description={'Sorry! Try again.'}
        defaultMessage={'Sorry! Try again.'}
      />
    </Root>
  );
}

export default HybridCheckoutFailed;

const Root = styled.div`
  padding: 8px;
  font-size: 1.4em;
  line-height: 1.3;
`;

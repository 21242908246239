import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import formatDataAttributes from 'utils/object/formatDataAttributes';
import { mediaQueries } from 'styles/utils/mediaQueries';

function IncrementDecrementComponent({ label, value, decrement, increment, data }) {
  return (
    <Root>
      {label && <Label>{label}</Label>}
      <Group label={label}>
        {decrement && (
          <Button
            onClick={decrement.onClick}
            disabled={decrement.disabled}
            data-update-value={-1}
            {...formatDataAttributes(data)}
          >
            <ButtonSign icon='minus' />
          </Button>
        )}
        <Value>{value}</Value>
        {increment && (
          <Button
            onClick={increment.onClick}
            disabled={increment.disabled}
            data-update-value={1}
            {...formatDataAttributes(data)}
          >
            <ButtonSign icon='plus' />
          </Button>
        )}
      </Group>
    </Root>
  );
}

export default IncrementDecrementComponent;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0 0;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text.darkGrey};

  ${mediaQueries.fromAndAbove.desktop`
    font-size: 1.6em;
  `}
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ label }) => (label ? '24px' : '0')};
`;

const Value = styled.div`
  padding: 0 18px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.darkGrey};

  ${mediaQueries.fromAndAbove.desktop`
    font-size: 1.6em;
  `}
`;

const Button = styled.button`
  position: relative;
  height: 36px;
  width: 36px;
  background-color: ${({ theme }) => theme.colors.adjacent.default};
  border: none;
  color: ${({ theme }) => theme.colors.text.white};
  cursor: pointer;
  border-radius: 8px;

  &:active {
    transform: translateY(3px);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.2;
    }
  }

  &:disabled,
  [disabled] {
    cursor: initial;
    background-color: ${({ theme }) => theme.colors.background.disabled};
  }

  ${mediaQueries.fromAndAbove.desktop`
    height: 36px;
    width: 36px;
  `}
`;

const ButtonSign = styled(FontAwesomeIcon)`
  ${mediaQueries.fromAndAbove.desktop`
    font-size: 12px;
  `}
`;

export const getAbandonedCartForCookie = (uuid, hotelsSearchRequestParameters, hotelInfo) => {
  const { departureAirport, departureDate, returnDate } = hotelsSearchRequestParameters;
  const { name: hotelName, images, location, stars } = hotelInfo;
  const hotelImages = images.slice(0, 1);
  const url = typeof window !== 'undefined' && `${window.location.href}&abandonedCart=${uuid}`;

  return {
    uuid,
    timestamp: Date.now(),
    data: {
      departureAirport,
      departureDate,
      returnDate,
      hotelName,
      hotelImages,
      url,
      location,
      stars,
    },
  };
};

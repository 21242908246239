import React from 'react';
import styled, { css } from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';
import { usePreventHtmlScroll } from 'hooks/usePreventHtmlScroll';

const defaultProps = {
  config: {
    content: {
      offset: 0,
      styles: undefined,
    },
  },
};

function SelectModalComponent({ open, config, children }) {
  const { content, preventHtmlScroll = true } = config;
  const { offset, styles } = content;

  usePreventHtmlScroll(open && preventHtmlScroll);

  return (
    <Root open={open}>
      <Content open={open} offset={offset} styles={styles}>
        {children}
      </Content>
    </Root>
  );
}

SelectModalComponent.defaultProps = defaultProps;

export default SelectModalComponent;

const Root = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  z-index: 9;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${({ open }) => (open ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;

  ${mediaQueries.fromAndAbove.desktop`
    display: initial;
    height: initial;
    position: initial;
    z-index: initial;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    background-color: initial;
    opacity: initial;
    transition: initial;
  `}
`;

const Content = styled.div`
  height: 100vh;
  height: 100dvh;
  position: relative;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: ${({ open, offset = 0 }) => (open ? `0px` : '-100vh')};
  overflow: auto;
  outline: none;
  transition: bottom 0.3s ease-out;

  ${mediaQueries.fromAndAbove.desktop`
    height: initial;
    position: initial;
    left: initial;
    right: initial;
    bottom: initial;
    overflow: initial;
    outline: initial;
    transition: initial;
  `}

  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `};
`;

import React, { Component } from 'react';
import SelectRoomsComponent from './SelectRoomsComponent';
import getNumberOfAdultsAndChildren from 'state/selectRooms/selectors/getNumberOfAdultsAndChildren';
import { connect } from 'react-redux';

class SelectRooms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.handleOnToggle = this.handleOnToggle.bind(this);
  }

  componentDidMount() {
    const { hydrateSelectedRooms } = this.props;
    hydrateSelectedRooms();
  }

  handleOnToggle() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  render() {
    const { selectRooms, menuList, airlineWhitelabel, isFrontPage, isHotelPage, thirdPartyHeaderHeight, headerHeight } =
      this.props;
    const { isOpen } = this.state;
    const { numberOfAdults, numberOfChildren } = getNumberOfAdultsAndChildren(selectRooms.rooms);

    return (
      <SelectRoomsComponent
        selectedRooms={{
          numberOfRooms: selectRooms.rooms.length,
          numberOfAdults,
          numberOfChildren,
          airlineWhitelabel,
        }}
        MenuList={menuList}
        handleOnClick={this.handleOnToggle}
        isOpen={isOpen}
        isFrontPage={isFrontPage}
        isHotelPage={isHotelPage}
        thirdPartyHeaderHeight={thirdPartyHeaderHeight}
        headerHeight={headerHeight}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    thirdPartyHeaderHeight: state.refs.thirdPartyHeaderHeight,
    headerHeight: state.refs.headerHeight,
  };
}

export default connect(mapStateToProps)(SelectRooms);

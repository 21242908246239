import React from 'react';
import Select, { createFilter } from 'react-select';
import styled from 'styled-components';
import axis from 'axis.js';

import InputTooltip from 'components/inputs/components/inputTooltip/InputTooltipComponent';
import { boxShadowValue } from 'styles/utils/boxShadow';

function SelectCommonComponent({ className, tooltipIsVisible, messageId, ...props }) {
  const isCombinedFilter = props.data && props.data.isCombinedFilter;

  // Custom filter function to include selected options
  const combinedFilterFunction = (option, inputValue, selectedValues) => {
    // Return option if it is selected
    const isSelected = selectedValues.some((val) => val.value === option.value);

    if (isSelected) {
      return true;
    }

    if (inputValue) {
      //check for all for country option
      const optionLabel = axis.isObject(option.label) ? option.label.values.country : option.label;

      return (optionLabel || '').toLowerCase().includes(inputValue.toLowerCase());
    }

    return true;
  };

  return (
    <InputTooltip visible={tooltipIsVisible} messageId={messageId}>
      <Root
        className={className}
        {...props}
        airlineWhitelabel={props.data && props.data.airlineWhitelabel}
        filterOption={
          isCombinedFilter
            ? (option, inputValue) => combinedFilterFunction(option, inputValue, props.value)
            : createFilter({ ignoreAccents: false })
        }
      />
    </InputTooltip>
  );
}

export default SelectCommonComponent;

const Root = styled(Select)`
  .styled-select {
    &__control {
      height: 100%;
      background-color: white;
      border-color: ${({ theme }) => theme.colors.primary.default};
      border-radius: 0;
      box-shadow: none;

      &:hover {
        border-color: ${({ theme }) => theme.colors.primary.default};
      }
    }

    &__menu {
      margin-top: 0;
      top: calc(100% + 10px);
      border: 1px solid;
      border-width: 0 1px 1px 1px;
      border-color: ${({ theme }) => theme.colors.primary.default};
      border-radius: 8px;
      box-shadow: ${boxShadowValue};
      font-size: 1.6em;
      z-index: 4;
      padding: 2px;
    }

    &__value-container {
      padding: 4px 0px 4px 10px;
      font-size: 1.6em;
      display: flex;
    }

    &__placeholder {
      color: ${({ theme }) => theme.colors.primary.dark};
      font-size: 18px;
      font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 600)};
      padding-top: 10px;
      margin: 0;
    }

    &__single-value {
      font-size: 18px;
      font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 'bold')};
      color: ${({ theme }) => theme.colors.primary.dark};
      padding: 4px 0px;
    }

    &__multi-value {
      max-width: 70%;
      margin: 0;
      margin-top: 2px;
      padding: 8px 0px 2px 0px;
      background-color: transparent;
      border-radius: 8px;
      display: flex;
      justify-content: 'space-between';
      align-items: center;

      @media (max-width: 768px) {
        margin-top: 2px 0px 0px 0px;
        border-radius: 0;
      }

      &__label {
        margin: 0;
        color: ${({ theme }) => theme.colors.text.darkGrey};
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        padding: 2px 0px;
      }

      &__remove {
        // If we need single remove button for every option remove display flex
        display: none;
        padding: 2px;
        margin-right: 4px;
        border-radius: 16px;
        color: ${({ theme }) => theme.colors.primary.dark};
        position: absolute;
        right: 0;
        background: transparent;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      display: none;
    }
  }
`;

import HttpClient from 'httpClient';
import isWorldSearch from 'validators/isWorldSearch';
import proxy from 'httpClient/requests';

export function getOrigins() {
  const worldSearch = isWorldSearch();
  if (worldSearch) {
    return proxy({
      httpMethod: 'GET',
      timeout: 30,
      version: 3,
      apiMethod: 'airports/world',
    });
  }

  return HttpClient.get('/wp-json/tripx/v1/origins');
}

export function getDestinationOrigins(destination) {
  return HttpClient.get(`/wp-json/tripx/v1/destination-origins/${destination}`);
}

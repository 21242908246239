import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import DropdownSearchTrigger from './components/dropdownSearchTrigger/DropdownSearchTrigger';
import DropdownSearch from './components/dropdownSearch/DropdownSearch';
import { HeaderStyledCommon, HeaderLeftCommon } from './HeaderComponentCommon';
import { mediaQueries } from 'styles/utils/mediaQueries';
import { isMobile as getIsMobile } from 'utils/isMobile';
import HeaderMenuTransition from './components/headerMenu/HeaderMenuTransition';
import SearchResultsPriceBars from './components/searchResultsPriceBars/SearchResultsPriceBarsWrap';

const travelSummaryBarPromise = import('./components/travelSummaryBar/TravelSummaryBar');
const TravelSummaryBar = dynamic(() => travelSummaryBarPromise);

const FiltersBar = dynamic(() => import('./components/filtersBar/FiltersBar'), { ssr: false });

const travelSummaryConfigMain = {
  button: {
    styles: { display: 'none' },
  },
  content: {
    styles: { border: 'none' },
  },
};

const travelSummaryConfigDeal = {
  summaries: {
    hotel: {
      exclusive: true,
    },
  },
};

function HeaderComponent({
  headerRef,
  headerHeight,
  menuContent,
  isSearchResults,
  isCheckoutPage,
  isHotelPage,
  isDeal,
  isFrontpage,
  dropdownSearchTriggerProps,
  dropdownSearchProps,
  airlineWhitelabel,
  showTravelSummary,
  customHeaderHeight,
  setActiveAction,
}) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(getIsMobile());

    window.addEventListener('resize', () => setIsMobile(getIsMobile()));

    return () => window.removeEventListener('resize', () => setIsMobile(getIsMobile()));
  }, []);

  const travelSummaryConfig = isDeal ? travelSummaryConfigDeal : travelSummaryConfigMain;

  return (
    <Root headerHeight={headerHeight} isCheckoutPage={isCheckoutPage} isHotelPage={isHotelPage}>
      <Container
        ref={headerRef}
        isNorwegian={airlineWhitelabel && airlineWhitelabel.isNorwegian}
        customHeaderHeight={isFrontpage && customHeaderHeight}
        isHotelPage={isHotelPage}
        isCheckoutPage={isCheckoutPage}
      >
        <HeaderLeftCommon>
          {isMobile && <DropdownSearchTrigger {...dropdownSearchTriggerProps} airlineWhitelabel={airlineWhitelabel} />}
          <DropdownSearch {...dropdownSearchProps} isMobile={isMobile} menuContent={menuContent} />
          {(isHotelPage || isCheckoutPage) && (
            <Actions>
              <HeaderMenuTransition
                isHotelPage={isHotelPage}
                isCheckoutPage={isCheckoutPage}
                menuContent={menuContent}
                isHamburger={true}
                showForMedia={['fromAndAbove', 'l']}
              />
            </Actions>
          )}
        </HeaderLeftCommon>
      </Container>
      {isSearchResults && <FiltersBar />}
      {isSearchResults && <SearchResultsPriceBars setActiveAction={setActiveAction} />}

      {showTravelSummary && <TravelSummaryBar config={travelSummaryConfig} />}
    </Root>
  );
}

export default HeaderComponent;

const Root = styled.div`
  position: fixed; // ????
  position: sticky; // ????
  top: ${({ isCheckoutPage, isHotelPage, headerHeight }) =>
    isCheckoutPage || isHotelPage ? `-${headerHeight}px` : '0'};
  width: 100%;
  margin: 0 auto;
  padding: 0;
  z-index: 3;

  ${mediaQueries.fromAndAbove.desktop`
    top: 0;
  `}
`;

const Container = styled(HeaderStyledCommon)`
  background-color: ${({ theme }) => theme.colors.primary.light};

  ${mediaQueries.below.l`
    border-bottom: none;
  `};
`;

const Actions = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
});

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import { FormattedMessage } from 'i18n';
import truncate from 'styles/utils/truncate';

const propTypes = {
  data: PropTypes.shape({
    numberOfRooms: PropTypes.number,
    numberOfAdults: PropTypes.number.isRequired,
    numberOfChildren: PropTypes.number.isRequired,
  }).isRequired,
};

function SingleValueComponent({ data: { numberOfRooms, numberOfAdults, numberOfChildren, airlineWhitelabel } }) {
  return (
    <Root>
      {numberOfRooms && (
        <NumberOfRooms airlineWhitelabel={airlineWhitelabel}>
          <FormattedMessage
            id='selectRooms.component.numberOfRooms.label'
            description='format for plural: {number, number} {number, plural, one {room} other {rooms}'
            defaultMessage={`{number, number} {number, plural,
                one {room}
                other {rooms}
              }`}
            values={{ number: numberOfRooms }}
          />
        </NumberOfRooms>
      )}
      <NumberOfGuests airlineWhitelabel={airlineWhitelabel}>
        <FormattedMessage
          id='selectRooms.component.numberOfAdults.label'
          description='format for plural: {number, number} {number, plural, one {adult} other {adults}'
          defaultMessage={`{number, number} {number, plural,
            one {adult}
            other {adults}
          }`}
          values={{ number: numberOfAdults }}
        />
        <Margin right='0.25' inline>
          ,
        </Margin>
        <FormattedMessage
          id='selectRooms.component.numberOfChildren.label'
          description='format for plural: {number, number} {number, plural, one {child} other {children}'
          defaultMessage={`{number, number} {number, plural,
            one {child}
            other {children}
          }`}
          values={{ number: numberOfChildren }}
        />
      </NumberOfGuests>
    </Root>
  );
}

SingleValueComponent.propTypes = propTypes;

export default SingleValueComponent;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 6px;
  ${truncate()}
`;

const NumberOfRooms = styled.div`
  font-size: 10px;
  font-weight: 400;
  letter-spacing: normal;
  color: ${({ theme }) => (theme.colors.text.label ? theme.colors.text.label : theme.colors.adjacent.default)};
`;

const NumberOfGuests = styled.div`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.text.darkGrey};
`;

import React from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'i18n';
import Banner from '../../../../Banner';
import flexbox from 'styles/utils/flexbox';
import { mediaQueries } from 'styles/utils/mediaQueries';

const bannerConfig = {
  hasClose: true,
  styles: {
    root: {
      margin: '17px 0 0 0',
      'background-color': '#ffffff',
    },
  },
};

function AbandonedCartComponent({ uuid, name, image, onClickFinishBooking, airlineWhitelabel }) {
  return (
    <Banner bannerKey={`abandoned_cart_${uuid}`} config={bannerConfig}>
      <Root>
        <ImageShadow>
          <Image src={image} />
        </ImageShadow>
        <Center>
          <Message>
            <FormattedMessage
              id={'stillLookingForATripTo.message'}
              description={'Still looking for a trip to: '}
              defaultMessage={'Still looking for a trip to: '}
            />
          </Message>
          <HotelName>{name}</HotelName>
        </Center>
        <ButtonShadow>
          <Button onClick={onClickFinishBooking} airlineWhitelabel={airlineWhitelabel}>
            <FormattedMessage
              id={'finishBooking.label'}
              description={'Finish booking'}
              defaultMessage={'Finish booking'}
            />
            <Margin right='1' inline />
            <FontAwesomeIcon icon='arrow-right' />
          </Button>
        </ButtonShadow>
      </Root>
    </Banner>
  );
}

export default AbandonedCartComponent;

const bannerHeightDesktop = 120;
const bannerBorderRadius = '4px';
const edgeElementsWidth = 290;
const edgeElementsRadius = '120px';

const Root = styled.div`
  ${flexbox({
    flexDirection: 'column',
  })}

  border-radius: ${bannerBorderRadius};

  ${mediaQueries.fromAndAbove.desktop`
    ${flexbox({
      justifyContent: 'space-between',
    })}

    height: ${bannerHeightDesktop}px;
  `}
`;

const ImageShadow = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.background.disabled};
  border-radius: ${bannerBorderRadius};

  ${mediaQueries.fromAndAbove.desktop`
    width: ${edgeElementsWidth + 12}px;
    border-top-right-radius: ${edgeElementsRadius};
  `}
`;

const Image = styled.div`
  width: 100%;
  height: 170px;
  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  border-radius: ${bannerBorderRadius};

  ${mediaQueries.fromAndAbove.desktop`
    width: ${edgeElementsWidth}px;
    height: ${bannerHeightDesktop}px;
    border-top-right-radius: ${edgeElementsRadius};
  `}
`;

const Center = styled.div`
  margin: 16px;
  text-align: center;

  ${mediaQueries.fromAndAbove.desktop`
    ${flexbox({
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    })}

    margin: 0;
  `}
`;

const Message = styled.div`
  margin-bottom: 8px;
  font-size: 1.8em;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.default};

  ${mediaQueries.fromAndAbove.desktop`
    margin-bottom: 0;
  `}
`;

const HotelName = styled.div`
  max-width: 390px;
  font-family: ${({ theme }) => theme.fonts.displaySubtle};
  font-size: 2.8em;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.templates.header};
`;

const ButtonShadow = styled.div`
  padding: 16px;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  ${mediaQueries.fromAndAbove.desktop`
    ${flexbox({
      justifyContent: 'flex-end',
    })}

    padding: 0;
    width: ${edgeElementsWidth + 12}px;
    height: 100%;
    background: ${({ theme }) => theme.colors.background.disabled};
    border-radius: ${bannerBorderRadius};
    border-bottom-left-radius: ${edgeElementsRadius};
    border-bottom-right-radius: 0;
  `}
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  background: ${({ theme }) => theme.colors.adjacent.default};
  border: none;
  border-radius: ${bannerBorderRadius};
  font-size: 1.8em;
  font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 700)};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.buttons.primary.text};

  ${mediaQueries.fromAndAbove.desktop`
    width: ${edgeElementsWidth}px;
    height: 100%;
    border-bottom-left-radius: ${edgeElementsRadius};
    font-size: 2.2em;
  `}
`;

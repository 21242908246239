import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setSteps(steps) {
  return {
    type: types.SET_STEPS,
    payload: {
      steps,
    },
  };
}

export function setCurrentStepById(stepId) {
  return {
    type: types.SET_CURRENT_STEP_BY_ID,
    payload: {
      stepId,
    },
  };
}

export function setStepDisabledById(stepId, disabled) {
  return {
    type: types.SET_STEP_DISABLED_BY_ID,
    payload: {
      stepId,
      disabled,
    },
  };
}

export function setLastEnabledStep(stepId) {
  return {
    type: types.SET_LAST_ENABLED_STEP,
    payload: {
      stepId,
    },
  };
}

export function setHybridCheckoutFetching(fetching) {
  return {
    type: types.SET_HYBRID_CHECKOUT_FETCHING,
    payload: {
      fetching,
    },
  };
}

export function resetCheckoutWizard() {
  return {
    type: types.RESET_CHECKOUT_WIZARD,
  };
}

/**
 * Component that creates a portal to render its children and append them  to the document body.
 *
 * @component
 * @param {Object} props
 * @param {React.ReactNode} props.children - The content to be rendered within the portal.
 * @param {boolean} props.usePortal - Boolean to determine if we should use portal or render children
 * @returns {React.ReactPortal} The React portal component.
 */

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children, usePortal }) => {
  const [container] = useState(() => {
    return typeof window !== 'undefined' && document.createElement('div');
  });

  useEffect(() => {
    if (usePortal) {
      document.body.appendChild(container);

      return () => {
        document.body.removeChild(container);
      };
    }
  }, [container, usePortal]);

  if (usePortal) {
    return ReactDOM.createPortal(children, container);
  }

  return <>{children}</>;
};

export default Portal;

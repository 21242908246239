import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { mediaQueries } from 'styles/utils/mediaQueries';
import { PRICES } from 'state/actionBar/constants/actionsIds';
import Backdrop from 'components/common/backdrop/Backdrop';
import SearchResultsPriceBars from './SearchResultsPriceBars';
import { isMobile } from 'utils/isMobile';
import BaseTransition from 'components/transition/Transition';

function SearchResultsPriceBarsWrap({ setActiveAction }) {
  const activeAction = useSelector((state) => state.actionBar.activeAction);
  const rolledUp = useSelector((state) => state.priceBars.rolledUp);
  const displayPriceBars = useSelector((state) => state.priceBars.displayPriceBars);
  const results = useSelector((state) => state.priceBars.result);

  const isOpenOnMobile = activeAction === PRICES;
  const isOpen = isMobile() ? isOpenOnMobile : !rolledUp && displayPriceBars;

  return (
    <>
      {isOpenOnMobile && <BackdropWrap onClick={() => setActiveAction(PRICES)} />}
      <SearchResultsPriceBarsWrapComponent rolledUp={rolledUp}>
        {results.length > 0 ? (
          <Transition rolledUp={isOpen} isOpen={!rolledUp} type='vertical'>
            {isOpen && <SearchResultsPriceBars />}
          </Transition>
        ) : null}
      </SearchResultsPriceBarsWrapComponent>
    </>
  );
}

export default SearchResultsPriceBarsWrap;

const SearchResultsPriceBarsWrapComponent = styled.div`
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;

  ${mediaQueries.fromAndAbove.desktop`
    position: relative;
    z-index: -1;
    background-color:${({ theme }) => theme.colors.background.greyV6};
    border-bottom: ${({ theme, rolledUp }) => !rolledUp && `1px solid ${theme.colors.background.greyV3}`};
    padding: 0 16px;
  `}
`;

const BackdropWrap = styled(Backdrop)`
  ${mediaQueries.fromAndAbove.l`
    display:none;
  `}
`;

const Transition = styled(BaseTransition)`
  position: relative;
  height: ${({ rolledUp }) => (rolledUp ? '160px' : '0px')} !important;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: height 0.3s ease-in-out;

  ${mediaQueries.below.desktop`
      height: ${({ rolledUp }) => (rolledUp ? '136px' : '0px')} !important;
      position: fixed;
      bottom: 0;
      transition: height 0.3s ease-in-out;
      z-index: 5;
  `};
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import axis from 'axis.js';
import withControlledReactSelect from 'components/hocs/withControlledReactSelect';
import fetchDestinations from 'state/selectDestinations/operations/fetchDestinations';
import hydrateSelectedDestinations from 'state/selectDestinations/operations/hydrateSelectedDestinations';
import updateSelectedDestinations from 'state/selectDestinations/operations/updateSelectedDestinations';
import evalFetchCorrectCalendar from 'state/selectDates/operations/evalFetchCorrectCalendar';
import updateAvailableOriginsForSelectedDestinations from 'state/selectOrigins/operations/updateAvailableOriginsForSelectedDestinations';
import fetchHotelQuerySearch from 'state/hotelQuerySearch/operations/fetchHotelQuerySearch';
import { setHotelScoreReset } from 'state/searchResultsFilters/actions';
import { setMissingValuesForInput } from 'state/searchConditions/actions';
import { resetState as resetHotelQuerySearch } from 'state/hotelQuerySearch/actions';
import getAvailableCountries from 'state/selectDestinations/selectors/getAvailableCountries';
import formatDestinationsForReactSelect from 'state/selectDestinations/transforms/formatDestinationsForReactSelect';
import SelectDestinationsComponent from './SelectDestinationsComponent';
import getIsOptionSelected from 'utils/getIsOptionSelected';
import { isMobile as getIsMobile } from 'utils/isMobile';

class SelectDestinations extends Component {
  constructor(props) {
    super(props);

    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnFocusMobile = this.handleOnFocusMobile.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnInputChange = this.handleOnInputChange.bind(this);
  }

  async componentDidMount() {
    const { destinations, fetchDestinations } = this.props;
    const requests = [];

    if (destinations.length === 0) {
      requests.push(fetchDestinations());
    }

    try {
      await axios.all(requests);

      const {
        showAllOrigins,
        selectedOriginsLength,
        hydrateSelectedDestinations,
        updateAvailableOriginsForSelectedDestinations,
        evalFetchCorrectCalendar,
      } = this.props;

      hydrateSelectedDestinations();

      if (!showAllOrigins) {
        updateAvailableOriginsForSelectedDestinations();
      }

      if (selectedOriginsLength > 0) {
        evalFetchCorrectCalendar();
      }
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps) {
    const { inputValue } = this.props;
    const { inputValue: prevInputValue } = prevProps;

    if (inputValue !== prevInputValue) {
      this.handleOnInputChange(inputValue, { action: 'input-change' });
    }
  }

  handleOnFocus() {
    const { tooltipIsVisible, setMissingValuesForInput, onFocus } = this.props;

    if (tooltipIsVisible) {
      setMissingValuesForInput('selectDestinations', false);
    }

    if (axis.isFunction(onFocus)) {
      onFocus();
    }
  }

  handleOnFocusMobile() {
    this.handleOnFocus();
  }

  handleOnChange(selectedDestinations) {
    const { selectedDestinations: stateSelectedDestinations } = this.props;
    if (selectedDestinations && stateSelectedDestinations) {
      selectedDestinations = getIsOptionSelected(selectedDestinations, stateSelectedDestinations);
    }
    if (!selectedDestinations) {
      selectedDestinations = [];
    } else if (!axis.isArray(selectedDestinations)) {
      selectedDestinations = [selectedDestinations];
    }

    const {
      showAllOrigins,
      selectedOriginsLength,
      updateSelectedDestinations,
      setHotelScoreReset,
      updateAvailableOriginsForSelectedDestinations,
      evalFetchCorrectCalendar,
      onRequestClose,
    } = this.props;
    const lastSelectedDestination = selectedDestinations[selectedDestinations.length - 1];
    const lastSelectedDestinationIsHotel = lastSelectedDestination && lastSelectedDestination.hotel;

    updateSelectedDestinations(selectedDestinations);
    setHotelScoreReset(true);

    if (!showAllOrigins && !lastSelectedDestinationIsHotel) {
      updateAvailableOriginsForSelectedDestinations();
    }

    // If origins selected, update the react calendar
    if (selectedOriginsLength > 0) {
      evalFetchCorrectCalendar();
    }
    const isMobile = getIsMobile();
    (lastSelectedDestinationIsHotel || !isMobile) && onRequestClose();
  }

  handleOnInputChange(value, { action }) {
    const { fetchHotelQuerySearch, resetHotelQuerySearch, disableHotelSuggestSearch } = this.props;

    if (action === 'input-change') {
      if (value.length > 4) {
        // THIS IS TEMPORARY SOLUTION !!
        if (!disableHotelSuggestSearch) {
          fetchHotelQuerySearch(value);
        }
      } else {
        resetHotelQuerySearch();
      }
    } else if (action === 'menu-close') {
      resetHotelQuerySearch();
    }
  }

  render() {
    const {
      hotelQuerySearchResults,
      isFrontpage,
      headerHeight,
      airlineWhitelabel,
      forceSingleValue,
      tooltipIsVisible,
      instanceId,
      className,
      isDisabled,
      selectedOriginsLength,
    } = this.props;

    const availableCountries = getAvailableCountries(
      this.props.availableDestinations,
      this.props.destinationToCountryMap
    );
    const destinationOptions = formatDestinationsForReactSelect({
      destinations: this.props.destinations,
      availableCountriesCodes: availableCountries,
      availableDestinations: this.props.availableDestinations,
      selectedOriginsLength,
    });

    const hotelOptions = hotelQuerySearchResults.map(({ name, destination, destinationCode, hotelSlug }) => ({
      value: hotelSlug.replace(/^\//, ''),
      label: name,
      destination,
      destinationCode,
      hotel: true,
    }));

    const isMulti = forceSingleValue ? false : true;

    destinationOptions.hotels = hotelOptions;

    const _className = className ? className : 'select-destinations';

    const { inputValue, menuIsOpen, onChangeInputValue, onRequestClose } = this.props;

    return (
      <SelectDestinationsComponent
        destinationOptions={destinationOptions}
        selectedDestinations={this.props.selectedDestinations}
        isMulti={isMulti}
        tooltipIsVisible={tooltipIsVisible}
        onChange={this.handleOnChange}
        onFocus={this.handleOnFocus}
        onFocusMobile={this.handleOnFocusMobile}
        onInputChange={this.handleOnInputChange}
        airlineWhitelabel={airlineWhitelabel}
        isFrontpage={isFrontpage}
        headerHeight={headerHeight}
        instanceId={instanceId}
        className={_className}
        isDisabled={isDisabled}
        inputValue={inputValue}
        menuIsOpen={menuIsOpen}
        onChangeInputValue={onChangeInputValue}
        onRequestClose={onRequestClose}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    hotelQuerySearchResults: state.hotelQuerySearch.value.results,
    headerHeight: state.refs.headerHeight,
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    showAllOrigins: state.settings.value.showAllOrigins,
    tld: state.settings.value.tld,
    websiteId: state.settings.value.websiteId,
    selectedOriginsLength: state.selectOrigins.selectedOrigins.length,
    disableHotelSuggestSearch:
      state.settings.value.customSettings && state.settings.value.customSettings.disableHotelSuggestSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDestinations() {
      return dispatch(fetchDestinations());
    },
    hydrateSelectedDestinations() {
      dispatch(hydrateSelectedDestinations());
    },
    updateSelectedDestinations(selectedDestinations) {
      dispatch(updateSelectedDestinations(selectedDestinations));
    },
    updateAvailableOriginsForSelectedDestinations() {
      dispatch(updateAvailableOriginsForSelectedDestinations());
    },
    fetchHotelQuerySearch(name) {
      dispatch(fetchHotelQuerySearch(name));
    },
    resetHotelQuerySearch() {
      dispatch(resetHotelQuerySearch());
    },
    evalFetchCorrectCalendar() {
      dispatch(evalFetchCorrectCalendar());
    },
    setHotelScoreReset(reset) {
      dispatch(setHotelScoreReset(reset));
    },
    setMissingValuesForInput(input, value) {
      dispatch(setMissingValuesForInput(input, value));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withControlledReactSelect()(SelectDestinations));

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import { darken } from 'polished';
import Toggle from 'react-toggle';
import { rgba } from 'polished';

// role='dialog' aria-live='polite' aria-label='cookieconsent' aria-describedby='cookieconsent:desc'

function CookieSettingsComponent({
  onClickAcceptAll,
  onToggleAnalytics,
  onToggleMarketing,
  onSaveSettings,
  defaultAnalytics,
  defaultMarketing,
  isHolidayPirates,
}) {
  return (
    <Root>
      <SettingsBody>
        <Item>
          <ItemHeader>
            <ToggleWrap disabled>
              <Toggle defaultChecked={true} disabled={true} />
            </ToggleWrap>
            <ItemTitle>
              <FormattedMessage
                id={'cookieConsent.necessary.title'}
                description={'Necessary'}
                defaultMessage={'Necessary'}
              />
            </ItemTitle>
          </ItemHeader>
          <ItemBody>
            <FormattedMessage
              id={'cookieConsent.necessary.body'}
              description={
                'Necessary for our website to function correctly as you navigate. Therefore, you cannot opt out of them.'
              }
              defaultMessage={
                'Necessary for our website to function correctly as you navigate. Therefore, you cannot opt out of them.'
              }
            />
          </ItemBody>
        </Item>
        <Item>
          <ItemHeader>
            <ToggleWrap>
              <Toggle defaultChecked={defaultAnalytics} onChange={onToggleAnalytics} />
            </ToggleWrap>
            <ItemTitle>
              <FormattedMessage
                id={'cookieConsent.analytics.title'}
                description={'Analytics'}
                defaultMessage={'Analytics'}
              />
            </ItemTitle>
          </ItemHeader>
          <ItemBody>
            <FormattedMessage
              id={'cookieConsent.analytics.body'}
              description={
                'Helps us understand how visitors use our site, and to customize our web pages based on your previous choices.'
              }
              defaultMessage={
                'Helps us understand how visitors use our site, and to customize our web pages based on your previous choices.'
              }
            />
          </ItemBody>
        </Item>
        <Item>
          <ItemHeader>
            <ToggleWrap>
              <Toggle defaultChecked={defaultMarketing} onChange={onToggleMarketing} />
            </ToggleWrap>
            <ItemTitle>
              <FormattedMessage
                id={'cookieConsent.marketing.title'}
                description={'Marketing'}
                defaultMessage={'Marketing'}
              />
            </ItemTitle>
          </ItemHeader>
          <ItemBody>
            <FormattedMessage
              id={'cookieConsent.marketing.body'}
              description={
                'Enables us and third parties to show you personalized and relevant marketing content based on your usage of our website as well as other websites.'
              }
              defaultMessage={
                'Enables us and third parties to show you personalized and relevant marketing content based on your usage of our website as well as other websites.'
              }
            />
          </ItemBody>
        </Item>
        {isHolidayPirates && (
          <Item>
            <CookieDisclaimer>
              <FormattedMessage
                id={'cookieConsent.disclaimer'}
                description={'TripX Travel is responsible for data processing and tracking on this site.'}
                defaultMessage={'TripX Travel is responsible for data processing and tracking on this site.'}
              />
            </CookieDisclaimer>
          </Item>
        )}
      </SettingsBody>
      <SettingsFooter>
        <FooterButtons>
          <SaveSettings onClick={onSaveSettings}>
            <FormattedMessage
              id={'cookieConsent.buttons.saveSettings'}
              description={'Save cookies'}
              defaultMessage={'Save cookies'}
            />
          </SaveSettings>
          <AcceptAll onClick={onClickAcceptAll}>
            <FormattedMessage
              id={'cookieConsent.buttons.acceptAll'}
              description={'Accept all'}
              defaultMessage={'Accept all'}
            />
          </AcceptAll>
        </FooterButtons>
      </SettingsFooter>
    </Root>
  );
}

export default CookieSettingsComponent;

const Root = styled.div`
  background-color: ${({ theme }) => theme.colors.text.white};
`;
const SettingsBody = styled.div`
  padding: 18px 14px 10px 14px;
`;

const Item = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.grey};
  &:last-child {
    margin-bottom: 0px;
    border-bottom: 0px;
  }
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 17px;
  font-weight: 600;
`;

const ItemTitle = styled.div`
  margin-left: 10px;
`;
const ItemBody = styled.div`
  margin: 0 0 12px 57px;
  font-size: 15px;
`;

const SettingsFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.background.grey};
  padding: 10px 14px 10px 14px;
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;
`;

const AcceptAll = styled.div`
  line-height: 1;
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.text.white};
  background-color: ${({ theme }) => theme.colors.adjacent.default};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => darken('0.10', theme.colors.adjacent.default)};
  }
`;

const SaveSettings = styled.div`
  line-height: 1;
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.text.white};
  background-color: ${({ theme }) => theme.colors.secondary.default};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => darken('0.10', theme.colors.secondary.default)};
  }
`;

const ToggleWrap = styled.div`
  display: inline-block;
  vertical-align: middle;

  .react-toggle {
    touch-action: pan-x;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    .react-toggle-track {
      width: 46px;
      height: 22px;
      padding: 0;
      background-color: ${({ theme }) => theme.colors.background.placeholder};
      border-radius: 22px;
      transition: all 0.2s ease;
    }

    .react-toggle-thumb {
      position: absolute;
      top: -2px;
      left: -2px;
      width: 25px;
      height: 25px;
      background-color: ${({ theme }) => theme.colors.background.grey};
      border: none;
      border-radius: 50%;
      box-sizing: border-box;
      box-shadow: 0 1px 2px -1px rgba(89, 84, 77, 0.5);
      transition: all 0.25s ease;

      &:before {
        content: '';
        display: block;
        position: relative;
        left: 9px;
        top: 5px;
        width: 7px;
        height: 12px;
        border: solid ${(props) => props.theme.colors.background.grey};
        border-width: 0 3px 3px 0;
        z-index: 1;
        transform: rotate(45deg);
        transition: left 0.3s;
      }
    }

    .react-toggle-track-check,
    .react-toggle-track-x {
      display: none;
    }

    .react-toggle-screenreader-only {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }

  .react-toggle--checked {
    .react-toggle-track {
      background-color: ${({ theme, disabled }) =>
        disabled
          ? rgba(theme.colors.inputs.radio.backgroundChecked, 0.5)
          : theme.colors.inputs.radio.backgroundChecked};
    }

    .react-toggle-thumb {
      left: 25px;
    }
  }
`;
const CookieDisclaimer = styled.div`
  text-align: center;
  font-size: 12px;
  padding: 3px 0px;
  font-style: italic;
`;

import React, { Fragment } from 'react';
import LinkIntercept from 'components/linkIntercept/LinkIntercept';

function MenuLinkComponent({ data, onClick }) {
  return (
    <Fragment>
      {data.slug ? (
        <LinkIntercept to={`/${data.slug}`} onClick={onClick}>
          {data.title}
        </LinkIntercept>
      ) : (
        <a href={data.url} target={data.target} onClick={onClick}>
          {data.title}
        </a>
      )}
    </Fragment>
  );
}

export default MenuLinkComponent;

import React from 'react';
import { components } from 'react-select';

import { FormattedMessage } from 'i18n';

const Placeholder = (props) => (
  <components.Placeholder {...props}>
    <FormattedMessage id={'search.component.input.placeholder.destinations'} description='To' defaultMessage='To' />
  </components.Placeholder>
);

export default Placeholder;

import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'i18n';

function CloseButtonComponent({ showMessage, onClick, styles }) {
  return (
    <Root onClick={onClick} styles={styles}>
      {showMessage && (
        <Message>
          <FormattedMessage id='close.label' description='Close' defaultMessage='Close' />
        </Message>
      )}
      <FontAwesomeIcon icon='times' />
    </Root>
  );
}

export default CloseButtonComponent;

const Root = styled.button`
  user-select: none;
  display: flex;
  align-items: center;
  padding: 15px;
  background: none;
  border: none;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.darkGrey};
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `};
`;

const Message = styled.div`
  margin-right: 8px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary.dark};
`;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';

class Container extends Component {
  render() {
    const { fullWidth, style, children } = this.props;
    return (
      <Wrap fullWidth={fullWidth} style={style}>
        {children}
      </Wrap>
    );
  }
}

Container.propTypes = {
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
};

export default Container;

const Wrap = styled.div`
  position: relative;
  width: 100%;
  padding: 0 2rem;
  ${mediaQueries.fromAndAbove.tablet`
    width: ${(props) => (props.fullWidth ? '100%' : props.theme.containerWidths.tablet + 'px')};
    margin: 0 auto;
  `}
  ${mediaQueries.fromAndAbove.desktop`
    width: ${(props) => (props.fullWidth ? '100%' : props.theme.containerWidths.desktop + 'px')};
    padding: 0;
  `}
`;

/**
 * Processes label information about  selected origins.
 *
 * @param {Array} selectedOrigins - An array containing selected origins.
 * @returns {string|null} - Processed label string, or null if no label is found.
 
 */

import React from 'react';
import OverflowCounter from 'components/inputs/selectComponents/OverflowCounter';

const getSearchSummarySelectedOriginsLabel = (selectedOrigins) => {
  if (selectedOrigins && selectedOrigins.length > 0) {
    const overflowCounter = selectedOrigins.slice(1).length;
    return (
      <>
        {selectedOrigins[0].label}
        {overflowCounter > 0 && <OverflowCounter overflowCounter={overflowCounter} styles={{ marginLeft: '5px' }} />}
      </>
    );
  }
  return null;
};

export default getSearchSummarySelectedOriginsLabel;

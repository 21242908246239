import React from 'react';
import { useSelector } from 'react-redux';
import { Margin } from 'styled-components-spacing';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'i18n';
import getNumberOfAdultsAndChildren from 'state/selectRooms/selectors/getNumberOfAdultsAndChildren';
import getSearchSummarySelectedDestinationsLabel from '../utils/getSearchSummarySelectedDestinationsLabel';
import getSearchSummarySelectedOriginsLabel from '../utils/getSearchSummarySelectedOriginsLabel';

function SearchSummaryContent({ onClick }) {
  const { selectedOrigins } = useSelector((state) => state.selectOrigins);
  const { selectedDestinations } = useSelector((state) => state.selectDestinations);
  const { from, to } = useSelector((state) => state.selectDates.dates);
  const { rooms } = useSelector((state) => state.selectRooms);

  const numberOfRooms = rooms.length;
  const { numberOfAdults, numberOfChildren } = getNumberOfAdultsAndChildren(rooms);

  const numberOfTravelers = numberOfAdults + numberOfChildren;

  const selectedDestinationsLabel = getSearchSummarySelectedDestinationsLabel(selectedDestinations);
  const selectedOriginsLabel = getSearchSummarySelectedOriginsLabel(selectedOrigins);

  return (
    <Root onClick={onClick}>
      <GroupTop>
        <JourneyInfo>
          {selectedOriginsLabel || (
            <IconWrap>
              <FontAwesomeIcon icon='map-marker-alt' />
            </IconWrap>
          )}
        </JourneyInfo>
        <Separator>-</Separator>
        <JourneyInfo>
          {selectedDestinationsLabel || (
            <IconWrap>
              <FontAwesomeIcon icon='map-marker-alt' />
            </IconWrap>
          )}
        </JourneyInfo>
      </GroupTop>
      <GroupBottom>
        <Dates>
          <JourneyInfo>
            {from ? (
              from.format('D MMM')
            ) : (
              <IconWrap>
                <FontAwesomeIcon icon='calendar-alt' />
              </IconWrap>
            )}
          </JourneyInfo>
          <Separator>-</Separator>
          <JourneyInfo>
            {to ? (
              to.format('D MMM')
            ) : (
              <IconWrap>
                <FontAwesomeIcon icon='calendar-alt' />
              </IconWrap>
            )}
          </JourneyInfo>
        </Dates>
        <Rooms>
          <FormattedMessage
            id='selectRooms.component.numberOfTravelers.label'
            description='format for plural: {number, number} {number, plural, one {Traveler} other {Travelers}'
            defaultMessage={`{number, number} {number, plural,
            one {Traveler}
            other {Travelers}
          }`}
            values={{ number: numberOfTravelers }}
          />
          <Margin right='0.25' inline>
            ,
          </Margin>
          <FormattedMessage
            id='selectRooms.component.numberOfRooms.label'
            description='format for plural: {number, number} {number, plural, one {room} other {rooms}'
            defaultMessage={`{number, number} {number, plural,
                one {room}
                other {rooms}
              }`}
            values={{ number: numberOfRooms }}
          />
        </Rooms>
      </GroupBottom>
    </Root>
  );
}

export default SearchSummaryContent;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 8px 4px 10px;
  background-color: ${({ theme }) => theme.colors.background.light};
  color: ${({ theme }) => theme.colors.text.darkGrey};
  font-size: 14px;
  flex: 1;
  line-height: normal;
  border-radius: 8px;
  gap: 4px;
`;

const GroupTop = styled.div`
  display: flex;
  font-weight: 600;
  flex-wrap: wrap;
  align-items: center;
`;

const GroupBottom = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 12px;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 6px;
`;

const JourneyInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Separator = styled.div`
  margin: 0 4px;
`;

const Dates = styled.div`
  display: flex;
  padding-right: 6px;
  border-right: 1px solid ${({ theme }) => theme.colors.background.grey}; ;
`;

const Rooms = styled.div``;

const IconWrap = styled.div`
  color: ${({ theme }) => theme.colors.background.grey};
`;

import searchQueryLS from 'managers/localStorage/searchQuery/searchQueryLS';
import searchPropertiesKeys from 'state/_constants/searchPropertiesKeys';
import { refsActions } from 'state/refs';
import { setSelectedOrigins } from '../actions';

export default function updateSelectedOrigins(selectedOrigins, skipReset) {
  return (dispatch) => {
    dispatch(setSelectedOrigins(selectedOrigins));

    const storageValue = selectedOrigins.map((origins) => origins.value);
    searchQueryLS.update(searchPropertiesKeys.SELECTED_ORIGINS_KEY, storageValue);

    // Depending on the available space header height can change when adding/removing origins/destinations.
    // The header height is required to correctly place some absolutley positioned elements affected by the header.
    // Use this action to force update of the header height in redux.
    dispatch(refsActions.setHeaderHeight(0));
  };
}

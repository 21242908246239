import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IN_PROGRESS as FETCHING_STATUS_IN_PROGRESS } from 'constants/status/fetchingStatus';
import StageHotelResultsComponent from './StageHotelResultsComponent';

class StageHotelResults extends Component {
  render() {
    const { results, countriesAndDestinationsVisible, showCountriesAndDestinations, hotelQuerySearchFetching } =
      this.props;

    return (
      <StageHotelResultsComponent
        fetching={hotelQuerySearchFetching}
        results={results}
        countriesAndDestinationsVisible={countriesAndDestinationsVisible}
        showCountriesAndDestinations={showCountriesAndDestinations}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    hotelQuerySearchFetching: state.hotelQuerySearch.fetching === FETCHING_STATUS_IN_PROGRESS,
    inputValue: state.hotelQuerySearch.value.input,
  };
}

export default connect(mapStateToProps)(StageHotelResults);

import React, { Fragment } from 'react';
import { createMedia } from '@artsy/fresnel';
import { deviceWidths } from 'styles/utils/mediaQueries';

const AppMedia = createMedia({
  breakpoints: {
    xs: 0,
    sm: deviceWidths.phone,
    md: deviceWidths.tablet,
    lg: deviceWidths.desktop,
    lg2: deviceWidths.l,
    xl: deviceWidths.xl,
    xxl: deviceWidths.xxl,
  },
});

const { MediaContextProvider, Media } = AppMedia;

export default MediaContextProvider;

export const mediaStyle = AppMedia.createMediaStyle();

export const MediaQuery = Media;

export function MediaQueryWrap({ medias }) {
  return (
    <Fragment>
      {medias.map(({ key, query, component }) => (
        <Media key={key} {...query}>
          {component}
        </Media>
      ))}
    </Fragment>
  );
}

import React from 'react';
import styled from 'styled-components';
import SelectCommon from './SelectCommonComponent';

function SelectCommonNarrowComponent({ className, ...props }) {
  return <Root className={className} {...props} />;
}

export default SelectCommonNarrowComponent;

const Root = styled(SelectCommon)`
  .styled-select {
    &__control {
      display: flex;
      width: 220px;
      border: none;

      ::-webkit-scrollbar {
        width: 4px;
        background-color: white;
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.primary.default};
        border-radius: 10px;
      }
    }

    &__placeholder {
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    &__single-value {
      margin-left: 0;
    }
  }
`;

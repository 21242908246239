import React from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'i18n';
import { FRONTPAGE as FRONTPAGE_PAGE } from 'constants/pages';
import { mediaQueries } from 'styles/utils/mediaQueries';

function LogoutComponent({ pathToFrontpage, onClickLogout }) {
  return (
    <Root>
      <Link href={FRONTPAGE_PAGE} as={pathToFrontpage}>
        <ToFrontpage>
          <FontAwesomeIcon icon='arrow-left' />
          <Margin right='0.25' inline />
          <FormattedMessage id={'toFrontpage.label'} description={'To frontpage'} defaultMessage={'To frontpage'} />
        </ToFrontpage>
      </Link>
      <Margin right='1' inline />
      <LogoutButton onClick={onClickLogout}>
        <FormattedMessage id={'logout.label'} description={'Logout'} defaultMessage={'Logout'} />
      </LogoutButton>
    </Root>
  );
}

export default LogoutComponent;

const Root = styled.div`
  display: none;

  ${mediaQueries.fromAndAbove.tablet`
    display: block;
    margin-left: 15px;
  `}
`;

const ToFrontpage = styled.button`
  background: none;
  border: none;
  font-size: 1.4em;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary.dark};
  cursor: pointer;
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  font-size: 1.4em;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.buttons.red};
  cursor: pointer;
`;

import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import SecondaryButton from 'components/buttons/secondaryButton/SecondaryButtonComponent';
import RoomConfiguration from './components/roomConfiguration/RoomConfigurationComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';

function ContentComponent({ rooms, eventHandlers, numberOfAdults, numberOfChildren }) {
  const totalPax = numberOfAdults + numberOfChildren;
  const buttonAddRoomDisabled =
    (rooms[rooms.length - 1].numberOfAdults === 0 && rooms[rooms.length - 1].numberOfChildren === 0) || totalPax >= 9;

  return (
    <Root>
      {rooms.map((room, index, array) => (
        <RoomConfiguration
          key={`room-config-${index}`}
          {...{
            ...room,
            roomIndex: index,
            numberOfRooms: array.length,
            eventHandlers,
          }}
          totalPax={totalPax}
        />
      ))}
      <AddRoomButton onClick={eventHandlers.onClickAddRoom} disabled={buttonAddRoomDisabled}>
        <FormattedMessage
          id={'selectRooms.component.addRoom.button.label'}
          description={'Add Room'}
          defaultMessage={'Add Room'}
        />
      </AddRoomButton>
    </Root>
  );
}

export default ContentComponent;

const Root = styled.div`
  padding: 18px;
  overflow-y: auto;
  ${mediaQueries.fromAndBelow.tablet`
    flex:1;
  `}
`;

const AddRoomButton = styled(SecondaryButton)`
  display: flex;
  margin: 6px 0 0 auto;
  padding: 8px;
  color: ${({ theme }) => theme.colors.adjacent.default};
  border: none;
  background-color: transparent;
  font-weight: 600;
  border-radius: 8px;

  &:disabled,
  [disabled] {
    background-color: transparent;
  }
`;

import React, { Fragment } from 'react';
import styled from 'styled-components';
import CloseButton from 'components/buttons/closeButton/CloseButtonComponent';
import HeaderMenu from './HeaderMenu';

function HeaderMenuTransitionComponent({ headerMenuProps, transitionToCheckout, onClickClose }) {
  return (
    <Fragment>
      <HeaderMenu {...headerMenuProps} transitionToCheckout={transitionToCheckout} />
      <CloseButtonTransition transitionToCheckout={transitionToCheckout}>
        <CloseButton showMessage onClick={onClickClose} />
      </CloseButtonTransition>
    </Fragment>
  );
}

export default HeaderMenuTransitionComponent;

const CloseButtonTransition = styled.div`
  height: 100%;
  display: ${({ transitionToCheckout }) => (transitionToCheckout ? 'block' : 'none')};
  opacity: ${({ transitionToCheckout }) => (transitionToCheckout ? '1' : '0')};
  visibility: ${({ transitionToCheckout }) => (transitionToCheckout ? 'visible' : 'hidden')};
  transition: opacity 0.5s ease-out;
  z-index: 3;
`;

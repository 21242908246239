import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PriceBarsMonthGroupBar from './PriceBarsMonthGroupBar';
// import normalizeRangePercentage from 'utils/math/normalizeRangePercentage';
import flexbox from 'styles/utils/flexbox';
import moment from 'moment';

const propTypes = {
  monthTag: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      dow: PropTypes.string.isRequired,
      month: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      price: PropTypes.number.isRequired,
      days: PropTypes.number.isRequired,
      percentage: PropTypes.number.isRequired,
    })
  ).isRequired,
  minMax: PropTypes.arrayOf(PropTypes.number),
  onClickPriceBar: PropTypes.func.isRequired,
};

const PriceBarsMonthGroup = ({
  monthTag,
  items,
  minMax,
  onClickPriceBar,
  airlineWhitelabel,
  selectedBarRef,
  isInProgress,
  dates,
}) => (
  <PriceBarsMonthGroupStyled>
    <Header airlineWhitelabel={airlineWhitelabel}>{monthTag}</Header>
    <Bars>
      {items.map((result, index) => (
        <PriceBarsMonthGroupBar
          key={`${monthTag}-${index}`}
          index={index}
          dateDay={result.date.slice(result.date.lastIndexOf('-') + 1)}
          weekDay={result.dow}
          percentage={result.percentage}
          selected={isInProgress ? false : dates.from && dates.from.isSame(moment(result.date), 'day')}
          tooltipData={{
            date: result.date,
            price: result.price,
            days: result.days,
          }}
          onClickPriceBar={onClickPriceBar}
          selectedBarRef={selectedBarRef}
        />
      ))}
    </Bars>
  </PriceBarsMonthGroupStyled>
);

PriceBarsMonthGroup.propTypes = propTypes;

export default PriceBarsMonthGroup;

const headerFontSize = 15;
const headerPaddingHorizontal = 6;

const PriceBarsMonthGroupStyled = styled.div`
  display: inline-block;
  height: 100%;
  margin-right: 8px;
  vertical-align: bottom;
`;

const Header = styled.div`
  text-align: left;
  padding: 6px 0 3px 0;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.grey};
`;

const Bars = styled.div`
  ${flexbox({})}
  height: calc(100% - ${headerFontSize + headerPaddingHorizontal * 2}px);
  padding-top: 5px;
`;

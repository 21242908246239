import moment from 'moment';
import * as inputNames from 'state/checkoutPage/customerDetails/constants/inputNames';
import * as passengerTypes from '../constants/passengerTypes';
import getTraveler from './getTraveler';

export default function generateMockTravelers(selectedRooms, checkInDate) {
  return selectedRooms.map(({ adults, childages }, roomIndex) => {
    const _adults = Array.from(Array(adults)).map((item, index) =>
      getTraveler(true, passengerTypes.ADULT, index, roomIndex, {
        [inputNames.DATE_OF_BIRTH]: moment().subtract(30, 'years'),
      })
    );
    const _children = childages
      .filter((age) => age >= 2)
      .map((age, index) =>
        getTraveler(true, passengerTypes.CHILD, index, roomIndex, {
          [inputNames.DATE_OF_BIRTH]: moment(checkInDate).subtract(10, 'days').subtract(age, 'years'),
        })
      );
    const _infants = childages
      .filter((age) => age < 2)
      .map((age, index) =>
        getTraveler(true, passengerTypes.INFANT, index, roomIndex, {
          [inputNames.DATE_OF_BIRTH]: moment(checkInDate).subtract(10, 'days').subtract(age, 'years'),
        })
      );

    return {
      [passengerTypes.ADULT]: _adults,
      [passengerTypes.CHILD]: _children,
      [passengerTypes.INFANT]: _infants,
    };
  });
}

import React from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import { FormattedMessage } from 'i18n';
import a from 'i18n/richTextFormats/a';
import { mediaQueries } from 'styles/utils/mediaQueries';

function NotFoundComponent({ pathToFrontpage }) {
  return (
    <Root>
      <Exclamation>
        <FormattedMessage id={'pageNotFound.exclamation'} description={'Oops!'} defaultMessage={'Oops!'} />
      </Exclamation>
      <Margin bottom='2' />
      <Message>
        <FormattedMessage
          id={'pageNotFound.message'}
          description={"We can't seem to find the page you're looking for."}
          defaultMessage={"We can't seem to find the page you're looking for."}
        />
      </Message>
      <Margin bottom='2' />
      <HelpfulLinksMessage>
        <FormattedMessage
          id={'pageNotFound.helpfulLinks.message'}
          description={'Try going back to the home <a>page</a>'}
          defaultMessage={'Try going back to the home <a>page</a>'}
          values={{
            a: a(pathToFrontpage),
          }}
        />
        .
      </HelpfulLinksMessage>
    </Root>
  );
}

export default NotFoundComponent;

const Root = styled.div`
  width: 100%;
  padding: 32px;
  text-align: center;

  ${mediaQueries.fromAndAbove.desktop`
		width: 920px;
		margin: 64px auto;
		padding: 0;
		text-align: left;
	`}
`;

const Exclamation = styled.div`
  font-size: 6.4em;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.templates.header};

  ${mediaQueries.fromAndAbove.desktop`
		font-size: 8.4em;
	`}
`;

const Message = styled.div`
  font-size: 2.4em;
  color: ${({ theme }) => theme.colors.text.templates.header};

  ${mediaQueries.fromAndAbove.desktop`
		font-size: 3.4em;
	`}
`;

const HelpfulLinksMessage = styled.div`
  font-size: 1.8em;
  color: ${({ theme }) => theme.colors.text.default};
  & > a {
    font-weight: 600;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'styles/utils/mediaQueries';
import formatDataAttributes from 'utils/object/formatDataAttributes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'i18n';

function ChildAgeComponent({ age, data, onClick }) {
  return (
    <Root>
      <ChildAgeWrap>
        <ChildTitle>
          <FormattedMessage id={'chd.label'} description={'Child'} defaultMessage={'Child'} />
        </ChildTitle>
        <ChildAge>
          (
          <FormattedMessage
            id={'yearsOld.label'}
            description={'{age} years old'}
            defaultMessage={'{age} years old'}
            values={{ age }}
          />
          )
        </ChildAge>
      </ChildAgeWrap>
      <CloseButton onClick={onClick} data-update-value={-1} {...formatDataAttributes(data)}>
        <FontAwesomeIcon icon='times' />
      </CloseButton>
    </Root>
  );
}

export default ChildAgeComponent;

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0;
`;
const ChildAgeWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ChildTitle = styled.div`
  font-size: 16px;
  font-weight: 400;

  color: ${({ theme }) => theme.colors.text.darkGrey};

  ${mediaQueries.fromAndAbove.desktop`
    font-size: 1.6em;
  `}
`;
const ChildAge = styled.div`
  font-size: 14px;
  font-weight: 400;

  color: ${({ theme }) => theme.colors.text.grey};

  ${mediaQueries.fromAndAbove.desktop`
    font-size: 1.2em;
  `}
`;

const CloseButton = styled.button`
  user-select: none;
  display: flex;
  align-items: center;
  padding: 15px;
  background: none;
  border: none;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.darkGrey};
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

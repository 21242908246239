import { getMenu } from 'httpClient/requests/menu';
import * as fetchingStatus from 'constants/status/fetchingStatus';
import transformMenuResponse from '../transforms/transformMenuResponse';
import { setFetching, setMenu } from '../actions';

export default function fetchMenu(menuName) {
  return async (dispatch, getState) => {
    const fetching = getState().menus[menuName].fetching;

    if (fetching === fetchingStatus.IN_PROGRESS) {
      return;
    }
    dispatch(setFetching(menuName, fetchingStatus.IN_PROGRESS));

    const { customMenu = {} } = getState().settings.value.customSettings || {};

    const requiredMenu = customMenu[menuName] || menuName;

    try {
      const { data } = await getMenu(requiredMenu);
      const menu = data.items.map(transformMenuResponse);
      dispatch(setMenu(menuName, menu));
      dispatch(setFetching(menuName, fetchingStatus.SUCCEEDED));
    } catch (error) {
      console.error(error);
      dispatch(setFetching(menuName, fetchingStatus.FAILED));
    }
  };
}

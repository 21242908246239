import React from 'react';
import styled from 'styled-components';
import { Margin } from 'styled-components-spacing';
import flexbox from 'styles/utils/flexbox';

import flagDK from 'static/images/flags/flag-dk.svg';
import flagSE from 'static/images/flags/flag-se.svg';
import flagNO from 'static/images/flags/flag-no.svg';
import flagFI from 'static/images/flags/flag-fi.svg';
import flagUK from 'static/images/flags/flag-uk.svg';
import flagEU from 'static/images/flags/flag-eu.svg';

const items = [
  { href: 'https://www.tripx.dk/', domain: 'dk', flagImage: flagDK, imageAlt: 'danish flag' },
  { href: 'https://www.tripx.se/', domain: 'se', flagImage: flagSE, imageAlt: 'swedish flag' },
  { href: 'https://www.tripx.no/', domain: 'no', flagImage: flagNO, imageAlt: 'norwegian flag' },
  { href: 'https://www.tripx.fi/', domain: 'fi', flagImage: flagFI, imageAlt: 'finnish flag' },
  { href: 'https://www.tripx.co.uk/', domain: 'uk', flagImage: flagUK, imageAlt: 'uk flag' },
  { href: 'https://www.tripx.eu/', domain: 'eu', flagImage: flagEU, imageAlt: 'eu flag' },
];

function LocaleFlagsComponent({ hostname }) {
  const hrefs = items.map(({ href }) => href);
  const isTripX = process.browser && hrefs.indexOf(window.location.href) !== -1;
  const domainArray = hostname.split('.');

  if (!isTripX) {
    return null;
  }

  return (
    <Margin top={{ tiny: 1 }} right={{ tiny: 0, phone: 2 }}>
      <Root>
        {items.map((item) => (
          <LocaleFlagItemComponent {...item} currentDomain={domainArray[domainArray.length - 1]} />
        ))}
      </Root>
    </Margin>
  );
}

function LocaleFlagItemComponent({ href, domain, currentDomain, flagImage, imageAlt }) {
  return (
    <FlagItem>
      <a href={href} style={{ opacity: domain === currentDomain ? 1 : '' }}>
        <div>
          <img src={flagImage} alt={imageAlt} />
        </div>
      </a>
    </FlagItem>
  );
}

export default LocaleFlagsComponent;

const Root = styled.div`
  ${flexbox({
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
  })}
`;

const FlagItem = styled.div`
  flex: 1 0 33%;
  width: 30%;
  max-width: 48px;
  margin-right: 16px;
  border-radius: 4px;
  overflow: hidden;
  opacity: 0.2;
  transition: opacity 100ms ease-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export default function (href) {
  const element = document.createElement('a');
  element.href = href;

  return {
    href: element.href,
    hostname: element.hostname,
    pathname: element.pathname,
  };
}

import proxy from 'httpClient/requests';
import HttpClient from 'httpClient';
import { urlBuilder } from 'httpClient/aws/getAwsEndpoint';
import setTripxCacheKey from 'httpClient/aws/headers/setTripxCacheKey';

const methodProxyCache = '/pc';
const destinationsMethod = 'destinations';

export function getDestinations(websiteId) {
  const config = { headers: setTripxCacheKey({ method: destinationsMethod, websiteId }) };
  const url = urlBuilder({ method: methodProxyCache, queryParam: null });

  return HttpClient.post(url, { httpMethod: 'GET', timeout: 20, version: 3, apiMethod: destinationsMethod }, config);
}

export function getDestination(destinationCode) {
  return proxy({
    httpMethod: 'GET',
    timeout: 20,
    version: 3,
    apiMethod: `destinations/${destinationCode}`,
  });
}

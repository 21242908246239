import React from 'react';
import styled from 'styled-components';

import InfiniteCircleDots from 'components/spinners/InfiniteCircleDots';

export default function PriceBarsSpinner() {
  return (
    <Root>
      <InfiniteCircleDots removeMargin useAdjacentColor />
    </Root>
  );
}

const Root = styled('div')({
  position: 'absolute',
  backdropFilter: 'blur(2px)',
  top: 0,
  zIndex: 3,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function requestCancellationProtections(fetching) {
  return {
    type: types.REQUEST_CANCELLATION_PROTECTIONS,
    payload: {
      fetching,
    },
  };
}

export function setCancellationProtections(cancellationProtections) {
  return {
    type: types.SET_CANCELLATION_PROTECTIONS,
    payload: {
      cancellationProtections,
    },
  };
}

export function setCurrentTokens(currentTokens) {
  return {
    type: types.SET_CURRENT_TOKENS,
    payload: {
      currentTokens,
    },
  };
}

export function setValidity(validity) {
  return {
    type: types.SET_VALIDITY,
    payload: {
      validity,
    },
  };
}

export function setLocked(locked) {
  return {
    type: types.SET_LOCKED,
    payload: {
      locked,
    },
  };
}

export function resetState() {
  return {
    type: types.RESET_STATE,
  };
}

import moment from 'moment';

import { SPECIFIC_DATES_CALENDAR, PERIOD_CALENDAR } from '../constants/calendarSwitch';
import { MAX_RANGE_DAYS } from './maxRangeDays';
import isSameDay from './isSameDay';

// Function to determine if a date should be disabled
export default function isDateDisabled(day, fromDate, toDate, flexibleRange, focusedInput, calendarOption) {
  let disabled = false;

  if (focusedInput === 'endDate') {
    // Calculate the end of the flexible range from 'fromDate'
    const fromDatePlusRange = moment(fromDate)
      .clone()
      .add(flexibleRange - 1, 'days');
    disabled = !day.isBetween(moment(), fromDate, '[]') && !day.isAfter(fromDatePlusRange, 'day');
  }

  // if (focusedInput === 'startDate') {
  //   // Calculate the beginning of the flexible range from 'toDate'
  //   disabled = day.diff(toDate, 'days') < 0 && day.diff(toDate, 'days') > -flexibleRange;
  // }
  const maxDate = fromDate && fromDate.clone().add(MAX_RANGE_DAYS, 'days');
  const isOutOfRange =
    (focusedInput !== 'startDate' && fromDate && !toDate && day.isAfter(maxDate, 'day')) ||
    (focusedInput !== 'endDate' && !fromDate && toDate && day.diff(toDate, 'days') < -MAX_RANGE_DAYS);

  return (
    (calendarOption === PERIOD_CALENDAR && disabled) ||
    (calendarOption === SPECIFIC_DATES_CALENDAR && isOutOfRange) ||
    isSameDay(moment(), day)
  );
}

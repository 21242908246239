import React from 'react';
import styled from 'styled-components';
import SelectCommon from './SelectCommonComponent';

function SelectCommonSingleValueComponent({ className, ...props }) {
  return <Root className={className} {...props} />;
}

export default SelectCommonSingleValueComponent;

const Root = styled(SelectCommon)`
  .styled-select {
    &__control {
      flex-direction: row-reverse;
      border: none;
      width: 220px;

      @media (max-width: 1080px) {
        width: 200px;
      }
    }

    &__value-container {
      margin: 0;
    }

    &__placeholder {
      margin: 0;
      padding-top: 12px;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    &__single-value {
      font-size: 18px;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.primary.dark};
      padding-top: 12px;
      margin: 0;
    }

    &__indicators {
      display: flex;
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      width: 18px;
      height: 18px;
      margin-left: 16px;
      padding: 0;
      background-color: ${({ theme }) => theme.colors.primary.default};
      border-radius: 50%;

      svg {
        width: 14px;
        height: 14px;
        margin: 2px;
        color: ${({ theme }) => theme.colors.primary.dark};
      }
    }
  }
`;

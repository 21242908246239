import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectOrigins from '../components/SelectOrigins/SelectOrigins';
import SelectDestinations from '../components/SelectDestinations/SelectDestinations';
import SelectRoomConfigurations from '../components/selectRoomConfigurations/SelectRoomConfigurations';
import SelectDates from '../components/SelectDates/SelectDates';
import SearchButton from '../components/SearchButton/SearchButton';
import { mediaQueries } from 'styles/utils/mediaQueries';
import flexbox from 'styles/utils/flexbox';

const propTypes = {
  isFrontpage: PropTypes.bool.isRequired,
};

const SearchComponent = ({
  isFrontpage,
  isHotelPage,
  isSearchPage,
  inputsHidden,
  onClickClose,
  airlineWhitelabel,
  isBorderLess,
}) => (
  <Container isFrontpage={isFrontpage}>
    <ContainerInputs isFrontpage={isFrontpage} isBorderLess={isBorderLess}>
      <InputGroupTop isFrontpage={isFrontpage}>
        <SelectOrigins isFrontpage={isFrontpage} />
        <Plane>
          <FontAwesomeIcon icon='plane-departure' />
          <Cover />
        </Plane>
        <SelectDestinations isFrontpage={isFrontpage} />
      </InputGroupTop>
      <InputGroupBottom isFrontpage={isFrontpage}>
        <SelectDates isFrontpage={isFrontpage} isSearchPage={isSearchPage} />
        <SelectRoomConfigurations isFrontPage={isFrontpage} isHotelPage={isHotelPage} />
      </InputGroupBottom>
    </ContainerInputs>
    <SearchButton
      isFrontpage={isFrontpage}
      isHotelPage={isHotelPage}
      isSearchPage={isSearchPage}
      inputsHidden={inputsHidden}
      onClickClose={onClickClose}
      airlineWhitelabel={airlineWhitelabel}
      styles={{}}
    />
  </Container>
);

SearchComponent.propTypes = propTypes;

export default SearchComponent;

const Container = styled.div`
  display: ${({ isFrontpage }) => (isFrontpage ? 'block' : 'flex')};
  justify-content: ${({ isFrontpage }) => (isFrontpage ? 'unset' : 'center')};
  padding: ${({ isFrontpage }) => (isFrontpage ? '0' : '10px 0')};

  ${mediaQueries.below.l`
    width: 100%;
    flex-direction: ${({ isFrontpage }) => (isFrontpage ? 'unset' : 'column')};
    height: initial;
    gap: 9px;
  `}
`;

const ContainerInputs = styled.div`
  ${flexbox({
    flexDirection: 'row',
    justifyContent: 'space-between',
  })}
  flex-wrap: ${({ isFrontpage }) => (isFrontpage ? 'wrap' : 'nowrap')};
  width: ${({ isFrontpage }) => (isFrontpage ? '100%' : '1024px')};
  border: ${({ theme, isBorderLess }) => (isBorderLess ? 0 : `4px solid ${theme.colors.adjacent.default}`)};
  border-width: ${({ isBorderLess }) => (isBorderLess ? 0 : '4px')};
  border-radius: ${({ isBorderLess }) => (isBorderLess ? '16px' : '0.5rem')};

  ${mediaQueries.below.l`
    width: 100%;
    flex-wrap:wrap;
    border-width: ${({ isFrontpage }) => (isFrontpage ? '4px' : '0')};
    ${({ isFrontpage }) =>
      isFrontpage &&
      `
      flex-direction: column;
    `}
    ${({ isFrontpage }) =>
      !isFrontpage &&
      `
      gap: 9px;
    `}
  `}

  @media only screen and (max-width: 1439px) and (min-width: 1280px) {
    flex-wrap: ${({ isFrontpage }) => (isFrontpage ? 'wrap' : 'nowrap')};
    width: ${({ isFrontpage }) => (isFrontpage ? '100%' : '862px')};
  }
`;

const InputGroupTop = styled.div`
  ${flexbox({
    flexDirection: 'row',
  })};
  flex: 1 1 auto;
  position: relative;
  width: ${(props) => (props.isFrontpage ? '100%' : 'initial')};

  .select-origins-mobile,
  .select-destinations-mobile {
    width: 100vw;
    display: inline-block;
    font-size: 0.7em;
    padding: 16px 0;
  }

  ${mediaQueries.below.l`
    width: 100%
  `};

  ${mediaQueries.fromAndBelow.tablet`
     ${flexbox({
       flexDirection: 'column',
     })};
     
     .select-origins,
     .select-destinations {
       width: 100%;
     }
  `};
`;

const InputGroupBottom = styled.div`
  flex: 0 1 auto;
  width: ${({ isFrontpage }) => (isFrontpage ? '100%' : 'initial')};
  display: flex;

  ${mediaQueries.below.l`
    width: 100%
    ${({ isFrontpage }) =>
      !isFrontpage &&
      `
      ${flexbox({
        flexDirection: 'column',
      })}
      gap:9px;
    `}
  `}

  ${mediaQueries.fromAndBelow.tablet`
    ${({ isFrontpage }) =>
      isFrontpage &&
      `
      ${flexbox({
        flexDirection: 'column',
      })}
    `}
  `}
`;

const Plane = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 19px;
  height: 13px;
  transform: translate(-50%, -30%);
  z-index: 1;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text.light};

  ${mediaQueries.fromAndBelow.tablet`
    display: none;
 `};
`;

const Cover = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0%;
  width: 24px;
  height: 4px;
  transform: translate(-50%, 100%);
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.background.light};
`;

import moment from 'moment';
import { getPriceBarsPaggination } from 'httpClient/requests/priceBars';
import buildSearchResultsRequestParams from 'state/searchResults/selectors/buildSearchResultsRequestParams';
import { updatePriceBarsResult } from '../actions';
import groupByMonth from '../selectors/groupByMonth';
import findMinMax from 'utils/array/findMinMax';

export default function fetchPriceBarsPaggination(isBarsPast) {
  return (dispatch, getState) => {
    const { selectOrigins, selectDestinations, selectDates, selectRooms, searchResultsFilters, priceBars } = getState();

    const params = buildSearchResultsRequestParams(
      selectOrigins.selectedOrigins,
      selectDestinations.selectedDestinations,
      selectDates.dates,
      selectRooms.rooms,
      searchResultsFilters,
      null,
      {
        calendarOption: selectDates.calendarOption,
      }
    );

    const queryDate = getQueryDateForPaggination(isBarsPast, priceBars);

    return getPriceBarsPaggination(queryDate, params, { isBarsPast })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const { minMax } = priceBars;
          const resultsWithMinMax =
            minMax.length === 2
              ? [...response.data, { percentage: minMax[0] }, { percentage: minMax[1] }]
              : response.data;
          const updatedMinMax = findMinMax(resultsWithMinMax, 'percentage');

          const result = groupByMonth(response.data);
          dispatch(updatePriceBarsResult(result, isBarsPast, updatedMinMax));
        }
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

function getQueryDateForPaggination(isBarsPast, priceBars) {
  let queryDate = null;

  if (!isBarsPast) {
    const lastResult = priceBars.result[priceBars.result.length - 1];
    const lastResultLastItem = lastResult.items[lastResult.items.length - 1];
    queryDate = lastResultLastItem.date;
    queryDate = moment(queryDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
  } else {
    if (priceBars.result && priceBars.result.length > 0) {
      queryDate = priceBars.result[0].items[0].date;
      queryDate = moment(queryDate, 'YYYY-MM-DD').add(-1, 'days').format('YYYY-MM-DD');
    }
  }

  return queryDate;
}

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const propTypes = {
  isNavNext: PropTypes.bool,
  isNavPrev: PropTypes.bool,
};

const CustomMonthNav = ({ isNavNext, isNavPrev, matches }) => {
  if (matches) {
    return null;
  }
  return (
    <Fragment>
      {isNavPrev ? (
        <ChevronLeft
          style={{
            left: 17,
          }}
        >
          <FontAwesomeIcon icon='chevron-left' />
        </ChevronLeft>
      ) : null}
      {isNavNext ? (
        <ChevronRight
          style={{
            right: 17,
          }}
        >
          <FontAwesomeIcon icon='chevron-right' />
        </ChevronRight>
      ) : null}
    </Fragment>
  );
};

CustomMonthNav.propTypes = propTypes;

export default CustomMonthNav;

// Styled components
const ChevronBase = styled.span`
  position: absolute;
  top: 18px;
  width: 26px;
  height: 26px;
  padding: 4px 0;
  background-color: ${(props) => props.theme.colors.adjacent.default};
  border-radius: 4px;
  font-size: 18px;
  line-height: 0.78;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.white};
`;

const ChevronRight = styled(ChevronBase)``;

const ChevronLeft = styled(ChevronBase)``;

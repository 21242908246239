import React from 'react';
import styled from 'styled-components';
import { deviceWidths } from 'styles/utils/mediaQueries';
import MediaQuery from 'react-responsive';

export const ClearIndicator = (props) => {
  return (
    <MediaQuery maxWidth={deviceWidths.tablet}>
      {(matches) => {
        const { clearValue } = props;

        const handleClearClick = (event) => {
          event.preventDefault();
          event.stopPropagation();
          clearValue();
        };

        if (matches) {
          return (
            <Root onTouchEnd={handleClearClick} onMouseDown={handleClearClick}>
              <span>&times;</span>
            </Root>
          );
        }

        return (
          <DesktopRoot
            onTouchEnd={handleClearClick}
            onMouseDown={handleClearClick}
            isFrontpage={props.selectProps.data.isFrontpage}
          >
            <span>&times;</span>
          </DesktopRoot>
        );
      }}
    </MediaQuery>
  );
};

const Root = styled.div`
  color: ${({ theme }) => theme.colors.background.greyV4};
  font-size: 24px;
  z-index: 3;
  padding: 5px 10px;
  margin-bottom: 2px;
`;

const DesktopRoot = styled.button`
  padding: 2px;
  color: ${({ theme }) => theme.colors.primary.dark};
  background: transparent;
  border: none;
  margin-right: ${({ isFrontpage }) => (isFrontpage ? '14px' : '6px')};
  margin-top: ${({ isFrontpage }) => (isFrontpage ? 'unset' : '4px')};
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
`;

/**
 * Get the message content for the search button.
 *
 * @param {React.ReactNode} content - The content for the button (ex. FA search icon). If provided, this content is returned.
 * @param {boolean} inputsHidden - Whether the search inputs are hidden.
 * @param {boolean} isSearchPage - Whether the current page is a search page.
 * @param {boolean} isHotelPage - Whether the current page is a hotel page.
 *
 * @returns {React.ReactNode|string}
 */
export const getSearchButtonMessageContent = (content, inputsHidden, isSearchPage, isHotelPage) => {
  const message = {};

  if (content) {
    return content;
  }

  if (inputsHidden) {
    message.id = 'search.component.newSearch.button.label';
    message.description = 'New Search';
    message.defaultMessage = 'New Search';
  } else if (isSearchPage || isHotelPage) {
    message.id = 'search.component.searchAgain.button.label';
    message.description = 'Search again';
    message.defaultMessage = 'Search again';
  } else {
    message.id = 'search.component.search.button.label';
    message.description = 'Search';
    message.defaultMessage = 'Search';
  }

  return message;
};

export default {
  'TripX SE': { ID: 6 },
  'TripX NO': { ID: 10 },
  'TripX DK': { ID: 14 },
  'Reseguiden SE': { ID: 17 },
  'Reseguiden NO': { ID: 18 },
  'Internal tools/search robot': { ID: 19 },
  'KAYAK SE': { ID: 22 },
  'NH NO': { ID: 24, calendarDaysBlocked: true },
  'NH SE': { ID: 25, calendarDaysBlocked: true },
  'NH DK': { ID: 26, calendarDaysBlocked: true },
  'NH FI': { ID: 27, calendarDaysBlocked: true },
  'NH UK': { ID: 28, calendarDaysBlocked: true },
  'NH EU': { ID: 29, calendarDaysBlocked: true },
  'Let`s Deal AB': { ID: 30 },
  'Let`s Deal AS': { ID: 31 },
  'Rejsespejder ApS': { ID: 32 },
  Turkietresor: { ID: 34 },
  FraTromsø: { ID: 35 },
  'KAYAK DK': { ID: 36 },
  'KAYAK NO': { ID: 37 },
  'TripX UK': { ID: 43 },
  'TripX FI': { ID: 44 },
  'TripX EU': { ID: 50 },
  'AMH UK': { ID: 52, calendarDaysBlocked: true },
  'AMH EU': { ID: 53, calendarDaysBlocked: true },
  'AMH MT': { ID: 54, calendarDaysBlocked: true },
  'AMH DE': { ID: 55, calendarDaysBlocked: true },
  'AMH FR': { ID: 56, calendarDaysBlocked: true },
  'AMH IT': { ID: 57, calendarDaysBlocked: true },
  'Big Travel': { ID: 60 },
  'Dohop EN': { ID: 61 },
  SealFX: { ID: 62 },
  'Vola.ro': { ID: 64 },
  'Lugi Handboll': { ID: 65 },
  'Air Baltic': { ID: 66, calendarDaysBlocked: true },
  'Air Baltic FI': { ID: 77, calendarDaysBlocked: true },
  'Air Baltic SE': { ID: 101, calendarDaysBlocked: true },
  'Air Baltic NO': { ID: 102, calendarDaysBlocked: true },
  'Air Baltic DK': { ID: 103, calendarDaysBlocked: true },
  'Hillman Travel EU': { ID: 69 },
  'Hillman Travel NL': { ID: 70 },
  'Sweetdeal Rejser': { ID: 71 },
  'Cyprus Holiday Deals EU': { ID: 72, calendarDaysBlocked: true },
  'Cyprus Holiday Deals GR': { ID: 73, calendarDaysBlocked: true },
  'Cyprus Holiday Deals FR': { ID: 79, calendarDaysBlocked: true },
  'Cyprus Holiday Deals IT': { ID: 80, calendarDaysBlocked: true },
  'Hillman Travel FR': { ID: 74 },
  'BE Hillman Travel NL': { ID: 75 },
  'BE Hillman Travel FR': { ID: 76 },
  'Amano Holidays': { ID: 81 },
  'Offerilla Holidays': { ID: 82 },
  'Holiday Pirates': { ID: 83 },
  Wakacje: { ID: 88 },
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import axis from 'axis.js';
import { heartbeat } from 'styles/utils/animations';
import heartOutline from 'static/icons/heart-outline.svg';
import greyOutline from 'static/icons/heart-grey-outline.svg';
import heart from 'static/icons/heart.svg';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import { CLICKED_ADD_TO_FAVORITES } from 'managers/gtmEventTracking/constants/eventsNames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const propTypes = {
  static: PropTypes.bool,
  greyOutline: PropTypes.bool,
};

class Heart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hearted: this.props.hearted || false,
    };

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { hearted } = this.props;
    const { hearted: prevHearted } = prevProps;

    if (hearted !== prevHearted) {
      this.setState({
        hearted: hearted,
      });
    }
  }

  handleOnClick(event) {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();

    const { onClickRemoveHearted, onClickAddHearted, executeTrackingEvent } = this.props;
    const { hearted } = this.state;

    if (this.props.static) {
      return;
    }

    if (hearted && axis.isFunction(onClickRemoveHearted)) {
      onClickRemoveHearted();
    } else if (!hearted && axis.isFunction(onClickAddHearted)) {
      onClickAddHearted();
      executeTrackingEvent(CLICKED_ADD_TO_FAVORITES);
    }

    this.setState({
      hearted: !hearted,
    });
  }

  render() {
    const { static: isStatic, greyOutline: isGreyOutline, isFrontpage, isHotelPage, isCheckoutPage } = this.props;
    const { hearted } = this.state;

    const src = hearted || isStatic ? heart : !isGreyOutline ? heartOutline : greyOutline;
    const hasAnimation = !isStatic && hearted;

    if (isStatic && !isFrontpage) {
      return (
        <Root hasAnimation={hasAnimation} isHotelPage={isHotelPage} isCheckoutPage={isCheckoutPage}>
          <FontAwesomeIcon icon={['far', 'heart']} />
        </Root>
      );
    }

    return <ImageRoot src={src} alt='save package' hasAnimation={hasAnimation} onClick={this.handleOnClick} />;
  }
}

function mapDispatchToProps(dispatch) {
  return {
    executeTrackingEvent(eventName, eventData) {
      dispatch(executeTrackingEvent(eventName, eventData));
    },
  };
}

Heart.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(Heart);

const Root = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => (theme.colors.heart && theme.colors.heart.primary) || theme.colors.adjacent.default};
  width: ${({ isHotelPage, isCheckoutPage }) => (isCheckoutPage || isHotelPage ? '34px' : '42px')};
  height: ${({ isHotelPage, isCheckoutPage }) => (isCheckoutPage || isHotelPage ? '34px' : '48px')};
  font-size: ${({ isHotelPage, isCheckoutPage }) => (isCheckoutPage || isHotelPage ? '16px' : '20px')};
  border-radius: 8px;
  border: none;
  box-shadow: 0 0 0 1px
    ${({ theme }) => (theme.colors.heart && theme.colors.heart.primary) || theme.colors.adjacent.default};
  cursor: pointer;
  background-color: transparent;
`;

const ImageRoot = styled.img`
  width: 100%;
  animation: ${(props) => (props.hasAnimation ? heartbeat : undefined)} 1s ease-out;
`;

import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PrimaryButton from 'components/buttons/primaryButton/PrimaryButton';

export default function BasicAuth() {
  const customSettings = useSelector((state) => state.settings.value.customSettings);
  const [value, setValue] = useState('');
  const [basicAuthPassword, setBasicAuthPassword] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setBasicAuthPassword(localStorage.getItem('basic_auth_password'));
      setLoading(false);
    }
  }, []);

  const handleInputChange = (e) => {
    setValue(e.target.value);
    if (!e.target.value) {
      setHasError(true);
      setErrorMessage('This field is required');
    } else {
      setHasError(false);
    }
  };

  const handleOnClick = () => {
    if (!value || value !== customSettings.websitePassword) {
      setHasError(true);
      setErrorMessage('Wrong password');
    } else {
      localStorage.setItem('basic_auth_password', value);
      window.location.reload();
    }
  };

  if (loading) {
    return null;
  }

  return (
    <div className='basicAuthWrapper'>
      {!customSettings || (customSettings && !customSettings.websitePassword) || basicAuthPassword ? null : (
        <ModalOverlay>
          <ModalContainer>
            <InputWrapper>
              <StyledInputLabel htmlFor='password'>
                <FormattedMessage
                  id='basicAuth.password.placeholder'
                  description={'Password placeholder'}
                  defaultMessage={'Enter password'}
                />
              </StyledInputLabel>
              <StyledInput
                type='password'
                value={value}
                hasError={hasError}
                onChange={handleInputChange}
                name='password'
              />
              {hasError && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <SubmitButton onClick={handleOnClick}>
                <FormattedMessage id={'basicAuth.submit'} description={'Submit'} defaultMessage={'Submit'} />
              </SubmitButton>
            </InputWrapper>
          </ModalContainer>
        </ModalOverlay>
      )}
    </div>
  );
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const InputWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const StyledInput = styled.input`
  padding: 10px;
  margin-top: 8px;
  border: 1px solid ${(props) => (props.hasError ? 'red' : 'gray')};
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  width: 100%;
`;

const StyledInputLabel = styled.label``;

const ErrorMessage = styled.span`
  display: block;
  color: red;
  margin-top: 5px;
`;

const SubmitButton = styled(PrimaryButton)`
  margin-top: 16px;
  width: 100%;
`;

import { css } from 'styled-components';

export default css`
  .input-tooltip-v2 {
    position: absolute;
    border-radius: 2px;
    z-index: 4;

    .input-tooltip-v2-arrow {
      position: absolute;
      width: 0;
      height: 0;
      bottom: 0;
      left: 15%;
    }
  }
`;

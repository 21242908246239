/**
 * Processes label information about  selected destinations.
 *
 * @param {Array} selectedDestinations - An array containing selected destinations.
 * @returns {string|ReactElement|null} - Processed label string or React element (which is string from i18n), or null if no label is found.
 
 */

import React from 'react';
import axis from 'axis.js';
import { FormattedMessage } from 'i18n';
import OverflowCounter from 'components/inputs/selectComponents/OverflowCounter';

const getSearchSummarySelectedDestinationsLabel = (selectedDestinations) => {
  if (selectedDestinations && selectedDestinations.length > 0 && selectedDestinations[0].label) {
    const overflowCounter = selectedDestinations.slice(1).length;
    let label;
    if (axis.isString(selectedDestinations[0].label)) {
      label = selectedDestinations[0].label.slice(0, selectedDestinations[0].label.indexOf('-'));
    } else {
      label = <FormattedMessage {...selectedDestinations[0].label} />;
    }
    return (
      <>
        {label}
        {overflowCounter > 0 && <OverflowCounter overflowCounter={overflowCounter} styles={{ marginLeft: '5px' }} />}
      </>
    );
  }
  return null;
};

export default getSearchSummarySelectedDestinationsLabel;

export default function getTotalPrice(paymentOptions, config = {}, selectedPaymentOption) {
  if (!paymentOptions || (paymentOptions && !paymentOptions.finalPrice)) {
    return NaN;
  }

  const { type = 'total' } = config;

  if (selectedPaymentOption && selectedPaymentOption.serviceFee) {
    return paymentOptions.finalPrice[type].amount + selectedPaymentOption.serviceFee;
  }

  return paymentOptions.finalPrice[type].amount;
}

import React from 'react';
import styled from 'styled-components';
import SelectCommon from './SelectCommonComponent';

function SelectCommonFrontpageComponent({ className, ...props }) {
  return <Root className={className} {...props} />;
}

export default SelectCommonFrontpageComponent;

const Root = styled(SelectCommon)`
  .styled-select {
    &__control {
      min-height: 41px;
      border-width: 0 1px 1px 0;
    }

    &__placeholder {
      margin: 0;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    &__value-container {
      padding: 4px 0px 4px 20px;

      @media (max-width: 768px) {
        padding: 4px 0px 4px 0px;
        height: 100%;
      }
    }

    &__single-value {
      margin: 0;
    }

    &__multi-value {
      &__remove {
        margin-right: 12px;
      }
    }

    &__input {
      padding-top: 12px;
      > div {
        font-size: 19px !important;
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StyledButtonPrimary } from './ButtonPrimaryComponent';
import { mediaQueries } from 'styles/utils/mediaQueries';

const propTypes = {
  onClick: PropTypes.func,
};

function ButtonHeaderComponent({ onClick, children, isAirBaltic, styles = {} }) {
  return (
    <Root onClick={onClick} isAirBaltic={isAirBaltic} styles={styles}>
      {children}
    </Root>
  );
}

ButtonHeaderComponent.propTypes = propTypes;

export default ButtonHeaderComponent;

const Root = styled(StyledButtonPrimary)`
  width: 134px;
  height: 48px;
  border-radius: 0;
  box-shadow: none;
  ${({ styles }) => styles};

  &:hover {
    transform: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.43);
  }

  &:active {
    transition: all 50ms ease-in;
    transform: none;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.43);
  }

  ${mediaQueries.fromAndBelow.desktop`
    width: 100%;
    padding: 6px;
    border-radius: 8px;
    min-height: 46px;
  `}
`;

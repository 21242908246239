import React from 'react';
import MenuLink from './components/menuLink/MenuLinkComponent';

const defaultProps = {
  items: [],
  config: {},
};

function MenuListComponent({ config, ...props }) {
  const { vertical = false } = config;

  if (vertical) {
    return <MenuListVertical {...props} />;
  }

  return <MenuListHorizontal {...props} />;
}

MenuListComponent.defaultProps = defaultProps;

export default MenuListComponent;

function MenuListVertical({ items, onClick }) {
  return (
    <ul>
      {items.map((item) => (
        <li key={item.menuOrder}>
          <MenuLink data={item} onClick={onClick} />
        </li>
      ))}
    </ul>
  );
}

function MenuListHorizontal({ items, onClick }) {
  return (
    <div>
      {items.map((item) => (
        <MenuLink key={item.menuOrder} data={item} onClick={onClick} />
      ))}
    </div>
  );
}

import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function requestTransfers(fetching) {
  return {
    type: types.REQUEST_TRANSFERS,
    payload: {
      fetching,
    },
  };
}

export function setTransfers(transfers) {
  return {
    type: types.SET_TRANSFERS,
    payload: {
      transfers,
    },
  };
}

export function setCurrentTokens(currentTokens) {
  return {
    type: types.SET_CURRENT_TOKENS,
    payload: {
      currentTokens,
    },
  };
}

export function setValidity(validity) {
  return {
    type: types.SET_VALIDITY,
    payload: {
      validity,
    },
  };
}

export function resetTransfers() {
  return {
    type: types.RESET_TRANSFERS,
  };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import getLogoContainer from './getLogoContainer';
import getLogoLink from './getLogoLink';
import { FRONTPAGE as ROUTE_KEY_FRONTPAGE } from 'constants/routesKeys';
import { getRoutePathByRouteKey } from 'state/routes/operations';
import websiteIds from 'constants/website.config';

class LogoMediaWrap extends Component {
  render() {
    const pathToFrontpage = this.props.getRoutePathByRouteKey(ROUTE_KEY_FRONTPAGE);

    return <Logo {...this.props} pathToFrontpage={pathToFrontpage} />;
  }
}

function mapStateToProps(state) {
  return {
    whitelabel: state.settings.value.whitelabel,
    checkoutOnlyWhitelabel: state.settings.value.checkoutOnlyWhitelabel,
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    websiteId: state.settings.value.websiteId,
    isHillmanTravel: [
      websiteIds['Hillman Travel EU'].ID,
      websiteIds['Hillman Travel NL'].ID,
      websiteIds['BE Hillman Travel FR'].ID,
      websiteIds['BE Hillman Travel NL'].ID,
      websiteIds['Hillman Travel FR'].ID,
    ].includes(state.settings.value.websiteId),
    isHotelPage: state.currentPageComponent.isHotelPage,
    isCheckoutPage: state.currentPageComponent.isCheckoutPage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getRoutePathByRouteKey(routeKey) {
      return dispatch(getRoutePathByRouteKey(routeKey));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoMediaWrap);

function Logo({
  config,
  isFrontpage,
  mobile,
  whitelabel,
  pathToFrontpage,
  checkoutOnlyWhitelabel,
  airlineWhitelabel,
  websiteId,
  isHillmanTravel,
  customSettings,
}) {
  const { container, images = [] } = config;
  const Container = getLogoContainer(airlineWhitelabel, isHillmanTravel);

  return (
    <Container
      airlineWhitelabel={airlineWhitelabel}
      {...container}
      websiteId={websiteId}
      isHillmanTravel={isHillmanTravel}
    >
      {getLogoLink(
        airlineWhitelabel,
        images,
        isFrontpage,
        mobile,
        whitelabel,
        pathToFrontpage,
        checkoutOnlyWhitelabel,
        websiteId,
        customSettings
      )}
    </Container>
  );
}

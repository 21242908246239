import * as types from './types';

export function setState(state) {
  return {
    type: types.SET_STATE,
    payload: {
      state,
    },
  };
}

export function setFetchingPayment(fetchingPayment) {
  return {
    type: types.SET_FETCHING_PAYMENT,
    payload: {
      fetchingPayment,
    },
  };
}

export function setPayment(payment) {
  return {
    type: types.SET_PAYMENT,
    payload: {
      payment,
    },
  };
}

export function resetPaymentNetsEasy() {
  return {
    type: types.RESET_PAYMENT_NETS_EASY,
  };
}

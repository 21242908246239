import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import SearchResultsPriceBarsComponent from './SearchResultsPriceBarsComponent';
import { CLICKED_PRICE_BARS } from 'managers/gtmEventTracking/constants/eventsNames';
import calculateAndUpdateSelectedDates from 'state/selectDates/operations/calculateAndUpdateSelectedDates';
import { filterSearchResults } from 'state/searchResults/actions';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import fetchPriceBarsPaggination from 'state/priceBars/operations/fetchPriceBarsPaggination';
import * as fetchingStatus from 'constants/status/fetchingStatus';

function isPagginationLeftEnabled(firstPriceBarDate) {
  const firstDate = moment(firstPriceBarDate, 'YYYY-MM-DD');
  const currentDate = moment().format('YYYY-MM-DD');
  const diffInDays = firstDate.diff(currentDate, 'days');

  return diffInDays > 1;
}

export default function SearchResultsPriceBars() {
  const scrollLeft = useRef(0);
  const pagginationLeftEnabled = useRef(false);

  const groupsContainerRef = useRef(null);
  const selectedBarRef = useRef(null);

  const [pagginationRequestInProgress, setPagginationRequestInProgress] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(null);

  const dispatch = useDispatch();

  const { result: priceBars, minMax, rolledUp, displayPriceBars, status } = useSelector((state) => state.priceBars);
  const dates = useSelector((state) => state.selectDates.dates);

  const airlineWhiteLabel = useSelector((state) => state.settings.value.airlineWhitelabel);

  useEffect(() => {
    if (priceBars && priceBars.length > 0) {
      pagginationLeftEnabled.current = isPagginationLeftEnabled(priceBars[0].items[0].date);
    }
  }, [priceBars]);

  useEffect(() => {
    const refGroups = groupsContainerRef.current;

    function handleHorizontalScroll(event) {
      refGroups.scrollLeft -= event.wheelDelta;
      event.preventDefault();
    }
    //On first mount scroll into view the selected

    if (dates.from) {
      const preselectedItem = document.querySelector(`[data-date="${dates.from.format('YYYY-MM-DD')}"]`);

      if (preselectedItem) {
        scrollIntoView(preselectedItem);
      }
    }

    if (refGroups !== null) {
      window.addWheelListener(refGroups, handleHorizontalScroll);
    }

    return () => {
      if (refGroups !== null) {
        window.removeEventListener('wheel', handleHorizontalScroll);
      }
    };

    // eslint-disable-next-line
  }, []);

  function handleOnScrollGroupsContainer() {
    const threshold = 130;
    const scrollPosition = groupsContainerRef.current.clientWidth + groupsContainerRef.current.scrollLeft;
    const distanceToScrollEnd = groupsContainerRef.current.scrollWidth - scrollPosition;
    const isScrollRight = scrollLeft.current < groupsContainerRef.current.scrollLeft;

    if (groupsContainerRef.current.scrollLeft <= 100) {
      setScrollPosition('left');
    } else if (
      groupsContainerRef.current.scrollLeft + groupsContainerRef.current.clientWidth >=
      groupsContainerRef.current.scrollWidth
    ) {
      setScrollPosition('right');
    } else {
      setScrollPosition(null);
    }

    if (!pagginationRequestInProgress) {
      if (isScrollRight && distanceToScrollEnd < threshold) {
        setPagginationRequestInProgress(true);
        dispatch(fetchPriceBarsPaggination(!isScrollRight)).then(() => {
          setPagginationRequestInProgress(false);
        });
      } else if (!isScrollRight && pagginationLeftEnabled && groupsContainerRef.current.scrollLeft < threshold) {
        setPagginationRequestInProgress(true);

        dispatch(fetchPriceBarsPaggination(!isScrollRight)).then((response) => {
          setPagginationRequestInProgress(false);
          if (response && groupsContainerRef.current) {
            groupsContainerRef.current.scrollLeft += response.length * 20;
          }
        });
      }
    }

    scrollLeft.current = groupsContainerRef.current.scrollLeft;
  }

  function scrollIntoView(selectedItem) {
    if (groupsContainerRef.current && selectedItem) {
      const container = groupsContainerRef.current;

      // Get the scrollable width of the container
      const containerViewWidth = container.clientWidth;

      // Get the position and width of the selected item
      const itemOffsetLeft = selectedItem.offsetLeft;
      const itemWidth = selectedItem.clientWidth;

      // Calculate the scroll position to center the selected item
      const scrollPosition = itemOffsetLeft - containerViewWidth / 2 + itemWidth / 2;

      // Scroll the container to the calculated position
      container.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  }

  function handleOnClickPriceBar(event) {
    const date = event.currentTarget.dataset.date;
    const numberOfDays = event.currentTarget.dataset.numberOfDays;
    const selectedItem = event.target;

    //Maybe we can make it like contants in the future if we have more like this
    const typeOfAction = 'priceBarsAction';

    scrollIntoView(selectedItem);

    batch(() => {
      dispatch(calculateAndUpdateSelectedDates(date, numberOfDays));
      dispatch(filterSearchResults(typeOfAction));
      dispatch(executeTrackingEvent(CLICKED_PRICE_BARS, { date, numberOfDays }));
    });
  }

  function handleOnScroll(type) {
    if (type === 'left' && groupsContainerRef.current) {
      groupsContainerRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }

    if (type === 'right' && groupsContainerRef.current) {
      groupsContainerRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  }

  if (!process.browser) {
    return null;
  }

  if (priceBars && priceBars.length === 0) {
    return null;
  }

  const showLoaderLeft = pagginationRequestInProgress && scrollLeft.current < 130;
  const showLoaderRight = pagginationRequestInProgress && scrollLeft.current > 130;
  const istEndLeftScroll = groupsContainerRef.current && groupsContainerRef.current.scrollLeft < 100;

  return (
    <SearchResultsPriceBarsComponent
      ref={groupsContainerRef}
      priceBars={priceBars}
      minMax={minMax}
      rolledUp={rolledUp}
      selectedBarRef={selectedBarRef}
      onClickPriceBar={handleOnClickPriceBar}
      onScrollGroupsContainer={handleOnScrollGroupsContainer}
      showLoaderLeft={showLoaderLeft}
      showLoaderRight={showLoaderRight}
      airlineWhitelabel={airlineWhiteLabel}
      displayPriceBars={displayPriceBars}
      handleOnScroll={handleOnScroll}
      isAtEndRightScroll={scrollPosition === 'right'}
      isAtEndLeftScroll={scrollPosition === 'left' || istEndLeftScroll}
      isInProgress={status === fetchingStatus.IN_PROGRESS}
      dates={dates}
    />
  );
}

import styled from 'styled-components';
import React from 'react';

/**
 *
 * @param {string} type 'vertical' | 'horizontal' // By default is vertical
 * @returns
 */
export default function Divider({ type = 'vertical', ...props }) {
  return <CustomDivider type={type} {...props} />;
}

const CustomDivider = styled('div')(({ theme, type }) => ({
  height: type === 'vertical' ? '75%' : '1px',
  width: type === 'vertical' ? '2px' : '100%',
  margin: type === 'vertical' ? '4px 0' : '0 4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.colors.background.greyV3,
}));

import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import axis from 'axis.js';
import { FormattedMessage } from 'i18n';
import { ALL_FOR_COUNTRY } from 'constants/selectValues';
import { mediaQueries } from 'styles/utils/mediaQueries';
import CloseButtonComponent from 'components/buttons/closeButton/CloseButtonComponent';
import { useSelector } from 'react-redux';
import truncate from 'styles/utils/truncate';

const propTypes = {
  data: PropTypes.shape({
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        defaultMessage: PropTypes.string.isRequired,
        values: PropTypes.object,
      }),
    ]).isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};

function OptionComponentDestination(props) {
  const { customSettings } = useSelector((state) => state.settings.value);
  const isHolidayPirates = customSettings && customSettings.isHolidayPirates;

  // To be able to filter the options by aliases we need to include them in the label.
  // Then we override the react-select Option component to display only the city.name.
  const label = axis.isString(props.data.label) ? (
    props.data.label.slice(0, props.data.label.indexOf('-'))
  ) : (
    <FormattedMessage {...props.data.label} />
  );

  // eslint-disable-next-line no-unused-vars
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };

  return (
    <Root isHolidayPirates={isHolidayPirates}>
      <components.Option {...newProps}>
        <Destination>
          <DestinationIcon
            icon={`${props.data && props.data.value.includes(ALL_FOR_COUNTRY) ? 'flag-alt' : 'map-marker-alt'}`}
          />
          <Label isSelected={props.isSelected}>{label}</Label>
        </Destination>
        {props.isSelected && <CloseButtonComponent styles={{ padding: 0, opacity: 0.5 }} />}
      </components.Option>
    </Root>
  );
}

OptionComponentDestination.propTypes = propTypes;

export default OptionComponentDestination;

const Label = styled.div`
  ${truncate()}
  max-width: ${({ isSelected }) => (isSelected ? '100px' : '150px')};
  padding: 2px 0px;

  ${mediaQueries.fromAndBelow.tablet`
    max-width:100%;
  `}
`;

const Root = styled.div`
  .styled-select__option {
    padding: 6px 12px;
    background-color: initial;
    border-radius: initial;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.text.darkGrey};

    &--is-disabled {
      color: ${({ theme }) => theme.colors.text.disabled};
      cursor: default;
    }

    &--is-selected {
      border-radius: 8px;
      background-color: ${({ theme }) => theme.colors.secondary.light};
      color: ${({ theme, isHolidayPirates }) =>
        isHolidayPirates ? theme.colors.text.darkGrey : theme.colors.text.white};

      ${mediaQueries.fromAndBelow.tablet`
        background-color: transparent;
      `}
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.primary.default};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.primary.default};
    }
  }
`;

const Destination = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  ${mediaQueries.fromAndBelow.tablet`
    line-height:1.2;
  `};
`;

const DestinationIcon = styled(FontAwesomeIcon)`
  margin-right: 6px;
  font-size: 14px;
`;

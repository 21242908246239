import getAvailableOrigins from '../selectors/getAvailableOrigins';
import { setAvailableOrigins } from '../actions';

export default function updateAvailableOrigins(selectedDestinationsCodes) {
  return (dispatch, getState) => {
    const { settings, selectOrigins } = getState();
    const { availableOriginsMap } = selectOrigins;
    const availableOrigins = getAvailableOrigins(availableOriginsMap, selectedDestinationsCodes);
    const forceSingleValue = !settings.value.survivalMode
      ? selectedDestinationsCodes.length > 0 && availableOriginsMap.length === 0
      : true;

    dispatch(setAvailableOrigins(availableOrigins, forceSingleValue));
  };
}
